import React, { useEffect, useState } from 'react';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import ShouldRenderInput from '@app/components/common/ModulesProfileConfig/ShouldRenderInput';
import { Col, Form, Row, Space } from 'antd';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { CardInfoProperty, CardInfoTitle } from '../../Card/CardInfo/CardDeviceInformation.styles';
4;
import * as S from '../ModulesProfileConfig.styles';
import { Input } from '../../inputs/Input/Input';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import TooltipInfoIcon from '@app/components/common/TooltipInfoIcon/TooltipInfoIcon';
import * as ST from '@app/components/common/ModulesProfileConfig/PerfilEnvio/PerfilEnvio.styles';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { googlecode } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeStringExample } from '@app/components/common/ModulesProfileConfig/PerfilEnvio/PerfilEnvioUtils';
import { Panel } from '@app/components/common/Collapse/Collapse';
import { decode } from '@app/components/common/ModulesProfileConfig/PerfilEnvio/encodeDecode';
import { ocasionalParameterEmbarcado } from './Modal/ModalParametersPerfilEnvio';
import { PropertyModel } from '@app/domain/property/propertyModel';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { useAppSelector } from '@app/hooks/reduxHooks';

interface PerfilEnvioProps {
  properties: DeviceProfileProperty[];
}

const PerfilEnvioView: React.FC<PerfilEnvioProps> = ({ properties }) => {
  const props = properties as PropertyModel[];
  const { parameters } = useAppSelector((state) => state.sendParameters);
  const [selectedParams, setSelectedParams] = useState<number[]>([]);

  const getPropertyValue = (idEmbarcado: DevicePropertyType) =>
    properties?.find((p) => p.idEmbarcado == idEmbarcado)?.valorPropriedade ?? '';

  const selectedParamsObrigatorios = parameters.filter(
    (p) => p.isObrigatorio && selectedParams.includes(p.identificadorEmbarcado),
  );
  const selectedParamsOpcionais = parameters.filter(
    (p) => !p.isObrigatorio && selectedParams.includes(p.identificadorEmbarcado),
  );

  useEffect(() => {
    setSelectedParams(decode(getPropertyValue(DevicePropertyType.ParametrosEnvio)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties]);

  return (
    <Form layout="vertical">
      <Row>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.IntervaloEnvioCarroLigado} properties={props}>
          <Col xs={24} xl={12} xxl={8}>
            <Col xs={24} md={12}>
              <CardInfoProperty>Intervalo ignição ligada (s)</CardInfoProperty>
            </Col>
            <Col span={8}>
              <Input
                key={3}
                type="text"
                className="ant-input"
                placeholder="Digite o valor"
                value={getPropertyValue(DevicePropertyType.IntervaloEnvioCarroLigado)}
                disabled
                style={{ maxWidth: '7rem' }}
                suffix="s"
              />
            </Col>
          </Col>
        </ShouldRenderInput>

        <ShouldRenderInput idEmbarcado={DevicePropertyType.IntervaloEnvioBateriaExterna} properties={props}>
          <Col xs={24} xl={12} xxl={8}>
            <Col xs={24} md={12}>
              <CardInfoProperty>Intervalo bateria externa (s)</CardInfoProperty>
            </Col>
            <Col span={8}>
              <Input
                key={3}
                type="text"
                className="ant-input"
                placeholder="Digite o valor"
                value={getPropertyValue(DevicePropertyType.IntervaloEnvioBateriaExterna)}
                disabled
                style={{ maxWidth: '7rem' }}
                suffix="s"
              />
            </Col>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.IntervaloEnvioBateriaInterna} properties={props}>
          <Col xs={24} xl={12} xxl={8}>
            <Col xs={24} md={12}>
              <CardInfoProperty>Intervalo bateria interna (s)</CardInfoProperty>
            </Col>
            <Col span={8}>
              <Input
                key={3}
                type="text"
                className="ant-input"
                placeholder="Digite o valor"
                value={getPropertyValue(DevicePropertyType.IntervaloEnvioBateriaInterna)}
                disabled
                style={{ maxWidth: '7rem' }}
                suffix="s"
              />
            </Col>
          </Col>
        </ShouldRenderInput>
      </Row>
      <Row gutter={[18, 18]} style={{ marginTop: '2rem' }}>
        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoEnvioDistancia} properties={props}>
                <Checkbox key={48} checked={getPropertyValue(DevicePropertyType.EventoEnvioDistancia) === '1'} disabled>
                  <S.CheckboxLabel>Envio por distância (m)</S.CheckboxLabel>
                  <S.InputObs>50 a 1000</S.InputObs>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={8}>
              <ShouldRenderInput idEmbarcado={DevicePropertyType.LimiteDistancia} properties={props}>
                {getPropertyValue(DevicePropertyType.EventoEnvioDistancia) === '1' && (
                  <Input
                    key={3}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={getPropertyValue(DevicePropertyType.LimiteDistancia)}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="m"
                  />
                )}
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>

        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoEnvioAngulo} properties={props}>
                <Checkbox key={50} checked={getPropertyValue(DevicePropertyType.EventoEnvioDistancia) === '1'} disabled>
                  <S.CheckboxLabel>Envio por ângulo (°)</S.CheckboxLabel>
                  <S.InputObs>10 a 179</S.InputObs>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={8}>
              <ShouldRenderInput idEmbarcado={DevicePropertyType.LimiteAngulo} properties={props}>
                {getPropertyValue(DevicePropertyType.EventoEnvioDistancia) === '1' && (
                  <Input
                    key={51}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={getPropertyValue(DevicePropertyType.LimiteAngulo)}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="°"
                  />
                )}
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.TipoEnvio} properties={props}>
          <BaseFormInputItem label="Tipo de envio">
            <RadioGroup key={68} value={getPropertyValue(DevicePropertyType.TipoEnvio)} disabled>
              <Space direction="vertical">
                <Radio value={'0'}>
                  JSON{' '}
                  <TooltipInfoIcon title={'Tipo de mensagem altamente estruturada. Alto consumo de dados por envio'} />
                </Radio>
                <Radio value={'1'}>
                  String <TooltipInfoIcon title={'Tipo de mensagem estruturada. Médio consumo de dados por envio.'} />
                </Radio>
                <Radio value={'2'}>
                  .Zip <TooltipInfoIcon title={'Tipo de mensagem não estruturada. Baixo consumo de dados por envio.'} />
                </Radio>
              </Space>
            </RadioGroup>
          </BaseFormInputItem>
          <ST.Collapse bordered={false} style={{ width: '100%', marginBottom: '1rem' }}>
            <ST.Panel key={'pre-visualization'} header={'Pré-visualização do envio da mensagem'}>
              <HelperNotification>Exemplo de como a mensagem será enviada ao dispositivo.</HelperNotification>
              <SyntaxHighlighter
                language="json"
                style={googlecode}
                customStyle={{
                  backgroundColor: 'transparent',
                }}
              >
                {codeStringExample[getPropertyValue(DevicePropertyType.TipoEnvio)] ?? ''}
              </SyntaxHighlighter>
            </ST.Panel>
          </ST.Collapse>
        </ShouldRenderInput>
      </Row>
      {getPropertyValue(DevicePropertyType.TipoEnvio) != '0' && (
        <S.Row style={{ margin: '1rem 0' }}>
          <Col xs={24}>
            <S.Collapse defaultActiveKey={'1'}>
              <Panel
                key="1"
                header={
                  <Row align={'middle'} justify={'space-between'}>
                    <Col>
                      <ST.CollapseHeaderName>Parâmetros de envio</ST.CollapseHeaderName>
                    </Col>
                  </Row>
                }
              >
                <S.Row>
                  <CardInfoTitle>Obrigatórios</CardInfoTitle>
                </S.Row>
                <S.Row style={{ columnGap: '0.8rem' }}>
                  <strong>{selectedParamsObrigatorios.length}</strong> parâmetros
                </S.Row>
                <S.Row style={{ margin: '0.9rem 0' }}>
                  {selectedParamsObrigatorios.map((param) => (
                    <Col key={param.identificadorEmbarcado} xs={24} md={4} lg={4} style={{ minHeight: '32px' }}>
                      {param.nome}
                    </Col>
                  ))}
                </S.Row>

                <S.Row>
                  <CardInfoTitle>Opcionais</CardInfoTitle>
                </S.Row>
                <S.Row>
                  <strong>
                    {
                      selectedParamsOpcionais.filter(
                        (p) => !ocasionalParameterEmbarcado.includes(p.identificadorEmbarcado),
                      ).length
                    }
                  </strong>{' '}
                  parâmetros
                </S.Row>
                <S.Row style={{ margin: '0.9rem 0' }}>
                  {selectedParamsOpcionais
                    .filter((p) => !ocasionalParameterEmbarcado.includes(p.identificadorEmbarcado))
                    .map((param) => (
                      <Col key={param.identificadorEmbarcado} xs={24} md={4} lg={4} style={{ minHeight: '32px' }}>
                        {param.nome}
                      </Col>
                    ))}
                </S.Row>
              </Panel>
            </S.Collapse>
          </Col>
        </S.Row>
      )}
    </Form>
  );
};

export default PerfilEnvioView;
