import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import ModuleViewComponent from '@app/pages/device-profile/components/ModuleViewComponent';
import { Col, Row } from 'antd';

interface IViewDeviceProfile {
  properties: DeviceProfileProperty[];
}

export const ViewDeviceProfile: React.FC<IViewDeviceProfile> = ({ properties }) => {
  return (
    <>
      {properties.length > 0 ? (
        <>
          <h1>Informações dos parâmetros do perfil</h1>
          <BaseForm layout="vertical" style={{ width: '100%' }}>
            <ModuleViewComponent properties={properties} />
          </BaseForm>
        </>
      ) : (
        <Row justify="center" align="middle">
          <Col xs={24} md={24} style={{ color: '#707070', fontSize: '1rem', fontWeight: 700, textAlign: 'center' }}>
            Nenhum perfil de configuração associado
          </Col>
        </Row>
      )}
    </>
  );
};
