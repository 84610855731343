import React, { useCallback, useEffect, useState } from 'react';
import { Description } from '@app/components/common/Description/Description';
import { ConfigUpdateModel } from '@app/domain/deviceConfigUpdate/deviceConfigUpdate';
import { Col, Row } from 'antd';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '@app/components/common/selects/Select/Select';
import { Button } from '@app/components/common/buttons/Button/Button';
import { notificationController } from '@app/controllers/notificationController';
import { ModuleModel } from '@app/domain/module/moduleModel';
import { CardTitle } from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import IDeviceConfigUpdateService, { DeviceConfigUpdateService } from '@app/services/deviceConfigUpdateService';
import ModulesComponent from '../Modules/ModulesComponent';
import { readUser } from '@app/services/localStorage.service';
import { useParams } from 'react-router-dom';
import * as S from './create-step-two.styles';
import { DeviceProfileProperty, VersionProperties } from '@app/domain/deviceProfile/deviceProfileModel';
import IFirmwareGroupService, { FirmwareGroupService } from '@app/services/firmwareGroupService';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { deviceValidateRede, formatPropertiesValues } from '@app/utils/device';

interface ICreateStepTwo {
  deviceConfigUpdate: ConfigUpdateModel;
  setDeviceConfigUpdate: React.Dispatch<React.SetStateAction<ConfigUpdateModel>>;
}

const firmwareGroupService: IFirmwareGroupService = new FirmwareGroupService();
const configUpdateService: IDeviceConfigUpdateService = new DeviceConfigUpdateService();

export const CreateDeviceConfigUpdateStepTwo: React.FC<ICreateStepTwo> = ({
  deviceConfigUpdate,
  setDeviceConfigUpdate,
}) => {
  const user = readUser();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [module, setModule] = useState<ModuleModel>();
  const [modules, setModules] = useState<ModuleModel[]>([]);
  const [properties, setProperties] = useState<DeviceProfileProperty[]>([]);
  const [versionProperties, setVersionProperties] = useState<VersionProperties>();

  const handleSaveConfigUpdate = async (): Promise<number> => {
    try {
      const response = await configUpdateService.api.post('', {
        ...deviceConfigUpdate,
        usuario: user?.userName ?? '',
        dataHora: new Date(),
        idCliente: user?.idCliente ?? 1,
      });

      if (response.status != 200) throw Error(response.data);

      const idConfigUpdate = response.data as number;

      setDeviceConfigUpdate({
        ...deviceConfigUpdate,
        id: idConfigUpdate,
      });

      return idConfigUpdate;
    } catch (error) {
      throw error;
    }
  };

  const handleSendProperties = async () => {
    if (!module) return;

    setLoading(true);

    let config = {};
    let endpoint = '';
    const idConfigUpdate = deviceConfigUpdate.id ?? (await handleSaveConfigUpdate());

    if (module.nome == 'Cercas') {
      const idGroupFences = properties.find((p) => p.idEmbarcado == DevicePropertyType.IdGrupoCercas)?.valorPropriedade;

      if (!idGroupFences) {
        notificationController.error({ message: 'Erro!', description: `Necessário selecionar um grupo de cercas` });
        setLoading(false);
        return;
      }

      endpoint = `${idConfigUpdate}/grupo-cercas`;
      config = {
        idGrupoCercas: Number(idGroupFences),
        dispositivos: deviceConfigUpdate.dispositivos.map((d) => d.id),
      };
    } else {
      endpoint = `${idConfigUpdate}/propriedades`;
      config = {
        propriedades: properties
          .map((m) => (m.valorPropriedade != null && m.valorPropriedade != undefined ? m : undefined))
          .filter((m) => m),
        dispositivos: deviceConfigUpdate.dispositivos,
      };
    }

    configUpdateService.api
      .post(endpoint, config)
      .then(() => {
        setModule({} as ModuleModel);
        notificationController.success({
          message: `Envio das configurações de "${module.nome}" realizado com sucesso!`,
        });
      })
      .catch((error) =>
        notificationController.error({
          message: 'Erro!',
          description: `Houve um erro ao enviar as propriedades. ${error}`,
        }),
      )
      .finally(() => setLoading(false));
  };
  const handleChangeDeviceProfileProperties = (props: DeviceProfileProperty[], toRemove?: boolean) => {
    if (toRemove) {
      setProperties(properties?.filter((dp) => !props.find((p) => p.idPropriedade == dp.idPropriedade)));
      return;
    }

    setProperties(properties?.filter((dp) => !props.find((p) => p.idPropriedade == dp.idPropriedade)).concat(props));
  };
  const getPropertyValue = (idEmbarcado: DevicePropertyType) =>
    properties?.find((p) => p.idEmbarcado == idEmbarcado)?.valorPropriedade ?? '';

  useEffect(() => {
    if (deviceConfigUpdate.idGrupoFirmware)
      firmwareGroupService
        .getPropertiesByIdGroupFirmware(deviceConfigUpdate.idGrupoFirmware)
        .then((res) => {
          setVersionProperties(res);
          setModules(res.modulos);
        })
        .catch((error) => notificationController.error(error));
  }, [deviceConfigUpdate.idGrupoFirmware]);

  useEffect(() => {
    if (module?.id) {
      setProperties(formatPropertiesValues(versionProperties?.modulos.filter((m) => m.id == module.id) ?? []));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module]);

  const handleDisableSendButton = useCallback((): boolean => {
    return module?.nome === 'Rede' ? !deviceValidateRede(properties) : false;
  }, [properties, module]);

  return (
    <>
      <S.ConfigUpdateCardStepTwoWrapper>
        <S.ConfigUpdateCardStepTwoForm layout="vertical">
          <S.ConfigUpdateCardStepTwoRow>
            <S.ConfigUpdateCardStepTwoCol span={24}>
              <Description
                title={id ? 'Envio de Parâmetros' : 'Etapa 2 de 2'}
                subtitle="Após preencher os campos, clique em finalizar."
              />
              <Row justify={'start'} style={{ marginBottom: '1rem' }}>
                <CardTitle>Configurações</CardTitle>
              </Row>
              <Row gutter={6}>
                <Col xs={24}>
                  <BaseFormInputItem label="Tipo de configuração" errorText="Campo obrigatório">
                    <Select
                      showArrow
                      showSearch
                      allowClear
                      placeholder="Selecione o tipo"
                      value={module?.id}
                      onChange={(value) => setModule(modules.find((e) => e.id == value))}
                      options={modules
                        .map((c) => ({
                          value: c.id,
                          label: `${c.nomeExibicao}`,
                        }))
                        .filter((c) => c.label != 'CAN')}
                      filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24}>
                  {versionProperties?.modulos
                    .filter((m) => m.id == module?.id)
                    .map((m) => (
                      <ModulesComponent
                        key={m.id}
                        module={m}
                        idModel={deviceConfigUpdate.idModelo}
                        getPropertyValue={getPropertyValue}
                        handleChangeDeviceProfileProperties={handleChangeDeviceProfileProperties}
                      />
                    ))}
                </Col>
              </Row>
            </S.ConfigUpdateCardStepTwoCol>
          </S.ConfigUpdateCardStepTwoRow>
        </S.ConfigUpdateCardStepTwoForm>
      </S.ConfigUpdateCardStepTwoWrapper>
      <Row align={'bottom'} justify={'end'}>
        <Col xs={24} sm={12} md={6}>
          <Button
            block
            type="primary"
            onClick={handleSendProperties}
            loading={loading}
            disabled={handleDisableSendButton()}
          >
            Enviar
          </Button>
        </Col>
      </Row>
    </>
  );
};
