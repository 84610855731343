import { Panel } from '@app/components/common/Collapse/Collapse';
import { CollapseStep } from '@app/components/common/CollapseStep/CollapseStep';
import { CollapseStepHeader } from '@app/components/common/CollapseStep/CollapseStepHeader';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { Select } from '@app/components/common/selects/Select/Select';
import { UserType } from '@app/constants/enums/userType';
import { notificationController } from '@app/controllers/notificationController';
import { ClientModel } from '@app/domain/client/clientModel';
import { FenceGroupModel } from '@app/domain/fence/fenceGroupModel';
import { ModelDeviceModel } from '@app/domain/modelDevice/modelDeviceModel';
import IClientService, { ClientService } from '@app/services/clientService';
import { readUser } from '@app/services/localStorage.service';
import IModelDeviceService, { ModelDeviceService } from '@app/services/modelDeviceService';
import { Col, Row } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';

const modelService: IModelDeviceService = new ModelDeviceService();
const clientService: IClientService = new ClientService();

interface CreateFenceStepOneProps {
  groupFence?: FenceGroupModel;
  setGroupFence: React.Dispatch<React.SetStateAction<FenceGroupModel | undefined>>;
}

export const CreateFenceStepOne: FC<CreateFenceStepOneProps> = ({ groupFence, setGroupFence }) => {
  const user = readUser();
  const [stepFinished, setStepFinished] = useState<boolean>(false);
  const [modelsLoading, setModelsLoading] = useState<boolean>(false);
  const [clientsLoading, setClientsLoading] = useState<boolean>(false);
  const [models, setModels] = useState<ModelDeviceModel[]>([]);
  const [clients, setClients] = useState<ClientModel[]>([]);

  const handleOnChangeModel = (id?: number) => {
    const model = models.find((m) => m.id == id);
    setGroupFence({ ...groupFence, idModelo: model?.id, modelo: model });
  };

  const fetchModels = useCallback(() => {
    setModelsLoading(true);
    modelService
      .getArray('')
      .then((res) => setModels(res.filter((m) => m.permiteCerca)))
      .catch(() =>
        notificationController.error({ message: 'Erro!', description: 'Não foi possível buscar os modelos.' }),
      )
      .finally(() => setModelsLoading(false));
  }, []);
  const fetchClients = useCallback(() => {
    setClientsLoading(true);
    clientService
      .getArray('')
      .then((res) => setClients(res))
      .catch(() =>
        notificationController.error({ message: 'Erro!', description: 'Não foi possível buscar os modelos.' }),
      )
      .finally(() => setClientsLoading(false));
  }, []);

  useEffect(() => {
    fetchModels();
    user?.type != UserType.Client ? fetchClients() : setGroupFence({ ...groupFence, idCliente: user.idCliente });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStepFinished(!!groupFence?.idCliente && !!groupFence.idModelo && !!groupFence.nome);
  }, [groupFence]);

  return (
    <CollapseStep defaultActiveKey={'1'}>
      <Panel key="1" header={<CollapseStepHeader title="Grupo" key="1" finished={stepFinished} />}>
        <BaseForm layout="vertical">
          <Row gutter={16}>
            {user?.type != UserType.Client && (
              <Col xs={24} md={8}>
                <BaseFormInputItem label="Cliente">
                  <Select
                    showArrow
                    showSearch
                    loading={clientsLoading}
                    disabled={clientsLoading}
                    placement="bottomLeft"
                    dropdownAlign={{ overflow: { adjustX: false, adjustY: false } }}
                    placeholder="Selecione o cliente"
                    value={groupFence?.idCliente}
                    onChange={(value) => setGroupFence({ ...groupFence, idCliente: value as number })}
                    options={clients.map((m) => ({ label: m.razaoSocial, value: m.id }))}
                    filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  />
                </BaseFormInputItem>
              </Col>
            )}
            <Col xs={24} md={8}>
              <BaseFormInputItem label="Modelo">
                <Select
                  showArrow
                  showSearch
                  disabled={modelsLoading}
                  loading={modelsLoading}
                  placeholder="Selecione o modelo"
                  value={groupFence?.idModelo}
                  onChange={(value) => handleOnChangeModel(value as number)}
                  options={models.map((m) => ({ label: m.nome, value: m.id }))}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </BaseFormInputItem>
            </Col>
            <Col xs={24} md={8}>
              <BaseFormInputItem label="Grupo">
                <Input
                  placeholder="Digite o nome do grupo"
                  maxLength={50}
                  value={groupFence?.nome}
                  onChange={(event) => setGroupFence((prevState) => ({ ...prevState, nome: event.target.value }))}
                  onBlur={() => setGroupFence((prevState) => ({ ...prevState, nome: prevState?.nome?.trim() }))}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
        </BaseForm>
      </Panel>
    </CollapseStep>
  );
};
