import { IconMore } from '@app/assets/slump-icons';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Modal } from '@app/components/common/Modal/Modal';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import { Tag } from '@app/components/common/Tag/Tag';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import Dashboard from '@app/components/dashboard/Dashboard';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeader } from '@app/store/slices/headerSlice';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import { useNavigate } from 'react-router-dom';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { FactoryDeviceService } from '@app/services/factoryDeviceService';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { hasAccessByRole, hasAccessByRoles } from '@app/controllers/accessController';
import { UserType } from '@app/constants/enums/userType';
import * as S from './create.styles';
import moment from 'moment';
import SendCommandToDeviceModal from '@app/components/device/SendCommandToDeviceModal';

const deviceService = new FactoryDeviceService();

export const FactoryDeviceDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const [selectedDevice, setSelectedDevice] = useState<FactoryDeviceModel>({} as FactoryDeviceModel);
  const [factoryDevices, setFactoryDevices] = useState<FactoryDeviceModel[]>();
  const [loading, setLoading] = useState(false);
  const [modalSendCommandVisible, setModalSendCommandVisible] = useState(false);
  const [deviceId, setDeviceId] = useState<number>(0);
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
  const [searchDeviceFiltered, setSearchDeviceFiltered] = useState<FactoryDeviceModel[]>();
  const [searchValue, setSearchValue] = useState<string>('');
  const navigate = useNavigate();
  const [factoryDevicePage, setFactoryDevicePage] = useState<number>(1);

  const handleDeleteClick = (device: FactoryDeviceModel) => {
    setModalDeleteVisible(true);
    setSelectedDevice(device);
  };
  const handleDeleteDevice = () => {
    setLoading(true);

    if (!selectedDevice.id) {
      notificationController.error({ message: 'Dispositivo sem todas informações necessárias' });
      setLoading(false);
      return;
    }

    deviceService
      .delete(`${selectedDevice.id}`)
      .then(() => {
        notificationController.success({ message: `Dispositivo deletado com sucesso!` });
        setFactoryDevices(factoryDevices?.filter((d) => d.id !== selectedDevice.id));
        setModalDeleteVisible(false);
        setLoading(false);
      })
      .catch((error) => {
        setModalDeleteVisible(false);
        setLoading(false);
        notificationController.error(error);
      });
  };
  const handleNewDeviceClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/dispositivos-fabrica/cadastrar`);
  };
  const handleExportToExcel = () => {
    genericExportToExcel('dispositivos_fabrica', columns, searchDeviceFiltered ?? []);
  };
  const handleSearchFilter = (event: React.FormEvent<HTMLInputElement>) => {
    const inputValue = event.currentTarget.value.toLowerCase();
    setSearchValue(inputValue);
    localStorage.setItem('factoryDeviceSearch', inputValue);

    if (inputValue.length > 0) {
      setSearchDeviceFiltered(
        factoryDevices?.filter(
          (item) =>
            item.id?.toString().toLowerCase().includes(inputValue) ||
            item.numeroSerie?.toString().toLowerCase().includes(inputValue) ||
            item.modelo?.toLowerCase().includes(inputValue) ||
            item.imei?.toLowerCase().includes(inputValue) ||
            item.cliente?.toLowerCase().includes(inputValue) ||
            item.tipoEquipamento?.toLowerCase().includes(inputValue) ||
            item.versaoFirmware?.toLowerCase().includes(inputValue),
        ),
      );
    } else {
      setSearchDeviceFiltered(factoryDevices);
    }
  };

  const columns: ColumnsType<FactoryDeviceModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: true,
      sorter: (a, b) => (a.id ?? 0) - (b.id ?? 0),
      defaultSortOrder: 'descend',
      width: '88px',
    },
    {
      title: 'Equipamento',
      dataIndex: 'tipoEquipamento',
      showSorterTooltip: true,
      sorter: (a, b) => (a.tipoEquipamento ?? '').localeCompare(b.tipoEquipamento ?? ''),
      width: '140px',
    },
    {
      title: 'Modelo',
      dataIndex: 'modelo',
      showSorterTooltip: true,
      sorter: (a, b) => (a.modelo ?? '').localeCompare(b.modelo ?? ''),
      render: (modelo) => <div style={{ minWidth: '70px' }}>{modelo}</div>,
      width: '100px',
    },
    {
      title: 'SN',
      dataIndex: 'numeroSerie',
      showSorterTooltip: true,
      sorter: (a, b) => (a.numeroSerie ?? '').localeCompare(b.numeroSerie ?? ''),
      render: (numeroSerie) => <div style={{ minWidth: '175px' }}>{numeroSerie}</div>,
      width: '200px',
    },
    {
      title: 'Versão de firmware',
      dataIndex: 'versaoFirmware',
      showSorterTooltip: true,
      sorter: (a, b) => (a.versaoFirmware ?? '').localeCompare(b.versaoFirmware ?? ''),
      width: '192px',
    },
    {
      title: 'IMEI',
      dataIndex: 'imei',
      showSorterTooltip: true,
      sorter: (a, b) => (a.imei ?? '').localeCompare(b.imei ?? ''),
      width: '150px',
    },
    {
      title: 'Cliente',
      dataIndex: 'cliente',
      showSorterTooltip: true,
      width: '22%',
      sorter: (a, b) => (a.cliente ?? '').localeCompare(b.cliente ?? ''),
      render: (cliente) => (cliente ? <S.ClientTag>{cliente}</S.ClientTag> : <>-</>),
    },
    {
      title: 'Conexão',
      dataIndex: 'connectionState',
      showSorterTooltip: true,
      sorter: (a, b) => (a.connectionState ?? '').localeCompare(b.connectionState ?? ''),
      width: '120px',
      render: (connectionState: string) => {
        return (
          <>
            {connectionState === 'Connected' ? (
              <Tag color="#E9F4EE" style={{ color: '#083F18', width: '70px', textAlign: 'center' }}>
                Online
              </Tag>
            ) : (
              <Tag color="#FEE9EA" style={{ color: '#620E12', width: '70px', textAlign: 'center' }}>
                Offline
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: 'Última conexão',
      dataIndex: 'ultimaConexao',
      showSorterTooltip: true,
      width: '158px',
      sorter: (a, b) =>
        (a.ultimaConexao ? new Date(a.ultimaConexao).getTime() : 0) -
        (b.ultimaConexao ? new Date(b.ultimaConexao).getTime() : 0),
      render: (ultimaConexao: Date) => (ultimaConexao ? moment(ultimaConexao).format('DD/MM/YYYY HH:mm') + 'h' : '-'),
    },
    {
      title: '',
      dataIndex: 'id',
      width: '10px',
      sorter: (a, b) => (a?.id ? a.id : 0) - (b?.id ? b.id : 0),
      sortDirections: [],
      defaultSortOrder: 'descend',
      fixed: 'right',
      render: (_, device) => {
        return {
          props: {
            style: { background: 'white' },
          },
          children: (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
              {hasAccessByRoles([UserType.FactoryAdmin, UserType.Factory]) && (
                <DropdownTable
                  buttonText=""
                  iconD={
                    <div style={{ color: '#545454' }}>
                      <IconMore />
                    </div>
                  }
                  getPopupContainer={() => document.body}
                  trigger={['click']}
                  align={{ offset: [40, -10] }}
                  overlay={
                    <Menu>
                      <MenuItem key="view" onClick={() => navigate(`visualizar/${device.id}`)}>
                        <Button type="text">Visualizar dispositivo</Button>
                      </MenuItem>
                      {device.connectionState === 'Connected' && (
                        <MenuItem
                          key="view"
                          onClick={() => {
                            setDeviceId(device.id ?? 0);
                            const selectedDevice = factoryDevices?.find((d) => d.idModelo === device.idModelo);
                            setSelectedDevice(selectedDevice || ({} as FactoryDeviceModel));
                            setModalSendCommandVisible(true);
                          }}
                        >
                          <Button type="text">Enviar comando</Button>
                        </MenuItem>
                      )}
                      <MenuItem key="view" onClick={() => navigate(`/agendamento-versao/cadastrar/${device.id}`)}>
                        <Button type="text">Atualização de firmware</Button>
                      </MenuItem>
                      {hasAccessByRole(UserType.FactoryAdmin) && (
                        <MenuItem onClick={() => handleDeleteClick(device)}>
                          <Button type="text">Deletar</Button>
                        </MenuItem>
                      )}
                    </Menu>
                  }
                ></DropdownTable>
              )}
            </div>
          ),
        };
      },
    },
  ];

  const fetchDevices = useCallback(async () => {
    setLoading(true);

    const deviceResponse = await deviceService.getArray('');
    const savedSearch = localStorage.getItem('factoryDeviceSearch');
    const savedPage = localStorage.getItem('factoryDevicePage');

    if (savedSearch) {
      const inputValue = savedSearch.toLowerCase();
      setSearchValue(inputValue);
      setSearchDeviceFiltered(
        deviceResponse.filter(
          (item) =>
            item.id?.toString().toLowerCase().includes(inputValue) ||
            item.numeroSerie?.toString().toLowerCase().includes(inputValue) ||
            item.modelo?.toLowerCase().includes(inputValue) ||
            item.imei?.toLowerCase().includes(inputValue) ||
            item.cliente?.toLowerCase().includes(inputValue) ||
            item.tipoEquipamento?.toLowerCase().includes(inputValue) ||
            item.versaoFirmware?.toLowerCase().includes(inputValue),
        ),
      );
    } else {
      setSearchDeviceFiltered(deviceResponse);
    }

    if (savedPage) {
      setFactoryDevicePage(Number(savedPage));
    }

    setFactoryDevices(deviceResponse);
    setLoading(false);
  }, []);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setFactoryDevicePage(pagination.current || 1);
    localStorage.setItem('factoryDevicePage', (pagination.current || 1).toString());
  };

  useEffect(() => {
    dispatch(
      setHeader({
        title: 'Dispositivos na fábrica',
      }),
    );
    fetchDevices();
    const savedSearchValue = localStorage.getItem('factoryDeviceSearch');
    if (savedSearchValue) {
      setSearchValue(savedSearchValue);
      handleSearchFilter({ currentTarget: { value: savedSearchValue } } as React.FormEvent<HTMLInputElement>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!modalDeleteVisible) {
      setSelectedDevice({} as FactoryDeviceModel);
    }
  }, [modalDeleteVisible]);

  useEffect(() => {
    setSearchDeviceFiltered(factoryDevices);
  }, [factoryDevices]);

  useEffect(() => {
    if (searchValue.length > 0) {
      const filteredDevices = factoryDevices?.filter(
        (item) =>
          item.id?.toString().toLowerCase().includes(searchValue) ||
          item.numeroSerie?.toString().toLowerCase().includes(searchValue) ||
          item.perfil?.toLowerCase().includes(searchValue) ||
          item.imei?.toLowerCase().includes(searchValue) ||
          item.tipoEquipamento?.toLowerCase().includes(searchValue) ||
          item.modelo?.toLowerCase().includes(searchValue) ||
          item.apelido?.toLowerCase().includes(searchValue) ||
          item.grupo?.toLowerCase().includes(searchValue) ||
          item.versaoFirmware?.includes(searchValue),
      );

      setSearchDeviceFiltered(filteredDevices);
    } else {
      setSearchDeviceFiltered(factoryDevices);
    }
  }, [factoryDevices, searchValue]);

  return (
    <>
      <SendCommandToDeviceModal
        visible={modalSendCommandVisible}
        setVisible={setModalSendCommandVisible}
        deviceId={deviceId}
      />
      <Modal
        title="Deletar dispositivo"
        open={modalDeleteVisible}
        onOk={() => handleDeleteDevice()}
        onCancel={() => setModalDeleteVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <SpinnerSlump spinning={loading}>
          <p>Tem certeza que deseja deletar o dispositivo selecionado?</p>
        </SpinnerSlump>
      </Modal>
      <PageTitle>Dispositivos na fábrica</PageTitle>

      <Spinner spinning={loading}>
        <Dashboard
          title=""
          buttonText={hasAccessByRoles([UserType.FactoryAdmin, UserType.Factory]) ? 'Cadastrar novo dispositivo' : ''}
          handleButtonClick={handleNewDeviceClick}
          placeholderTextSearch="Pesquisar"
          handleSearchOnChange={handleSearchFilter}
          valueSearch={searchValue}
          table={
            <Table
              columns={columns}
              dataSource={searchDeviceFiltered}
              scroll={{ x: 'max-content' }}
              bordered
              pagination={{ current: factoryDevicePage, pageSize: 10, total: searchDeviceFiltered?.length }}
              onChange={handleTableChange}
            />
          }
          exportToExcel={handleExportToExcel}
        />
      </Spinner>
    </>
  );
};
