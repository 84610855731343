import { useState, useRef, useCallback, useEffect, FC } from 'react';
import * as S from './styles';
import { GoogleMap, LoadScript, GoogleMapProps } from '@react-google-maps/api';
import { FenceModel } from '@app/domain/fence/fenceModel';
import appSettings from '@app/config/appsettings';
import { MapFenceSaved } from './map-fence-saved';
import { MapFenceSelectedCard } from './map-fence-selected-card/map-fence-selected-card';
import { MapFenceModal } from './map-fence-modal/map-fence-modal';
import { Spin } from 'antd';

const containerStyle = { width: '100%', height: '37rem', borderRadius: '0.5rem' };
const supermixLocation = { lat: -19.878877423869252, lng: -43.97931295928024 };

interface MapFenceViewProps {
  fences: FenceModel[];
}

export const MapFenceView: FC<MapFenceViewProps> = ({ fences }) => {
  const [mapFences, setMapFences] = useState<FenceModel[]>([]);
  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>();
  const [selectedFence, setSelectedFence] = useState<FenceModel>();
  const controlsRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);

  const getMapInitialPoint = useCallback((fences: FenceModel[]) => {
    setLocationLoading(true);

    // Find the first active fence with points
    const activeFenceWithPoints = fences.find((fence) => fence.ativo && fence.pontos?.length > 0);

    if (activeFenceWithPoints) {
      setCenter({
        lat: activeFenceWithPoints.pontos[0].lat,
        lng: activeFenceWithPoints.pontos[0].lng,
      });
      setLocationLoading(false);
      return;
    }

    // If no active fences, find the first inactive fence with points
    const inactiveFenceWithPoints = fences.find((fence) => !fence.ativo && fence.pontos?.length > 0);
    if (inactiveFenceWithPoints) {
      setCenter({
        lat: inactiveFenceWithPoints.pontos[0].lat,
        lng: inactiveFenceWithPoints.pontos[0].lng,
      });
      setLocationLoading(false);
      return;
    }

    // Default location if no fences have points
    setCenter(supermixLocation);
    setLocationLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleOnSelectFence = (fence: FenceModel) => {
    setMapFences(
      (prevState) =>
        prevState?.map((c) => (c.id == fence.id ? { ...c, selected: !c.selected } : { ...c, selected: false })),
    );
    setSelectedFence((prevState) => (prevState?.nome == fence.nome ? undefined : fence));
  };
  const onMapLoad: GoogleMapProps['onLoad'] = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
    map.setOptions({
      gestureHandling: 'cooperative',
      fullscreenControl: true,
      zoomControl: false,
      streetViewControl: false,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      },
    });
    if (map && controlsRef.current) {
      map.controls[google.maps.ControlPosition.RIGHT_TOP].push(controlsRef.current);
    }
  }, []);

  useEffect(() => {
    getMapInitialPoint(fences);
    setMapFences(fences);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fences]);

  return (
    <LoadScript googleMapsApiKey={appSettings().MAPS_API_KEY || ''} libraries={['places', 'geometry']}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14} onLoad={onMapLoad}>
        <S.MapLoading visible={locationLoading}>
          <Spin /> Carregando localização
        </S.MapLoading>
        <MapFenceSelectedCard onTop fence={selectedFence} />
        <MapFenceModal fences={mapFences} handleOnSelectFence={handleOnSelectFence} />
        <MapFenceSaved
          fences={mapFences}
          selectedFence={selectedFence}
          handleOnSelectFence={handleOnSelectFence}
          showInactiveFences={false}
        />
      </GoogleMap>
    </LoadScript>
  );
};
