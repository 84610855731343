import styled, { css } from 'styled-components';

interface ButtonsModalFencesProps {
  disabled?: boolean;
}

export const ButtonModalFences = styled.div<ButtonsModalFencesProps>`
  position: absolute;
  background-color: #ffffff;
  width: 17rem;
  height: 3rem;
  bottom: 1rem;
  left: 50%;
  margin-left: -10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #bababa;
  box-shadow: 0px 0px 24px 0px #0000001f;
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #545454;
  ${(props) =>
    props.disabled &&
    css`
      background-color: #ffffff;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.8;
    `};
`;

interface ModalFencesProps {
  visible?: boolean;
}

export const ModalFences = styled.div<ModalFencesProps>`
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  bottom: 0rem;
  padding: 2rem;
  border: 1px solid #bababa;
  border-radius: 0.5rem;
  height: ${(props) => (props.visible ? '31rem' : '0')};
  transition:
    height 0.5s ease,
    visibility 0.3s ease;
  overflow: hidden;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

export const ModalFencesHeader = styled.div`
  font-family: Mulish;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.75rem;
  text-align: left;
`;
