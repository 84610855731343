import React, { FC } from 'react';
import * as S from './modules.styles';
import { DeviceProfileProperty, ModuleProperties } from '@app/domain/deviceProfile/deviceProfileModel';
import Rede from '@app/components/common/ModulesProfileConfig/Rede/Rede';
import PerfilEnvio from '@app/components/common/ModulesProfileConfig/PerfilEnvio/PerfilEnvio';
import Eventos from '@app/components/common/ModulesProfileConfig/Eventos/Eventos';
import Entrada from '@app/components/common/ModulesProfileConfig/Entrada/Entrada';
import { CercaConfigUpdate } from '@app/components/common/ModulesProfileConfig/Cerca/CercaConfigUpdate';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';

interface ModuleComponentProps {
  idModel?: number;
  module: ModuleProperties;
  getPropertyValue: (idEmbarcado: DevicePropertyType) => string;
  handleChangeDeviceProfileProperties: (properties: DeviceProfileProperty[], toRemove?: boolean) => void;
}

const ModulesComponent: FC<ModuleComponentProps> = ({
  idModel,
  module,
  getPropertyValue,
  handleChangeDeviceProfileProperties,
}) => {
  const componentsMap: { [key: string]: React.ElementType } = {
    Rede,
    Entrada,
    PerfilEnvio,
    Evento: Eventos,
    Cercas: CercaConfigUpdate,
  };
  const renderModuleContent = (name: string) => {
    const Component = componentsMap[name];

    if (Component) {
      return (
        <Component
          idModel={idModel}
          properties={module.propriedades}
          getPropertyValue={getPropertyValue}
          handleChangeDeviceProfileProperties={handleChangeDeviceProfileProperties}
        />
      );
    }

    return <></>;
  };

  return (
    <S.Container>
      <S.Wrapper>{renderModuleContent(module.nome)}</S.Wrapper>
    </S.Container>
  );
};

export default ModulesComponent;
