import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { Col, Row, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import IDeviceProfileService, { DeviceProfileService } from '@app/services/deviceProfileService';
import { DeviceProfileModel } from '@app/domain/deviceProfile/deviceProfileModel';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Description } from '@app/components/common/Description/Description';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import {
  CardInfoContainer,
  CardInfoProperty,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { DividerLine } from '@app/components/common/divider/DividerLine.styles';
import ModuleViewComponent from './components/ModuleViewComponent';
import { setFooter } from '@app/store/slices/footerSlice';
import IFirmwareGroupService, { FirmwareGroupService } from '@app/services/firmwareGroupService';
import { SendParameter, setParameters } from '@app/store/slices/sendParametersSlice';

const deviceProfileService: IDeviceProfileService = new DeviceProfileService();
const firmwareGroupService: IFirmwareGroupService = new FirmwareGroupService();

export const DeviceProfileView: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [deviceProfile, setDeviceProfile] = useState<DeviceProfileModel>();

  const handleBackClick = () => navigate('/perfil-configuracao');

  const fetchSendProfileProperties = useCallback(
    async (idVersaoFirmware: number) => {
      setLoading(true);
      return firmwareGroupService
        .getArray(`obter-propriedades-perfil-envio/${idVersaoFirmware}`)
        .then((res) => {
          dispatch(setParameters(res as unknown as SendParameter[]));
        })
        .catch((error) => {
          notificationController.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch],
  );

  const fetchDeviceProfile = useCallback((deviceProfileId: number) => {
    setLoading(true);
    return deviceProfileService
      .get(`/${deviceProfileId}`)
      .then((res) => {
        res.idGrupoFirmware && fetchSendProfileProperties(res.idGrupoFirmware);
        setDeviceProfile(res);
        setLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: 'Visualizar perfil de configuração',
        handleBackClick: handleBackClick,
      }),
    );
    dispatch(
      setFooter({
        confirmButtonText: '',
        cancelButtonText: '',
      }),
    );
    if (id) fetchDeviceProfile(Number(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Spinner spinning={loading}>
        <PageContainer>
          <CardInfoContainer style={{ marginBottom: '5rem' }}>
            <Description title="Informações do perfil" />
            <Row gutter={24} justify={'space-between'}>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Equipamento</CardInfoProperty>
                <CardInfoValue>{deviceProfile?.equipamento}</CardInfoValue>
              </Col>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Modelo</CardInfoProperty>
                <CardInfoValue>{deviceProfile?.modelo}</CardInfoValue>
              </Col>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Nome do perfil</CardInfoProperty>
                <CardInfoValue>{deviceProfile?.nome}</CardInfoValue>
              </Col>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Status</CardInfoProperty>
                <CardInfoValue>
                  {deviceProfile?.ativo ? (
                    <Tag color="#E9F4EE" style={{ color: '#083F18', width: '64px', textAlign: 'center' }}>
                      Ativo
                    </Tag>
                  ) : (
                    <Tag color="#FEE9EA" style={{ color: '#620E12', width: '64px', textAlign: 'center' }}>
                      Inativo
                    </Tag>
                  )}
                </CardInfoValue>
              </Col>
            </Row>
            <DividerLine />
            <Description title="Abas de configuração" />
            <BaseForm layout="vertical" style={{ width: '100%' }}>
              <ModuleViewComponent properties={deviceProfile?.propriedades ?? []} />
            </BaseForm>
          </CardInfoContainer>
        </PageContainer>
      </Spinner>
    </>
  );
};
