import React, { useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Table } from '@app/components/common/Table/Table';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Button } from '@app/components/common/buttons/Button/Button';
import { IconMore } from '@app/assets/slump-icons';
import { hasAccessByRoles } from '@app/controllers/accessController';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { UserType } from '@app/constants/enums/userType';
import { TipoVeiculoParametro, VehicleModel, VehicleParameterLinkedModel } from '@app/domain/vehicle/vehicleModel';
import { Col, Modal, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ModalEditCanVehicle } from '../edit/modal-edit';

interface ICreateCanVehicleParametersTableProps {
  vehicle: VehicleModel;
  setVehicle: React.Dispatch<React.SetStateAction<VehicleModel>>;
}

export const CreateCanVehicleParametersTable: React.FC<ICreateCanVehicleParametersTableProps> = ({
  vehicle,
  setVehicle,
}) => {
  const [modalEditVisible, setModalEditVisible] = useState<boolean>(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState<boolean>(false);
  const [parameterSelected, setParameterSelected] = useState<VehicleParameterLinkedModel>();

  const handleEditClickParameter = (id: number) => {
    setParameterSelected(vehicle.parametros?.find((p) => p.id == id));
    setModalEditVisible(true);
  };
  const handleDeleteClickParameter = (id: number) => {
    setParameterSelected(vehicle.parametros?.find((p) => p.id == id));
    setModalDeleteVisible(true);
  };
  const handleDeleteParameter = () => {
    setVehicle({ ...vehicle, parametros: vehicle.parametros?.filter((p) => p.id != parameterSelected?.id) });
    setModalDeleteVisible(false);
  };

  const calculatedColumns: ColumnsType<VehicleParameterLinkedModel> = [
    {
      title: 'Nome ID',
      dataIndex: 'nome',
      showSorterTooltip: false,
      sortDirections: [],
      defaultSortOrder: 'descend',
      width: '10%',
    },
    {
      title: 'ID',
      dataIndex: 'parametroId',
      showSorterTooltip: false,
      width: '8%',
    },
    {
      title: 'Resolução',
      dataIndex: 'resolucao',
      showSorterTooltip: false,
      width: '18%',
    },
    {
      title: 'Offset',
      dataIndex: 'offset',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: 'Bit inicial',
      dataIndex: 'bitInicial',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: 'Tamanho em bits',
      dataIndex: 'tamanhoBits',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: 'Tipo da informação',
      dataIndex: 'tipoInformacao',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: '',
      dataIndex: 'id',
      width: '1%',
      render: (id) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {hasAccessByRoles([UserType.FactoryAdmin, UserType.Factory]) && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ color: '#545454' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    <MenuItem>
                      <Button type="text" onClick={() => handleEditClickParameter(id)}>
                        Editar
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button type="text" onClick={() => handleDeleteClickParameter(id)}>
                        Excluir
                      </Button>
                    </MenuItem>
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];
  const onOffColumns: ColumnsType<VehicleParameterLinkedModel> = [
    {
      title: 'Nome ID',
      dataIndex: 'nome',
      showSorterTooltip: false,
      sortDirections: [],
      defaultSortOrder: 'descend',
      width: '10%',
    },
    {
      title: 'ID',
      dataIndex: 'parametroId',
      showSorterTooltip: false,
      width: '8%',
    },
    {
      title: 'Bit inicial',
      dataIndex: 'bitInicial',
      showSorterTooltip: false,
      width: '18%',
    },
    {
      title: 'Tamanho em bits',
      dataIndex: 'tamanhoBits',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: 'Tipo da informação',
      dataIndex: 'tipoInformacao',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: 'Ligado',
      dataIndex: 'ligado',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: 'Desligado',
      dataIndex: 'desligado',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: '',
      dataIndex: 'id',
      width: '1%',
      render: (id) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {hasAccessByRoles([UserType.FactoryAdmin, UserType.Client]) && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ color: '#545454' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    <MenuItem>
                      <Button type="text" onClick={() => handleEditClickParameter(id)}>
                        Editar
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button type="text" onClick={() => handleDeleteClickParameter(id)}>
                        Excluir
                      </Button>
                    </MenuItem>
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        title="Excluir parâmetro"
        open={modalDeleteVisible}
        onOk={handleDeleteParameter}
        onCancel={() => setModalDeleteVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>
            Deseja realmente <strong>&nbsp;excluir o parâmetro do veículo CAN?</strong>
          </Col>
        </Row>
      </Modal>

      <ModalEditCanVehicle
        visible={modalEditVisible}
        setVisible={setModalEditVisible}
        vehicle={vehicle}
        setVehicle={setVehicle}
        idParameter={parameterSelected?.id}
      />

      {vehicle.parametros?.find((p) => p.tipo == TipoVeiculoParametro.Calculado) && (
        <>
          <h2>Calculados</h2>
          <Table
            columns={calculatedColumns}
            dataSource={vehicle.parametros?.filter((p) => p.tipo == TipoVeiculoParametro.Calculado)}
            scroll={{ x: 800 }}
            bordered
          />
        </>
      )}
      {vehicle.parametros?.find((p) => p.tipo == TipoVeiculoParametro.LigadoDesligado) && (
        <>
          <h2>On/Off</h2>
          <Table
            columns={onOffColumns}
            dataSource={vehicle.parametros?.filter((p) => p.tipo == TipoVeiculoParametro.LigadoDesligado)}
            scroll={{ x: 800 }}
            bordered
          />
        </>
      )}
    </>
  );
};
