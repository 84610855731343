import { Spinner } from '@app/components/common/Spinner/Spinner';
import { notificationController } from '@app/controllers/notificationController';
import { FenceGroupModel } from '@app/domain/fence/fenceGroupModel';
import IFenceService, { FenceService } from '@app/services/fenceService';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as S from './styles';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { setFooter } from '@app/store/slices/footerSlice';
import { Col, Row } from 'antd';
import { Switch } from '@app/components/common/Switch/Switch';
import { Description } from '@app/components/common/Description/Description';
import { ViewFenceStepUnique } from './components/steps/view-step-unique';

const fenceService: IFenceService = new FenceService();

export const FenceView: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [showInactiveFences, setShowInactiveFences] = useState<boolean>(false);
  const [groupFence, setGroupFence] = useState<FenceGroupModel>();

  const handleBack = () => navigate('/cercas');

  const fetch = () => {
    if (id) {
      setLoading(true);
      fenceService
        .get(`grupos/${id}`)
        .then((res) => setGroupFence(res))
        .catch(() => {
          notificationController.error({
            message: 'Erro!',
            description: 'Não foi possível obter os dados do grupo de cercas.',
          });
          handleBack();
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    fetch();
    dispatch(
      setHeaderRegister({
        title: 'Visualizar grupo de cercas',
        handleBackClick: () => handleBack(),
      }),
    );
    dispatch(
      setFooter({
        confirmButtonText: 'Clonar grupo',
        handleConfirmButtonClick: () => navigate(`/cercas/clonar/${id}`),
        cancelButtonText: 'Sair',
        handleCancelButtonClick: () => handleBack(),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Spinner spinning={loading} />
      <S.FencePageContainer>
        <Row justify={'space-between'} align={'middle'}>
          <Col>
            <Description
              title={`${groupFence?.nome} | ${groupFence?.modelo?.nome}`}
              subtitle={`${groupFence?.cercas?.length} ${
                groupFence?.cercas?.length ?? 0 > 1 ? 'cercas cadastradas' : 'cerca cadastrada'
              }`}
            />
          </Col>
          <Col>
            <Switch
              size="default"
              checked={showInactiveFences}
              checkedChildren="Cercas ativas"
              unCheckedChildren="Cercas inativas"
              style={{ marginRight: '0.25rem' }}
              onChange={() => setShowInactiveFences(!showInactiveFences)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ViewFenceStepUnique
              groupFence={groupFence}
              setGroupFence={setGroupFence}
              showInactives={showInactiveFences}
            />
          </Col>
        </Row>
      </S.FencePageContainer>
    </>
  );
};
