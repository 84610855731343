import React, { useEffect, useState } from 'react';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import * as S from '../ModulesProfileConfig.styles';
import * as ST from './PerfilEnvio.styles';
import ShouldRenderInput from '@app/components/common/ModulesProfileConfig/ShouldRenderInput';
import { Col, Form, Row, Space } from 'antd';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import TooltipInfoIcon from '@app/components/common/TooltipInfoIcon/TooltipInfoIcon';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';
import { googlecode } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeStringExample } from '@app/components/common/ModulesProfileConfig/PerfilEnvio/PerfilEnvioUtils';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Panel } from '@app/components/common/Collapse/Collapse';
import { Button } from '@app/components/common/buttons/Button/Button';
import ModalParametersPerfilEnvio, { ocasionalParameterEmbarcado } from './Modal/ModalParametersPerfilEnvio';
import { CardInfoTitle } from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { encode } from '@app/components/common/ModulesProfileConfig/PerfilEnvio/encodeDecode';
import { PropertyModel } from '@app/domain/property/propertyModel';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface PerfilEnvioProps {
  properties: PropertyModel[];
  getPropertyValue: (idEmbarcado: DevicePropertyType) => string;
  handleChangeDeviceProfileProperties: (properties: DeviceProfileProperty[]) => void;
}

const PerfilEnvio: React.FC<PerfilEnvioProps> = ({
  properties,
  getPropertyValue,
  handleChangeDeviceProfileProperties,
}) => {
  const { parameters } = useAppSelector((state) => state.sendParameters);
  const [parameterJustSeted, setParameterJustSeted] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedParams, setSelectedParams] = useState<number[]>([]);

  const handleOnBlurEventValidateNumber = (e: React.FocusEvent<HTMLInputElement>) => {
    const idEmbarcado = Number(e.target.name);

    if (!idEmbarcado) return;

    let propertyValue = Number(e.target.value);
    const min = e.target.min ? Number(e.target.min) : undefined;
    const max = e.target.max ? Number(e.target.max) : undefined;

    if (min !== undefined && propertyValue < min) propertyValue = min;
    if (max !== undefined && propertyValue > max) propertyValue = max;

    handleChangePropertyValue(idEmbarcado, `${propertyValue}`);
  };
  const handleOnChangeEventPropertyValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const idEmbarcado = Number(e.target.name);
    const propertyValue = e.target.value;

    if (!idEmbarcado) return;

    if (e.target.type === 'number') {
      const max = e.target.max ? Number(e.target.max) : undefined;
      const value = Number(propertyValue);

      if (max !== undefined && value > max) return;
    }

    handleChangePropertyValue(idEmbarcado, propertyValue);
  };
  const handleOnChangeEventCheckboxProperty = (e: CheckboxChangeEvent, sideProperty: DevicePropertyType) => {
    const idEmbarcado = e.target.name;
    const propertyValue = e.target.checked ? '1' : '0';

    if (!idEmbarcado) return;

    const propCheck = {
      ...properties?.find((p) => p.idEmbarcado == Number(idEmbarcado)),
      valorPropriedade: propertyValue,
    } as DeviceProfileProperty;

    const propSide = {
      ...properties?.find((p) => p.idEmbarcado == sideProperty),
      valorPropriedade: e.target.checked ? getDefaultPropertyValue(sideProperty) : '',
    } as DeviceProfileProperty;

    handleChangeDeviceProfileProperties([propCheck, propSide]);
  };
  const handleChangePropertyValue = (idEmbarcado: DevicePropertyType, value: string) => {
    const prop = {
      ...properties?.find((p) => p.idEmbarcado == idEmbarcado),
      valorPropriedade: value,
    } as DeviceProfileProperty;
    handleChangeDeviceProfileProperties([prop]);
  };
  const getDefaultPropertyValue = (idEmbarcado: DevicePropertyType) =>
    properties?.find((p) => p.idEmbarcado == idEmbarcado)?.valorDefault ?? '';

  const getValueFieldLinkedWithCheckbox = (idEmbarcadoCheckbox: DevicePropertyType, idEmbarcado: DevicePropertyType) =>
    getPropertyValue(idEmbarcadoCheckbox) == '1' ? getPropertyValue(idEmbarcado) : getDefaultPropertyValue(idEmbarcado);

  useEffect(() => {
    if (parameters.length > 0 && !parameterJustSeted) {
      setParameterJustSeted(true);
      setSelectedParams(
        parameters
          .filter((p) => !ocasionalParameterEmbarcado.includes(p.identificadorEmbarcado))
          .map((param) => param.identificadorEmbarcado),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);
  useEffect(() => {
    if (selectedParams.length > 0)
      handleChangePropertyValue(DevicePropertyType.ParametrosEnvio, encode(selectedParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParams]);

  return (
    <>
      <ModalParametersPerfilEnvio
        visible={modalVisible}
        setVisible={setModalVisible}
        selectedParams={selectedParams}
        setSelectedParams={setSelectedParams}
      />

      <Form style={{ width: '100%' }} layout="vertical">
        <S.Row>
          <ShouldRenderInput idEmbarcado={DevicePropertyType.IntervaloEnvioCarroLigado} properties={properties}>
            <BaseFormInputItem label="Intervalo ignição ligada (s)" style={{ width: '17rem' }}>
              <>
                <Input
                  key={2}
                  type="number"
                  min={10}
                  max={172800}
                  className="ant-input"
                  placeholder="Digite o intervalo"
                  value={getPropertyValue(DevicePropertyType.IntervaloEnvioCarroLigado)}
                  name={`${DevicePropertyType.IntervaloEnvioCarroLigado}`}
                  onChange={handleOnChangeEventPropertyValue}
                  onBlur={handleOnBlurEventValidateNumber}
                />
                <S.InputObs>Entre 10 e 172800 segundos</S.InputObs>
              </>
            </BaseFormInputItem>
          </ShouldRenderInput>
          <ShouldRenderInput idEmbarcado={DevicePropertyType.IntervaloEnvioBateriaExterna} properties={properties}>
            <BaseFormInputItem label="Intervalo bateria externa (s)" style={{ width: '17rem' }}>
              <>
                <Input
                  key={3}
                  type="number"
                  min={10}
                  max={172800}
                  className="ant-input"
                  placeholder="Digite o intervalo"
                  value={getPropertyValue(DevicePropertyType.IntervaloEnvioBateriaExterna)}
                  name={`${DevicePropertyType.IntervaloEnvioBateriaExterna}`}
                  onChange={handleOnChangeEventPropertyValue}
                  onBlur={handleOnBlurEventValidateNumber}
                />
                <S.InputObs>Entre 10 e 172800 segundos</S.InputObs>
              </>
            </BaseFormInputItem>
          </ShouldRenderInput>
          <ShouldRenderInput idEmbarcado={DevicePropertyType.IntervaloEnvioBateriaInterna} properties={properties}>
            <BaseFormInputItem label="Intervalo bateria interna (s)" style={{ width: '17rem' }}>
              <>
                <Input
                  key={4}
                  type="number"
                  min={10}
                  max={172800}
                  className="ant-input"
                  placeholder="Digite o intervalo"
                  value={getPropertyValue(DevicePropertyType.IntervaloEnvioBateriaInterna)}
                  name={`${DevicePropertyType.IntervaloEnvioBateriaInterna}`}
                  onChange={handleOnChangeEventPropertyValue}
                  onBlur={handleOnBlurEventValidateNumber}
                />
                <S.InputObs>Entre 10 e 172800 segundos</S.InputObs>
              </>
            </BaseFormInputItem>
          </ShouldRenderInput>
        </S.Row>
        <S.Row style={{ columnGap: '10rem' }}>
          <S.FieldCheckboxValue>
            <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoEnvioDistancia} properties={properties}>
              <Checkbox
                key={48}
                checked={getPropertyValue(DevicePropertyType.EventoEnvioDistancia) == '1'}
                name={`${DevicePropertyType.EventoEnvioDistancia}`}
                onChange={(e) => handleOnChangeEventCheckboxProperty(e, DevicePropertyType.LimiteDistancia)}
                style={{ width: '13rem' }}
              >
                <S.CheckboxLabel>Envio por distância (m)</S.CheckboxLabel>
                <S.InputObs>50 a 1000</S.InputObs>
              </Checkbox>
            </ShouldRenderInput>
            <ShouldRenderInput idEmbarcado={DevicePropertyType.LimiteDistancia} properties={properties}>
              <Input
                key={3}
                type="number"
                min={50}
                max={1000}
                className="ant-input"
                placeholder="Digite o valor"
                disabled={getPropertyValue(DevicePropertyType.EventoEnvioDistancia) !== '1'}
                value={getValueFieldLinkedWithCheckbox(
                  DevicePropertyType.EventoEnvioDistancia,
                  DevicePropertyType.LimiteDistancia,
                )}
                name={`${DevicePropertyType.LimiteDistancia}`}
                onChange={handleOnChangeEventPropertyValue}
                onBlur={handleOnBlurEventValidateNumber}
                style={{ width: '8rem' }}
              />
            </ShouldRenderInput>
          </S.FieldCheckboxValue>
          <S.FieldCheckboxValue>
            <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoEnvioAngulo} properties={properties}>
              <Checkbox
                key={50}
                checked={getPropertyValue(DevicePropertyType.EventoEnvioAngulo) == '1'}
                name={`${DevicePropertyType.EventoEnvioAngulo}`}
                onChange={(e) => handleOnChangeEventCheckboxProperty(e, DevicePropertyType.LimiteAngulo)}
                style={{ width: '13rem' }}
              >
                <S.CheckboxLabel>Envio por ângulo (°)</S.CheckboxLabel>
                <S.InputObs>10 a 179</S.InputObs>
              </Checkbox>
            </ShouldRenderInput>
            <ShouldRenderInput idEmbarcado={DevicePropertyType.LimiteAngulo} properties={properties}>
              <Input
                key={51}
                type="number"
                min={10}
                max={179}
                className="ant-input"
                placeholder="Digite o valor"
                disabled={getPropertyValue(DevicePropertyType.EventoEnvioAngulo) !== '1'}
                defaultValue={getDefaultPropertyValue(DevicePropertyType.LimiteAngulo)}
                value={getValueFieldLinkedWithCheckbox(
                  DevicePropertyType.EventoEnvioAngulo,
                  DevicePropertyType.LimiteAngulo,
                )}
                name={`${DevicePropertyType.LimiteAngulo}`}
                onChange={handleOnChangeEventPropertyValue}
                onBlur={handleOnBlurEventValidateNumber}
                style={{ width: '8rem' }}
              />
            </ShouldRenderInput>
          </S.FieldCheckboxValue>
        </S.Row>
        <S.Row>
          <ShouldRenderInput idEmbarcado={DevicePropertyType.TipoEnvio} properties={properties}>
            <HelperNotification style={{ marginTop: '1rem' }}>
              Não é possível selecionar os parâmetros de envio no formato JSON. A seleção dos parâmetros só está
              disponível para tipo String e .Zip
            </HelperNotification>
            <BaseFormInputItem label="Tipo de envio">
              <RadioGroup
                key={68}
                defaultValue={'1'}
                value={getPropertyValue(DevicePropertyType.TipoEnvio)}
                onChange={(e) => {
                  const tipo = e.target.value;
                  handleChangePropertyValue(DevicePropertyType.TipoEnvio, tipo);
                  if (tipo === '0') {
                    setSelectedParams(
                      parameters
                        .filter((p) => !ocasionalParameterEmbarcado.includes(p.identificadorEmbarcado))
                        .map((param) => param.identificadorEmbarcado),
                    );
                  }
                }}
              >
                <Space direction="vertical">
                  <Radio value={'0'}>
                    JSON
                    <TooltipInfoIcon
                      title={'Tipo de mensagem altamente estruturada. Alto consumo de dados por envio'}
                    />
                  </Radio>
                  <Radio value={'1'}>
                    String <TooltipInfoIcon title={'Tipo de mensagem estruturada. Médio consumo de dados por envio.'} />
                  </Radio>
                  <Radio value={'2'}>
                    .Zip
                    <TooltipInfoIcon title={'Tipo de mensagem não estruturada. Baixo consumo de dados por envio.'} />
                  </Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
            <ST.Collapse bordered={false} style={{ width: '100%', marginBottom: '1rem' }}>
              <ST.Panel key={'pre-visualization'} header={'Pré-visualização do envio da mensagem'}>
                <HelperNotification>Exemplo de como a mensagem será enviada ao dispositivo.</HelperNotification>
                <SyntaxHighlighter
                  language="json"
                  style={googlecode}
                  customStyle={{
                    backgroundColor: 'transparent',
                  }}
                >
                  {codeStringExample[getPropertyValue(DevicePropertyType.TipoEnvio) ?? ''] ?? ''}
                </SyntaxHighlighter>
              </ST.Panel>
            </ST.Collapse>
          </ShouldRenderInput>
        </S.Row>
        {getPropertyValue(DevicePropertyType.TipoEnvio) != '0' && (
          <S.Row style={{ margin: '1rem 0' }}>
            <Col xs={24}>
              <S.Collapse defaultActiveKey={'1'}>
                <Panel
                  key="1"
                  header={
                    <Row align={'middle'} justify={'space-between'}>
                      <Col>
                        <ST.CollapseHeaderName>Parâmetros de envio</ST.CollapseHeaderName>
                      </Col>
                      <Button
                        type="ghost"
                        onClick={() => setModalVisible(true)}
                        disabled={getPropertyValue(DevicePropertyType.TipoEnvio) == '0'}
                      >
                        Selecionar parâmetros
                      </Button>
                    </Row>
                  }
                >
                  <S.Row>
                    <CardInfoTitle>Obrigatórios</CardInfoTitle>
                  </S.Row>
                  <S.Row style={{ columnGap: '0.8rem' }}>
                    <strong>{parameters.filter((p) => p.isObrigatorio).length}</strong> parâmetros
                  </S.Row>
                  <S.Row style={{ margin: '0.9rem 0' }}>
                    {parameters
                      .filter((p) => p.isObrigatorio)
                      .map((param) => (
                        <Col key={param.identificadorEmbarcado} xs={24} md={4} lg={4} style={{ minHeight: '32px' }}>
                          {param.nome}
                        </Col>
                      ))}
                  </S.Row>

                  <S.Row>
                    <CardInfoTitle>Opcionais</CardInfoTitle>
                  </S.Row>
                  <S.Row>
                    <strong>
                      {selectedParams.filter((p) => !ocasionalParameterEmbarcado.includes(p)).length -
                        parameters.filter((p) => p.isObrigatorio).length}
                    </strong>{' '}
                    parâmetros
                  </S.Row>
                  <S.Row style={{ margin: '0.9rem 0' }}>
                    {parameters
                      .filter(
                        (p) =>
                          !p.isObrigatorio &&
                          !ocasionalParameterEmbarcado.includes(p.identificadorEmbarcado) &&
                          selectedParams.includes(p.identificadorEmbarcado),
                      )
                      .map((param) => (
                        <Col key={param.identificadorEmbarcado} xs={24} md={4} lg={4} style={{ minHeight: '32px' }}>
                          {param.nome}
                        </Col>
                      ))}
                  </S.Row>
                </Panel>
              </S.Collapse>
            </Col>
          </S.Row>
        )}
      </Form>
    </>
  );
};

export default PerfilEnvio;
