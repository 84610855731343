import styled from 'styled-components';
import { Input } from '../inputs/Input/Input';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ButtonsMapWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 1.12rem;
  z-index: 100;
  width: auto;
  height: auto;
`;

export const MapLoading = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 50%;
  left: 45%;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const MapInputSearch = styled(Input)`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 400px;
  border-radius: 2.5rem;
  padding-left: 20px;
`;
