import styled from 'styled-components';
import { Collapse as AntdCollapse } from 'antd';

export const Collapse = styled(AntdCollapse)`
  background-color: #fff;
  height: 100%;
  margin: 0;
  padding: 0;
  border: unset;

  .ant-collapse-item {
    background-color: #ecf1f9;
    border: 1px solid #bababa;
    border-radius: 0.5rem;
    margin: 1rem auto;
    width: 100%;
  }

  .ant-collapse-item:first-child {
    margin-top: 0;
  }

  .ant-collapse-item:last-child {
    border: 1px solid #bababa;
    border-radius: 0.5rem;
    margin: 1rem auto;
    width: 100%;
  }

  .ant-collapse-header {
    margin: 0.5rem 0.5rem;
    font-size: 1.25rem;
  }

  .ant-collapse-content {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`;

export const CollapseStepIcon = styled.div`
  display: flex;
  justify-content: start;
  color: #545454;
  font-size: 1.25rem !important;
  font-weight: 600;
  line-height: 1.5rem;
  padding-right: 1rem;
`;

export const CollapseStepIndicatorHelperList = styled.ul`
  font-family: Mulish;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  color: #545454;
  padding: 0.75rem 0rem 0rem 1.5rem;
`;

export const CollapseStepIndicatorHelper = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  font-size: 1.15rem;
  font-family: Mulish, sans-serif;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 1.5rem;
  padding: 0.3rem;
  color: white;
  background-color: #545454;
  transition:
    background-color 0.3s,
    border-color 0.3s;

  &:after {
    content: '?';
  }
`;
export const CollapseStepIndicatorFinished = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  font-size: 1rem;
  font-family: Mulish, sans-serif;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 1.5rem;
  padding: 0.3rem;
  color: white;
  background-color: #159d3b;
  transition:
    background-color 0.3s,
    border-color 0.3s;

  & .anticon {
    font-size: 0.85rem;
  }
`;
export const CollapseStepIndicator = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  font-size: 1rem;
  font-family: Mulish, sans-serif;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 1.5rem;
  padding: 0.3rem;
  border: 3px solid #1c213c;
  transition:
    background-color 0.3s,
    border-color 0.3s;
`;
export const CollapseStepIndicatorContent = styled.div`
  width: 100%;
  height: 100%;
  background-color: #1c213c;
  border-radius: 50%;
`;

export const CollapseStepWrapper = styled.div`
  height: 3rem;
  width: 100%;
  display: flex;
  justify-content: start;
  padding: 0.75rem 1rem 1rem 1rem;
  font-family: Mulish;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: left;
  color: #383838;
  background-color: #ecf1f9;
`;
