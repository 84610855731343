import React from 'react';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import * as S from '../ModulesProfileConfig.styles';
import * as SE from './Eventos.styles';
import ShouldRenderInput from '@app/components/common/ModulesProfileConfig/ShouldRenderInput';
import { Form } from 'antd';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { PropertyModel } from '@app/domain/property/propertyModel';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface EventosProps {
  properties: PropertyModel[];
  getPropertyValue: (idEmbarcado: DevicePropertyType) => string;
  handleChangeDeviceProfileProperties: (properties: DeviceProfileProperty[]) => void;
}

const Eventos: React.FC<EventosProps> = ({ properties, getPropertyValue, handleChangeDeviceProfileProperties }) => {
  const handleOnBlurEventValidateNumber = (e: React.FocusEvent<HTMLInputElement>) => {
    const idEmbarcado = Number(e.target.name);

    if (!idEmbarcado) return;

    let propertyValue = Number(e.target.value);
    const min = e.target.min ? Number(e.target.min) : undefined;
    const max = e.target.max ? Number(e.target.max) : undefined;

    if (min !== undefined && propertyValue < min) propertyValue = min;
    if (max !== undefined && propertyValue > max) propertyValue = max;

    handleChangePropertyValue(idEmbarcado, `${propertyValue}`);
  };
  const handleOnChangeEventPropertyValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const idEmbarcado = Number(e.target.name);
    const propertyValue = e.target.value;

    if (!idEmbarcado) return;

    if (e.target.type === 'number') {
      const max = e.target.max ? Number(e.target.max) : undefined;
      const value = Number(propertyValue);

      if (max !== undefined && value > max) return;
    }

    handleChangePropertyValue(idEmbarcado, propertyValue);
  };
  const handleOnChangeEventCheckboxProperty = (e: CheckboxChangeEvent, sideProperty?: DevicePropertyType[]) => {
    const idEmbarcado = e.target.name;
    const propertyValue = e.target.checked ? '1' : '0';

    if (!idEmbarcado) return;

    let propsToUpdate: DeviceProfileProperty[] = [];

    properties.map((p) => {
      if (p.idEmbarcado == Number(idEmbarcado))
        propsToUpdate = propsToUpdate.concat({ ...p, valorPropriedade: propertyValue } as DeviceProfileProperty);
      if (sideProperty?.includes(p.idEmbarcado))
        propsToUpdate = propsToUpdate.concat({
          ...p,
          valorPropriedade: e.target.checked ? getDefaultPropertyValue(p.idEmbarcado) : '',
        } as DeviceProfileProperty);
    });

    handleChangeDeviceProfileProperties(propsToUpdate);
  };
  const handleChangePropertyValue = (idEmbarcado: DevicePropertyType, value: string) => {
    const prop = {
      ...properties?.find((p) => p.idEmbarcado == idEmbarcado),
      valorPropriedade: value,
    } as DeviceProfileProperty;
    handleChangeDeviceProfileProperties([prop]);
  };
  const getDefaultPropertyValue = (idEmbarcado: DevicePropertyType) =>
    properties?.find((p) => p.idEmbarcado == idEmbarcado)?.valorDefault ?? '';
  const getValueFieldLinkedWithCheckbox = (idEmbarcadoCheckbox: DevicePropertyType, idEmbarcado: DevicePropertyType) =>
    getPropertyValue(idEmbarcadoCheckbox) == '1' ? getPropertyValue(idEmbarcado) : getDefaultPropertyValue(idEmbarcado);

  return (
    <Form layout="vertical">
      <SE.Container>
        <SE.EventsItem>
          <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoEstadoIgnicao} properties={properties}>
            <Checkbox
              key={21}
              checked={getPropertyValue(DevicePropertyType.EventoEstadoIgnicao) === '1'}
              name={`${DevicePropertyType.EventoEstadoIgnicao}`}
              onChange={handleOnChangeEventCheckboxProperty}
              style={{ width: '13rem' }}
            >
              <S.CheckboxLabel>Ignição</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </SE.EventsItem>
        <SE.EventsItem>
          <S.FieldCheckboxValue>
            <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoLimiteVelocidade} properties={properties}>
              <S.CheckboxContainer>
                <Checkbox
                  key={16}
                  checked={getPropertyValue(DevicePropertyType.EventoLimiteVelocidade) === '1'}
                  name={`${DevicePropertyType.EventoLimiteVelocidade}`}
                  onChange={(e) => handleOnChangeEventCheckboxProperty(e, [DevicePropertyType.LimiteVelocidade])}
                  style={{ width: '13rem' }}
                >
                  <S.CheckboxLabel>Limite de velocidade (km/h)</S.CheckboxLabel>
                  <S.InputObs>40 a 120 km/h</S.InputObs>
                </Checkbox>
              </S.CheckboxContainer>
            </ShouldRenderInput>
            <ShouldRenderInput idEmbarcado={DevicePropertyType.LimiteVelocidade} properties={properties}>
              <BaseFormInputItem>
                <Input
                  key={3}
                  type="number"
                  min={80}
                  max={120}
                  className="ant-input"
                  placeholder="Digite o valor"
                  disabled={getPropertyValue(DevicePropertyType.EventoLimiteVelocidade) !== '1'}
                  value={getValueFieldLinkedWithCheckbox(
                    DevicePropertyType.EventoLimiteVelocidade,
                    DevicePropertyType.LimiteVelocidade,
                  )}
                  name={`${DevicePropertyType.LimiteVelocidade}`}
                  onChange={handleOnChangeEventPropertyValue}
                  onBlur={handleOnBlurEventValidateNumber}
                  style={{ width: '8rem' }}
                />
              </BaseFormInputItem>
            </ShouldRenderInput>
          </S.FieldCheckboxValue>
        </SE.EventsItem>
        <SE.EventsItem>
          <ShouldRenderInput
            idEmbarcado={DevicePropertyType.EventoDeteccaoMovimentoIgnicaoDesligada}
            properties={properties}
          >
            <Checkbox
              key={59}
              checked={getPropertyValue(DevicePropertyType.EventoDeteccaoMovimentoIgnicaoDesligada) === '1'}
              name={`${DevicePropertyType.EventoDeteccaoMovimentoIgnicaoDesligada}`}
              onChange={handleOnChangeEventCheckboxProperty}
              style={{ width: '13rem' }}
            >
              <S.CheckboxLabel>Detecção de movimento</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </SE.EventsItem>
        <SE.EventsItem>
          <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoEstadoEntradaDigital} properties={properties}>
            <Checkbox
              key={25}
              checked={getPropertyValue(DevicePropertyType.EventoEstadoEntradaDigital) === '1'}
              name={`${DevicePropertyType.EventoEstadoEntradaDigital}`}
              onChange={handleOnChangeEventCheckboxProperty}
              style={{ width: '13rem' }}
            >
              <S.CheckboxLabel>Entrada digital 1</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </SE.EventsItem>
        <SE.EventsItem>
          <S.FieldCheckboxValue>
            {getPropertyValue(DevicePropertyType.EntradaAnalogicaHabilitada) == '1' && (
              <>
                <ShouldRenderInput
                  idEmbarcado={DevicePropertyType.EventoLimiteEntradaAnalogica}
                  properties={properties}
                >
                  <S.CheckboxContainer>
                    <Checkbox
                      key={27}
                      checked={getPropertyValue(DevicePropertyType.EventoLimiteEntradaAnalogica) === '1'}
                      name={`${DevicePropertyType.EventoLimiteEntradaAnalogica}`}
                      onChange={(e) =>
                        handleOnChangeEventCheckboxProperty(e, [DevicePropertyType.LimiteEntradaAnalogica])
                      }
                      style={{ width: '13rem' }}
                    >
                      <S.CheckboxLabel>Limite da entrada analógica (mA)</S.CheckboxLabel>
                      <S.InputObs>4 a 20 km/h</S.InputObs>
                    </Checkbox>
                  </S.CheckboxContainer>
                </ShouldRenderInput>
                <ShouldRenderInput idEmbarcado={DevicePropertyType.LimiteEntradaAnalogica} properties={properties}>
                  <BaseFormInputItem>
                    <Input
                      key={3}
                      type="number"
                      min={4}
                      max={20}
                      className="ant-input"
                      placeholder="Digite o valor"
                      disabled={getPropertyValue(DevicePropertyType.EventoLimiteEntradaAnalogica) !== '1'}
                      value={getValueFieldLinkedWithCheckbox(
                        DevicePropertyType.EventoLimiteEntradaAnalogica,
                        DevicePropertyType.LimiteEntradaAnalogica,
                      )}
                      name={`${DevicePropertyType.LimiteEntradaAnalogica}`}
                      onChange={handleOnChangeEventPropertyValue}
                      onBlur={handleOnBlurEventValidateNumber}
                      style={{ width: '8rem' }}
                    />
                  </BaseFormInputItem>
                </ShouldRenderInput>
              </>
            )}
          </S.FieldCheckboxValue>
        </SE.EventsItem>
        <SE.EventsItem>
          <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoMensagemRS232} properties={properties}>
            <Checkbox
              key={56}
              checked={getPropertyValue(DevicePropertyType.EventoMensagemRS232) === '1'}
              name={`${DevicePropertyType.EventoMensagemRS232}`}
              onChange={handleOnChangeEventCheckboxProperty}
              style={{ width: '13rem' }}
            >
              <S.CheckboxLabel>RS232</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </SE.EventsItem>
        <SE.EventsItem>
          <S.FieldCheckboxValue>
            <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoDeteccaoFrenagemBrusca} properties={properties}>
              <S.CheckboxContainer>
                <Checkbox
                  key={52}
                  checked={getPropertyValue(DevicePropertyType.EventoDeteccaoFrenagemBrusca) === '1'}
                  name={`${DevicePropertyType.EventoDeteccaoFrenagemBrusca}`}
                  onChange={(e) =>
                    handleOnChangeEventCheckboxProperty(e, [DevicePropertyType.SensibilidadeDeteccaoFrenagemBrusca])
                  }
                  style={{ width: '13rem' }}
                >
                  <S.CheckboxLabel>Sensibilidade frenagem brusca (g)</S.CheckboxLabel>
                  <S.InputObs>0,25 a 2</S.InputObs>
                </Checkbox>
              </S.CheckboxContainer>
            </ShouldRenderInput>
            <ShouldRenderInput
              idEmbarcado={DevicePropertyType.SensibilidadeDeteccaoFrenagemBrusca}
              properties={properties}
            >
              <BaseFormInputItem>
                <Input
                  key={3}
                  step={0.1}
                  type="number"
                  min={0.25}
                  max={2}
                  className="ant-input"
                  placeholder="Digite o valor"
                  disabled={getPropertyValue(DevicePropertyType.EventoDeteccaoFrenagemBrusca) !== '1'}
                  value={getValueFieldLinkedWithCheckbox(
                    DevicePropertyType.EventoDeteccaoFrenagemBrusca,
                    DevicePropertyType.SensibilidadeDeteccaoFrenagemBrusca,
                  )}
                  name={`${DevicePropertyType.SensibilidadeDeteccaoFrenagemBrusca}`}
                  onChange={handleOnChangeEventPropertyValue}
                  onBlur={handleOnBlurEventValidateNumber}
                  style={{ width: '8rem' }}
                />
              </BaseFormInputItem>
            </ShouldRenderInput>
          </S.FieldCheckboxValue>
        </SE.EventsItem>
        <SE.EventsItem>
          <S.FieldCheckboxValue>
            <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoDeteccaoAceleracaoBrusca} properties={properties}>
              <S.CheckboxContainer>
                <Checkbox
                  key={53}
                  checked={getPropertyValue(DevicePropertyType.EventoDeteccaoAceleracaoBrusca) === '1'}
                  name={`${DevicePropertyType.EventoDeteccaoAceleracaoBrusca}`}
                  onChange={(e) =>
                    handleOnChangeEventCheckboxProperty(e, [DevicePropertyType.SensibilidadeDeteccaoAceleracaoBrusca])
                  }
                  style={{ width: '13rem' }}
                >
                  <S.CheckboxLabel>Sensibilidade aceleração brusca (g)</S.CheckboxLabel>
                  <S.InputObs>0,25 a 2</S.InputObs>
                </Checkbox>
              </S.CheckboxContainer>
            </ShouldRenderInput>
            <ShouldRenderInput
              idEmbarcado={DevicePropertyType.SensibilidadeDeteccaoAceleracaoBrusca}
              properties={properties}
            >
              <BaseFormInputItem>
                <Input
                  key={3}
                  step={0.1}
                  type="number"
                  min={0.25}
                  max={2}
                  className="ant-input"
                  placeholder="Digite o valor"
                  disabled={getPropertyValue(DevicePropertyType.EventoDeteccaoAceleracaoBrusca) !== '1'}
                  value={getValueFieldLinkedWithCheckbox(
                    DevicePropertyType.EventoDeteccaoAceleracaoBrusca,
                    DevicePropertyType.SensibilidadeDeteccaoAceleracaoBrusca,
                  )}
                  name={`${DevicePropertyType.SensibilidadeDeteccaoAceleracaoBrusca}`}
                  onChange={handleOnChangeEventPropertyValue}
                  onBlur={handleOnBlurEventValidateNumber}
                  style={{ width: '8rem' }}
                />
              </BaseFormInputItem>
            </ShouldRenderInput>
          </S.FieldCheckboxValue>
        </SE.EventsItem>
        <SE.EventsItem>
          <S.FieldCheckboxValue>
            <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoDeteccaoCurvaAcentuada} properties={properties}>
              <S.CheckboxContainer>
                <Checkbox
                  key={54}
                  checked={getPropertyValue(DevicePropertyType.EventoDeteccaoCurvaAcentuada) === '1'}
                  name={`${DevicePropertyType.EventoDeteccaoCurvaAcentuada}`}
                  onChange={(e) =>
                    handleOnChangeEventCheckboxProperty(e, [DevicePropertyType.SensibilidadeDeteccaoCurvaAcentuada])
                  }
                  style={{ width: '13rem' }}
                >
                  <S.CheckboxLabel>Sensibilidade curva acentuada (g)</S.CheckboxLabel>
                  <S.InputObs>0,25 a 2</S.InputObs>
                </Checkbox>
              </S.CheckboxContainer>
            </ShouldRenderInput>
            <ShouldRenderInput
              idEmbarcado={DevicePropertyType.SensibilidadeDeteccaoCurvaAcentuada}
              properties={properties}
            >
              <BaseFormInputItem>
                <Input
                  key={3}
                  step={0.1}
                  type="number"
                  min={0.25}
                  max={2}
                  className="ant-input"
                  placeholder="Digite o valor"
                  disabled={getPropertyValue(DevicePropertyType.EventoDeteccaoCurvaAcentuada) !== '1'}
                  value={getValueFieldLinkedWithCheckbox(
                    DevicePropertyType.EventoDeteccaoCurvaAcentuada,
                    DevicePropertyType.SensibilidadeDeteccaoCurvaAcentuada,
                  )}
                  name={`${DevicePropertyType.SensibilidadeDeteccaoCurvaAcentuada}`}
                  onChange={handleOnChangeEventPropertyValue}
                  onBlur={handleOnBlurEventValidateNumber}
                  style={{ width: '8rem' }}
                />
              </BaseFormInputItem>
            </ShouldRenderInput>
          </S.FieldCheckboxValue>
        </SE.EventsItem>
        <SE.EventsItem>
          <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoDeteccaoModoBateriaInterna} properties={properties}>
            <Checkbox
              key={55}
              checked={getPropertyValue(DevicePropertyType.EventoDeteccaoModoBateriaInterna) === '1'}
              name={`${DevicePropertyType.EventoDeteccaoModoBateriaInterna}`}
              onChange={handleOnChangeEventCheckboxProperty}
            >
              <S.CheckboxLabel>Modo bateria interna</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </SE.EventsItem>
        <SE.EventsItem>
          <S.FieldCheckboxValue>
            <ShouldRenderInput
              idEmbarcado={DevicePropertyType.EventoDeteccaoBateriaInternaBaixa}
              properties={properties}
            >
              <S.CheckboxContainer>
                <Checkbox
                  key={57}
                  checked={getPropertyValue(DevicePropertyType.EventoDeteccaoBateriaInternaBaixa) === '1'}
                  name={`${DevicePropertyType.EventoDeteccaoBateriaInternaBaixa}`}
                  onChange={(e) => handleOnChangeEventCheckboxProperty(e, [DevicePropertyType.LimiteBateriaBaixa])}
                  style={{ width: '13rem' }}
                >
                  <S.CheckboxLabel>Limite bateria interna baixa (V)</S.CheckboxLabel>
                  <S.InputObs>3,4 a 3,6</S.InputObs>
                </Checkbox>
              </S.CheckboxContainer>
            </ShouldRenderInput>
            <ShouldRenderInput idEmbarcado={DevicePropertyType.LimiteBateriaBaixa} properties={properties}>
              <BaseFormInputItem>
                <Input
                  key={3}
                  step={0.1}
                  type="number"
                  min={3.4}
                  max={3.6}
                  className="ant-input"
                  placeholder="Digite o valor"
                  disabled={getPropertyValue(DevicePropertyType.EventoDeteccaoBateriaInternaBaixa) !== '1'}
                  value={getValueFieldLinkedWithCheckbox(
                    DevicePropertyType.EventoDeteccaoBateriaInternaBaixa,
                    DevicePropertyType.LimiteBateriaBaixa,
                  )}
                  name={`${DevicePropertyType.LimiteBateriaBaixa}`}
                  onChange={handleOnChangeEventPropertyValue}
                  onBlur={handleOnBlurEventValidateNumber}
                  style={{ width: '8rem' }}
                />
              </BaseFormInputItem>
            </ShouldRenderInput>
          </S.FieldCheckboxValue>
        </SE.EventsItem>
        <SE.EventsItem>
          <ShouldRenderInput idEmbarcado={DevicePropertyType.MensagemRecebidaSerial} properties={properties}>
            <Checkbox
              key={21}
              checked={getPropertyValue(DevicePropertyType.MensagemRecebidaSerial) === '1'}
              name={`${DevicePropertyType.MensagemRecebidaSerial}`}
              onChange={handleOnChangeEventCheckboxProperty}
              style={{ width: '13rem' }}
            >
              <S.CheckboxLabel>TTL</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </SE.EventsItem>
        <SE.EventsItem>
          <S.FieldCheckboxValue style={{ gap: '.3rem' }}>
            <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoLimiteRpm} properties={properties}>
              <S.CheckboxContainer>
                <Checkbox
                  key={18}
                  checked={getPropertyValue(DevicePropertyType.EventoLimiteRpm) === '1'}
                  name={`${DevicePropertyType.EventoLimiteRpm}`}
                  onChange={(e) =>
                    handleOnChangeEventCheckboxProperty(e, [
                      DevicePropertyType.LimiteRpmFaixaAmarela,
                      DevicePropertyType.LimiteRpmFaixaVermelha,
                    ])
                  }
                  style={{ width: '13rem' }}
                >
                  <S.CheckboxLabel>Limite de rpm (alto e médio)</S.CheckboxLabel>
                  <S.InputObs>1000 a 7000 rpm</S.InputObs>
                </Checkbox>
              </S.CheckboxContainer>
            </ShouldRenderInput>
            <ShouldRenderInput idEmbarcado={DevicePropertyType.LimiteRpmFaixaVermelha} properties={properties}>
              <BaseFormInputItem>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Input
                    key={20}
                    type="number"
                    min={1000}
                    max={7000}
                    className="ant-input"
                    placeholder="Digite o valor"
                    disabled={getPropertyValue(DevicePropertyType.EventoLimiteRpm) !== '1'}
                    value={getValueFieldLinkedWithCheckbox(
                      DevicePropertyType.EventoLimiteRpm,
                      DevicePropertyType.LimiteRpmFaixaVermelha,
                    )}
                    name={`${DevicePropertyType.LimiteRpmFaixaVermelha}`}
                    onChange={handleOnChangeEventPropertyValue}
                    onBlur={handleOnBlurEventValidateNumber}
                    style={{ width: '8rem' }}
                  />
                  <S.InputObs style={{ fontSize: '0.875rem' }}>Limite rpm alto</S.InputObs>
                </div>
              </BaseFormInputItem>
            </ShouldRenderInput>
            <ShouldRenderInput idEmbarcado={DevicePropertyType.LimiteRpmFaixaAmarela} properties={properties}>
              <BaseFormInputItem>
                <SE.InputsContainer>
                  <Input
                    key={19}
                    type="number"
                    min={1000}
                    max={7000}
                    className="ant-input"
                    placeholder="Digite o valor"
                    disabled={getPropertyValue(DevicePropertyType.EventoLimiteRpm) !== '1'}
                    value={getValueFieldLinkedWithCheckbox(
                      DevicePropertyType.EventoLimiteRpm,
                      DevicePropertyType.LimiteRpmFaixaAmarela,
                    )}
                    name={`${DevicePropertyType.LimiteRpmFaixaAmarela}`}
                    onChange={handleOnChangeEventPropertyValue}
                    onBlur={handleOnBlurEventValidateNumber}
                    style={{ width: '8rem' }}
                  />
                  <S.InputObs style={{ fontSize: '0.875rem' }}>Limite rpm médio</S.InputObs>
                </SE.InputsContainer>
              </BaseFormInputItem>
            </ShouldRenderInput>
          </S.FieldCheckboxValue>
        </SE.EventsItem>
      </SE.Container>
    </Form>
  );
};

export default Eventos;
