import { Col, Row, Space } from 'antd';
import { BaseFormInputItem } from '../../forms/components/BaseFormInputItem/BaseFormInputItem';
import { Option, Select } from '../../selects/Select/Select';
import { Radio, RadioGroup } from '../../Radio/Radio';
import { Input } from '../../inputs/Input/Input';
import ShouldRenderInput from '../ShouldRenderInput';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import { PropertyModel } from '@app/domain/property/propertyModel';
import { ProtocolCommunication } from '@app/domain/protocolCommunication/protocolCommunicationModel';

interface IdentificadorMotoristaProps {
  properties: PropertyModel[];
  getPropertyValue: (idEmbarcado: DevicePropertyType) => string;
  handleChangeDeviceProfileProperties: (properties: DeviceProfileProperty[]) => void;
  protocols?: ProtocolCommunication[];
}

const IdentificadorMotorista: React.FC<IdentificadorMotoristaProps> = ({
  properties,
  getPropertyValue,
  handleChangeDeviceProfileProperties,
  protocols,
}) => {
  const getPropByIdEmbarcado = (idEmbarcado: DevicePropertyType) =>
    properties?.find((p) => p.idEmbarcado == idEmbarcado);

  const handleOnBlurEventValidateNumber = (e: React.FocusEvent<HTMLInputElement>) => {
    const idEmbarcado = Number(e.target.name);

    if (!idEmbarcado) return;

    let propertyValue = Number(e.target.value);
    const min = e.target.min ? Number(e.target.min) : undefined;
    const max = e.target.max ? Number(e.target.max) : undefined;

    if (min !== undefined && propertyValue < min) propertyValue = min;
    if (max !== undefined && propertyValue > max) propertyValue = max;

    handleChangePropertyValue(idEmbarcado, `${propertyValue}`);
  };

  const handleOnChangeEventPropertyValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const idEmbarcado = Number(e.target.name);
    const propertyValue = e.target.value;

    if (!idEmbarcado) return;

    if (e.target.type === 'number') {
      const max = e.target.max ? Number(e.target.max) : undefined;
      const value = Number(propertyValue);

      if (max !== undefined && value > max) return;
    }

    handleChangePropertyValue(idEmbarcado, propertyValue);
  };

  const handleChangePropertyValue = (idEmbarcado: DevicePropertyType, value: string) => {
    let props: DeviceProfileProperty[] = [];

    switch (idEmbarcado) {
      case DevicePropertyType.TipoIdentificacao:
        //passageiro
        if (value == '1') {
          props = [
            { ...getPropByIdEmbarcado(DevicePropertyType.TipoBloqueio), valorPropriedade: '1' },
            { ...getPropByIdEmbarcado(DevicePropertyType.PacoteLogout), valorPropriedade: '0' },
            { ...getPropByIdEmbarcado(DevicePropertyType.AcaoDesligarVeiculo), valorPropriedade: undefined },
            { ...getPropByIdEmbarcado(DevicePropertyType.AcaoBloqueioTemporizado), valorPropriedade: '0' },
          ] as DeviceProfileProperty[];
        }
        break;
      case DevicePropertyType.TipoBloqueio:
        //desativado
        if (value == '1') {
          props = [
            { ...getPropByIdEmbarcado(DevicePropertyType.AcaoBloqueioTemporizado), valorPropriedade: '0' },
            { ...getPropByIdEmbarcado(DevicePropertyType.AcaoDesligarVeiculo), valorPropriedade: undefined },
            { ...getPropByIdEmbarcado(DevicePropertyType.PacoteLogout), valorPropriedade: '0' },
          ] as DeviceProfileProperty[];
        }
        break;
      case DevicePropertyType.PacoteLogout:
        props = [
          {
            ...getPropByIdEmbarcado(DevicePropertyType.AcaoDesligarVeiculo),
            valorPropriedade: value == '0' ? undefined : '0',
          },
        ] as DeviceProfileProperty[];
        break;
      default:
        break;
    }

    const prop = {
      ...getPropByIdEmbarcado(idEmbarcado),
      valorPropriedade: value,
    } as DeviceProfileProperty;

    handleChangeDeviceProfileProperties([...props, prop]);
  };

  return (
    <>
      <Row gutter={18} style={{ marginTop: '1rem' }}>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.ProtocoloComunicacaoRastreador} properties={properties}>
          <Col xl={8} md={12} sm={24}>
            <BaseFormInputItem label="Protocolo de comunicação" supportText="Escolha apenas uma opção">
              <Select
                placeholder="Selecione o protocolo de comunicação"
                value={getPropertyValue(DevicePropertyType.ProtocoloComunicacaoRastreador)}
                onChange={(value) =>
                  handleChangePropertyValue(DevicePropertyType.ProtocoloComunicacaoRastreador, value as string)
                }
                showArrow
                showSearch
                options={protocols?.map((p) => ({ value: `${p.idEmbarcado}`, label: p.nome }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
              />
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput properties={properties} idEmbarcado={DevicePropertyType.TipoIdentificacao}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Tipo de identificação">
              <RadioGroup
                value={getPropertyValue(DevicePropertyType.TipoIdentificacao)}
                onChange={(e) => {
                  handleChangePropertyValue(DevicePropertyType.TipoIdentificacao, e.target.value);
                }}
              >
                <Space size={0} direction="vertical">
                  <Radio value="0">Motorista</Radio>
                  <Radio value="1">Passageiro</Radio>
                  <Radio value="2">Motorista e passageiro</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.TipoBloqueio} properties={properties}>
          <Col xl={5} md={12} sm={24}>
            <BaseFormInputItem label="Bloqueio">
              <RadioGroup
                disabled={getPropertyValue(DevicePropertyType.TipoIdentificacao) == '1'}
                value={getPropertyValue(DevicePropertyType.TipoBloqueio)}
                onChange={(e) => {
                  handleChangePropertyValue(DevicePropertyType.TipoBloqueio, e.target.value);
                }}
              >
                <Space size={0} direction="vertical">
                  <Radio value="0">Qualquer cartão RFID</Radio>
                  <Radio value="2">Somente cartões cadastrados</Radio>
                  <Radio value="1">Desativado</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput properties={properties} idEmbarcado={DevicePropertyType.PacoteLogout}>
          <Col xl={3} md={12} sm={24}>
            <BaseFormInputItem label="Pacote de logout">
              <RadioGroup
                disabled={getPropertyValue(DevicePropertyType.TipoBloqueio) == '1'}
                value={getPropertyValue(DevicePropertyType.PacoteLogout)}
                onChange={(e) => {
                  handleChangePropertyValue(DevicePropertyType.PacoteLogout, e.target.value);
                }}
              >
                <Space size={0} direction="vertical">
                  <Radio value="1">Ativo</Radio>
                  <Radio value="0">Inativo</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput properties={properties} idEmbarcado={DevicePropertyType.AcaoDesligarVeiculo}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Ação ao desligar o veículo">
              <RadioGroup
                disabled={getPropertyValue(DevicePropertyType.PacoteLogout) == '0'}
                value={getPropertyValue(DevicePropertyType.AcaoDesligarVeiculo)}
                onChange={(e) => {
                  handleChangePropertyValue(DevicePropertyType.AcaoDesligarVeiculo, e.target.value);
                }}
              >
                <Space size={0} direction="vertical">
                  <Radio value="0">Logout automático</Radio>
                  <Radio value="1">Logout por RFID</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
      </Row>
      <Row gutter={18}>
        <ShouldRenderInput properties={properties} idEmbarcado={DevicePropertyType.DelayIgnicaoLigada}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Delay de ignição ligada (s)" supportText="Entre 1 a 30 segundos">
              <Input
                type="number"
                min={1}
                max={30}
                className="ant-input"
                placeholder="Digite o intervalo"
                value={getPropertyValue(DevicePropertyType.DelayIgnicaoLigada)}
                name={`${DevicePropertyType.DelayIgnicaoLigada}`}
                onChange={handleOnChangeEventPropertyValue}
                onBlur={handleOnBlurEventValidateNumber}
              />
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput properties={properties} idEmbarcado={DevicePropertyType.DelayIgnicaoDesligada}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Delay de ignição desligada (s)" supportText="Entre 1 a 30 segundos">
              <Input
                type="number"
                min={1}
                max={30}
                name={`${DevicePropertyType.DelayIgnicaoDesligada}`}
                onChange={handleOnChangeEventPropertyValue}
                onBlur={handleOnBlurEventValidateNumber}
                className="ant-input"
                placeholder="Digite o intervalo"
                value={getPropertyValue(DevicePropertyType.DelayIgnicaoDesligada)}
              />
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput properties={properties} idEmbarcado={DevicePropertyType.AcaoBloqueioTemporizado}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Bloqueio temporizado (15s)">
              <RadioGroup
                disabled={getPropertyValue(DevicePropertyType.TipoBloqueio) == '1'}
                value={getPropertyValue(DevicePropertyType.AcaoBloqueioTemporizado)}
                onChange={(e) => {
                  handleChangePropertyValue(DevicePropertyType.AcaoBloqueioTemporizado, e.target.value);
                }}
              >
                <Space size={0} direction="vertical">
                  <Radio value="1">Ativo</Radio>
                  <Radio value="0">Inativo</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput properties={properties} idEmbarcado={DevicePropertyType.EstadoBuzzer}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Buzzer">
              <RadioGroup
                value={getPropertyValue(DevicePropertyType.EstadoBuzzer)}
                onChange={(e) => {
                  handleChangePropertyValue(DevicePropertyType.EstadoBuzzer, e.target.value);
                }}
              >
                <Space size={0} direction="vertical">
                  <Radio value="1">Ativo</Radio>
                  <Radio value="0">Inativo</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
      </Row>
      <Row gutter={18}>
        <ShouldRenderInput properties={properties} idEmbarcado={DevicePropertyType.VelocidadeTTL}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Velocidade serial - TTL (kbps)" supportText="Escolha apenas uma opção">
              <Select
                placeholder="Selecione a velocidade"
                value={getPropertyValue(DevicePropertyType.VelocidadeTTL) || undefined}
                onChange={(value) => handleChangePropertyValue(DevicePropertyType.VelocidadeTTL, value as string)}
                showArrow
                showSearch
                filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
              >
                <Option className="option-tab" value="115200">
                  115.200
                </Option>
                <Option className="option-tab" value="19200">
                  19.200
                </Option>
                <Option className="option-tab" value="9600">
                  9.600
                </Option>
              </Select>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput properties={properties} idEmbarcado={DevicePropertyType.VelocidadeRS232}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Velocidade serial - RS232 (kbps)" supportText="Escolha apenas uma opção">
              <Select
                placeholder="Selecione a velocidade"
                value={getPropertyValue(DevicePropertyType.VelocidadeRS232) || undefined}
                onChange={(value) => handleChangePropertyValue(DevicePropertyType.VelocidadeRS232, value as string)}
                showArrow
                showSearch
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option className="option-tab" value="115200">
                  115.200
                </Option>
                <Option className="option-tab" value="19200">
                  19.200
                </Option>
                <Option className="option-tab" value="9600">
                  9.600
                </Option>
              </Select>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
      </Row>
    </>
  );
};

export default IdentificadorMotorista;
