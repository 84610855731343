/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { QuestionOutlined } from '@ant-design/icons';
import { Description } from '@app/components/common/Description/Description';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { Col, Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { Button } from '@app/components/common/buttons/Button/Button';
import { setFooter } from '@app/store/slices/footerSlice';
import * as S from './create.styles';
import { notificationController } from '@app/controllers/notificationController';
import { CardSelectProfileDevice } from './components/DeviceCards/CardSelectProfileDevice/CardSelectProfileDevice';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { CreateDeviceTable } from './components/CreateDeviceTable/CreateDeviceTable';
import { CardUSB } from './components/DeviceCards/CardUSB/CardUSB';
import { ReactComponent as IconSettings } from '@app/assets/icons/settings.svg';
import { ReactComponent as IconLink } from '@app/assets/icons/link.svg';
import { ReactComponent as IconSettingCell } from '@app/assets/icons/settings_cell.svg';
import { ReactComponent as IconAccessTime } from '@app/assets/icons/access_time.svg';
import { ReactComponent as IconConnection } from '@app/assets/icons/connection_icon.svg';
import { ReactComponent as IconDownload } from '@app/assets/icons/download.svg';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { ModalTutorial } from '@app/components/common/ModalTutorial/ModalTutorial';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { HideDeviceTutorialLocalStorage } from '@app/constants/enums/device/device-tutorials-local-storage';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { FactoryDeviceService } from '@app/services/factoryDeviceService';
import { Modal } from '@app/components/common/Modal/Modal';
import DeviceSerialPort from '@app/services/deviceSerialPortClass';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import {
  DeviceProfileCommand,
  DeviceProfileModel,
  DeviceProfileProperty,
} from '@app/domain/deviceProfile/deviceProfileModel';
import { DeviceProfileService } from '@app/services/deviceProfileService';
import { ModalFastTest } from './components/ModalFastTest/ModalFastTest';
import { DeviceGroupCard } from './components/DeviceGroupCard/DeviceGroupCard';
import { DeviceGroupModel } from '@app/domain/deviceGroup/deviceGroupModel';
import { DeviceGroupService } from '@app/services/deviceGroupService';
import { CardTags } from '@app/pages/device/components/DeviceCards/CardTags/CardTags';
import { ModalIndividualTag } from '@app/pages/device/components/ModalIndividualTag/ModalIndividualTag';
import { isJM15 } from '@app/utils/utils';
import { ModalGroupTag } from '@app/pages/device/components/ModalGroupTag/ModalGroupTag';
import { TagGroupModel } from '@app/domain/tagGroup/TagGroupModel';
import { TagsGroupService } from '@app/services/tagsGroupService';
import DriverDownload from '@app/components/device/DriverDownload/DriverDownload';
import {
  utilCleanDeviceSerial,
  utilConfirmUpdateDeviceSerialProperties,
  utilSaveTagsOnDeviceSerial,
  utilUpdateDeviceSerialCommands,
  utilUpdateDeviceSerialProperties,
  utilUpdateDeviceSerialPropertiesFromDevice,
} from '@app/utils/serial';
import CollapseCard from '@app/components/common/CollapseCard/CollapseCard';
import { Panel } from '@app/components/common/Collapse/Collapse';

const deviceService = new FactoryDeviceService();
const deviceGroupService = new DeviceGroupService();
const deviceSerialPortClass = DeviceSerialPort.getInstance();
const deviceProfileClass = new DeviceProfileService();
const tagsGroupService = new TagsGroupService();

export const DeviceCreate: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [isUpdatingDevice, setIsUpdatingDevice] = useState(false);
  const [errorConnectDevice, setErrorConnectDevice] = useState(false);
  const [errorConnectDeviceMessage, setErrorConnectDeviceMessage] = useState<string>();
  const [loadingConnectDevice, setLoadingConnectDevice] = useState(true);
  const [modalTutorial, setModalTutorial] = useState(false);
  const [modalIndividualTag, setModalIndividualTag] = useState(false);
  const [modalGroupTag, setModalGroupTag] = useState(false);
  const [tagGroupsOptions, setTagGroupsOptions] = useState<TagGroupModel[]>([]);
  const [stepforModelModal, setStepforModelModal] = useState(false);
  const [isModalProgress, setIsModalProgress] = useState(false);
  const [modalFastTestVisible, setModalFastTestVisible] = useState<boolean>(false);
  const [deviceProfiles, setDeviceProfiles] = useState<DeviceProfileModel[]>([]);
  const [showCards, setShowCards] = useState<boolean>(false);
  const [clientDevices, setClientDevices] = useState<FactoryDeviceModel[]>([]);
  const [newClientDevice, setNewClientDevice] = useState<FactoryDeviceModel>({} as FactoryDeviceModel);
  const [deviceGroup, setDeviceGroup] = useState<DeviceGroupModel[]>([]);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const getUser = JSON.parse(localStorage.getItem('user') ?? '{}');

  const handleBackClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/meus-dispositivos`);
  };
  const handleCheckbox = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    setCheckboxChecked(checked);
    localStorage.setItem(HideDeviceTutorialLocalStorage.ClientDevice, checked ? 'true' : 'false');
  };
  const handleSaveClick = () => {
    // e.preventDefault();

    setTimeout(() => {
      setLoading(true);
      notificationController.success({ message: 'Cadastro de novos dispositivos finalizado com sucesso!' });
      setClientDevices([]);
      setNewClientDevice({} as FactoryDeviceModel);
      navigate(`/meus-dispositivos`);
    }, 1700);
    setLoading(false);
  };

  const fetchProfiles = () => {
    setShowCards(false);
    deviceProfileClass
      .getArray(`obter-por-idmodelo/${newClientDevice.idModelo}`)
      .then((result) => {
        setDeviceProfiles(result);
        setShowCards(true);
      })
      .catch((err) => {
        notificationController.error(err);
      });
  };

  const fetchGroups = () => {
    deviceGroupService
      .getArray('')
      .then((result) => {
        setDeviceGroup(result);
      })
      .catch((err) => {
        notificationController.error(err);
      });
  };

  const deviceIsReady = useMemo(
    () =>
      !!(
        newClientDevice.numeroSerie &&
        newClientDevice.lote &&
        newClientDevice.imei &&
        newClientDevice?.modelo &&
        newClientDevice?.versaoFirmware &&
        newClientDevice?.anatel
      ),
    [
      newClientDevice?.anatel,
      newClientDevice.imei,
      newClientDevice.lote,
      newClientDevice?.modelo,
      newClientDevice.numeroSerie,
      newClientDevice?.versaoFirmware,
    ],
  );

  const saveDevice = async () => {
    try {
      if (!newClientDevice.idPerfilDispositivo) throw Error('Necessário selecionar um idPerfil');

      setLoading(true);

      if (isJM15(newClientDevice.modelo)) {
        const properties = await deviceProfileClass.getPropertiesByIdProfile(newClientDevice.idPerfilDispositivo);
        await handleSavePropertiesOnDeviceSerialJM15(properties);
      } else {
        const commands = await deviceProfileClass.getCommandsByIdProfile(newClientDevice.idPerfilDispositivo);

        if (commands.length <= 0) {
          notificationController.error({
            message: 'Erro!',
            description: 'Não encontrado comandos para o perfil selecionado.',
          });
          return;
        }

        await handleSavePropertiesOnDeviceSerial(commands);
      }

      await deviceService.update('atualizar-dispositivo-cliente', newClientDevice);

      notificationController.success({ message: 'Dispositivo cadastrado.' });
      setNewClientDevice({} as FactoryDeviceModel);
    } catch (error: any) {
      notificationController.error({ message: 'Erro!', description: 'Disposititivo não atualizado' });
    } finally {
      await deviceSerialPortClass.close();
      setLoading(false);
      setLoadingConnectDevice(false);
    }
  };

  const saveDeviceFasTest = async (properties: DeviceProfileProperty[]) => {
    try {
      setLoading(true);
      await handleSavePropertiesOnDeviceSerial(properties);
      // await deviceService.update('atualizar-dispositivo-cliente', newClientDevice);
      notificationController.success({ message: 'Dispositivo cadastrado.' });
      setLoading(false);
    } catch (error: any) {
      notificationController.error({ message: 'Erro!', description: 'Disposititivo não atualizado' });
      setLoading(false);
    } finally {
      await deviceSerialPortClass.close();
    }
  };

  // serial port functions
  const handleSavePropertiesOnDeviceSerial = async (commands: DeviceProfileCommand[]) => {
    try {
      await utilCleanDeviceSerial(newClientDevice);
      await utilUpdateDeviceSerialPropertiesFromDevice(newClientDevice, isUpdatingDevice);
      await utilUpdateDeviceSerialCommands(commands);
      await utilConfirmUpdateDeviceSerialProperties(isUpdatingDevice);
      setClientDevices([...clientDevices.filter((d) => d.id != newClientDevice.id), newClientDevice]);
      setDeviceProfiles([]);
      setShowCards(false);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: 'Não foi possível finalizar a atualização do dispositivo, existem parâmetros inválidos.',
      });
      throw Error();
    }
  };

  // JM15 serial port functions
  const handleSavePropertiesOnDeviceSerialJM15 = async (properties: DeviceProfileProperty[]) => {
    try {
      await utilUpdateDeviceSerialPropertiesFromDevice(newClientDevice, isUpdatingDevice);
      await utilUpdateDeviceSerialProperties(properties);
      await utilSaveTagsOnDeviceSerial(newClientDevice, tagGroupsOptions);
      // const deviceTags = await deviceSerialPortClass.get(DevicePropertyType.ListaTags);

      setClientDevices([...clientDevices.filter((d) => d.id != newClientDevice.id), newClientDevice]);
      setDeviceProfiles([]);
      setShowCards(false);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: 'Não foi possível finalizar a atualização do dispositivo, existem parâmetros inválidos.',
      });
      throw Error();
    }
  };

  const synchronize = async () => {
    try {
      setLoadingConnectDevice(true);

      const deviceConnected = await deviceSerialPortClass.connect();

      if (!deviceConnected) {
        setErrorConnectDevice(true);
        setLoadingConnectDevice(false);
        return;
      }

      setErrorConnectDevice(false);
      setLoadingConnectDevice(false);

      const imei = await deviceSerialPortClass.get(DevicePropertyType.Imei);

      let deviceResponse;
      try {
        deviceResponse = await deviceService.get(`obter-por-imei/${imei}`);
        if (!deviceResponse?.id) throw Error('Dispositivo não encontrado ou configurado pela Fábrica.');
      } catch (error) {
        throw new Error('Dispositivo não encontrado ou configurado pela Fábrica.');
      }

      if (id && Number(id) != deviceResponse.id) throw Error('Dispositivo sincronizado é diferente do selecionado.');

      notificationController.success({ message: 'Dispositivo conectado.' });

      setIsUpdatingDevice(id || deviceResponse?.idPerfilDispositivo ? true : false);
      setNewClientDevice({ ...deviceResponse, idCliente: getUser.idCliente ?? 1 });

      if (newClientDevice.idModelo === 41) {
        setStepforModelModal(true);
      }
    } catch (error: any) {
      notificationController.error(error);
      setErrorConnectDevice(true);
      setLoadingConnectDevice(false);
      setErrorConnectDeviceMessage('Dispositivo não encontrado ou configurado pela Fábrica.');
      throw error;
    }
  };

  const stepModel = () => (
    <ModalTutorial
      title={`Tutorial para ${isUpdatingDevice ? 'atualização' : 'cadastro'} do dispositivo`}
      open={stepforModelModal}
      onCancel={() => setStepforModelModal(false)}
      onOk={() => setStepforModelModal(false)}
      okText="Vamos lá!"
    >
      <Row align="middle">
        <Col xs={24} md={24}>
          <S.ContainerModal>
            <S.WapperModal>
              <IconConnection />
              <S.TitleModal>Etapa 5</S.TitleModal>
            </S.WapperModal>
            <S.SubtitleModal>Selecione cadastrar tag individual ou grupo de tags.</S.SubtitleModal>
          </S.ContainerModal>
        </Col>
        <Col xs={24} md={24}>
          <S.ContainerModal>
            <S.WapperModal>
              <IconConnection />
              <S.TitleModal>Etapa 5.1</S.TitleModal>
            </S.WapperModal>
            <S.SubtitleModal>
              Tag individual - Aproxime a tag ao dispositivo ou informe manualmente o seu ID para realizar o vínculo. (É
              uma boa prática vincular as tags por aproximação para evitar falhas no cadastro.)
            </S.SubtitleModal>
          </S.ContainerModal>
        </Col>
        <Col xs={24} md={24}>
          <S.ContainerModal>
            <S.WapperModal>
              <IconConnection />
              <S.TitleModal>Etapa 5.1</S.TitleModal>
            </S.WapperModal>
            <S.SubtitleModal>Grupo de tag - Selecione o grupo de tags.</S.SubtitleModal>
          </S.ContainerModal>
        </Col>

        <Col xs={24} md={24}>
          <S.ContainerModal>
            <S.WapperModal>
              <IconAccessTime />
              <S.TimeModal>Tempo necessário: 1 minuto</S.TimeModal>
            </S.WapperModal>
          </S.ContainerModal>
        </Col>

        <Col xs={24} md={24}>
          <S.ContainerModal>
            <S.WapperModal>
              <Checkbox
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  alignSelf: 'flex-end',
                  flexDirection: 'row',
                }}
                checked={checkboxChecked}
                value={checkboxChecked}
                onChange={(e: CheckboxChangeEvent) => handleCheckbox(e)}
              >
                <S.CheckboxModal>Não mostrar novamente</S.CheckboxModal>
              </Checkbox>
            </S.WapperModal>
          </S.ContainerModal>
        </Col>
      </Row>
    </ModalTutorial>
  );

  const fetchTagGroups = () => {
    tagsGroupService
      .getArray('')
      .then((result) => {
        setTagGroupsOptions(result);
      })
      .catch((err) => {
        notificationController.error(err);
      });
  };

  //effects
  useEffect(() => {
    if (deviceIsReady) {
      fetchProfiles();
    }
    if (isJM15(newClientDevice.modelo)) {
      fetchTagGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceIsReady]);

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    localStorage.setItem('clientDevices', JSON.stringify(clientDevices));
  }, [clientDevices]);

  useEffect(() => {
    const dontShowModalTutorial = localStorage.getItem(HideDeviceTutorialLocalStorage.ClientDevice) === 'true';
    setCheckboxChecked(dontShowModalTutorial);
    setModalTutorial(!dontShowModalTutorial);
  }, []);

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: isUpdatingDevice ? 'Alterar perfil de configuração' : 'Novo(s) dispositivo(s)',
        handleBackClick: handleBackClick,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdatingDevice]);

  useEffect(() => setIsUpdatingDevice(id ? true : false), [id]);

  useEffect(() => {
    dispatch(
      setFooter({
        confirmButtonText: id ? 'Salvar' : 'Finalizar cadastro',
        confirmButtonDisabled: clientDevices.length === 0,
        handleConfirmButtonClick: handleSaveClick,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientDevices.length, dispatch]);

  return (
    <>
      <ModalFastTest
        device={newClientDevice}
        visible={modalFastTestVisible}
        setVisible={setModalFastTestVisible}
        saveDeviceFasTest={saveDeviceFasTest}
      />
      {modalTutorial && (
        <ModalTutorial
          title={`Tutorial para ${isUpdatingDevice ? 'atualização' : 'cadastro'} do dispositivo`}
          open={modalTutorial}
          onCancel={() => setModalTutorial(false)}
          onOk={() => setModalTutorial(false)}
          okText="Vamos lá!"
        >
          <Row align="middle">
            <Col xs={24} md={24}>
              <S.ContainerModal>
                <S.WapperModal>
                  <IconDownload />
                  <S.TitleModal>Etapa 1</S.TitleModal>
                </S.WapperModal>
                <S.SubtitleModal>
                  Caso seja necessário instalar o driver do dispositivo clique na aba de “Drivers”.
                </S.SubtitleModal>
              </S.ContainerModal>
            </Col>
            <Col xs={24} md={24}>
              <S.ContainerModal>
                <S.WapperModal>
                  <IconLink />
                  <S.TitleModal>Etapa 2</S.TitleModal>
                </S.WapperModal>
                <S.SubtitleModal>Pegue o dispositivo e conecte a saída USB-C dele no computador.</S.SubtitleModal>
              </S.ContainerModal>
            </Col>

            <Col xs={24} md={24}>
              <S.ContainerModal>
                <S.WapperModal>
                  <IconSettingCell />
                  <S.TitleModal>Etapa 3</S.TitleModal>
                </S.WapperModal>
                <S.SubtitleModal>
                  Clique no botão de sincronizar e depois autorize o navegador para abrir a conexão com o dispositivo.
                </S.SubtitleModal>
              </S.ContainerModal>
            </Col>
            <Col xs={24} md={24}>
              <S.ContainerModal>
                <S.WapperModal>
                  <IconSettings />
                  <S.TitleModal>Etapa 4</S.TitleModal>
                </S.WapperModal>
                <S.SubtitleModal>Selecione o perfil de configuração.</S.SubtitleModal>
              </S.ContainerModal>
            </Col>

            <Col xs={24} md={24}>
              <S.ContainerModal>
                <S.WapperModal>
                  <IconAccessTime />
                  <S.TimeModal>Tempo necessário: 1 minuto</S.TimeModal>
                </S.WapperModal>
              </S.ContainerModal>
            </Col>

            <Col xs={24} md={24}>
              <S.ContainerModal>
                <S.WapperModal>
                  <Checkbox
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                      alignSelf: 'flex-end',
                      flexDirection: 'row',
                    }}
                    checked={checkboxChecked}
                    value={checkboxChecked}
                    onChange={(e: CheckboxChangeEvent) => handleCheckbox(e)}
                  >
                    <S.CheckboxModal>Não mostrar novamente</S.CheckboxModal>
                  </Checkbox>
                </S.WapperModal>
              </S.ContainerModal>
            </Col>
          </Row>
        </ModalTutorial>
      )}
      {isJM15(newClientDevice.modelo) && stepModel()}
      <Modal
        open={isModalProgress}
        footer={null}
        onCancel={() => setIsModalProgress(false)}
        width={700}
        style={{ marginTop: '8rem', padding: '1rem' }}
      >
        <Row align="middle" justify="center">
          <Col>{/* <Progress type="circle" percent={progress} width={90} strokeWidth={13} /> */}</Col>
          <Col>
            <S.TitleProgress>Aguarde enquanto carregamos as informações do dispositivo</S.TitleProgress>
          </Col>
          <Col>
            <S.ContainerModal>
              <S.WapperModal>
                <IconAccessTime />
                <S.TimeModal>Tempo estimado: 10 segundos</S.TimeModal>
              </S.WapperModal>
            </S.ContainerModal>
          </Col>
        </Row>
      </Modal>

      <ModalIndividualTag
        visible={modalIndividualTag}
        setVisible={setModalIndividualTag}
        device={newClientDevice}
        setDevice={setNewClientDevice}
      />

      <ModalGroupTag
        visible={modalGroupTag}
        setVisible={setModalGroupTag}
        device={newClientDevice}
        setDevice={setNewClientDevice}
        tagGroupsOptions={tagGroupsOptions}
      />

      <PageContainer>
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Spinner spinning={loading}>
            <Row>
              <S.ContainerNewDevice>
                <Description
                  title={isUpdatingDevice ? 'Leitura do dispositivo' : 'Cadastro do dispositivo'}
                  subtitle={`Siga as etapas para concluir ${
                    isUpdatingDevice ? 'a alteração do perfil de configuração' : 'o cadastro do dispositivo'
                  }`}
                  icon={<QuestionOutlined />}
                  iconTooltipText="Visualizar tutorial"
                  iconOnClick={() => {
                    setModalTutorial(true);
                  }}
                >
                  <CollapseCard>
                    <Panel header="Drivers" key="1">
                      <DriverDownload />
                    </Panel>
                    <Panel header="Conecte o USB" key="2">
                      <CardUSB
                        device={newClientDevice}
                        synchronize={synchronize}
                        errorConnecting={errorConnectDevice}
                        errorConnectingMessage={errorConnectDeviceMessage}
                        loading={loadingConnectDevice}
                      />
                    </Panel>
                    {showCards && (
                      <>
                        <Panel header="Selecione o perfil de configuração" key="4">
                          <CardSelectProfileDevice
                            deviceProfiles={deviceProfiles}
                            newDevice={newClientDevice}
                            setNewDevice={setNewClientDevice}
                            setModalFastTestVisible={setModalFastTestVisible}
                          />
                        </Panel>
                        <Panel header="Selecione o grupo de dispositivo" key="3">
                          <DeviceGroupCard
                            newClientDevice={newClientDevice}
                            setNewClientDevice={setNewClientDevice}
                            deviceGroups={deviceGroup}
                          />
                        </Panel>
                        {isJM15(newClientDevice.modelo) && (
                          <Panel header="Vincular" key="5">
                            <CardTags
                              newDevice={newClientDevice}
                              openModalIndividualTag={() => setModalIndividualTag(true)}
                              openModalGroupTag={() => setModalGroupTag(true)}
                            />
                          </Panel>
                        )}
                      </>
                    )}
                  </CollapseCard>
                </Description>

                <S.ButtonContainer>
                  <Button
                    style={{ width: '14.75rem', height: '3.5rem' }}
                    type="primary"
                    onClick={() => saveDevice()}
                    disabled={!newClientDevice.idPerfilDispositivo}
                  >
                    {isUpdatingDevice ? 'Alterar' : 'Adicionar'}
                  </Button>
                </S.ButtonContainer>
              </S.ContainerNewDevice>
              <S.ContainerListDevices>
                <Description
                  title={`Dispositivos ${isUpdatingDevice ? 'alterados' : 'cadastrados'}`}
                  subtitle={
                    isUpdatingDevice
                      ? 'Após a leitura de dispositivo e selecionar o perfil que deseja alterar, clique em salvar para finalizar'
                      : 'Após a leitura de todos os dispositivos, clique em finalizar cadastro'
                  }
                >
                  <CreateDeviceTable devices={clientDevices} />
                </Description>
              </S.ContainerListDevices>
            </Row>
          </Spinner>
        </BaseForm>
      </PageContainer>
    </>
  );
};
export default DeviceCreate;
