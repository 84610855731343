import React from 'react';
import { Col, Row } from 'antd';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '@app/components/common/selects/Select/Select';
import { DeviceGroupModel } from '@app/domain/deviceGroup/deviceGroupModel';
import MaskedInput from 'react-text-mask';
import { CloseCircleFilled } from '@ant-design/icons';

interface IDeviceGroupCard {
  deviceGroups: DeviceGroupModel[];
  newClientDevice: FactoryDeviceModel;
  setNewClientDevice: React.Dispatch<React.SetStateAction<FactoryDeviceModel>>;
}

export const DeviceGroupCard: React.FC<IDeviceGroupCard> = ({ deviceGroups, newClientDevice, setNewClientDevice }) => {
  return (
    <>
      {/* <Card style={{ marginBottom: '1rem' }}> */}
      <BaseForm layout="vertical" style={{ width: '100%' }}>
        {/* <CardInfoTitle>Selecione o grupo do dispositivo</CardInfoTitle> */}

        <Row gutter={18} style={{ marginBottom: '1rem' }}>
          <Col xs={24} md={12}>
            <BaseFormInputItem label="Apelido" supportText="Opcional">
              <MaskedInput
                className="ant-input"
                mask={() => Array(24).fill(/[a-zA-Z0-9-_]/)}
                guide={false}
                type="text"
                key={1}
                value={newClientDevice.apelido}
                placeholder="Digite o apelido"
                maxLength={12}
                onChange={(event) =>
                  setNewClientDevice((prevState) => ({
                    ...prevState,
                    apelido: event.target.value,
                  }))
                }
              />
            </BaseFormInputItem>
          </Col>
          <Col xs={24} md={12}>
            <BaseFormInputItem label="Grupo" supportText="Opcional">
              <Select
                showArrow
                showSearch
                allowClear
                clearIcon={<CloseCircleFilled style={{ color: 'var(--secondary-color)' }} />}
                placeholder="Selecione o grupo"
                value={newClientDevice.idGrupo}
                onChange={(value) =>
                  setNewClientDevice((prevState) => ({
                    ...prevState,
                    idGrupo: value as number,
                  }))
                }
                options={deviceGroups.map((g) => ({
                  value: g.id,
                  label: `${g.nome}`,
                }))}
                filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              />
            </BaseFormInputItem>
          </Col>
        </Row>
      </BaseForm>
      {/* </Card> */}
    </>
  );
};
