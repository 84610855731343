import { Button } from '@app/components/common/buttons/Button/Button';
import { CollapseStep } from '@app/components/common/CollapseStep/CollapseStep';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';
import { Input } from '@app/components/common/inputs/Input/Input';
import { Radio, RadioChangeEvent, RadioGroup } from '@app/components/common/Radio/Radio';
import { FenceGroupModel } from '@app/domain/fence/fenceGroupModel';
import { FenceModel } from '@app/domain/fence/fenceModel';
import { Col, Row, Space, Tooltip } from 'antd';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { MapFenceCreate } from '../../../../components/common/FenceMap/map-fence-create';
import { FenceType } from '@app/constants/enums/fence/fence-type-enum';
import IModelDeviceService, { ModelDeviceService } from '@app/services/modelDeviceService';
import { ModelDeviceModel } from '@app/domain/modelDevice/modelDeviceModel';
import { notificationController } from '@app/controllers/notificationController';
import * as S from './styles';
import { CollapseStepHeader } from '@app/components/common/CollapseStep/CollapseStepHeader';
import { Panel } from '@app/components/common/Collapse/Collapse';

const modelService: IModelDeviceService = new ModelDeviceService();

interface CreateFenceStepTwoProps {
  groupFence?: FenceGroupModel;
  setGroupFence: React.Dispatch<React.SetStateAction<FenceGroupModel | undefined>>;
  showTutorial: boolean;
}

export const CreateFenceStepTwo: FC<CreateFenceStepTwoProps> = ({ groupFence, setGroupFence, showTutorial }) => {
  const [fence, setFence] = useState<FenceModel>({} as FenceModel);
  const [model, setModel] = useState<ModelDeviceModel>();
  const [errorDescription, setErrorDescription] = useState<string>();
  const [stepFenceFinished, setStepFenceFinished] = useState<boolean>(false);
  const [stepConfigFinished, setStepConfigFinished] = useState<boolean>(false);
  const [collapseActiveKey, setCollapseActiveKey] = useState<string | string[]>();

  const handleRemoveFence = (fence: FenceModel) => {
    setGroupFence({ ...groupFence, cercas: groupFence?.cercas?.filter((c) => c.nome != fence.nome) });
    notificationController.warning({ message: `Cerca ${fence.nome} removida.` });
  };
  const handleActivateInactivateFence = (fence: FenceModel) => {
    setGroupFence((prevState) => ({
      ...prevState,
      cercas: prevState?.cercas?.map((c) => (c.nome == fence.nome ? { ...c, ativo: !c.ativo } : c)),
    }));
    notificationController.success({
      message: 'Sucesso!',
      description: `Cerca ${fence.nome} ${fence.ativo ? 'inativada' : 'ativada'}.`,
    });
  };
  const handleSelectFence = (fence: FenceModel) => {
    setGroupFence((prevState) => ({
      ...prevState,
      cercas: prevState?.cercas?.map((c) =>
        c.nome == fence.nome ? { ...c, selected: c.selected === true ? false : true } : { ...c, selected: false },
      ),
    }));
  };
  const handleAddFenceToGroup = useCallback(() => {
    if (!!groupFence?.cercas?.find((c) => c.nome == fence.nome)) {
      notificationController.error({
        message: 'Atenção!',
        description: 'Não foi possível adicionar a cerca, já existe uma cerca adicionada com esse nome.',
      });
      setCollapseActiveKey('1');
      return;
    }

    const fenceToAdd = {
      ...fence,
      ativo: true,
      eventos: {
        ...fence.eventos,
        entradaSaida: !fence.eventos?.entradaSaida ? 0 : fence.eventos?.entradaSaida,
        entradaSaidaPeriferico: !fence.eventos?.entradaSaidaPeriferico ? 0 : fence.eventos?.entradaSaidaPeriferico,
        limiteVelocidade: !fence.eventos?.limiteVelocidade ? 0 : fence.eventos?.limiteVelocidade,
        limiteVelocidadePulsado: !fence.eventos?.limiteVelocidadePulsado ? 0 : fence.eventos?.limiteVelocidadePulsado,
      },
    };

    setGroupFence((prev) => ({ ...prev, cercas: [...(prev?.cercas ?? []), fenceToAdd] }));
    setFence({ eventos: { limiteVelocidade: 0, limiteVelocidadePulsado: 0, entradaSaidaPeriferico: 0 } } as FenceModel);
    setCollapseActiveKey('1');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fence]);
  const handleOnChangeInputFenceEvent = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === 'number') {
      const max = e.target.max ? Number(e.target.max) : undefined;
      const value = Number(e.target.value);

      if (max !== undefined && value > max) return;
    }

    setFence({ ...(fence ?? ({} as FenceModel)), eventos: { ...fence?.eventos, [e.target.name]: e.target.value } });
  };
  const handleOnBlurEventValidateNumber = (e: React.FocusEvent<HTMLInputElement>) => {
    let value = Number(e.target.value);
    const min = e.target.min ? Number(e.target.min) : undefined;
    const max = e.target.max ? Number(e.target.max) : undefined;

    if (min !== undefined && value < min) value = min;
    if (max !== undefined && value > max) value = max;

    setFence({ ...(fence ?? ({} as FenceModel)), eventos: { ...fence?.eventos, [e.target.name]: Math.round(value) } });
  };
  const handleChangeRadio = (e: RadioChangeEvent) => {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case 'entradaSaida':
        if (value === 0) {
          setFence({
            ...(fence ?? ({} as FenceModel)),
            eventos: {
              ...fence?.eventos,
              entradaSaida: value,
              entradaSaidaPeriferico: 0,
              entradaSaidaCiclos: 0,
              entradaSaidaTempoDesligado: 0,
              entradaSaidaTempoLigado: 0,
            },
          });
          return;
        }
        setFence({ ...(fence ?? ({} as FenceModel)), eventos: { ...fence?.eventos, entradaSaida: value } });
        break;
      case 'entradaSaidaPeriferico':
        setFence({
          ...(fence ?? ({} as FenceModel)),
          eventos: {
            ...fence?.eventos,
            entradaSaidaPeriferico: value,
            entradaSaidaCiclos: value !== 1 ? 0 : 30,
            entradaSaidaTempoLigado: value !== 1 ? 0 : 10,
            entradaSaidaTempoDesligado: value !== 1 ? 0 : 5,
          },
        });
        break;
      case 'limiteVelocidade':
        setFence({
          ...(fence ?? ({} as FenceModel)),
          eventos: {
            ...fence?.eventos,
            limiteVelocidade: value,
            limiteVelocidadeValor: value === 0 ? 0 : 70,
            limiteVelocidadePulsado: 0,
            limiteVelocidadeCiclos: 0,
            limiteVelocidadeTempoLigado: 0,
            limiteVelocidadeTempoDesligado: 0,
          },
        });
        break;
      case 'limiteVelocidadePulsado':
        setFence({
          ...(fence ?? ({} as FenceModel)),
          eventos: {
            ...fence?.eventos,
            limiteVelocidadePulsado: value,
            limiteVelocidadeCiclos: value !== 1 ? 0 : 30,
            limiteVelocidadeTempoLigado: value !== 1 ? 0 : 10,
            limiteVelocidadeTempoDesligado: value !== 1 ? 0 : 5,
          },
        });
        break;
      default:
        break;
    }
  };

  useMemo(() => {
    if (groupFence?.idModelo)
      modelService
        .get(`${groupFence?.idModelo}`)
        .then((res) => setModel(res))
        .catch(() =>
          notificationController.error({
            message: 'Erro!',
            description: 'Não foi possível buscar as propriedades do modelo selecionado.',
          }),
        );
  }, [groupFence?.idModelo]);

  // valida step de pontos e nome
  useEffect(() => {
    let error = '';
    let validFenceStep = !!fence.nome && !!fence.idTipoCerca;

    try {
      if (!validFenceStep) {
        error = 'Preencha os campos de nome e tipo da cerca';
        return;
      }

      switch (fence.idTipoCerca) {
        case FenceType.Poligono:
          validFenceStep = fence.pontos?.length >= 3;
          if (!validFenceStep) error = 'Selecione ao menos 3 pontos no mapa.';
          break;
        case FenceType.PontoRaio:
          validFenceStep = fence.pontos?.length == 1 && !!fence.raio;
          if (!validFenceStep) error = 'Selecione 1 ponto e preencha o raio no mapa.';
          break;
        case FenceType.Rota:
          validFenceStep = fence.pontos?.length >= 1 && !!fence.largura;
          if (!validFenceStep) error = 'Selecione 1 ponto e preencha a largura no mapa.';
          break;
        default:
          break;
      }
    } finally {
      setStepFenceFinished(validFenceStep);
      setErrorDescription(error);
    }
  }, [fence]);

  // valida step de evento
  useEffect(() => {
    if (!stepFenceFinished) return;

    let error = '';
    let validFenceStep = true;

    try {
      if (!fence.eventos?.entradaSaida && !fence.eventos?.limiteVelocidade) {
        validFenceStep = false;
        error = 'É obrigatório selecionar pelo menos um evento: entrada/saída e/ou limite de velocidade.';
        return;
      }
    } finally {
      setErrorDescription(error);
      setStepConfigFinished(validFenceStep);
    }
  }, [fence.eventos, stepFenceFinished]);

  return (
    <Row gutter={8}>
      <Col xs={24} sm={8} style={{ height: 'calc(100vh - 19.7rem)' }}>
        <S.StepsContainer>
          <div>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <CollapseStep
                  defaultActiveKey="1"
                  activeKey={collapseActiveKey}
                  onChange={(key) => setCollapseActiveKey(key)}
                >
                  <Panel
                    key="1"
                    header={
                      <CollapseStepHeader
                        finished={stepFenceFinished}
                        title="Adicionar cercas"
                        helperTextList={[
                          'Cadastre uma cerca de cada vez.',
                          'Preencha o campo “Cerca” e, em seguida, escolha o “tipo de cerca” e marque a localização da cerca no mapa.',
                        ]}
                      />
                    }
                  >
                    <BaseForm layout="vertical">
                      <Row>
                        <HelperNotification>
                          Escolha o tipo da cerca e depois selecione os pontos no mapa ao lado antes de clicar no botão
                          “Adicionar cerca”.
                        </HelperNotification>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <BaseFormInputItem label="Cerca">
                            <>
                              <Input
                                placeholder="Digite o nome da cerca"
                                maxLength={15}
                                value={fence?.nome}
                                onChange={(event) =>
                                  setFence({ ...(fence ?? ({} as FenceModel)), nome: event.target.value })
                                }
                                onBlur={() => setFence({ ...(fence ?? ({} as FenceModel)), nome: fence?.nome?.trim() })}
                              />
                            </>
                          </BaseFormInputItem>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <BaseFormInputItem label="Tipo de cerca">
                            <Row gutter={8}>
                              <Col>
                                <Button
                                  onClick={() => setFence({ ...(fence ?? ({} as FenceModel)), idTipoCerca: 1 })}
                                  type={fence?.idTipoCerca == 1 ? 'primary' : 'default'}
                                  shape="round"
                                >
                                  Polígono
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  onClick={() => setFence({ ...(fence ?? ({} as FenceModel)), idTipoCerca: 2 })}
                                  type={fence?.idTipoCerca == 2 ? 'primary' : 'default'}
                                  shape="round"
                                >
                                  Ponto e raio
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  onClick={() => setFence({ ...(fence ?? ({} as FenceModel)), idTipoCerca: 3 })}
                                  type={fence?.idTipoCerca == 3 ? 'primary' : 'default'}
                                  shape="round"
                                >
                                  Rota
                                </Button>
                              </Col>
                            </Row>
                          </BaseFormInputItem>
                        </Col>
                      </Row>
                    </BaseForm>
                  </Panel>
                  <Panel
                    key="2"
                    collapsible={!stepFenceFinished ? 'disabled' : undefined}
                    header={
                      <CollapseStepHeader
                        errorDescription={errorDescription}
                        finished={stepConfigFinished}
                        title="Evento de entrada/saída da cerca"
                        helperTextList={[
                          'Obrigatório selecionar pelo menos um evento, seja “Evento de entrada/saída da cerca” ou “Evento de limite de velocidade” e essa opção precisa ser diferente de “Não se aplica”.',
                          'O preenchimento do periférico é opcional. ',
                          'Após isso, clique em “Adicionar cerca” para concluir o cadastro.',
                        ]}
                      />
                    }
                  >
                    <BaseForm layout="vertical">
                      <Row>
                        <Col span={24}>
                          <BaseFormInputItem label="Tipo">
                            <RadioGroup
                              name="entradaSaida"
                              defaultValue={0}
                              value={fence?.eventos?.entradaSaida}
                              onChange={handleChangeRadio}
                            >
                              <Space size={0} direction="vertical">
                                <Radio value={1}>Entrada</Radio>
                                <Radio value={2}>Saída</Radio>
                                <Radio value={3}>Entrada e Saída</Radio>
                                <Radio value={0}>Não se aplica</Radio>
                              </Space>
                            </RadioGroup>
                          </BaseFormInputItem>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <BaseFormInputItem label="Periferico">
                            <RadioGroup
                              name="entradaSaidaPeriferico"
                              defaultValue={0}
                              value={fence?.eventos?.entradaSaidaPeriferico}
                              onChange={handleChangeRadio}
                              disabled={!fence?.eventos?.entradaSaida}
                              style={{ width: '100%' }}
                            >
                              <Space size={0} direction="vertical" style={{ width: '100%' }}>
                                <Radio value={1}>Pulsado</Radio>
                                <Row gutter={18} style={{ marginTop: '0.5rem' }}>
                                  <Col xs={8}>
                                    <BaseFormInputItem label="Quantidade de ciclos" supportText="Entre 1 a 100 ciclos">
                                      <Input
                                        name="entradaSaidaCiclos"
                                        type="number"
                                        placeholder="Informe a quantidade de ciclos"
                                        defaultValue={0}
                                        value={fence?.eventos?.entradaSaidaCiclos}
                                        onChange={handleOnChangeInputFenceEvent}
                                        min={1}
                                        max={100}
                                        onBlur={handleOnBlurEventValidateNumber}
                                        disabled={
                                          fence?.eventos?.entradaSaidaPeriferico !== 1 ||
                                          fence?.eventos?.entradaSaida === 0
                                        }
                                      />
                                    </BaseFormInputItem>
                                  </Col>
                                  <Col xs={8}>
                                    <BaseFormInputItem label="Tempo Ligado(s)" supportText="Entre 1 a 50 segundos">
                                      <Input
                                        name="entradaSaidaTempoLigado"
                                        type="number"
                                        step={1}
                                        placeholder="Informe a quantidade de ciclos"
                                        defaultValue={0}
                                        value={fence?.eventos?.entradaSaidaTempoLigado}
                                        onChange={handleOnChangeInputFenceEvent}
                                        min={1}
                                        max={50}
                                        onBlur={handleOnBlurEventValidateNumber}
                                        disabled={
                                          fence?.eventos?.entradaSaidaPeriferico !== 1 ||
                                          fence?.eventos?.entradaSaida === 0
                                        }
                                      />
                                    </BaseFormInputItem>
                                  </Col>
                                  <Col xs={8}>
                                    <BaseFormInputItem label="Tempo Desligado(s)" supportText="Entre 1 a 50 segundos">
                                      <Input
                                        name="entradaSaidaTempoDesligado"
                                        type="number"
                                        placeholder="Informe a quantidade de ciclos"
                                        defaultValue={0}
                                        value={fence?.eventos?.entradaSaidaTempoDesligado}
                                        onChange={handleOnChangeInputFenceEvent}
                                        min={1}
                                        max={50}
                                        onBlur={handleOnBlurEventValidateNumber}
                                        disabled={
                                          fence?.eventos?.entradaSaidaPeriferico !== 1 ||
                                          fence?.eventos?.entradaSaida === 0
                                        }
                                      />
                                    </BaseFormInputItem>
                                  </Col>
                                </Row>
                                <Radio value={2}>Corta corrente</Radio>
                                <Radio value={0}>Não se aplica</Radio>
                              </Space>
                            </RadioGroup>
                          </BaseFormInputItem>
                        </Col>
                      </Row>
                    </BaseForm>
                  </Panel>
                  <Panel
                    key="3"
                    collapsible={!stepFenceFinished ? 'disabled' : undefined}
                    header={
                      <CollapseStepHeader
                        errorDescription={errorDescription}
                        finished={stepConfigFinished}
                        title="Evento de limite de velocidade"
                        helperTextList={[
                          'Obrigatório selecionar pelo menos um evento, seja “Evento de entrada/saída da cerca” ou “Evento de limite de velocidade” e essa opção precisa ser diferente de “Não se aplica”.',
                          'O preenchimento do periférico é opcional. ',
                          'Após isso, clique em “Adicionar cerca” para concluir o cadastro.',
                        ]}
                      />
                    }
                  >
                    <BaseForm layout="vertical">
                      <Row>
                        <Col span={24}>
                          <BaseFormInputItem label="Tipo">
                            <RadioGroup
                              name="limiteVelocidade"
                              defaultValue={0}
                              value={fence?.eventos?.limiteVelocidade}
                              onChange={handleChangeRadio}
                              style={{ width: '100%' }}
                            >
                              <Space size={0} direction="vertical" style={{ width: '100%' }}>
                                <Row>
                                  <Col xs={12}>
                                    <Radio value={1}>{`Limite de velocidade (km/h)`}</Radio>
                                  </Col>
                                  <Col xs={6}>
                                    <BaseFormInputItem
                                      style={{ margin: '0' }}
                                      label=""
                                      supportText="Entre 1 a 200 Km/h"
                                    >
                                      <Input
                                        name="limiteVelocidadeValor"
                                        type="number"
                                        placeholder=""
                                        defaultValue={0}
                                        min={1}
                                        max={200}
                                        value={fence?.eventos?.limiteVelocidadeValor}
                                        onChange={handleOnChangeInputFenceEvent}
                                        onBlur={handleOnBlurEventValidateNumber}
                                        disabled={fence?.eventos?.limiteVelocidade !== 1}
                                      />
                                    </BaseFormInputItem>
                                  </Col>
                                </Row>
                                <Radio value={0}>Não se aplica</Radio>
                              </Space>
                            </RadioGroup>
                          </BaseFormInputItem>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <BaseFormInputItem label="Periferico">
                            <RadioGroup
                              name="limiteVelocidadePulsado"
                              defaultValue={0}
                              value={fence?.eventos?.limiteVelocidadePulsado}
                              onChange={handleChangeRadio}
                              disabled={fence?.eventos?.limiteVelocidade !== 1}
                              style={{ width: '100%' }}
                            >
                              <Space size={0} direction="vertical" style={{ width: '100%' }}>
                                <Radio value={1}>Pulsado</Radio>
                                <Row gutter={18} style={{ marginTop: '0.5rem' }}>
                                  <Col xs={8}>
                                    <BaseFormInputItem label="Quantidade de ciclos" supportText="Entre 1 a 100 ciclos">
                                      <Input
                                        name="limiteVelocidadeCiclos"
                                        type="number"
                                        min={1}
                                        max={100}
                                        placeholder="Informe a quantidade de ciclos"
                                        defaultValue={0}
                                        value={fence?.eventos?.limiteVelocidadeCiclos}
                                        onChange={handleOnChangeInputFenceEvent}
                                        onBlur={handleOnBlurEventValidateNumber}
                                        disabled={
                                          fence?.eventos?.limiteVelocidadePulsado !== 1 ||
                                          fence?.eventos?.limiteVelocidade === 0
                                        }
                                      />
                                    </BaseFormInputItem>
                                  </Col>
                                  <Col xs={8}>
                                    <BaseFormInputItem label="Tempo Ligado(s)" supportText="Entre 1 a 50 segundos">
                                      <Input
                                        name="limiteVelocidadeTempoLigado"
                                        type="number"
                                        min={1}
                                        max={50}
                                        placeholder="Informe a quantidade de ciclos"
                                        defaultValue={0}
                                        value={fence?.eventos?.limiteVelocidadeTempoLigado}
                                        onChange={handleOnChangeInputFenceEvent}
                                        onBlur={handleOnBlurEventValidateNumber}
                                        disabled={
                                          fence?.eventos?.limiteVelocidadePulsado !== 1 ||
                                          fence?.eventos?.limiteVelocidade === 0
                                        }
                                      />
                                    </BaseFormInputItem>
                                  </Col>
                                  <Col xs={8}>
                                    <BaseFormInputItem label="Tempo Desligado(s)" supportText="Entre 1 a 50 segundos">
                                      <Input
                                        name="limiteVelocidadeTempoDesligado"
                                        type="number"
                                        min={1}
                                        max={50}
                                        placeholder="Informe a quantidade de ciclos"
                                        defaultValue={0}
                                        value={fence?.eventos?.limiteVelocidadeTempoDesligado}
                                        onChange={handleOnChangeInputFenceEvent}
                                        onBlur={handleOnBlurEventValidateNumber}
                                        disabled={
                                          fence?.eventos?.limiteVelocidadePulsado !== 1 ||
                                          fence?.eventos?.limiteVelocidade === 0
                                        }
                                      />
                                    </BaseFormInputItem>
                                  </Col>
                                </Row>
                                <Radio value={0}>Não se aplica</Radio>
                              </Space>
                            </RadioGroup>
                          </BaseFormInputItem>
                        </Col>
                      </Row>
                    </BaseForm>
                  </Panel>
                </CollapseStep>
              </Col>
            </Row>
          </div>
          <div>
            <Row align={'bottom'} justify={'end'}>
              <Tooltip title={errorDescription}>
                <Col span={8}>
                  <Button
                    block
                    type="primary"
                    disabled={!(stepFenceFinished && stepConfigFinished)}
                    onClick={() => handleAddFenceToGroup()}
                  >
                    Adicionar cerca
                  </Button>
                </Col>
              </Tooltip>
            </Row>
          </div>
        </S.StepsContainer>
      </Col>
      <Col xs={24} sm={16} style={{ height: 'calc(100vh - 19.7rem)' }}>
        <MapFenceCreate
          fence={fence}
          setFence={setFence}
          fences={groupFence?.cercas ?? []}
          maxPointsPerFence={model?.quantidadeMaxPontosPorCerca ?? 0}
          showTutorial={showTutorial}
          handleRemoveFence={handleRemoveFence}
          handleActivateInactivateFence={handleActivateInactivateFence}
          handleSelectFence={handleSelectFence}
        />
      </Col>
    </Row>
  );
};
