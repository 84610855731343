import React, { useCallback, useEffect, useState } from 'react';
import { BaseForm } from '../../forms/BaseForm/BaseForm';
import { Col, Row } from 'antd';
import { BaseFormInputItem } from '../../forms/components/BaseFormInputItem/BaseFormInputItem';
import { notificationController } from '@app/controllers/notificationController';
import { HelperNotification } from '../../HelperNotification/HelperNotification';
import { Select } from '../../selects/Select/Select';
import { Panel } from '../../Collapse/Collapse';
import * as S from './CAN.styles';
import { Button } from '../../buttons/Button/Button';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import IVehicleService, { VehicleService } from '@app/services/vehicleService';
import { TipoVeiculoParametro, VehicleModel, VehicleParameterLinkedModel } from '@app/domain/vehicle/vehicleModel';

interface CanProps {
  properties: DeviceProfileProperty[];
}

const vehicleService: IVehicleService = new VehicleService();

export const CANView: React.FC<CanProps> = ({ properties }) => {
  const [vehicle, setVehicle] = useState<VehicleModel>();
  const [parametersCalculated, setParametersCalculated] = useState<VehicleParameterLinkedModel[]>([]);
  const [parametersOnOff, setParametersOnOff] = useState<VehicleParameterLinkedModel[]>([]);

  const getPropertyValue = (idEmbarcado: DevicePropertyType) =>
    properties?.find((p) => p.idEmbarcado == idEmbarcado)?.valorPropriedade;

  const fetchVehicle = useCallback((idVehicle: string) => {
    vehicleService
      .get(`${idVehicle}`)
      .then((response) => {
        setVehicle(response);
        const identifiers = properties?.filter((p) => p.idEmbarcado == DevicePropertyType.AdicionarIdentifierCan);
        const params = identifiers.map((p) => p.valorPropriedade ?? '');
        setParametersCalculated(
          response?.parametros?.filter(
            (p) => params?.includes(p.idIdentifierCan?.toString() ?? '') && p.tipo == TipoVeiculoParametro.Calculado,
          ) ?? [],
        );
        setParametersOnOff(
          response?.parametros?.filter(
            (p) =>
              params?.includes(p.idIdentifierCan?.toString() ?? '') && p.tipo == TipoVeiculoParametro.LigadoDesligado,
          ) ?? [],
        );
      })
      .catch(() => notificationController.error({ message: 'Erro ao buscar os veículo' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const idVehicle = getPropertyValue(DevicePropertyType.IdVeiculoCan);
    idVehicle && fetchVehicle(idVehicle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseForm layout="vertical">
      <Row>
        <Col xs={24} md={12}>
          <HelperNotification>
            Utilize este parâmetro quando a maioria dos modelos de veículos de sua frota forem do mesmo fabricante. Caso
            seja necessário alterar o veículo, utilize o &quot;Enviar Comando&quot; em meus dispositivos.
          </HelperNotification>
        </Col>
      </Row>
      <Row>
        <BaseFormInputItem>
          <S.CheckboxDisabled checked={!!vehicle?.id}>
            <S.CheckboxLabel>Habilitar CAN</S.CheckboxLabel>
          </S.CheckboxDisabled>
        </BaseFormInputItem>
      </Row>
      {vehicle?.id && (
        <>
          <Row gutter={18}>
            <Col xs={24} md={5}>
              <BaseFormInputItem label="Fabricante">
                <Select
                  disabled
                  placeholder="Selecione o fabricante"
                  value={vehicle?.idVeiculoFabricante}
                  options={[{ label: vehicle?.fabricante, value: vehicle?.idVeiculoFabricante }]}
                ></Select>
              </BaseFormInputItem>
            </Col>
            <Col xs={24} md={5}>
              <BaseFormInputItem label="Veículo">
                <Select
                  placeholder="Selecione o veículo"
                  value={vehicle?.id}
                  disabled
                  options={[{ label: `${vehicle?.nome} | ${vehicle?.ano}`, value: vehicle?.id }]}
                ></Select>
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row gutter={[18, 18]} style={{ marginTop: '1rem' }}>
            <Col xs={24}>
              <S.Collapse defaultActiveKey={'1'}>
                <Panel
                  key="1"
                  header={
                    <Row align={'middle'} justify={'space-between'}>
                      <Col>
                        <S.CollapseHeaderName>Parâmetros da CAN</S.CollapseHeaderName>
                      </Col>
                      <Button type="ghost" disabled>
                        Selecionar parâmetros
                      </Button>
                    </Row>
                  }
                >
                  {parametersCalculated && parametersCalculated.length > 0 && (
                    <>
                      <S.Subtitle>Calculado</S.Subtitle>
                      <Row style={{ paddingTop: '1rem' }}>
                        <S.ParametersQuantity>{parametersCalculated?.length}</S.ParametersQuantity>&nbsp;
                        <S.ParametersQuantityDescription>
                          {parametersCalculated?.length > 1 ? 'Parâmetros' : 'Parâmetro'}
                        </S.ParametersQuantityDescription>
                      </Row>
                      <Row gutter={[16, 16]} style={{ paddingBottom: '1rem' }}>
                        {parametersCalculated.map((parameter) => (
                          <Col key={parameter.idIdentifierCan} xs={24} md={4} lg={4}>
                            <S.CanVehiclesParameterName>{parameter.nome}</S.CanVehiclesParameterName>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                  {parametersOnOff && parametersOnOff.length > 0 && (
                    <>
                      <S.Subtitle>On/Off</S.Subtitle>
                      <Row style={{ paddingTop: '1rem' }}>
                        <S.ParametersQuantity>{parametersOnOff?.length}</S.ParametersQuantity>&nbsp;
                        <S.ParametersQuantityDescription>
                          {parametersOnOff?.length > 1 ? 'Parâmetros' : 'Parâmetro'}
                        </S.ParametersQuantityDescription>
                      </Row>
                      <Row gutter={[16, 16]}>
                        {parametersOnOff.map((parameter, index) => (
                          <Col key={index} xs={24} md={4} lg={4}>
                            <S.CanVehiclesParameterName>{parameter.nome}</S.CanVehiclesParameterName>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                </Panel>
              </S.Collapse>
            </Col>
          </Row>
        </>
      )}
    </BaseForm>
  );
};
