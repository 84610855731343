import { FenceModel } from '@app/domain/fence/fenceModel';
import { FC } from 'react';
import * as S from './map-fence-selected-card.styles';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import { TagColor } from '@app/components/common/TagColor/TagColor';
import { getFenceNameByType } from '@app/pages/fence/functions/commonFenceFunctions';

interface MapFenceSelectedCardProps {
  fence?: FenceModel;
  onTop?: boolean;
}
export const MapFenceSelectedCard: FC<MapFenceSelectedCardProps> = ({ fence, onTop }) => {
  return (
    <S.SelectedFenceCardCollapse
      topPosition={onTop ? '1rem' : '5rem'}
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <S.SelectedFenceCardCollapseIcon>
          {isActive ? <DownOutlined /> : <UpOutlined />}
        </S.SelectedFenceCardCollapseIcon>
      )}
    >
      <S.SelectedFenceCardCollapsePanel
        key={1}
        header={
          <S.SelectedFenceCardCollapseHeader>
            <Row gutter={16}>
              <Col>{fence?.nome ?? '-'}</Col>
              <Col>
                <TagColor background="#E8E8E8" color="#383838">
                  {getFenceNameByType(fence?.idTipoCerca ?? 0)}
                </TagColor>
              </Col>
              <Col>
                {fence?.ativo === false ? (
                  <TagColor background="#FEE9EA" color="#620E12">
                    Inativo
                  </TagColor>
                ) : (
                  <TagColor background="#E9F4EE" color="#083F18">
                    {fence?.ativo === true ? 'Ativo' : '-'}
                  </TagColor>
                )}
              </Col>
            </Row>
          </S.SelectedFenceCardCollapseHeader>
        }
      >
        <Row gutter={8}>
          <Col>
            <S.SelectedFenceCardCollapsePanelLabel>
              Qtde. de pontos <span>{fence?.pontos?.length ?? '-'}</span>
              <Divider type="vertical" />
            </S.SelectedFenceCardCollapsePanelLabel>
          </Col>
          <Col>
            <S.SelectedFenceCardCollapsePanelLabel>
              Raio <span>{fence?.raio ?? '-'}</span>
              <Divider type="vertical" />
            </S.SelectedFenceCardCollapsePanelLabel>
          </Col>
          <Col>
            <S.SelectedFenceCardCollapsePanelLabel>
              Largura da banda <span>{fence?.largura ?? '-'}</span>
            </S.SelectedFenceCardCollapsePanelLabel>
          </Col>
        </Row>
        <Row gutter={8} style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
          <Col>
            <S.SelectedFenceCardCollapsePanelLabel>
              Entrada/Saída{' '}
              <span>
                {
                  {
                    1: 'Entrada',
                    2: 'Saída',
                    3: 'Entrada e Saída',
                    0: 'Não se aplica',
                  }[fence?.eventos?.entradaSaida ?? 0]
                }
              </span>
              <Divider type="vertical" />
            </S.SelectedFenceCardCollapsePanelLabel>
          </Col>
          <Col>
            <S.SelectedFenceCardCollapsePanelLabel>
              Limite de velocidade{' '}
              <span>{!fence?.eventos?.limiteVelocidade ? 'Não' : `${fence.eventos.limiteVelocidadeValor} km/h`}</span>
            </S.SelectedFenceCardCollapsePanelLabel>
          </Col>
        </Row>
      </S.SelectedFenceCardCollapsePanel>
    </S.SelectedFenceCardCollapse>
  );
};
