import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Description } from '@app/components/common/Description/Description';
import { Col, Row } from 'antd';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '@app/components/common/selects/Select/Select';
import { Button, ButtonGroup } from '@app/components/common/buttons/Button/Button';
import { notificationController } from '@app/controllers/notificationController';
import { CardInfoContainer, CardTitle } from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import { CanVehicleBitsSize, CanVehicleInitialBit } from '@app/constants/canVehicleData';
import MaskedInput from 'react-text-mask';
import {
  TipoVeiculoParametro,
  VechicleParameterModel,
  VehicleModel,
  VehicleParameterLinkedModel,
} from '@app/domain/vehicle/vehicleModel';
import IVehicleService, { VehicleService } from '@app/services/vehicleService';
import { Input } from '@app/components/common/inputs/Input/Input';

interface ICreateStepThree {
  vehicle: VehicleModel;
  setVehicle: React.Dispatch<React.SetStateAction<VehicleModel>>;
}

const vehicleService: IVehicleService = new VehicleService();

export const CreateCanVehicleStepThree: React.FC<ICreateStepThree> = ({ vehicle, setVehicle }) => {
  const [parametersLoading, setParametersLoading] = useState(false);
  const [parameterSelected, setParameterSelected] = useState<VechicleParameterModel>();
  const [parameters, setParameters] = useState<VechicleParameterModel[]>([]);
  const [parameterLinked, setParameterLinked] = useState<VehicleParameterLinkedModel>();

  const fetchParameters = useCallback(async () => {
    setParametersLoading(true);
    vehicleService
      .getParameters()
      .then((res) => setParameters(res))
      .catch((error) => notificationController.error(error))
      .finally(() => setParametersLoading(false));
  }, []);

  const canSave = useMemo(
    () =>
      parameterLinked?.tipo == TipoVeiculoParametro.Calculado
        ? parameterLinked?.parametroId &&
          parameterLinked?.bitInicial !== undefined &&
          parameterLinked?.tamanhoBits !== undefined &&
          parameterLinked?.resolucao !== undefined &&
          parameterLinked?.resolucao !== '' &&
          parameterLinked?.offset !== undefined &&
          parameterLinked?.offset !== ''
        : parameterLinked?.parametroId &&
          parameterLinked?.bitInicial !== undefined &&
          parameterLinked?.tamanhoBits !== undefined &&
          parameterLinked?.ligado != parameterLinked.desligado,
    [parameterLinked],
  );
  const handleAddParameters = async () => {
    if (parameterLinked) {
      setVehicle({ ...vehicle, parametros: [...(vehicle.parametros ?? []), parameterLinked] });
      setParameterSelected(undefined);
      setParameterLinked(undefined);
    }
  };

  useEffect(() => {
    fetchParameters();
  }, [fetchParameters]);

  useEffect(() => {
    parameterSelected &&
      setParameterLinked({
        nome: parameterSelected?.nome,
        idEmbarcado: parameterSelected?.idEmbarcado,
        tipo: parameterSelected?.tipo,
        idIdentifierCan: parameterSelected?.id,
        idParametro: parameterSelected?.id,
        unidadeMedida: parameterSelected.unidadeMedida?.split(';')?.[0],
      });
  }, [parameterSelected]);

  return (
    <>
      <Description title="Etapa 3 de 3" subtitle="Após preencher os campos, clique em finalizar." />
      <CardInfoContainer>
        <Row justify={'start'} style={{ width: '100%', marginBottom: '1rem' }}>
          <CardTitle>Parâmetros do veículo</CardTitle>
        </Row>
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Row gutter={6}>
            <Col xs={12}>
              <BaseFormInputItem label="Nome ID" errorText="Campo obrigatório">
                <Select
                  showArrow
                  showSearch
                  loading={parametersLoading}
                  placeholder="Selecione o tipo"
                  value={parameterSelected?.id}
                  onChange={(value) => setParameterSelected(parameters.find((n) => n.id == value))}
                  options={parameters.map((c) => ({ value: c.id, label: `${c.nome}` }))}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </BaseFormInputItem>
            </Col>
            {parameterSelected?.tipo == TipoVeiculoParametro.Calculado && parameterSelected.unidadeMedida && (
              <Col xs={12}>
                <BaseFormInputItem label=" " errorText="">
                  <ButtonGroup>
                    {parameterSelected?.unidadeMedida &&
                      parameterSelected?.unidadeMedida?.split(';').map((u) => (
                        <Button
                          key={u}
                          type={parameterLinked?.unidadeMedida === u ? 'primary' : 'default'}
                          onClick={() => setParameterLinked({ ...parameterLinked, unidadeMedida: u })}
                        >
                          {u}
                        </Button>
                      ))}
                  </ButtonGroup>
                </BaseFormInputItem>
              </Col>
            )}
          </Row>
          {parameterSelected && (
            <Row gutter={6}>
              <Col xs={24} md={12}>
                <BaseFormInputItem label="Tipo de dado">
                  <RadioGroup
                    name="parameterType"
                    disabled={true}
                    value={parameterLinked?.tipo}
                    style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
                  >
                    <Radio value={TipoVeiculoParametro.Calculado}>Calculado</Radio>
                    <Radio value={TipoVeiculoParametro.LigadoDesligado}>ON/OFF</Radio>
                  </RadioGroup>
                </BaseFormInputItem>
              </Col>
            </Row>
          )}
          <Row gutter={6}>
            <Col xs={6}>
              <BaseFormInputItem label="ID" errorText="Campo obrigatório">
                <Input
                  className="ant-input"
                  type="number"
                  key={1}
                  placeholder="Digite o ID"
                  value={parameterLinked?.parametroId}
                  onChange={(event) => setParameterLinked({ ...parameterLinked, parametroId: event.target.value })}
                />
              </BaseFormInputItem>
            </Col>
            <Col xs={9}>
              <BaseFormInputItem label="Bit inicial" errorText="Campo obrigatório">
                <Select
                  showArrow
                  showSearch
                  placeholder="0 a 63"
                  value={parameterLinked?.bitInicial}
                  onChange={(value) => setParameterLinked({ ...parameterLinked, bitInicial: Number(value as string) })}
                  options={CanVehicleInitialBit.map((c) => ({ value: c, label: `${c}` }))}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </BaseFormInputItem>
            </Col>
            <Col xs={9}>
              <BaseFormInputItem label="Tamanho em bits" errorText="Campo obrigatório">
                <Select
                  showArrow
                  showSearch
                  placeholder="1 a 63"
                  value={parameterLinked?.tamanhoBits}
                  onChange={(value) => setParameterLinked({ ...parameterLinked, tamanhoBits: Number(value as string) })}
                  options={CanVehicleBitsSize.map((c) => ({ value: c, label: `${c}` }))}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
          {parameterSelected?.tipo == TipoVeiculoParametro.Calculado && (
            <>
              <Row gutter={6}>
                <Col xs={9}>
                  <BaseFormInputItem label="Offset" errorText="Campo obrigatório">
                    <MaskedInput
                      className="ant-input"
                      mask={() => Array(24).fill(/[0-9.-]/)}
                      guide={false}
                      type="text"
                      key={2}
                      placeholder="Digite o offset"
                      value={parameterLinked?.offset}
                      onChange={(event) => setParameterLinked({ ...parameterLinked, offset: event.target.value })}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={9}>
                  <BaseFormInputItem label="Resolução" errorText="Campo obrigatório">
                    <MaskedInput
                      className="ant-input"
                      mask={() => Array(24).fill(/[0-9.-]/)}
                      guide={false}
                      type="text"
                      key={3}
                      placeholder="Digite a resolução"
                      value={parameterLinked?.resolucao}
                      onChange={(event) => setParameterLinked({ ...parameterLinked, resolucao: event.target.value })}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            </>
          )}
          {parameterSelected?.tipo == TipoVeiculoParametro.LigadoDesligado && (
            <>
              <Row gutter={6}>
                <Col xs={6}>
                  <BaseFormInputItem label="Ligado" errorText="Campo obrigatório">
                    <RadioGroup
                      name="ligado"
                      onChange={(e) =>
                        setParameterLinked({
                          ...parameterLinked,
                          ligado: e.target.value ? 1 : 0,
                          desligado: e.target.value ? 0 : 1,
                        })
                      }
                      value={parameterLinked?.ligado === 1 ? true : false}
                      style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
                    >
                      <Radio value={true}>1</Radio>
                      <Radio value={false}>0</Radio>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
                <Col xs={6}>
                  <BaseFormInputItem label="Desligado" errorText="Campo obrigatório">
                    <RadioGroup
                      name="desligado"
                      onChange={(e) =>
                        setParameterLinked({
                          ...parameterLinked,
                          desligado: e.target.value ? 1 : 0,
                          ligado: e.target.value ? 0 : 1,
                        })
                      }
                      value={parameterLinked?.desligado === 1 ? true : false}
                      style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
                    >
                      <Radio value={true}>1</Radio>
                      <Radio value={false}>0</Radio>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
              </Row>
            </>
          )}
        </BaseForm>
      </CardInfoContainer>
      <Row style={{ height: '100%' }} align={'top'} justify={'end'}>
        <Col xs={24} sm={12} md={6}>
          <Button block type="primary" onClick={handleAddParameters} disabled={!canSave}>
            Adicionar
          </Button>
        </Col>
      </Row>
    </>
  );
};
