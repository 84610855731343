import { ApiServiceBase, IApiServiceBase } from '@app/services/base/apiServiceBase';
import {
  DeviceProfileCommand,
  DeviceProfileModel,
  DeviceProfileProperty,
} from '@app/domain/deviceProfile/deviceProfileModel';

export default interface IDeviceProfileService extends IApiServiceBase<DeviceProfileModel, DeviceProfileModel> {
  getPropertiesByIdProfile(id: number): Promise<DeviceProfileProperty[]>;
  getCommandsByIdProfile(id: number): Promise<DeviceProfileCommand[]>;
  getByModelAndClient(idModel: number, idClient: number): Promise<DeviceProfileModel[]>;
  thereIsFirmwareVersionre(idGrupoFirmware: number, versaoFirmware: string): Promise<boolean>;
}

export class DeviceProfileService extends ApiServiceBase<DeviceProfileModel, DeviceProfileModel> {
  constructor() {
    super('PerfilDispositivo');
  }

  async getPropertiesByIdProfile(id: number): Promise<DeviceProfileProperty[]> {
    try {
      return (await this.api.get(`${id}/propriedades`))?.data;
    } catch (error) {
      throw error;
    }
  }

  async getCommandsByIdProfile(id: number): Promise<DeviceProfileCommand[]> {
    try {
      return (await this.api.get(`${id}/comandos`))?.data;
    } catch (error) {
      throw error;
    }
  }

  async getByModelAndClient(idModel: number, idClient: number): Promise<DeviceProfileModel[]> {
    try {
      return (await this.api.get(`obter-por-modelo-e-cliente/${idModel}/${idClient}`))?.data;
    } catch (error) {
      throw error;
    }
  }

  async thereIsFirmwareVersionre(idGrupoFirmware: number, versaoFirmware: string): Promise<boolean> {
    try {
      const response = await this.api.get(`existe-grupo-versao-firmware/${idGrupoFirmware}/${versaoFirmware}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
