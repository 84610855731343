import React from 'react';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import * as S from '../ModulesProfileConfig.styles';
import ShouldRenderInput from '@app/components/common/ModulesProfileConfig/ShouldRenderInput';
import { Form } from 'antd';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { PropertyModel } from '@app/domain/property/propertyModel';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';

interface EntradaProps {
  properties: PropertyModel[];
  getPropertyValue: (idEmbarcado: DevicePropertyType) => string;
  handleChangeDeviceProfileProperties: (properties: DeviceProfileProperty[]) => void;
}

const Entrada: React.FC<EntradaProps> = ({ properties, getPropertyValue, handleChangeDeviceProfileProperties }) => {
  const handleChangePropertyValue = (idEmbarcado: DevicePropertyType, value: string) => {
    const prop = {
      ...properties?.find((p) => p.idEmbarcado == idEmbarcado),
      valorPropriedade: value,
    } as DeviceProfileProperty;
    handleChangeDeviceProfileProperties([prop]);
  };

  return (
    <Form layout="vertical">
      <ShouldRenderInput idEmbarcado={DevicePropertyType.EntradaAnalogicaHabilitada} properties={properties}>
        <Checkbox
          key={26}
          checked={getPropertyValue(DevicePropertyType.EntradaAnalogicaHabilitada) == '1'}
          onChange={(e) =>
            handleChangePropertyValue(DevicePropertyType.EntradaAnalogicaHabilitada, e.target.checked ? '1' : '0')
          }
          style={{ width: '13rem' }}
        >
          <S.CheckboxLabel>Habilitar entrada analógica</S.CheckboxLabel>
        </Checkbox>
      </ShouldRenderInput>
    </Form>
  );
};

export default Entrada;
