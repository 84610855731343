import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import Dashboard from '@app/components/dashboard/Dashboard';
import { UserType } from '@app/constants/enums/userType';
import { hasAccessByRoles } from '@app/controllers/accessController';
import { notificationController } from '@app/controllers/notificationController';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import { FactoryDeviceService } from '@app/services/factoryDeviceService';
import { setHeader } from '@app/store/slices/headerSlice';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router';
import { Tag } from '@app/components/common/Tag/Tag';
import moment from 'moment';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import IconMore from '@app/assets/slump-icons/IconMore';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Modal } from '@app/components/common/Modal/Modal';
import { Col, Row } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import SendCommandToDeviceModal from '@app/components/device/SendCommandToDeviceModal';
import { DeviceGroupService } from '@app/services/deviceGroupService';
import { DeviceGroupModel } from '@app/domain/deviceGroup/deviceGroupModel';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { Select } from '@app/components/common/selects/Select/Select';

const deviceService = new FactoryDeviceService();
const deviceGroupService = new DeviceGroupService();

export const DeviceDashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMounted } = useMounted();
  const [loading, setLoading] = useState(false);
  const [modalSendCommandVisible, setModalSendCommandVisible] = useState(false);
  const [devices, setDevices] = useState<FactoryDeviceModel[]>();
  const [searchDeviceFiltered, setSearchDeviceFiltered] = useState<FactoryDeviceModel[]>();
  const [selectedDevice, setSelectedDevice] = useState<FactoryDeviceModel>({} as FactoryDeviceModel);
  const [modalUnlink, setModalUnlink] = useState(false);
  const [groups, setGroups] = useState<DeviceGroupModel[]>();
  const [modalLink, setModalLink] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<number>();
  const [searchInput, setSearchInput] = useState<string>('');
  const [devicePage, setDevicePage] = useState<number>(1);

  const columns: ColumnsType<FactoryDeviceModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: true,
      sorter: (a: FactoryDeviceModel, b: FactoryDeviceModel) => (a.id ?? 0) - (b.id ?? 0),
      defaultSortOrder: 'descend',
      width: '83px',
    },
    {
      title: 'Equipamento',
      dataIndex: 'tipoEquipamento',
      showSorterTooltip: true,
      sorter: (a, b) => (a.tipoEquipamento ?? '').localeCompare(b.tipoEquipamento ?? ''),
      width: '163px',
    },
    {
      title: 'Modelo',
      dataIndex: 'modelo',
      showSorterTooltip: true,
      sorter: (a, b) => (a.modelo ?? '').localeCompare(b.modelo ?? ''),
      width: '100px',
    },
    {
      title: 'Apelido',
      dataIndex: 'apelido',
      showSorterTooltip: true,
      sorter: (a, b) => (a.apelido ?? '').localeCompare(b.apelido ?? ''),
      width: '130px',
      render: (_, device) => device.apelido ?? '-',
    },
    {
      title: 'Grupo',
      dataIndex: 'grupo',
      showSorterTooltip: true,
      sorter: (a, b) => (a.grupo ?? '').localeCompare(b.grupo ?? ''),
      width: '130px',
      render: (_, device) => device.grupo ?? '-',
    },
    {
      title: 'SN',
      dataIndex: 'numeroSerie',
      showSorterTooltip: true,
      sorter: (a, b) => (a.numeroSerie ?? '').localeCompare(b.numeroSerie ?? ''),
      render: (numeroSerie) => <div style={{ minWidth: '175px' }}>{numeroSerie}</div>,
      width: '200px',
    },
    {
      title: 'Versão de firmware',
      dataIndex: 'versaoFirmware',
      showSorterTooltip: true,
      sorter: (a, b) => (a.versaoFirmware ?? '').localeCompare(b.versaoFirmware ?? ''),
      width: '192px',
    },
    {
      title: 'IMEI',
      dataIndex: 'imei',
      showSorterTooltip: true,
      sorter: (a, b) => (a.imei ?? '').localeCompare(b.imei ?? ''),
      width: '210px',
    },
    {
      title: 'Perfil de configuração',
      dataIndex: 'perfil',
      showSorterTooltip: true,
      width: '200px',
      sorter: (a, b) => (a.perfil ?? '').localeCompare(b.perfil ?? ''),
      render: (perfil, device) => (device.testeRapido ? 'Teste Rápido' : perfil),
    },
    {
      title: 'Conexão',
      dataIndex: 'connectionState',
      showSorterTooltip: true,
      sorter: (a, b) => (a.connectionState ?? '').localeCompare(b.connectionState ?? ''),
      width: '96px',
      render: (connectionState: string, device: FactoryDeviceModel) => {
        return !device.permiteConexao ? (
          <>-</>
        ) : (
          <>
            {connectionState === 'Connected' ? (
              <Tag color="#E9F4EE" style={{ color: '#083F18', width: '70px', textAlign: 'center' }}>
                Online
              </Tag>
            ) : (
              <Tag color="#FEE9EA" style={{ color: '#620E12', width: '70px', textAlign: 'center' }}>
                Offline
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: 'Última conexão',
      dataIndex: 'ultimaConexao',
      showSorterTooltip: true,
      width: '160px',
      sorter: (a, b) =>
        (a.ultimaConexao ? new Date(a.ultimaConexao).getTime() : Infinity) -
        (b.ultimaConexao ? new Date(b.ultimaConexao).getTime() : Infinity),
      render: (ultimaConexao: Date) => (ultimaConexao ? moment(ultimaConexao).format('DD/MM/YYYY HH:mm') + 'h' : '-'),
    },
    {
      title: '',
      dataIndex: 'id',
      width: 20,
      showSorterTooltip: false,
      sortDirections: [],
      defaultSortOrder: 'descend',
      fixed: 'right',
      render: (_, device) => {
        return {
          props: {
            style: {
              background: 'white',
            },
          },
          children: (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
              <DropdownTable
                buttonText=""
                align={{ offset: [40, -10] }}
                iconD={
                  <div style={{ color: '#545454' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                getPopupContainer={() => document.body}
                overlay={
                  <Menu>
                    <MenuItem key="view" onClick={() => navigate(`visualizar/${device.id}`)}>
                      <Button type="text">Visualizar dispositivo</Button>
                    </MenuItem>
                    {(device.idPerfilDispositivo || device.testeRapido) && (
                      <MenuItem key="edit" onClick={() => navigate(`atualizar/${device.id}`)}>
                        <Button type="text">Alterar perfil de configuração</Button>
                      </MenuItem>
                    )}
                    {device.permiteConfiguracaoRemota && (
                      <MenuItem
                        key="view"
                        onClick={() => navigate(`/atualizacao-configuracao-dispositivo/agendar/${device.id}`)}
                      >
                        <Button type="text">Configuração remota</Button>
                      </MenuItem>
                    )}
                    {device.permiteEnvioComando && (
                      <MenuItem
                        key="view"
                        onClick={() => {
                          setSelectedDevice(devices?.find((d) => d.id === device.id) || ({} as FactoryDeviceModel));
                          setModalSendCommandVisible(true);
                        }}
                      >
                        <Button type="text">Enviar comando</Button>
                      </MenuItem>
                    )}
                    {!!device.idGrupo ? (
                      <MenuItem
                        key="view"
                        onClick={() => {
                          setSelectedDevice(device);
                          setModalUnlink(true);
                        }}
                      >
                        <Button type="text">Desvincular grupo de dispositivo</Button>
                      </MenuItem>
                    ) : (
                      <MenuItem
                        key="view"
                        onClick={() => {
                          setSelectedDevice(device);
                          setModalLink(true);
                          fetchGroups();
                        }}
                      >
                        <Button type="text">Vincular grupo de dispositivo</Button>
                      </MenuItem>
                    )}
                  </Menu>
                }
              ></DropdownTable>
            </div>
          ),
        };
      },
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigate('/meus-dispositivos/cadastrar');
  };

  const handleSearchFilter = (event: React.FormEvent<HTMLInputElement>) => {
    const inputValue = event.currentTarget.value.toLowerCase();
    setSearchInput(inputValue);
    localStorage.setItem('deviceSearch', inputValue);

    const filteredDevices = devices?.filter(
      (item) =>
        item.id?.toString().toLowerCase().includes(inputValue) ||
        item.numeroSerie?.toString().toLowerCase().includes(inputValue) ||
        item.perfil?.toLowerCase().includes(inputValue) ||
        item.imei?.toLowerCase().includes(inputValue) ||
        item.tipoEquipamento?.toLowerCase().includes(inputValue) ||
        item.modelo?.toLowerCase().includes(inputValue) ||
        item.apelido?.toLowerCase().includes(inputValue) ||
        item.grupo?.toLowerCase().includes(inputValue) ||
        item.versaoFirmware?.includes(inputValue),
    );

    setSearchDeviceFiltered(filteredDevices);
  };

  const handleExportDataExcel = () => {
    genericExportToExcel('meus_dispositivos', columns, searchDeviceFiltered ?? []);
  };

  const fetchDevices = useCallback(async () => {
    setLoading(true);

    deviceService
      .getArray(``)
      .then((res: FactoryDeviceModel[]) => {
        if (isMounted.current) {
          setDevices(res);
          const savedSearch = localStorage.getItem('deviceSearch');
          if (savedSearch) {
            const inputValue = savedSearch.toLowerCase();
            setSearchInput(inputValue);
            setSearchDeviceFiltered(
              res.filter(
                (item) =>
                  item.id?.toString().toLowerCase().includes(inputValue) ||
                  item.numeroSerie?.toString().toLowerCase().includes(inputValue) ||
                  item.perfil?.toLowerCase().includes(inputValue) ||
                  item.imei?.toLowerCase().includes(inputValue) ||
                  item.tipoEquipamento?.toLowerCase().includes(inputValue) ||
                  item.modelo?.toLowerCase().includes(inputValue) ||
                  item.apelido?.toLowerCase().includes(inputValue) ||
                  item.grupo?.toLowerCase().includes(inputValue) ||
                  item.versaoFirmware?.includes(inputValue),
              ),
            );
          } else {
            setSearchDeviceFiltered(res);
          }
          const savedPage = localStorage.getItem('devicePage');
          if (savedPage) {
            setDevicePage(Number(savedPage));
          }

          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({ message: `Erro ao obter dispositivos. ${err}` });
      });
  }, [isMounted]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setDevicePage(pagination.current || 1);
    localStorage.setItem('devicePage', (pagination.current || 1).toString());
  };

  const linkDeviceToGroup = useCallback(() => {
    setLoading(true);
    const payload = {
      idDispositivo: selectedDevice.id ?? 0,
      idGrupo: selectedGroupId ?? 0,
    };
    deviceGroupService
      .update('vincular-dispositivo', payload as DeviceGroupModel)
      .then(() => {
        notificationController.success({ message: 'Dispositivo vinculado com sucesso.' });
        fetchDevices();
      })
      .catch((err) => {
        notificationController.error({ message: `Erro ao vincular dispositivo. ${err}` });
      })
      .finally(() => {
        setSelectedDevice({} as FactoryDeviceModel);
        setLoading(false);
        setModalLink(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice.id, selectedGroupId]);

  const unlinkDeviceFromGroup = useCallback(() => {
    setLoading(true);
    deviceGroupService
      .update(`desvincular-dispositivo/${selectedDevice.id}`, {})
      .then(() => {
        notificationController.success({ message: 'Dispositivo desvinculado com sucesso.' });
        fetchDevices();
      })
      .catch((err) => {
        notificationController.error({ message: `Erro ao desvincular dispositivo. ${err}` });
      })
      .finally(() => {
        setSelectedDevice({} as FactoryDeviceModel);
        setLoading(false);
        setModalUnlink(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice.id]);

  const fetchGroups = useCallback(async () => {
    try {
      setLoading(true);
      setGroups(await deviceGroupService.getArray(''));
      setLoading(false);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um problema ao buscar os grupos de dispositivo',
      });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDevices();
    dispatch(setHeader({ title: 'Meus dispositivos' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, fetchDevices]);

  useEffect(() => {
    if (searchInput.length > 0) {
      const filteredDevices = devices?.filter(
        (item) =>
          item.id?.toString().toLowerCase().includes(searchInput) ||
          item.numeroSerie?.toString().toLowerCase().includes(searchInput) ||
          item.perfil?.toLowerCase().includes(searchInput) ||
          item.imei?.toLowerCase().includes(searchInput) ||
          item.tipoEquipamento?.toLowerCase().includes(searchInput) ||
          item.modelo?.toLowerCase().includes(searchInput) ||
          item.apelido?.toLowerCase().includes(searchInput) ||
          item.grupo?.toLowerCase().includes(searchInput) ||
          item.versaoFirmware?.includes(searchInput),
      );

      setSearchDeviceFiltered(filteredDevices);
    } else {
      setSearchDeviceFiltered(devices);
    }
  }, [devices, searchInput]);

  return (
    <>
      <Modal
        title="Desvincular grupo"
        open={modalUnlink}
        onCancel={() => setModalUnlink(false)}
        onOk={unlinkDeviceFromGroup}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle" gutter={18}>
          <Col>
            <CloseCircleOutlined size={20} style={{ color: '#C41B24', marginRight: '1rem' }} />
            <span>
              Deseja realmente <strong>desvincular o grupo de dispositivo selecionado?</strong>{' '}
            </span>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Vincular grupo"
        open={modalLink}
        onCancel={() => setModalLink(false)}
        onOk={linkDeviceToGroup}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle" gutter={18}>
          <Col xl={12} md={12}>
            <BaseFormInputItem label="SN/Apelido">
              <Input disabled value={selectedDevice.numeroSerie} placeholder="Informe o SN ou apelido" />
            </BaseFormInputItem>
          </Col>
          <Col xl={12} md={12}>
            <BaseFormInputItem label="Grupo">
              <Select
                style={{ width: '270px' }}
                value={selectedGroupId}
                showArrow
                showSearch
                placeholder="Selecione o grupo"
                onChange={(value) => setSelectedGroupId(Number(value))}
                options={groups?.map((c) => ({
                  value: c.id,
                  label: `${c.nome}`,
                }))}
                filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              />
            </BaseFormInputItem>
          </Col>
        </Row>
      </Modal>

      <PageTitle>Meus dispositivos</PageTitle>
      <SendCommandToDeviceModal
        visible={modalSendCommandVisible}
        setVisible={setModalSendCommandVisible}
        deviceId={selectedDevice.id ?? 0}
      />
      <Spinner spinning={loading}>
        <Dashboard
          title=""
          buttonText={hasAccessByRoles([UserType.FactoryAdmin, UserType.Client]) ? 'Cadastrar novo dispositivo' : ''}
          handleButtonClick={handleClick}
          placeholderTextSearch="Pesquisar"
          valueSearch={searchInput}
          table={
            <Table
              columns={columns}
              dataSource={searchDeviceFiltered}
              bordered={true}
              pagination={{ current: devicePage, pageSize: 10, total: searchDeviceFiltered?.length }}
              onChange={handleTableChange}
              scroll={{ x: 'max-content' }}
              style={{ marginTop: '1rem' }}
            />
          }
          handleSearchOnChange={handleSearchFilter}
          exportToExcel={handleExportDataExcel}
        />
      </Spinner>
    </>
  );
};
