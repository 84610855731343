import React, { useEffect, useState } from 'react';
import { Modal } from '@app/components/common/Modal/Modal';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Col, Row } from 'antd';
import { CardInfoTitle, CardInfoValue } from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { TipoVeiculoParametro, VehicleParameterLinkedModel } from '@app/domain/vehicle/vehicleModel';

interface ISendCommandToDeviceModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  parameters: VehicleParameterLinkedModel[];
  parametersSelecteds: string[];
  setParametersSelecteds: React.Dispatch<React.SetStateAction<string[]>>;
}

const ModalParametersCan: React.FC<ISendCommandToDeviceModalProps> = ({
  visible,
  setVisible,
  parameters,
  parametersSelecteds,
  setParametersSelecteds,
}) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);

  const handleCheckboxChange = (idIdentifierCan?: string) => {
    if (!idIdentifierCan) return;

    if (selectedCheckboxes.includes(idIdentifierCan)) {
      setSelectedCheckboxes(selectedCheckboxes.filter((item) => item !== idIdentifierCan));
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, idIdentifierCan]);
    }
  };

  const handleConfirmButtonClick = () => {
    setParametersSelecteds(selectedCheckboxes);
    setVisible(false);
  };

  useEffect(() => setSelectedCheckboxes(parametersSelecteds), [parametersSelecteds]);

  return (
    <>
      <Modal
        title={`Parâmetros da CAN`}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '0.25rem' }}>
            <Button
              type="ghost"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              disabled={selectedCheckboxes.length === 0}
              loading={false}
              onClick={handleConfirmButtonClick}
            >
              Confirmar
            </Button>
          </div>
        }
      >
        <CardInfoTitle>Selencione os parâmetros</CardInfoTitle>
        <Row gutter={18} style={{ marginBottom: '0.5rem' }}>
          <Col xs={24} sm={24} md={24}>
            <CardInfoValue>Calculado</CardInfoValue>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {parameters
            .filter((p) => p.tipo == TipoVeiculoParametro.Calculado)
            .map((item) => (
              <Col key={item.idIdentifierCan} xs={24} md={8} lg={8}>
                <Checkbox
                  checked={selectedCheckboxes.includes(item.idIdentifierCan?.toString() ?? '')}
                  onChange={() => handleCheckboxChange(item.idIdentifierCan?.toString())}
                >
                  {item.nome}
                </Checkbox>
              </Col>
            ))}
        </Row>
        <Row gutter={18} style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>
          <Col xs={24} sm={24} md={24}>
            <CardInfoValue>On/Off</CardInfoValue>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {parameters
            .filter((p) => p.tipo == TipoVeiculoParametro.LigadoDesligado)
            .map((item) => (
              <Col key={item.idIdentifierCan} xs={24} md={8} lg={8}>
                <Checkbox
                  checked={selectedCheckboxes.includes(item.idIdentifierCan?.toString() ?? '')}
                  onChange={() => handleCheckboxChange(item.idIdentifierCan?.toString())}
                >
                  {item.nome}
                </Checkbox>
              </Col>
            ))}
        </Row>
      </Modal>
    </>
  );
};

export default ModalParametersCan;
