import { Button } from '@app/components/common/buttons/Button/Button';
import { Row } from 'antd';
import styled from 'styled-components';

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  gap: 1rem;
`;

export const FenceViewCollapseButton = styled(Button)`
  position: absolute;
  top: 50%;
  left: -0.7rem;
  transform: translateY(-50%);
  z-index: 9;
`;

export const FenceViewRow = styled(Row)`
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-right: 0.5rem;
`;
