import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CardInfoTagBgBlue,
  CardInfoTitle,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Modal } from '@app/components/common/Modal/Modal';
import { RadioGroup } from '@app/components/common/Radio/Radio';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import { Button } from '../common/buttons/Button/Button';
import * as S from './SendCommandToDeviceModal.styles';
import { Tag } from '../common/Tag/Tag';
import { InfoCircleOutlined } from '@ant-design/icons';
import { notificationController } from '@app/controllers/notificationController';
import ISendCommandService, { SendCommandService } from '@app/services/sendCommandService';
import LastTransmission from './LastTransmission/LastTransmission';
import { handleEncryptText } from '@app/utils/encryptText';
import { SendCommandEnum } from '@app/constants/enums/device/sendCommand';
import { Input } from '../common/inputs/Input/Input';
import { BaseFormInputItem } from '../common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '../common/selects/Select/Select';
import IModelDeviceService, { ModelDeviceService } from '@app/services/modelDeviceService';
import { CommandModel } from '@app/domain/modelCommand/commandModel';
import { Checkbox } from '../common/Checkbox/Checkbox';
import IVehicleService, { VehicleService } from '@app/services/vehicleService';
import { TipoVeiculoParametro, VehicleManufacturerModel, VehicleModel } from '@app/domain/vehicle/vehicleModel';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import IFactoryDeviceService, { FactoryDeviceService } from '@app/services/factoryDeviceService';

const vehicleService: IVehicleService = new VehicleService();
const sendCommandService: ISendCommandService = new SendCommandService();
const modelService: IModelDeviceService = new ModelDeviceService();
const deviceService: IFactoryDeviceService = new FactoryDeviceService();

export interface LastTransmissionDataModel {
  gps: {
    lat: number;
    lng: number;
  } | null;
  payload: string;
}

interface ISendCommandToDeviceModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  deviceId: number;
}

const SendCommandToDeviceModal: React.FC<ISendCommandToDeviceModalProps> = ({ visible, setVisible, deviceId }) => {
  const [commandSelected, setCommandSelected] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingLastTransmission, setLoadingLastTransmission] = useState<boolean>(false);
  const [modalSendPowerCutVisible, setModalSendPowerCutVisible] = useState<boolean>(false);
  const [lastTransmissionData, setLastTransmissionData] = useState<LastTransmissionDataModel | null>(null);
  const [powerCut, setPowerCut] = useState<number | null>(null);
  const [powerCutStatus, setPowerCutStatus] = useState<number>(-1);
  const [changeHourOdoNickOldLabel, setChangeHourOdoNickOldLabel] = useState<string>('');
  const [changeHourOdoNickNewLabel, setChangeHourOdoNickNewLabel] = useState<string>('');
  const [changeHourOdoNickLastValue, setChangeHourOdoNickLastValue] = useState<string>('');
  const [changeHourOdoNickNewValue, setChangeHourOdoNickNewValue] = useState<string>('');
  const [modelCommand, setModelCommand] = useState<CommandModel[]>([]);
  const [device, setDevice] = useState<FactoryDeviceModel>({} as FactoryDeviceModel);
  const [vehicle, setVehicle] = useState<VehicleModel>();
  const [vehicles, setVehicles] = useState<VehicleModel[]>([]);
  const [manufacturer, setManufacturer] = useState<VehicleManufacturerModel>();
  const [manufacturers, setManufacturers] = useState<VehicleManufacturerModel[]>([]);
  const [vehicleParameterSelecteds, setVehicleParametersSelecteds] = useState<number[]>([]);

  const fetchCommandsByIdModel = useCallback(
    async (idModel: number) => {
      try {
        const response = await modelService.getCommandsByIdModel(idModel);
        setModelCommand(response);
      } catch (error) {
        notificationController.error({
          message: 'Erro!',
          description: 'Erro ao buscar os tipos de configurações',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (device?.idModelo) {
      fetchCommandsByIdModel(device.idModelo);
    }
  }, [fetchCommandsByIdModel, device?.idModelo]);

  const handleCleanLastCommand = () => {
    setLastTransmissionData(null);
    setPowerCut(null);
    setChangeHourOdoNickLastValue('');
    setChangeHourOdoNickNewLabel('');
    setChangeHourOdoNickNewValue('');
    setChangeHourOdoNickOldLabel('');
    setCommandSelected('');
    setManufacturer(undefined);
    setVehicle(undefined);
  };

  useEffect(() => {
    !visible && handleCleanLastCommand();
  }, [visible]);

  const handleGetPowerCutStatus = async () => {
    setLoading(true);
    if (device?.imei)
      sendCommandService
        .getPowerCutStatus(device?.imei)
        .then((res) => {
          setPowerCut(res);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          notificationController.error({ message: `${err}` });
        });
  };
  const handleGetLastOdo = async () => {
    if (device?.connectionState == 'Connected') {
      setChangeHourOdoNickOldLabel('Odômetro atual');
      setChangeHourOdoNickNewLabel('Novo odômetro');
      const data = await handleSendCommandToDevice(SendCommandEnum.GET_ODO);
      setChangeHourOdoNickLastValue(data?.payload ?? '-');
    } else {
      notificationController.error({
        message: 'Não é possível enviar comando, pois o dispositivo está off-line, tente novamente mais tarde!',
      });
      return;
    }
  };
  const handleGetLastHour = async () => {
    if (device?.connectionState == 'Connected') {
      setChangeHourOdoNickOldLabel('Horímetro atual');
      setChangeHourOdoNickNewLabel('Novo horímetro');
      const data = await handleSendCommandToDevice(SendCommandEnum.GET_HOUR);
      setChangeHourOdoNickLastValue(data?.payload ?? '-');
    } else {
      notificationController.error({
        message: 'Não é possível enviar comando, pois o dispositivo está off-line, tente novamente mais tarde!',
      });
      return;
    }
  };
  const handleGetLastNickname = async () => {
    setLoading(true);
    setChangeHourOdoNickOldLabel('Apelido atual');
    setChangeHourOdoNickNewLabel('Novo apelido');
    if (device?.imei)
      sendCommandService
        .getNickname(device?.imei)
        .then((res) => {
          setChangeHourOdoNickLastValue(res);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          notificationController.error({ message: `${err}` });
        });
  };
  const handleOnChangeVehicleParameterCheckbox = (ids: number[], add: boolean) => {
    add
      ? setVehicleParametersSelecteds(vehicleParameterSelecteds.filter((pid) => !ids.includes(pid)).concat(ids))
      : setVehicleParametersSelecteds(vehicleParameterSelecteds.filter((pid) => !ids.includes(pid)));
  };
  const fetchManufactures = useCallback(async () => {
    vehicleService
      .getManufactures()
      .then((res) => setManufacturers(res))
      .catch((error) => notificationController.error(error));
  }, []);
  const fetchVehicles = useCallback((idManufacturer: number) => {
    vehicleService
      .getArray(`obter-por-id-fabricante/${idManufacturer}`)
      .then((response) => setVehicles(response.filter((m) => m.status)))
      .catch(() => notificationController.error({ message: 'Erro ao buscar os veículo' }));
  }, []);

  useEffect(() => {
    if (deviceId) {
      setLoading(true);
      deviceService
        .get(`${deviceId}`)
        .then((res) => setDevice(res))
        .catch(() =>
          notificationController.error({
            message: 'Erro!',
            description: 'Não foi possível buscar os dados do dispositivo.',
          }),
        )
        .finally(() => setLoading(false));
    }
  }, [deviceId]);

  useMemo(() => {
    if (manufacturer?.id) fetchVehicles(manufacturer.id);
  }, [manufacturer, fetchVehicles]);

  const handleSendCommandClick = () => {
    if (device?.connectionState != 'Connected') {
      notificationController.error({
        message: 'Não é possível enviar comando, pois o dispositivo está off-line, tente novamente mais tarde!',
      });
      return;
    } else {
      handleSendCommand();
    }
  };
  const handleSendCommand = async () => {
    switch (commandSelected) {
      case SendCommandEnum.SET_GRAD_OUT:
        setVisible(false);
        setModalSendPowerCutVisible(true);
        break;
      case SendCommandEnum.REBOOT:
        setVisible(false);
        handleSendRebootCommand();
        break;
      case SendCommandEnum.CLEAN_CALIB:
        handleSendCleanCalibCommand();
        break;
      case SendCommandEnum.GET_LAST_JSON:
        handleSendLastTransmissionCommand();
        break;
      case SendCommandEnum.SET_HOUR_UPDATE:
        handleSendHourUpdateCommand();
        break;
      case SendCommandEnum.SET_ODO_UPDATE:
        handleSendOdoUpdateCommand();
        break;
      case SendCommandEnum.SET_NICKNAME_UPDATE:
        handleSendDeviceNicknameCommand();
        break;
      case SendCommandEnum.SET_CAN:
        handleSendCfgCanCommand();
        break;
      default:
        handleCleanLastCommand();
        break;
    }
  };
  const handleSendCleanCalibCommand = async () => {
    setLoading(true);

    try {
      await handleSendCommandToDevice(SendCommandEnum.CLEAN_CALIB);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Erro ao enviar comando para o dispositivo`,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleSendRebootCommand = async () => {
    setLoading(true);

    try {
      await handleSendCommandToDevice(SendCommandEnum.REBOOT);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Erro ao enviar comando para o dispositivo`,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleSendPowerCutCommand = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setLoading(true);

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const data = await handleSendCommandToDevice(
        SendCommandEnum.SET_GRAD_OUT,
        `${powerCutStatus.toString()}`,
        'comando',
        `${powerCutStatus ? '0' : '1'}`,
      );
      setVisible(true);
      setModalSendPowerCutVisible(false);
      setPowerCutStatus(-1);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Erro ao enviar comando para o dispositivo`,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleSendLastTransmissionCommand = async () => {
    setLoading(true);

    try {
      setLoadingLastTransmission(true);
      const data = await handleSendCommandToDevice(SendCommandEnum.GET_LAST_JSON);
      if (data !== null) {
        setLastTransmissionData({
          gps: data.gps
            ? {
                lat: data.gps[0],
                lng: data.gps[1],
              }
            : null,
          payload: data.payload,
        });
      }
      setLoadingLastTransmission(false);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Erro ao enviar comando para o dispositivo`,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleSendOdoUpdateCommand = async () => {
    setLoading(true);
    try {
      const data = await handleSendCommandToDevice(
        SendCommandEnum.SET_ODO_UPDATE,
        changeHourOdoNickNewValue,
        '',
        changeHourOdoNickLastValue,
      );
      if (data?.status === 200) {
        await handleGetLastOdo();
        setChangeHourOdoNickNewValue('');
      }
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Erro ao enviar comando para o dispositivo`,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleSendHourUpdateCommand = async () => {
    setLoading(true);

    try {
      const data = await handleSendCommandToDevice(
        SendCommandEnum.SET_HOUR_UPDATE,
        changeHourOdoNickNewValue,
        '',
        changeHourOdoNickLastValue,
      );
      if (data?.status === 200) {
        await handleGetLastHour();
        setChangeHourOdoNickNewValue('');
      }
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Erro ao enviar comando para o dispositivo`,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleSendDeviceNicknameCommand = async () => {
    setLoading(true);
    try {
      const data = await handleSendCommandToDevice(
        SendCommandEnum.SET_NICKNAME_UPDATE,
        changeHourOdoNickNewValue,
        '',
        changeHourOdoNickLastValue,
      );
      if (data?.status === 200) {
        await handleGetLastNickname();
        setChangeHourOdoNickNewValue('');
      }
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Erro ao enviar comando para o dispositivo`,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleSendCfgCanCommand = async () => {
    setLoading(true);

    try {
      if (!vehicle?.id) {
        notificationController.error({ message: 'Erro!', description: 'Nenhum veículo selecionado.' });
        return;
      }

      const message = `${vehicle.id};` + vehicleParameterSelecteds.join(';');
      const data = await handleSendCommandToDevice(SendCommandEnum.SET_CAN, message);

      if (data?.status === 200) {
        setDevice({
          ...device,
          idVeiculoCan: vehicle?.id,
          modeloVeiculo: vehicle.nome,
          idFabricante: vehicle.idVeiculoFabricante,
          fabricanteVeiculo: vehicle.fabricante,
        });
        setManufacturer(undefined);
        setVehicle(undefined);
        setVehicleParametersSelecteds([]);
      }
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Erro ao enviar comando para o dispositivo`,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleSendCommandToDevice = async (command: string, message?: string, endpoint?: string, oldValue?: string) => {
    setLoading(true);
    const response = await sendCommandService
      .sendCommand(endpoint ? endpoint : '', {
        idDispositivo: device?.imei ?? '',
        metodo: await handleEncryptText(command),
        mensagem: message ? await handleEncryptText(message) : '',
        valorAntigo: await handleEncryptText(oldValue ?? ''),
      })
      .catch((err: Error) => {
        setLoading(false);
        setLoadingLastTransmission(false);
        if (err.message.indexOf('429') > -1) {
          notificationController.error({
            message: `O limite de requisições foi ultrapassado para o endpoint solicitado.`,
          });
        } else {
          notificationController.error({
            message: `Ocorreu um erro durante a execução do comando.\n${err}`,
          });
        }
        return null;
      });

    if (response?.status === 200) notificationController.success({ message: 'Comando enviado com sucesso.' });

    setLoading(false);
    return response;
  };

  const handleSelectOnChange = async (value: unknown) => {
    handleCleanLastCommand();
    setCommandSelected(value as string);

    switch (value) {
      case SendCommandEnum.SET_GRAD_OUT:
        handleGetPowerCutStatus();
        break;
      case SendCommandEnum.SET_ODO_UPDATE:
        handleGetLastOdo();
        break;
      case SendCommandEnum.SET_HOUR_UPDATE:
        handleGetLastHour();
        break;
      case SendCommandEnum.SET_NICKNAME_UPDATE:
        handleGetLastNickname();
        break;
      case SendCommandEnum.SET_CAN:
        fetchManufactures();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setVehicle(undefined);
    setVehicleParametersSelecteds([]);
  }, [manufacturer]);

  return (
    <>
      <Modal
        title={`${powerCutStatus === 1 ? 'Ligar' : 'Desligar'} corta corrente`}
        open={modalSendPowerCutVisible}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '0.25rem' }}>
            <Button
              type="ghost"
              onClick={() => {
                setModalSendPowerCutVisible(false);
                setVisible(true);
              }}
            >
              Cancelar
            </Button>
            <Button type="primary" onClick={handleSendPowerCutCommand} disabled={loading} loading={loading}>
              Confirmar
            </Button>
          </div>
        }
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            Deseja realmente <strong>&nbsp;{powerCutStatus === 1 ? 'ligar' : 'desligar'} o corta corrente?</strong>
          </Col>
        </Row>
      </Modal>
      <Modal
        open={visible}
        onCancel={() => {
          handleCleanLastCommand();
          setVisible(false);
          setCommandSelected('');
        }}
        title="Envio de comando"
        size="large"
        footer={null}
      >
        <CardInfoTagBgBlue style={{ marginTop: '1rem', height: '3rem' }}>
          <Row>
            <Col style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <InfoCircleOutlined />
              Escolha apenas um comando por vez para enviar.
            </Col>
          </Row>
        </CardInfoTagBgBlue>
        <BaseForm layout="horizontal" style={{ width: '100%', marginTop: '1rem' }}>
          <Row align="middle" gutter={18}>
            <Col xs={24}>
              <S.Select
                showArrow
                style={{
                  width: '100%',
                }}
                loading={loading}
                disabled={loading}
                value={commandSelected}
                placeholder="Selecione um comando por vez para enviar"
                onChange={handleSelectOnChange}
                options={modelCommand.map((m) => ({
                  label: m.nome,
                  value: m.comando,
                }))}
              />
            </Col>
          </Row>
          {powerCut !== null && (
            <>
              <Row style={{ marginTop: '1rem' }}>
                <div>
                  <span>Estado atual: </span>
                  <Tag style={{ backgroundColor: '#E8E8E8', color: '#383838' }}>
                    {
                      {
                        0: 'Desligado',
                        1: 'Ligado',
                        2: 'Em andamento',
                      }[powerCut]
                    }
                  </Tag>
                </div>
              </Row>
              <Row style={{ marginTop: '0.5rem' }}>
                {powerCut === 0 && (
                  <CardInfoTagBgBlue style={{ height: '4rem' }}>
                    <Row>
                      <Col style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <InfoCircleOutlined />
                        Leva em torno de 60 segundos para finalizar o ciclo de corta corrente. Se enviou o comando
                        recentemente, ele ainda pode estar em processamento.
                      </Col>
                    </Row>
                  </CardInfoTagBgBlue>
                )}
                <RadioGroup
                  defaultValue={powerCutStatus}
                  value={powerCutStatus}
                  onChange={(e: RadioChangeEvent) => setPowerCutStatus(e.target.value)}
                >
                  {powerCut === 0 && <Radio value={1}>Ligar</Radio>}
                  {powerCut === 1 && <Radio value={0}>Desligar</Radio>}
                </RadioGroup>
              </Row>
            </>
          )}
          {commandSelected === SendCommandEnum.SET_CAN && (
            <BaseForm layout="vertical" style={{ width: '100%', marginTop: '1rem' }}>
              <Row gutter={[18, 48]}>
                <Col xs={24}>
                  <S.ContainerChangeValue>
                    <Row gutter={[18, 48]} style={{ width: '100%' }}>
                      <Col xs={12}>
                        <BaseFormInputItem label="Fabricante atual" style={{ width: '100%' }}>
                          <Input disabled value={device.fabricanteVeiculo} />
                        </BaseFormInputItem>
                      </Col>
                      <Col xs={12}>
                        <BaseFormInputItem label="Novo fabricante" style={{ width: '100%' }}>
                          <Select
                            showArrow
                            showSearch
                            value={manufacturer?.id}
                            placeholder="Selecione o fabricante"
                            onChange={(value) => setManufacturer(manufacturers.find((m) => m.id?.toString() == value))}
                            options={manufacturers.map((m) => ({ label: m.nome, value: m.id }))}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          ></Select>
                        </BaseFormInputItem>
                      </Col>
                      <Col xs={12}>
                        <BaseFormInputItem label="Modelo atual" style={{ width: '100%' }}>
                          <Input disabled value={device.modeloVeiculo} />
                        </BaseFormInputItem>
                      </Col>
                      <Col xs={12}>
                        <BaseFormInputItem label="Novo modelo de veículo" style={{ width: '100%' }}>
                          <Select
                            showArrow
                            showSearch
                            placeholder="Selecione o veículo"
                            value={vehicle?.id}
                            onChange={(value) => setVehicle(vehicles.find((v) => v.id?.toString() == value))}
                            disabled={!manufacturer?.id}
                            options={vehicles.map((m) => ({ label: `${m.nome} | ${m.ano}`, value: m.id }))}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          ></Select>
                        </BaseFormInputItem>
                      </Col>
                    </Row>
                  </S.ContainerChangeValue>
                </Col>
                {(vehicle?.parametros?.length ?? 0) != 0 && (
                  <Col xs={24}>
                    <Row>
                      <Col xs={24} sm={24} md={24}>
                        <CardInfoTitle style={{ marginTop: '0.625rem' }}>Selecione os parâmetros</CardInfoTitle>
                      </Col>

                      {vehicle?.parametros?.find((p) => p.tipo == TipoVeiculoParametro.Calculado) && (
                        <Row gutter={[10, 10]} style={{ marginBottom: '1rem' }}>
                          <Col xs={24} sm={24} md={24}>
                            <CardInfoValue>Calculado</CardInfoValue>
                          </Col>
                          <Col xs={24} md={24} lg={24}>
                            <Checkbox
                              checked={(() => {
                                const length = vehicle?.parametros?.filter(
                                  (p) => p.tipo == TipoVeiculoParametro.Calculado,
                                ).length;

                                if (length) {
                                  return (
                                    length ==
                                    vehicle?.parametros?.filter(
                                      (p) =>
                                        p.tipo == TipoVeiculoParametro.Calculado &&
                                        vehicleParameterSelecteds.includes(p.idIdentifierCan ?? 0),
                                    ).length
                                  );
                                }
                              })()}
                              onChange={(e) => {
                                const ids =
                                  vehicle?.parametros
                                    ?.filter((p) => p.tipo == TipoVeiculoParametro.Calculado)
                                    .map((p) => Number(p.idIdentifierCan)) ?? [];
                                handleOnChangeVehicleParameterCheckbox(ids, e.target.checked);
                              }}
                            >
                              Selecionar tudo
                            </Checkbox>
                          </Col>
                          {vehicle?.parametros
                            ?.filter((p) => p.tipo == TipoVeiculoParametro.Calculado)
                            .map((item) => (
                              <Col key={`${item.id}-${item.idIdentifierCan}`} xs={24} md={8} lg={8}>
                                <Checkbox
                                  checked={vehicleParameterSelecteds.includes(item.idIdentifierCan ?? 0)}
                                  onChange={(e) =>
                                    item.idIdentifierCan &&
                                    handleOnChangeVehicleParameterCheckbox([item.idIdentifierCan], e.target.checked)
                                  }
                                >
                                  {item.nome}
                                </Checkbox>
                              </Col>
                            ))}
                        </Row>
                      )}
                      {vehicle?.parametros?.find((p) => p.tipo == TipoVeiculoParametro.LigadoDesligado) && (
                        <Row gutter={[10, 10]}>
                          <Col xs={24} sm={24} md={24}>
                            <CardInfoValue>On/Off</CardInfoValue>
                          </Col>
                          <Col xs={24} md={24} lg={24}>
                            <Checkbox
                              checked={(() => {
                                const length = vehicle?.parametros?.filter(
                                  (p) => p.tipo == TipoVeiculoParametro.LigadoDesligado,
                                ).length;

                                if (length) {
                                  return (
                                    length ==
                                    vehicle?.parametros?.filter(
                                      (p) =>
                                        p.tipo == TipoVeiculoParametro.LigadoDesligado &&
                                        vehicleParameterSelecteds.includes(p.idIdentifierCan ?? 0),
                                    ).length
                                  );
                                }
                              })()}
                              onChange={(e) => {
                                const ids =
                                  vehicle?.parametros
                                    ?.filter((p) => p.tipo == TipoVeiculoParametro.LigadoDesligado)
                                    .map((p) => Number(p.idIdentifierCan)) ?? [];
                                handleOnChangeVehicleParameterCheckbox(ids, e.target.checked);
                              }}
                            >
                              Selecionar tudo
                            </Checkbox>
                          </Col>
                          {vehicle?.parametros
                            ?.filter((p) => p.tipo == TipoVeiculoParametro.LigadoDesligado)
                            .map((item) => (
                              <Col key={`${item.id}-${item.nome}`} xs={24} md={8} lg={8}>
                                <Checkbox
                                  checked={vehicleParameterSelecteds.includes(item.idIdentifierCan ?? 0)}
                                  onChange={(e) =>
                                    item.idIdentifierCan &&
                                    handleOnChangeVehicleParameterCheckbox([item.idIdentifierCan], e.target.checked)
                                  }
                                >
                                  {item.nome}
                                </Checkbox>
                              </Col>
                            ))}
                        </Row>
                      )}
                    </Row>
                  </Col>
                )}
              </Row>
            </BaseForm>
          )}

          {commandSelected != SendCommandEnum.SET_CAN && changeHourOdoNickNewLabel.length > 0 && (
            <BaseForm layout="vertical" style={{ width: '100%', marginTop: '1rem' }}>
              <S.ContainerChangeValue>
                <BaseFormInputItem label={changeHourOdoNickOldLabel}>
                  <Input disabled value={changeHourOdoNickLastValue} />
                </BaseFormInputItem>
                <BaseFormInputItem label={changeHourOdoNickNewLabel}>
                  <Input
                    value={changeHourOdoNickNewValue}
                    placeholder="Digite o novo valor"
                    maxLength={commandSelected === SendCommandEnum.SET_NICKNAME_UPDATE ? 12 : undefined}
                    onChange={(event) => setChangeHourOdoNickNewValue(event.target.value)}
                  />
                </BaseFormInputItem>
              </S.ContainerChangeValue>
            </BaseForm>
          )}
        </BaseForm>
        <S.Container>
          <S.FooterButtonsContainer>
            <Button
              type="ghost"
              onClick={() => {
                handleCleanLastCommand();
                setVisible(false);
                setCommandSelected('');
              }}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              onClick={handleSendCommandClick}
              disabled={
                commandSelected === SendCommandEnum.SET_GRAD_OUT
                  ? powerCutStatus < 0
                  : changeHourOdoNickNewLabel.length > 0
                  ? changeHourOdoNickNewValue.length <= 0
                  : loading
                  ? loading
                  : commandSelected.length <= 0
              }
            >
              Enviar comando
            </Button>
          </S.FooterButtonsContainer>
          {(loadingLastTransmission || lastTransmissionData !== null) && (
            <LastTransmission lastTransmissionData={lastTransmissionData} />
          )}
        </S.Container>
      </Modal>
    </>
  );
};

export default SendCommandToDeviceModal;
