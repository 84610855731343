import styled from 'styled-components';

interface TagColorProps {
  color: string;
  background: string;
}
export const TagMap = styled.div<TagColorProps>`
  font-family: Mulish;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  text-align: left;
  display: flex;
  justify-content: center;
  border-radius: 0.5rem;
  padding: 0.25rem;
  color: ${({ color }) => color};
  background-color: ${({ background }) => background};
`;
