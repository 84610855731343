import { Col, Row, Space } from 'antd';
import { BaseFormInputItem } from '../../forms/components/BaseFormInputItem/BaseFormInputItem';
import { Option, Select } from '../../selects/Select/Select';
import { Radio, RadioGroup } from '../../Radio/Radio';
import { Input } from '../../inputs/Input/Input';
import ShouldRenderInput from '../ShouldRenderInput';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import { PropertyModel } from '@app/domain/property/propertyModel';

interface IdentificadorMotoristaProps {
  properties: DeviceProfileProperty[];
}

const IdentificadorMotoristaView: React.FC<IdentificadorMotoristaProps> = ({ properties }) => {
  const props = properties as PropertyModel[];
  const getPropertyValue = (idEmbarcado: DevicePropertyType) =>
    properties?.find((p) => p.idEmbarcado == idEmbarcado)?.valorPropriedade;

  return (
    <>
      <Row gutter={18} style={{ marginTop: '1rem' }}>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.ProtocoloComunicacaoRastreador} properties={props}>
          <Col xl={8} md={12} sm={24}>
            <BaseFormInputItem label="Protocolo de comunicação" supportText="Escolha apenas uma opção">
              <Select
                disabled
                placeholder="Selecione o protocolo de comunicação"
                value={getPropertyValue(DevicePropertyType.ProtocoloComunicacaoRastreador)}
                showArrow
                showSearch
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option className="option-tab" value="0">
                  Jmak
                </Option>
                <Option className="option-tab" value="1">
                  Suntech
                </Option>
                <Option className="option-tab" value="2">
                  Queclink
                </Option>
                <Option className="option-tab" value="3">
                  Somente tag
                </Option>
                <Option className="option-tab" value="4">
                  Galileu (4 bytes)
                </Option>
              </Select>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.TipoBloqueio} properties={props}>
          <Col xl={5} md={12} sm={24}>
            <BaseFormInputItem label="Bloqueio">
              <RadioGroup value={getPropertyValue(DevicePropertyType.TipoBloqueio)} disabled>
                <Space size={0} direction="vertical">
                  <Radio value="0">Qualquer cartão RFID</Radio>
                  <Radio value="2">Somente cartões cadastrados</Radio>
                  <Radio value="1">Desativado</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput properties={props} idEmbarcado={DevicePropertyType.TipoIdentificacao}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Tipo de identificação">
              <RadioGroup value={getPropertyValue(DevicePropertyType.TipoIdentificacao)} disabled>
                <Space size={0} direction="vertical">
                  <Radio value="0">Motorista</Radio>
                  <Radio value="1">Passageiro</Radio>
                  <Radio value="2">Motorista e passageiro</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput properties={props} idEmbarcado={DevicePropertyType.PacoteLogout}>
          <Col xl={3} md={12} sm={24}>
            <BaseFormInputItem label="Pacote de logout">
              <RadioGroup value={getPropertyValue(DevicePropertyType.PacoteLogout)} disabled>
                <Space size={0} direction="vertical">
                  <Radio value="1">Ativo</Radio>
                  <Radio value="0">Inativo</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput properties={props} idEmbarcado={DevicePropertyType.AcaoDesligarVeiculo}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Ação ao desligar o veículo">
              <RadioGroup value={getPropertyValue(DevicePropertyType.AcaoDesligarVeiculo)} disabled>
                <Space size={0} direction="vertical">
                  <Radio value="0">Logout automático</Radio>
                  <Radio value="1">Logout por RFID</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
      </Row>
      <Row gutter={18}>
        <ShouldRenderInput properties={props} idEmbarcado={DevicePropertyType.DelayIgnicaoLigada}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Delay de ignição ligada (s)" supportText="Entre 1 a 30 segundos">
              <Input
                type="number"
                max={30}
                min={0}
                className="ant-input"
                placeholder="Digite o intervalo"
                value={getPropertyValue(DevicePropertyType.DelayIgnicaoLigada)}
                disabled
              />
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput properties={props} idEmbarcado={DevicePropertyType.DelayIgnicaoDesligada}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Delay de ignição desligada (s)" supportText="Entre 1 a 30 segundos">
              <Input
                type="number"
                max={30}
                min={0}
                disabled
                className="ant-input"
                placeholder="Digite o intervalo"
                value={getPropertyValue(DevicePropertyType.DelayIgnicaoDesligada)}
              />
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput properties={props} idEmbarcado={DevicePropertyType.AcaoBloqueioTemporizado}>
          <Col xl={5} md={12} sm={24}>
            <BaseFormInputItem label="Bloqueio temporizado (15s)">
              <RadioGroup value={getPropertyValue(DevicePropertyType.AcaoBloqueioTemporizado)} disabled>
                <Space size={0} direction="vertical">
                  <Radio value="1">Ativo</Radio>
                  <Radio value="0">Inativo</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput properties={props} idEmbarcado={DevicePropertyType.EstadoBuzzer}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Buzzer">
              <RadioGroup value={getPropertyValue(DevicePropertyType.EstadoBuzzer)} disabled>
                <Space size={0} direction="vertical">
                  <Radio value="1">Ativo</Radio>
                  <Radio value="0">Inativo</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
      </Row>
      <Row gutter={18}>
        <ShouldRenderInput properties={props} idEmbarcado={DevicePropertyType.VelocidadeTTL}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Velocidade serial - TTL (kbps)" supportText="Escolha apenas uma opção">
              <Select
                placeholder="Selecione a velocidade"
                value={getPropertyValue(DevicePropertyType.VelocidadeTTL) || undefined}
                disabled
                showArrow
                showSearch
                filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
              >
                <Option className="option-tab" value="0">
                  115.200
                </Option>
                <Option className="option-tab" value="1">
                  19.200
                </Option>
                <Option className="option-tab" value="2">
                  9.600
                </Option>
              </Select>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput properties={props} idEmbarcado={DevicePropertyType.VelocidadeRS232}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Velocidade serial - RS232 (kbps)" supportText="Escolha apenas uma opção">
              <Select
                placeholder="Selecione a velocidade"
                value={getPropertyValue(DevicePropertyType.VelocidadeRS232) || undefined}
                disabled
                showArrow
                showSearch
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option className="option-tab" value="0">
                  115.200
                </Option>
                <Option className="option-tab" value="1">
                  19.200
                </Option>
                <Option className="option-tab" value="2">
                  9.600
                </Option>
              </Select>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
      </Row>
    </>
  );
};

export default IdentificadorMotoristaView;
