import { Panel } from '@app/components/common/Collapse/Collapse';
import { Collapse } from 'antd';
import styled from 'styled-components';

export const SelectedFenceCardCollapse = styled(Collapse)<{ topPosition: string }>`
  position: absolute;
  background-color: #ffffff;
  width: 30rem;
  height: 3.9rem;
  left: 0.625rem;
  top: ${({ topPosition }) => topPosition};
  border-radius: unset;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-color: #ffffff;

  & .ant-collapse-content {
    border-top: unset;
  }

  & .ant-collapse-item {
    border-bottom: unset;
    width: 30rem;
    margin-left: -1px;
  }
`;

export const SelectedFenceCardCollapseHeader = styled.div`
  font-family: Mulish;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-align: left;
  color: #263668;
  padding: 0.75rem 1rem 0rem 0.5rem;
  height: 2.5rem;
  width: 30rem;
`;

export const SelectedFenceCardCollapseIcon = styled.div`
  display: flex;
  justify-content: start;
  color: #545454;
  font-size: 1.25rem !important;
  font-weight: 600;
  line-height: 1.5rem;
  padding-right: 1rem;
  padding-top: 0.65rem;
`;

export const SelectedFenceCardCollapsePanel = styled(Panel)``;

export const SelectedFenceCardCollapsePanelLabel = styled.div`
  font-family: Mulish;
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-align: left;
  color: #707070;

  & span {
    font-weight: 700;
    color: #263668;
  }
`;
