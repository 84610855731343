import { Steps } from '@app/components/common/Steps/Steps';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateFenceStepOne } from './components/steps/create-step-one';
import { FenceGroupModel } from '@app/domain/fence/fenceGroupModel';
import { setFooter } from '@app/store/slices/footerSlice';
import { Modal } from '@app/components/common/Modal/Modal';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CreateFenceStepTwo } from './components/steps/create-step-two';
import { Switch } from '@app/components/common/Switch/Switch';
import IFenceService, { FenceService } from '@app/services/fenceService';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { notificationController } from '@app/controllers/notificationController';
import { CardInfoProperty, CardInfoValue } from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';

const fenceService: IFenceService = new FenceService();

export const FenceCreate: React.FC = ({}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [groupFence, setGroupFence] = useState<FenceGroupModel>();
  const [showTutorial, setShowTutorial] = useState<boolean>(false);

  const handleGetClone = (id: string) => {
    setLoading(true);
    fenceService
      .get(`grupos/${id}`)
      .then((res) => setGroupFence({ ...res, nome: res.nome + ' clone' }))
      .catch(() => {
        notificationController.error({
          message: 'Erro!',
          description: 'Não foi possível obter os dados do grupo de cercas.',
        });
      })
      .finally(() => setLoading(false));
  };

  const handleSave = useCallback(() => {
    if (groupFence) {
      setLoading(true);
      fenceService
        .post(!id ? 'criar-completo' : 'clonar', groupFence)
        .then(() => {
          notificationController.success({
            message: 'Sucesso!',
            description: `Grupo de cercas ${!id ? 'cadastrado' : 'clonado'}.`,
          });
          navigate('/cercas');
        })
        .catch((error) => {
          notificationController.error(error);
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupFence]);

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: 'Novo grupo de cercas',
        handleBackClick: () => setModalCancelVisible(true),
      }),
    );
    id && handleGetClone(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    switch (currentStep) {
      case 0:
        dispatch(
          setFooter({
            confirmButtonDisabled: !groupFence?.idCliente || !groupFence.idModelo || !groupFence.nome,
            handleCancelButtonClick: () => setModalCancelVisible(true),
            confirmButtonText: 'Próxima etapa',
            handleConfirmButtonClick: () => setCurrentStep(1),
            cancelButtonText: 'Cancelar',
          }),
        );
        break;
      case 1:
        dispatch(
          setFooter({
            confirmButtonDisabled: !groupFence?.cercas?.length,
            handleCancelButtonClick: () => setModalCancelVisible(true),
            confirmButtonText: 'Finalizar cadastro',
            handleConfirmButtonClick: () => handleSave(),
            cancelButtonText: 'Cancelar',
            tagAButtonText: 'Voltar',
            handleTagAButtonClick: () => setCurrentStep(0),
          }),
        );
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupFence, groupFence?.cercas, currentStep]);

  useEffect(() => {
    const show = localStorage.getItem('showFenceTutorial');
    show == 'false' ? setShowTutorial(false) : setShowTutorial(true);
  }, []);
  useEffect(() => localStorage.setItem('showFenceTutorial', showTutorial.toString()), [showTutorial]);

  return (
    <>
      <Spinner spinning={loading} />
      <Modal
        title="Cancelar cadastro de grupo"
        open={modalCancelVisible}
        onOk={() => navigate('/cercas')}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            <span>
              Deseja realmente <strong> cancelar o cadastro do grupo? </strong>
            </span>
          </Col>
        </Row>
      </Modal>
      <S.FencePageContainer>
        <Row justify={'space-between'} align={'bottom'}>
          <Col span={4}>
            <Steps current={currentStep} items={[{ title: `Grupo` }, { title: 'Cercas' }]} />
          </Col>
          {currentStep == 1 && groupFence?.nome && groupFence?.modelo && (
            <Col>
              <Row gutter={16} align={'bottom'}>
                <Col>
                  <Row>
                    <CardInfoProperty>Grupo:</CardInfoProperty>
                    <CardInfoValue>&nbsp;{groupFence?.nome}</CardInfoValue>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <CardInfoProperty>Modelo:</CardInfoProperty>
                    <CardInfoValue>&nbsp;{groupFence?.modelo.nome}</CardInfoValue>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
          <Col>
            {currentStep == 1 && (
              <Switch
                size="default"
                checked={showTutorial}
                checkedChildren="Tutorial ativo"
                unCheckedChildren="Tutorial inativo"
                style={{ marginRight: '0.25rem' }}
                onChange={() => setShowTutorial(!showTutorial)}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <S.CreateFenceContainer>
              {
                {
                  0: <CreateFenceStepOne groupFence={groupFence} setGroupFence={setGroupFence} />,
                  1: (
                    <CreateFenceStepTwo
                      groupFence={groupFence}
                      setGroupFence={setGroupFence}
                      showTutorial={showTutorial}
                    />
                  ),
                }[currentStep]
              }
            </S.CreateFenceContainer>
          </Col>
        </Row>
      </S.FencePageContainer>
    </>
  );
};
