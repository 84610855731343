import { VersionProperties } from '@app/domain/deviceProfile/deviceProfileModel';
import { FirmwareGroupListModel, FirmwareGroupModel } from '@app/domain/firmwareGroup/firmwareGroupModel';
import { ProtocolCommunication } from '@app/domain/protocolCommunication/protocolCommunicationModel';
import { ApiServiceBase, IApiServiceBase } from '@app/services/base/apiServiceBase';

export default interface IFirmwareGroupService extends IApiServiceBase<FirmwareGroupModel, FirmwareGroupModel> {
  getListAll(): Promise<FirmwareGroupListModel[]>;
  getGroupByIdModelAndVersion(idModel?: number, version?: string): Promise<FirmwareGroupModel>;
  getPropertiesByIdGroupFirmware(id: number): Promise<VersionProperties>;
  getProtocolsCommunicationByIdGroupFirmware(id: number): Promise<ProtocolCommunication[]>;
}

export class FirmwareGroupService extends ApiServiceBase<FirmwareGroupModel, FirmwareGroupModel> {
  constructor() {
    super('GrupoFirmware');
  }

  async getListAll(): Promise<FirmwareGroupListModel[]> {
    return (await this.api.get(''))?.data;
  }
  async getGroupByIdModelAndVersion(idModel?: number, version?: string): Promise<FirmwareGroupModel> {
    return await this.get(`obter-por-id-modelo-versao?idModelo=${idModel}&versao=${version}`);
  }
  async getPropertiesByIdGroupFirmware(id: number): Promise<VersionProperties> {
    return (await this.api.get(`obter-propriedades-por-id-grupo-firmware/${id}`)).data;
  }
  async getProtocolsCommunicationByIdGroupFirmware(id: number): Promise<ProtocolCommunication[]> {
    return (await this.api.get(`${id}/protocolos-comunicacao`)).data;
  }
}
