/* eslint-disable @typescript-eslint/no-explicit-any */
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { isJM15 } from '../utils';
import { DeviceProfileCommand, DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import { DeviceMethods } from '@app/constants/enums/device/device-methods';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { groupBy } from 'lodash';
import { notificationController } from '@app/controllers/notificationController';
import DeviceSerialPort from '@app/services/deviceSerialPortClass';
import { TagGroupModel } from '@app/domain/tagGroup/TagGroupModel';

const deviceSerialPortClass = DeviceSerialPort.getInstance();

export const utilSaveTagsOnDeviceSerial = async (
  newClientDevice: FactoryDeviceModel,
  tagGroupsOptions: TagGroupModel[],
) => {
  try {
    await deviceSerialPortClass.set(DeviceMethods.SetInfo, DevicePropertyType.ExcluirTodasTags, '1');

    const allTags: string[] = [];
    allTags.push(...(newClientDevice.tagsToSendToDevice ?? []));
    allTags.push(...newClientDevice.tags);

    if (newClientDevice.idTagGrupo) {
      const tagsFromGroup = tagGroupsOptions.filter((t) => t.id === newClientDevice.idTagGrupo)[0].tags ?? [];
      allTags.push(...tagsFromGroup);
    }

    const tagToSend = Array.from(new Set(allTags?.map((tag) => tag.replace('\r\n', '').replace('\u0000', ''))));

    if (!tagToSend.length) return;

    await deviceSerialPortClass.setMultiple(DeviceMethods.SetInfo, DevicePropertyType.SalvarTag, tagToSend, 5);
  } catch (error) {
    notificationController.error({
      message: 'Erro!',
      description: 'Não foi possível finalizar a atualização do dispositivo, existem parâmetros inválidos.',
    });
  }
};

export const utilCleanDeviceSerial = async (newClientDevice: FactoryDeviceModel) => {
  try {
    if (newClientDevice.modelo !== 'J-R11') {
      await deviceSerialPortClass.set(DeviceMethods.SetInfo, DevicePropertyType.RemoverTodosIdentifiersCanSalvos, '0');
    }
    const savedFences = await deviceSerialPortClass.get(DevicePropertyType.CercasSalvas);

    if (!savedFences) return;

    for (const fenceName of savedFences.split(',')) {
      await deviceSerialPortClass.set(DeviceMethods.SetInfo, DevicePropertyType.RemoverCerca, fenceName);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const utilConfirmUpdateDeviceSerialProperties = async (isUpdatingDevice: boolean) => {
  if (!isUpdatingDevice) {
    const confirmed = await deviceSerialPortClass.confirm(DevicePropertyType.DadosValidos, '1');
    if (!confirmed) {
      const message = 'Erro ao confirmar os dados válidos';
      notificationController.error({ message });
      throw new Error(message);
    }
  }
};

export const utilUpdateDeviceSerialPropertiesFromDevice = async (
  newClientDevice: FactoryDeviceModel,
  isUpdatingDevice: boolean,
) => {
  if (newClientDevice.apelido)
    await deviceSerialPortClass.set(
      DeviceMethods.SetInfo,
      DevicePropertyType.ApelidoDispositivo,
      newClientDevice.apelido,
    );

  if (!isUpdatingDevice) {
    await deviceSerialPortClass.set(DeviceMethods.SetInfo, DevicePropertyType.NumeroSerie, newClientDevice.numeroSerie);

    if (isJM15(newClientDevice.modelo)) return;

    if (newClientDevice.connectionString)
      await deviceSerialPortClass.setCrypto(newClientDevice.connectionString, 'ConnectionString');
  }
};

export const utilUpdateDeviceSerialCommands = async (commands: DeviceProfileCommand[]) => {
  try {
    const groupedProperties = groupBy(commands, 'idModulo');

    for (const propertyName of Object.keys(groupedProperties)) {
      const formatedProperties = groupedProperties[propertyName]
        .filter((p) => p.comando)
        .sort((a, b) =>
          !a.ordemExecucao ? (!b.ordemExecucao ? 0 : 1) : !b.ordemExecucao ? -1 : a.ordemExecucao - b.ordemExecucao,
        );

      for (const property of formatedProperties) {
        property.comando && (await deviceSerialPortClass.setCrypto(property.comando, property.propriedade ?? ''));
      }
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const utilUpdateDeviceSerialProperties = async (properties: DeviceProfileProperty[]) => {
  try {
    const groupedProperties = groupBy(properties, 'idModulo');

    for (const propertyName of Object.keys(groupedProperties)) {
      const formatedProperties = groupedProperties[propertyName]
        .filter((p) => p.valorPropriedade)
        .sort((a, b) =>
          !a.ordemExecucao ? (!b.ordemExecucao ? 0 : 1) : !b.ordemExecucao ? -1 : a.ordemExecucao - b.ordemExecucao,
        );

      for (const property of formatedProperties) {
        const value = property.valorPropriedade;
        await deviceSerialPortClass.set(
          property.tipoComando as DeviceMethods,
          property.idEmbarcado,
          value === 'true' ? '1' : value === 'false' ? '0' : value,
        );
      }
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const serialNumberMask = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
