import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useCallback, useEffect, useState } from 'react';
import { Description } from '@app/components/common/Description/Description';
import {
  CardInfoContainer,
  CardInfoProperty,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Col, Row } from 'antd';
import { DividerLine } from '@app/components/common/divider/DividerLine.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setFooter } from '@app/store/slices/footerSlice';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { notificationController } from '@app/controllers/notificationController';
import { Text } from '@app/components/common/typography/P2/P2.styles';
import { Popover } from '@app/components/common/Popover/Popover';
import * as S from './view.styles';
import { FilePdfOutlined } from '@ant-design/icons';
import { ReactComponent as IconDownload } from '@app/assets/icons/file-download.svg';
import IVehicleService, { VehicleService } from '@app/services/vehicleService';
import { TipoVeiculoParametro, VehicleFileModel, VehicleModel } from '@app/domain/vehicle/vehicleModel';

const vehicleService: IVehicleService = new VehicleService();

export const CanVehicleView: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [canVehicle, setCanVehicle] = useState<VehicleModel>({} as VehicleModel);

  const fetchCanVehicle = useCallback(async () => {
    try {
      setLoading(true);
      const response = await vehicleService.get(`${id}`);

      setCanVehicle(response);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um erro ao tentar buscar os dados do veículo CAN.',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownloadSingleFile = async (url: string) => {
    window.open(url);
  };

  const handleDownloadMultiFile = async () => {
    try {
      setLoading(true);

      const response = await vehicleService.api.get(`baixar-arquivos-por-veiculo/${id}`);
      if (response.status === 200 && response.data.length > 0) {
        response.data.forEach(async (r: VehicleFileModel) => {
          const arquivo = r.url;
          window.open(arquivo);
        });
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um erro ao tentar baixar os arquivos do veículo CAN.',
      });
    }
  };

  useEffect(() => {
    dispatch(setFooter({}));
    dispatch(
      setHeaderRegister({
        title: 'Visualizar veículo CAN',
        handleBackClick: () => navigate('/veiculos-can'),
      }),
    );
    fetchCanVehicle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate]);

  return (
    <>
      <Spinner spinning={loading}>
        <PageContainer>
          <CardInfoContainer style={{ marginBottom: '5rem' }}>
            <Description title="Informações do veículo" />
            <Row gutter={24}>
              <Col xs={6} md={4} lg={4} xxl={3}>
                <CardInfoProperty>Nome do veículo</CardInfoProperty>
                <CardInfoValue>{canVehicle.nome}</CardInfoValue>
              </Col>
              <Col xs={6} md={4} lg={4} xxl={3}>
                <CardInfoProperty>Fabricante</CardInfoProperty>
                <CardInfoValue style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
                  <img width={40} src={canVehicle.fabricanteImagemUrl} /> <span>{canVehicle.fabricante}</span>
                </CardInfoValue>
              </Col>
              <Col xs={5} md={3} lg={3} xxl={2}>
                <CardInfoProperty>Ano</CardInfoProperty>
                <CardInfoValue>{canVehicle.ano}</CardInfoValue>
              </Col>
              <Col xs={6} md={4} lg={4} xxl={3}>
                <CardInfoProperty>Velocidade CAN</CardInfoProperty>
                <CardInfoValue>{canVehicle.velocidadeCan} kbps</CardInfoValue>
              </Col>
              <Col xs={6} md={4} lg={4} xxl={3}>
                <CardInfoProperty>Quantidade de Bits</CardInfoProperty>
                <CardInfoValue>{canVehicle.quantidadeBits} Bits</CardInfoValue>
              </Col>
              <Col xs={6} md={4} lg={4} xxl={3}>
                <CardInfoProperty>Tipo do veículo</CardInfoProperty>
                <CardInfoValue>{canVehicle.tipoVeiculo === 1 ? 'PGN' : 'Identifier'}</CardInfoValue>
              </Col>
              <Col xs={6} md={4} lg={4} xxl={3}>
                <CardInfoProperty>Tipo da informação</CardInfoProperty>
                <CardInfoValue>{canVehicle.tipoInformacao === 1 ? 'LSB' : 'MSB'}</CardInfoValue>
              </Col>
              <Col xs={6} md={4} lg={4} xxl={3}>
                <CardInfoProperty>Status</CardInfoProperty>
                <CardInfoValue>{canVehicle.status ? 'Ativo' : 'Inativo'}</CardInfoValue>
              </Col>
            </Row>
            <DividerLine />
            <Description title="Observações do modelo do veículo" />
            <Row>
              <Text>Observações</Text>
            </Row>
            <Row>
              <Text>{canVehicle.observacoes}</Text>
            </Row>
            <DividerLine />
            {canVehicle.arquivos && canVehicle.arquivos?.length > 0 && (
              <>
                <S.FilesContainer>
                  <S.Title>Arquivos</S.Title>
                  <S.FilesExportAll onClick={handleDownloadMultiFile}>
                    <IconDownload />
                    Exportar todos os arquivos
                  </S.FilesExportAll>
                </S.FilesContainer>
                <Row>
                  <S.FileUploadCardContainer>
                    {canVehicle.arquivos.map((file) => (
                      <S.FileUploadCard key={file.id}>
                        <FilePdfOutlined size={40} />
                        <Popover content={file.nome}>
                          <S.FileNameText>{file.nome}</S.FileNameText>
                        </Popover>
                        <span
                          style={{ marginTop: '0.5rem', cursor: 'pointer' }}
                          onClick={() => handleDownloadSingleFile(file.url)}
                        >
                          <IconDownload />
                        </span>
                      </S.FileUploadCard>
                    ))}
                  </S.FileUploadCardContainer>
                </Row>
                <DividerLine />
              </>
            )}
            <Description title="Parâmetros do veículo" />
            <h1>Calculados</h1>
            {canVehicle.parametros
              ?.filter((p) => p.tipo == TipoVeiculoParametro.Calculado)
              ?.map((parameter, index) => (
                <Row gutter={24} key={parameter.id}>
                  <Col xs={6} md={4} lg={4} xxl={3}>
                    <CardInfoProperty style={{ display: `${index === 0 ? 'block' : 'none'}` }}>
                      Nome ID
                    </CardInfoProperty>
                    <CardInfoValue>{parameter.nome}</CardInfoValue>
                  </Col>
                  <Col xs={6} md={4} lg={2} xxl={1}>
                    <CardInfoProperty style={{ display: `${index === 0 ? 'block' : 'none'}` }}>ID</CardInfoProperty>
                    <CardInfoValue>{parameter.parametroId}</CardInfoValue>
                  </Col>
                  <Col xs={6} md={4} lg={3} xxl={2}>
                    <CardInfoProperty style={{ display: `${index === 0 ? 'block' : 'none'}` }}>
                      Resolução
                    </CardInfoProperty>
                    <CardInfoValue>{parameter.resolucao}</CardInfoValue>
                  </Col>
                  <Col xs={6} md={4} lg={3} xxl={2}>
                    <CardInfoProperty style={{ display: `${index === 0 ? 'block' : 'none'}` }}>Offset</CardInfoProperty>
                    <CardInfoValue>{parameter.offset}</CardInfoValue>
                  </Col>
                  <Col xs={6} md={4} lg={3} xxl={2}>
                    <CardInfoProperty style={{ display: `${index === 0 ? 'block' : 'none'}` }}>
                      Bit inicial
                    </CardInfoProperty>
                    <CardInfoValue>{parameter.bitInicial}</CardInfoValue>
                  </Col>
                  <Col xs={8} md={6} lg={4} xxl={3}>
                    <CardInfoProperty style={{ display: `${index === 0 ? 'block' : 'none'}` }}>
                      Tamanho em bits
                    </CardInfoProperty>
                    <CardInfoValue>{parameter.tamanhoBits ?? '-'}</CardInfoValue>
                  </Col>
                </Row>
              ))}
            <br />
            <h1>On/Off</h1>
            {canVehicle.parametros
              ?.filter((p) => p.tipo == TipoVeiculoParametro.LigadoDesligado)
              ?.map((parameter, index) => (
                <Row gutter={24} key={parameter.id}>
                  <Col xs={6} md={4} lg={4} xxl={3}>
                    <CardInfoProperty style={{ display: `${index === 0 ? 'block' : 'none'}` }}>
                      Nome ID
                    </CardInfoProperty>
                    <CardInfoValue>{parameter.nome}</CardInfoValue>
                  </Col>
                  <Col xs={6} md={4} lg={3} xxl={2}>
                    <CardInfoProperty style={{ display: `${index === 0 ? 'block' : 'none'}` }}>ID</CardInfoProperty>
                    <CardInfoValue>{parameter.parametroId}</CardInfoValue>
                  </Col>
                  <Col xs={6} md={4} lg={3} xxl={2}>
                    <CardInfoProperty style={{ display: `${index === 0 ? 'block' : 'none'}` }}>
                      Bit inicial
                    </CardInfoProperty>
                    <CardInfoValue>{parameter.bitInicial}</CardInfoValue>
                  </Col>
                  <Col xs={8} md={6} lg={4} xxl={3}>
                    <CardInfoProperty style={{ display: `${index === 0 ? 'block' : 'none'}` }}>
                      Tamanho em bits
                    </CardInfoProperty>
                    <CardInfoValue>{parameter.tamanhoBits}</CardInfoValue>
                  </Col>
                  <Col xs={6} md={4} lg={3} xxl={2}>
                    <CardInfoProperty style={{ display: `${index === 0 ? 'block' : 'none'}` }}>Ligado</CardInfoProperty>
                    <CardInfoValue>{parameter.ligado}</CardInfoValue>
                  </Col>
                  <Col xs={6} md={4} lg={3} xxl={2}>
                    <CardInfoProperty style={{ display: `${index === 0 ? 'block' : 'none'}` }}>
                      Desligado
                    </CardInfoProperty>
                    <CardInfoValue>{parameter.desligado}</CardInfoValue>
                  </Col>
                </Row>
              ))}
          </CardInfoContainer>
        </PageContainer>
      </Spinner>
    </>
  );
};
