import { DownOutlined, UpOutlined } from '@ant-design/icons';
import * as S from './styles';
import React from 'react';
import { CollapseProps } from 'antd';

interface CollapseStepProps extends CollapseProps {}

export const CollapseStep: React.FC<CollapseStepProps> = (props) => {
  return (
    <S.Collapse
      accordion
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <S.CollapseStepIcon>{isActive ? <DownOutlined /> : <UpOutlined />}</S.CollapseStepIcon>
      )}
      {...props}
    />
  );
};
