import React, { FC } from 'react';
import { Collapse } from 'antd';
import * as S from '../styles';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import RedeView from '@app/components/common/ModulesProfileConfig/Rede/RedeView';
import EntradaView from '@app/components/common/ModulesProfileConfig/Entrada/EntradaView';
import PerfilEnvioView from '@app/components/common/ModulesProfileConfig/PerfilEnvio/PerfilEnvioView';
import EventosView from '@app/components/common/ModulesProfileConfig/Eventos/EventosView';
import { DividerLine } from '@app/components/common/divider/DividerLine.styles';
import { CANView } from '@app/components/common/ModulesProfileConfig/CAN/CANView';
import { CercaView } from '@app/components/common/ModulesProfileConfig/Cerca/CercaView';
import { groupByKey } from '@app/utils/utils';
import IdentificadorMotoristaView from '@app/components/common/ModulesProfileConfig/IdentificadorMotorista/IdentificadorMotoristaView';

const { Panel } = Collapse;

interface ModuleViewComponentProps {
  properties: DeviceProfileProperty[];
}
// Componente ModuleComponent
const ModuleViewComponent: FC<ModuleViewComponentProps> = ({ properties }) => {
  const componentsMap: { [key: string]: React.ElementType } = {
    Rede: RedeView,
    Entrada: EntradaView,
    PerfilEnvio: PerfilEnvioView,
    Evento: EventosView,
    IdentificadorMotorista: IdentificadorMotoristaView,
    CAN: CANView,
    Cercas: CercaView,
  };
  const renderModuleContent = (modulo: string) => {
    const Component = componentsMap[modulo];
    if (Component) return <Component properties={properties.filter((p) => p.modulo == modulo)} />;
    return <></>;
  };

  const renderModuleTitle = (name: string) => {
    switch (name) {
      case 'PerfilEnvio':
        return 'Perfil de envio';
      case 'CAN':
        return 'Veículos CAN';
      case 'IdentificadorMotorista':
        return 'Configurações';
      default:
        return name;
    }
  };

  return (
    <>
      {Object.keys(groupByKey(properties, 'idModulo'))
        .filter((idModulo) => idModulo != '0')
        .map((idModulo) => {
          const moduleName = properties.find((p) => p.idModulo == Number(idModulo))?.modulo ?? '';

          return (
            <S.CollapseContainer key={idModulo}>
              <S.Collapse bordered={false} defaultActiveKey={idModulo} style={{ paddingLeft: '-16px' }}>
                <Panel key={idModulo} header={renderModuleTitle(moduleName)} style={{ paddingLeft: '-16px' }}>
                  {renderModuleContent(moduleName)}
                </Panel>
                <div style={{ paddingLeft: '16px' }}>
                  <DividerLine />
                </div>
              </S.Collapse>
            </S.CollapseContainer>
          );
        })}
    </>
  );
};

export default ModuleViewComponent;
