import { IconDevices, IconHome, IconManifacturing, IconSettingRemote } from '@app/assets/slump-icons';
import AuthLayout from '@app/components/layouts/auth/AuthLayout';
import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import { RegisterLayout } from '@app/components/layouts/register/RegisterLayout/RegisterLayout';
import RequireAuth from '@app/pages/auth/require-auth';
import { ClientDashboard } from '@app/pages/client';
import ClientCreate from '@app/pages/client/create';
import { DeviceDashboard } from '@app/pages/device';
import { FactoryDeviceDashboard } from '@app/pages/factory-device';
import FactoryDeviceCreate from '@app/pages/factory-device/create';
import { UserDashboard } from '@app/pages/user';
import { LabelDashboard } from '@app/pages/label';
import React from 'react';
import DeviceCreate from '@app/pages/device/create';
import { LabelCreate } from '@app/pages/label/create';
import { UserCreate } from '@app/pages/user/create';
import { FactoryScheduleDashboard } from '@app/pages/factory-schedule';
import { FactoryScheduleCreate } from '@app/pages/factory-schedule/create/create';
import { FactoryScheduleView } from '@app/pages/factory-schedule/view/view';
import Error500Page from '@app/pages/errors/Error500';
import { DeviceConfigUpdateDashboard } from '@app/pages/device-config-update';
import { DeviceConfigUpdateCreate } from '@app/pages/device-config-update/create';
import { DeviceConfigUpdateView } from '@app/pages/device-config-update/view';
import { EquipmentDashboard } from '@app/pages/equipment';
import { EquipmentCreate } from '@app/pages/equipment/create';
import { FenceDashboard } from '@app/pages/fence';
import { FenceCreate } from '@app/pages/fence/create';
import { CanVehicleDashboard } from '@app/pages/can-vehicle';
import { CanVehicleView } from '@app/pages/can-vehicle/view';
import { CanVehicleCreate } from '@app/pages/can-vehicle/create';
import { DeviceProfileDashboard } from '@app/pages/device-profile';
import { DeviceProfileCreate } from '@app/pages/device-profile/create';
import { DeviceProfileView } from '@app/pages/device-profile/view';
import { DeviceView } from '@app/pages/device/view';
import { DeviceViewConfigUpdate } from '@app/pages/device/view-config-update';
import { LabelGeneratorPreviewMock } from '@app/pages/label/components/LabelGeneratorPreviewMock';
import { AppstoreAddOutlined, SettingOutlined, StopOutlined } from '@ant-design/icons';
import { DeviceGroupDashboard } from '@app/pages/device-group';
import { DeviceGroupCreate } from '@app/pages/device-group/create';
import { FirmwareGroupDashboard } from '@app/pages/firmware-group';
import { FirmwareGroupCreate } from '@app/pages/firmware-group/create';
import { TagGroupDashboard } from '@app/pages/tags-group';
import { TagGroupCreate } from '@app/pages/tags-group/create';
import { TagsGroupView } from '@app/pages/tags-group/view/view';
import { Auth } from '@app/pages/auth';
import Logout from '@app/pages/auth/Logout';
import { HomeDashboard } from '@app/pages/home';
import { SystemParametersDashboard } from '@app/pages/system-parameters';
import { SystemParametersCreate } from '@app/pages/system-parameters/create';
import { DeviceBlockDashboard } from '@app/pages/device-block';
import { DeviceBlockView } from '@app/pages/device-block/view';
import { DeviceBlockCreate } from '@app/pages/device-block/create';
import { FenceView } from '@app/pages/fence/view';
import { RedirectDeviceSerialNumber } from '@app/pages/public-pages/redirect-serial-number';
import PublicLayout from '@app/components/layouts/public-layout/PublicLayout';

export interface RouteSlumpMixConfig {
  key: string;
  title: string;
  isSideMenu: boolean;
  path?: string;
  roles?: string[];
  icon?: React.ReactNode;
  element?: JSX.Element;
  layout: JSX.Element;
  children?: RouteSlumpMixConfig[];
}

const authLayout = <AuthLayout key="auth" />;

const mainLayout = (
  <RequireAuth key="main">
    <MainLayout />
  </RequireAuth>
);
const registerLayout = (
  <RequireAuth key="registerPage">
    <RegisterLayout />
  </RequireAuth>
);

export const RoutesSlumpMix: RouteSlumpMixConfig[] = [
  {
    title: 'Home',
    isSideMenu: true,
    key: 'home',
    roles: ['factory', 'factory_adm', 'client'],
    path: '/',
    icon: (
      <i style={{ display: 'flex' }}>
        <IconHome />
      </i>
    ),
    layout: mainLayout,
    element: <HomeDashboard />,
  },
  {
    title: 'Dispositivos na fábrica',
    isSideMenu: true,
    key: 'factory-device',
    roles: ['factory', 'factory_adm'],
    path: '/dispositivos-fabrica',
    icon: (
      <i style={{ display: 'flex' }}>
        <IconManifacturing />
      </i>
    ),
    layout: mainLayout,
    element: <FactoryDeviceDashboard />,
  },
  {
    title: 'Redirecionar Dispositivos',
    isSideMenu: false,
    key: 'redirect-device',
    path: '/sn/:serial',
    layout: <PublicLayout />,
    element: <RedirectDeviceSerialNumber />,
  },
  {
    title: 'Cadastro Dispositivos',
    isSideMenu: false,
    key: 'factory-device-create',
    roles: ['factory', 'factory_adm'],
    path: '/dispositivos-fabrica/cadastrar',
    layout: registerLayout,
    element: <FactoryDeviceCreate />,
  },
  {
    title: 'Meus dispositivos',
    isSideMenu: true,
    key: 'devices',
    roles: ['factory_adm', 'client'],
    path: '/meus-dispositivos',
    icon: (
      <i style={{ display: 'flex' }}>
        <IconDevices />
      </i>
    ),
    layout: mainLayout,
    element: <DeviceDashboard />,
  },
  {
    title: 'Meus dispositivos',
    isSideMenu: false,
    key: 'devices-create',
    roles: ['factory_adm', 'client'],
    path: '/meus-dispositivos/cadastrar',
    layout: registerLayout,
    element: <DeviceCreate />,
  },
  {
    title: 'Meus dispositivos',
    isSideMenu: false,
    key: 'devices-update',
    roles: ['factory_adm', 'client'],
    path: '/meus-dispositivos/atualizar/:id',
    layout: registerLayout,
    element: <DeviceCreate />,
  },
  {
    title: 'Meus dispositivos',
    isSideMenu: false,
    key: 'devices-view',
    roles: ['factory_adm', 'client'],
    path: '/meus-dispositivos/visualizar/:id',
    layout: registerLayout,
    element: <DeviceView goBackTo={'/meus-dispositivos'} />,
  },
  {
    title: 'Dispositivo',
    isSideMenu: false,
    key: 'devices-view',
    roles: ['factory_adm', 'client'],
    path: 'dispositivos-fabrica/visualizar/:id',
    layout: registerLayout,
    element: <DeviceView goBackTo={'/dispositivos-fabrica'} />,
  },
  {
    title: 'Meus dispositivos',
    isSideMenu: false,
    key: 'devices-view-parameters',
    roles: ['factory_adm', 'client'],
    path: '/dispositivos-fabrica/visualizar/:id/parametros-atualizados/:idUpdate',
    layout: registerLayout,
    element: <DeviceViewConfigUpdate />,
  },
  {
    title: 'Meus dispositivos',
    isSideMenu: false,
    key: 'devices-view-parameters',
    roles: ['factory_adm', 'client'],
    path: '/meus-dispositivos/visualizar/:id/parametros-atualizados/:idUpdate',
    layout: registerLayout,
    element: <DeviceViewConfigUpdate />,
  },
  {
    title: 'Novo grupo',
    isSideMenu: false,
    key: 'device-group-create',
    roles: ['factory_adm', 'client'],
    path: '/dispositivos/grupos/cadastrar',
    layout: registerLayout,
    element: <DeviceGroupCreate />,
  },
  {
    title: 'Editar grupo',
    isSideMenu: false,
    key: 'device-group-create',
    roles: ['factory_adm', 'client'],
    path: '/dispositivos/grupos/editar/:id',
    layout: registerLayout,
    element: <DeviceGroupCreate />,
  },
  {
    title: 'Cadastros',
    isSideMenu: true,
    key: 'create',
    layout: mainLayout,
    roles: ['factory_adm', 'factory', 'client'],
    icon: (
      <i style={{ display: 'flex' }}>
        <AppstoreAddOutlined />
      </i>
    ),
    children: [
      {
        title: 'Clientes',
        isSideMenu: true,
        key: 'client',
        roles: ['factory_adm', 'factory'],
        path: '/clientes',
        layout: mainLayout,
        element: <ClientDashboard />,
      },
      {
        title: 'Equipamentos',
        isSideMenu: true,
        key: 'equipment',
        roles: ['factory', 'factory_adm'],
        path: '/equipamentos',
        layout: mainLayout,
        element: <EquipmentDashboard />,
      },
      {
        title: 'Etiquetas',
        isSideMenu: true,
        key: 'label',
        roles: ['factory', 'factory_adm'],
        path: '/etiquetas',
        layout: mainLayout,
        element: <LabelDashboard />,
      },
      {
        title: 'Usuários',
        isSideMenu: true,
        key: 'user',
        roles: ['factory_adm', 'factory'],
        path: '/usuarios',
        layout: mainLayout,
        element: <UserDashboard />,
      },
      {
        title: 'Grupos de dispositivos',
        isSideMenu: true,
        key: 'device-group',
        roles: ['factory_adm', 'client'],
        path: '/dispositivos/grupos',
        layout: mainLayout,
        element: <DeviceGroupDashboard />,
      },
      {
        title: 'Grupo de tags',
        isSideMenu: true,
        key: 'tag-group',
        roles: ['factory_adm', 'client'],
        path: '/grupo-tags',
        layout: mainLayout,
        element: <TagGroupDashboard />,
      },
      {
        title: 'Parametros do sistema',
        isSideMenu: true,
        key: 'system-parameters',
        roles: ['factory_adm'],
        path: '/parametros-sistema',
        layout: mainLayout,
        element: <SystemParametersDashboard />,
      },
    ],
  },
  {
    title: 'Parametros do sistema',
    isSideMenu: false,
    key: 'system-parameters-create',
    roles: ['factory_adm'],
    path: '/parametros-sistema/cadastrar',
    layout: registerLayout,
    element: <SystemParametersCreate />,
  },
  {
    title: 'Parametros do sistema',
    isSideMenu: false,
    key: 'system-parameters-edit',
    roles: ['factory_adm'],
    path: '/parametros-sistema/editar/:id',
    layout: registerLayout,
    element: <SystemParametersCreate />,
  },
  {
    title: 'Clientes',
    isSideMenu: false,
    key: 'client-create',
    roles: ['factory_adm'],
    path: '/clientes/cadastrar',
    layout: registerLayout,
    element: <ClientCreate />,
  },
  {
    title: 'Cliente',
    isSideMenu: false,
    key: 'client-edit',
    roles: ['factory_adm'],
    path: '/clientes/editar/:id',
    layout: registerLayout,
    element: <ClientCreate />,
  },
  {
    title: 'Equipamentos',
    isSideMenu: false,
    key: 'equipment-create',
    roles: ['factory', 'factory_adm'],
    path: '/equipamentos/criar',
    layout: registerLayout,
    element: <EquipmentCreate />,
  },
  {
    title: 'Equipamentos',
    isSideMenu: false,
    key: 'equipment-update',
    roles: ['factory', 'factory_adm'],
    path: '/equipamentos/editar/:id',
    layout: registerLayout,
    element: <EquipmentCreate />,
  },
  {
    title: 'Etiquetas',
    isSideMenu: false,
    key: 'label-create',
    roles: ['factory_adm'],
    path: '/etiquetas/cadastrar',
    layout: registerLayout,
    element: <LabelCreate />,
  },
  {
    title: 'Usuários',
    isSideMenu: false,
    key: 'user-edit',
    roles: ['factory_adm'],
    path: '/usuarios/editar/:id',
    layout: registerLayout,
    element: <UserCreate />,
  },
  {
    title: 'Usuários',
    isSideMenu: false,
    key: 'user-create',
    roles: ['factory_adm'],
    path: '/usuarios/cadastrar',
    layout: registerLayout,
    element: <UserCreate />,
  },
  {
    title: 'Atualizações remotas',
    isSideMenu: true,
    key: 'config_update',
    layout: mainLayout,
    roles: ['factory_adm', 'client'],
    icon: (
      <i style={{ display: 'flex', marginTop: '0.25rem', marginLeft: '-0.2rem' }}>
        <span role="img" aria-label="appstore-add" className="anticon anticon-appstore-add">
          <IconSettingRemote />
        </span>
      </i>
    ),
    children: [
      {
        title: 'Atualização de firmware',
        isSideMenu: true,
        key: 'factory-schedule',
        roles: ['factory', 'factory_adm'],
        path: '/agendamento-versao',
        layout: mainLayout,
        element: <FactoryScheduleDashboard />,
      },
      {
        title: 'Configuração remota',
        isSideMenu: true,
        key: 'device_config_update',
        roles: ['factory_adm', 'client'],
        path: '/atualizacao-configuracao-dispositivo',
        layout: mainLayout,
        element: <DeviceConfigUpdateDashboard />,
      },
    ],
  },
  {
    title: 'Bloqueio',
    isSideMenu: true,
    key: 'device-block',
    roles: ['factory_adm'],
    path: '/dispositivos-bloqueio',
    icon: (
      <i style={{ display: 'flex' }}>
        <StopOutlined />
      </i>
    ),
    layout: mainLayout,
    element: <DeviceBlockDashboard />,
  },
  {
    title: 'Bloqueio',
    isSideMenu: false,
    key: 'device-block',
    roles: ['factory_adm'],
    path: '/dispositivos-bloqueio/visualizar/:id',
    layout: registerLayout,
    element: <DeviceBlockView />,
  },
  {
    title: 'Bloqueio',
    isSideMenu: false,
    key: 'device-block',
    roles: ['factory_adm'],
    path: '/dispositivos-bloqueio/bloquear',
    layout: registerLayout,
    element: <DeviceBlockCreate />,
  },
  {
    title: 'Bloqueio',
    isSideMenu: false,
    key: 'device-block',
    roles: ['factory_adm'],
    path: '/dispositivos-bloqueio/:action/:id',
    layout: mainLayout,
    element: <DeviceBlockDashboard />,
  },
  {
    title: 'Agendamento versão',
    isSideMenu: false,
    key: 'factory-schedule-create',
    roles: ['factory_adm'],
    path: '/agendamento-versao/cadastrar',
    layout: registerLayout,
    element: <FactoryScheduleCreate />,
  },
  {
    title: 'Agendamento versão',
    isSideMenu: false,
    key: 'factory-schedule-view',
    roles: ['factory', 'factory_adm'],
    path: '/agendamento-versao/:id',
    layout: registerLayout,
    element: <FactoryScheduleView />,
  },
  {
    title: 'Configurações dispositivo',
    isSideMenu: false,
    key: 'device_config_update_view',
    roles: ['factory_adm', 'client'],
    path: '/atualizacao-configuracao-dispositivo/visualizar/:id',
    layout: registerLayout,
    element: <DeviceConfigUpdateView />,
  },
  {
    title: 'Configurações dispositivo',
    isSideMenu: false,
    key: 'device_config_update_create',
    roles: ['factory_adm', 'client'],
    path: '/atualizacao-configuracao-dispositivo/agendar',
    layout: registerLayout,
    element: <DeviceConfigUpdateCreate />,
  },
  {
    title: 'Configurações dispositivo',
    isSideMenu: false,
    key: 'device_config_update_create',
    roles: ['factory_adm', 'client'],
    path: '/atualizacao-configuracao-dispositivo/agendar/:id',
    layout: registerLayout,
    element: <DeviceConfigUpdateCreate />,
  },
  {
    title: 'Atualização de firmware',
    isSideMenu: false,
    key: 'factory-schedule-create',
    roles: ['factory_adm'],
    path: '/agendamento-versao/cadastrar/:id',
    layout: registerLayout,
    element: <FactoryScheduleCreate />,
  },
  {
    title: 'Configurações',
    isSideMenu: true,
    key: 'config',
    layout: mainLayout,
    roles: ['factory_adm', 'client'],
    icon: (
      <i style={{ display: 'flex', marginTop: '0.2rem' }}>
        <SettingOutlined />
      </i>
    ),
    children: [
      {
        title: 'Perfil de configuração',
        isSideMenu: true,
        key: 'device-profile',
        roles: ['factory_adm', 'client'],
        path: '/perfil-configuracao',
        layout: mainLayout,
        element: <DeviceProfileDashboard />,
      },
      {
        title: 'Veículos CAN',
        isSideMenu: true,
        key: 'canVehicle',
        roles: ['factory', 'factory_adm'],
        path: '/veiculos-can',
        layout: mainLayout,
        element: <CanVehicleDashboard />,
      },
      // {
      //   title: 'Versão Firmware',
      //   isSideMenu: true,
      //   key: 'firmware',
      //   roles: ['factory', 'factory_adm'],
      //   path: '/versao-firmware',
      //   layout: mainLayout,
      //   element: <FirmwareVersionDashboard />,
      // },
      {
        title: 'Grupo de Firmware',
        isSideMenu: true,
        key: 'firmware-group',
        roles: ['factory', 'factory_adm'],
        path: '/grupo-firmware',
        layout: mainLayout,
        element: <FirmwareGroupDashboard />,
      },
      {
        title: 'Cercas',
        isSideMenu: true,
        key: 'cercas',
        roles: ['factory', 'factory_adm', 'client'],
        path: '/cercas',
        layout: mainLayout,
        element: <FenceDashboard />,
      },
    ],
  },
  {
    title: 'Perfil de configuração',
    isSideMenu: false,
    key: 'devide-profile-create',
    roles: ['factory_adm', 'client'],
    path: '/perfil-configuracao/:id',
    layout: registerLayout,
    element: <DeviceProfileCreate />,
  },
  {
    title: 'Perfil de configuração',
    isSideMenu: false,
    key: 'devide-profile-view',
    roles: ['factory_adm', 'client'],
    path: '/perfil-configuracao/visualizar/:id',
    layout: registerLayout,
    element: <DeviceProfileView />,
  },
  {
    title: 'Veículos CAN',
    isSideMenu: false,
    key: 'can-vehicle-create',
    roles: ['factory_adm'],
    path: '/veiculos-can/criar',
    layout: registerLayout,
    element: <CanVehicleCreate />,
  },
  {
    title: 'Veículos CAN',
    isSideMenu: false,
    key: 'can-vehicle-edit',
    roles: ['factory_adm'],
    path: '/veiculos-can/editar/:id',
    layout: registerLayout,
    element: <CanVehicleCreate />,
  },
  {
    title: 'Veículos CAN',
    isSideMenu: false,
    key: 'can-vehicle-view',
    roles: ['factory_adm'],
    path: '/veiculos-can/visualizar/:id',
    layout: registerLayout,
    element: <CanVehicleView />,
  },
  // {
  //   title: 'Versão Firmware',
  //   isSideMenu: false,
  //   key: 'firmware-version-create',
  //   roles: ['factory_adm'],
  //   path: '/versao-firmware/:id',
  //   layout: registerLayout,
  //   element: <FirmwareVersionCreate />,
  // },
  {
    title: 'Grupo de Firmware',
    isSideMenu: false,
    key: 'firmware-group-create',
    roles: ['factory_adm'],
    path: '/grupo-firmware/create/:nomeModelo',
    layout: registerLayout,
    element: <FirmwareGroupCreate />,
  },
  {
    title: 'Cadastrar Grupo Cerca',
    isSideMenu: false,
    key: 'fence-create',
    roles: ['factory_adm', 'client'],
    path: '/cercas/cadastrar',
    layout: registerLayout,
    element: <FenceCreate />,
  },
  {
    title: 'Visualizar Grupo Cerca',
    isSideMenu: false,
    key: 'fence-create',
    roles: ['factory_adm', 'client'],
    path: '/cercas/:id',
    layout: registerLayout,
    element: <FenceView />,
  },
  {
    title: 'Clonar Grupo Cerca',
    isSideMenu: false,
    key: 'fence-create',
    roles: ['factory_adm', 'client'],
    path: '/cercas/clonar/:id',
    layout: registerLayout,
    element: <FenceCreate />,
  },
  {
    title: 'Cadastrar Grupo de tags',
    isSideMenu: false,
    key: 'tag-group-create',
    roles: ['factory_adm', 'client'],
    path: '/grupo-tags/cadastrar',
    layout: registerLayout,
    element: <TagGroupCreate />,
  },
  {
    title: 'Editar Grupo de tags',
    isSideMenu: false,
    key: 'tags-edit',
    roles: ['factory_adm', 'client'],
    path: '/grupo-tags/editar/:id',
    layout: registerLayout,
    element: <TagGroupCreate isEdit={true} />,
  },
  {
    title: 'Visualizar Grupo de tags',
    isSideMenu: false,
    key: 'tags-view',
    roles: ['factory_adm', 'client'],
    path: '/grupo-tags/visualizar/:id',
    layout: registerLayout,
    element: <TagsGroupView />,
  },
  {
    title: 'Clonar Grupo de tags',
    isSideMenu: false,
    key: 'tags-view',
    roles: ['factory_adm', 'client'],
    path: '/grupo-tags/clonar/:id',
    layout: registerLayout,
    element: <TagGroupCreate isCloning={true} />,
  },
  //TODO: Login
  {
    title: 'Error500',
    layout: registerLayout,
    isSideMenu: false,
    key: 'error-500',
    path: '/500',
    element: <Error500Page />,
  },
  {
    title: 'Auth',
    layout: authLayout,
    isSideMenu: false,
    key: 'auth',
    path: '/auth',
    element: <Auth />,
  },
  {
    title: 'NewPassword',
    layout: authLayout,
    isSideMenu: false,
    key: 'new-password',
    path: '/auth/:newpassword/:token',
    element: <Auth />,
  },
  {
    title: 'Logout',
    layout: authLayout,
    isSideMenu: false,
    key: 'logout',
    path: '/logout',
    element: <Logout />,
  },

  {
    title: 'LabelGeneratorPreviewMock',
    layout: authLayout,
    isSideMenu: false,
    key: 'new-password',
    path: '/label-generator-preview-mock',
    element: <LabelGeneratorPreviewMock />,
  },
];
