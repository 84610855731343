import { FC, ReactNode } from 'react';
import * as S from './TagColor.styles';

interface TagColorProps {
  color: string;
  background: string;
  children: ReactNode;
}

export const TagColor: FC<TagColorProps> = ({ color, background, children }) => {
  return (
    <S.TagMap background={background} color={color}>
      {children ?? '-'}
    </S.TagMap>
  );
};
