import styled from 'styled-components';
import { Steps as AntdSteps } from 'antd';

export const Steps = styled(AntdSteps)`
  font-family: Mulish;
  text-align: end;

  & .ant-steps-item-title {
    color: var(--primary-color);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
  }

  & .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--primary-color);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: #383838;
  }

  & .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #159d3b;
    border-color: #159d3b;
    & .ant-steps-finish-icon {
      color: white;
    }
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--text-main-color);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    border-color: var(--disabled-input-color);
    & > .ant-steps-icon {
      color: var(--disabled-input-color);
    }
  }

  .ant-steps-item-container {
    display: flex;
    align-items: flex-start;
  }

  &.ant-steps-vertical {
    .ant-steps-item-container {
      align-items: flex-start;
    }
  }
  &.ant-steps-vertical.ant-steps-small {
    .ant-steps-item-container .ant-steps-item-tail {
      padding: 1.625rem 0 0.375rem;
    }
  }
  & .ant-steps-item {
    &:not(.ant-steps-item-process) {
      .ant-steps-item-description {
        color: var(--black);
      }
    }
    .ant-steps-item-subtitle {
      color: var(--black);
    }
  }
  & .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon {
    background-color: white;
  }
  & .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon {
    color: var(--primary-color);
  }
  & .ant-steps-item-title::after {
    top: 0.85rem;
    background: #8c8c8c;
  }
  & .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    top: 0.85rem;
  }
  & .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--primary-color);
  }
`;
