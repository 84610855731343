/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { InfoCircleOutlined, QuestionOutlined } from '@ant-design/icons';
import { Description } from '@app/components/common/Description/Description';
import { Modal } from '@app/components/common/Modal/Modal';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { Col, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as S from './create.styles';
import { QRCodeReader } from './components/QRCodeReader/QRCodeReader';
import { USBConnector } from './components/USBConnector/USBConnector';
import { DevicesToSaveTable } from './components/DevicesToSaveTable/DevicesToSaveTable';
import React from 'react';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { Button } from '@app/components/common/buttons/Button/Button';
import { setFooter } from '@app/store/slices/footerSlice';
import { ModalTutorial } from '@app/components/common/ModalTutorial/ModalTutorial';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ReactComponent as IconSettings } from '@app/assets/icons/settings.svg';
import { ReactComponent as IconDevice } from '@app/assets/icons/devices_other.svg';
import { ReactComponent as IconLink } from '@app/assets/icons/link.svg';
import { ReactComponent as IconAccessTime } from '@app/assets/icons/access_time.svg';
import { ReactComponent as IconDownload } from '@app/assets/icons/download.svg';
import { HideDeviceTutorialLocalStorage } from '@app/constants/enums/device/device-tutorials-local-storage';
import { FactoryDeviceService } from '@app/services/factoryDeviceService';
import { notificationController } from '@app/controllers/notificationController';
import DriverDownload from '@app/components/device/DriverDownload/DriverDownload';
import CreateFlow from './components/CreateFlow/CreateFlow';
import ClientAndProfileSelector from './components/ClientAndProfileSelector/ClientAndProfileSelector';
import {
  DeviceProfileCommand,
  DeviceProfileModel,
  DeviceProfileProperty,
} from '@app/domain/deviceProfile/deviceProfileModel';
import {
  serialNumberMask,
  utilCleanDeviceSerial,
  utilConfirmUpdateDeviceSerialProperties,
  utilSaveTagsOnDeviceSerial,
  utilUpdateDeviceSerialCommands,
  utilUpdateDeviceSerialProperties,
  utilUpdateDeviceSerialPropertiesFromDevice,
} from '@app/utils/serial';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import DeviceSerialPort from '@app/services/deviceSerialPortClass';
import { DeviceProfileService } from '@app/services/deviceProfileService';
import { isJM15 } from '@app/utils/utils';
import { TagGroupModel } from '@app/domain/tagGroup/TagGroupModel';
import { CardTags } from '../device/components/DeviceCards/CardTags/CardTags';
import { ModalGroupTag } from '../device/components/ModalGroupTag/ModalGroupTag';
import { ModalIndividualTag } from '../device/components/ModalIndividualTag/ModalIndividualTag';
import CollapseCard from '@app/components/common/CollapseCard/CollapseCard';
import { Panel } from '@app/components/common/Collapse/Collapse';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import MaskedInput from 'react-text-mask';

const deviceService = new FactoryDeviceService();
const deviceSerialPortClass = DeviceSerialPort.getInstance();
const deviceProfileClass = new DeviceProfileService();

export enum FlowTypes {
  factory = 'factory',
  factoryClient = 'factory_client',
}

export const FactoryDeviceCreate: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);
  const [modalTutorial, setModalTutorial] = useState(false);
  const [modalConfirmSave, setModalConfirmSave] = useState(false);
  const [factoryDevices, setFactoryDevices] = useState<FactoryDeviceModel[]>([]); // list of devices to be saved
  const [newFactoryDevice, setNewFactoryDevice] = useState<FactoryDeviceModel>({} as FactoryDeviceModel);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [activeFlow, setActiveFlow] = useState<string>(FlowTypes.factory);
  const [typeConfigDeviceSelected, setTypeConfigDeviceSelected] = useState<0 | 1>(0);
  const [clientSelected, setClientSelected] = useState<number>(0);
  const [profileSelected, setProfileSelected] = useState<DeviceProfileModel | null>(null);
  const [selectClientBlocked, setSelectClientBlocked] = useState<boolean>(false);
  const [selectFlowBlocked, setSelectFlowBlocked] = useState<boolean>(false);
  const [isUpdatingDevice, setIsUpdatingDevice] = useState<boolean>(false);
  const [tagGroupsOptions, setTagGroupsOptions] = useState<TagGroupModel[]>([]);
  const [modalIndividualTag, setModalIndividualTag] = useState(false);
  const [modalGroupTag, setModalGroupTag] = useState(false);

  const handleChangeSerialNumberInput = (serial: string) => {
    if (serial.length >= 20) {
      const lote = serial.split('-')[1];
      setNewFactoryDevice({ ...newFactoryDevice, numeroSerie: serial, lote });
    } else {
      setNewFactoryDevice({ ...newFactoryDevice, numeroSerie: serial });
    }
  };

  const handleCancelRegister = () => {
    setFactoryDevices([]);
    localStorage.setItem('devicesToSave', JSON.stringify([]));
    setModalCancel(false);
    navigate(`/dispositivos-fabrica`);
  };

  const handleCheckbox = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    setCheckboxChecked(checked);
    localStorage.setItem(HideDeviceTutorialLocalStorage.FactoryDevice, checked ? 'true' : 'false');
  };

  const handleBackClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/dispositivos-fabrica`);
  };

  const factoryDeviceReadyToSave = (_factoryDevice: FactoryDeviceModel) =>
    _factoryDevice?.imei &&
    _factoryDevice?.numeroSerie &&
    _factoryDevice?.numeroSerie.length >= 20 &&
    _factoryDevice?.modelo &&
    (activeFlow === FlowTypes.factoryClient ? _factoryDevice?.idPerfilDispositivo : true);

  const addFactoryDeviceToTable = () => {
    const deviceIncluded = factoryDevices.find((device) => device.imei == newFactoryDevice.imei) ? true : false;

    if (deviceIncluded) {
      notificationController.error({ message: 'Já existe um dispositivo incluído com esse imei.' });
      return;
    }

    notificationController.success({ message: 'Dispositivo adicionado à lista para cadastro' });
    setFactoryDevices((prevState) => [...prevState, newFactoryDevice]);
    setNewFactoryDevice({} as FactoryDeviceModel);
    setSelectClientBlocked(true);
    setSelectFlowBlocked(true);
  };

  const saveFactoryDevices = async () => {
    try {
      setLoading(true);
      setModalConfirmSave(false);

      const factoryDevicesErrors: FactoryDeviceModel[] = [];
      const devicesErrors = await deviceService.inserirMuitos(factoryDevices);

      if (devicesErrors?.contemInvalidos) {
        factoryDevices.map((device) => {
          const deviceError = devicesErrors.dipositivos.find((error) => error.imei == device.imei);
          if (deviceError)
            factoryDevicesErrors.push({ ...device, messageError: deviceError.erro } as FactoryDeviceModel);
        });
        notificationController.error({ message: 'Alguns dispositivos não foram inseridos.' });
        setFactoryDevices(factoryDevicesErrors);
        setLoading(false);
        setSelectFlowBlocked(false);
        setSelectClientBlocked(false);
        setActiveFlow(FlowTypes.factory);
        return;
      } else {
        setFactoryDevices([]);
        localStorage.removeItem('devicesToSave');
        navigate(`/dispositivos-fabrica`);
      }

      setLoading(false);
    } catch (error) {
      notificationController.error({ message: 'Erro ao cadastrar os dispositivos' });
      setLoading(false);
    }
  };

  const saveFactoryDevice = async (deviceToSave: FactoryDeviceModel) => {
    try {
      setLoading(true);
      setModalConfirmSave(false);

      const factoryDevicesErrors: FactoryDeviceModel[] = [];
      const devicesErrors = await deviceService.inserir(deviceToSave);

      if (devicesErrors?.contemInvalidos) {
        factoryDevices.map((device) => {
          const deviceError = devicesErrors.dipositivos.find((error) => error.imei == device.imei);
          if (deviceError)
            factoryDevicesErrors.push({ ...device, messageError: deviceError.erro } as FactoryDeviceModel);
        });
        notificationController.error({ message: 'O dispositivo não foi inserido corretamente.' });
        setLoading(false);
        return false;
      }

      setLoading(false);
      return true;
    } catch (error) {
      notificationController.error({ message: 'Erro ao cadastrar os dispositivos' });
      setLoading(false);
      return false;
    }
  };

  const saveDevice = async (device: FactoryDeviceModel) => {
    try {
      if (!device.idPerfilDispositivo) throw Error('Necessário selecionar um idPerfil');

      setLoading(true);

      if (isJM15(device.modelo)) {
        const properties = await deviceProfileClass.getPropertiesByIdProfile(device.idPerfilDispositivo);
        await handleSavePropertiesOnDeviceSerialJM15(properties, device);
      } else {
        const commands = await deviceProfileClass.getCommandsByIdProfile(device.idPerfilDispositivo);

        if (commands.length <= 0) {
          notificationController.error({
            message: 'Erro!',
            description: 'Não encontrado comandos para o perfil selecionado.',
          });
          return;
        }

        await handleSavePropertiesOnDeviceSerial(commands, device);
      }

      // await deviceService.update('atualizar-dispositivo-cliente', device);

      notificationController.success({ message: 'Dispositivo cadastrado.' });
      setNewFactoryDevice({} as FactoryDeviceModel);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notificationController.error({ message: 'Erro!', description: 'Disposititivo não atualizado' });
    } finally {
      setLoading(false);
    }
  };

  // serial port functions
  const handleSavePropertiesOnDeviceSerial = async (commands: DeviceProfileCommand[], device: FactoryDeviceModel) => {
    try {
      await utilCleanDeviceSerial(device);
      await utilUpdateDeviceSerialPropertiesFromDevice(device, isUpdatingDevice);
      await utilUpdateDeviceSerialCommands(commands);
      await utilConfirmUpdateDeviceSerialProperties(isUpdatingDevice);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: 'Não foi possível finalizar a atualização do dispositivo, existem parâmetros inválidos.',
      });
      throw Error();
    }
  };

  // JM15 serial port functions
  const handleSavePropertiesOnDeviceSerialJM15 = async (
    properties: DeviceProfileProperty[],
    device: FactoryDeviceModel,
  ) => {
    try {
      await utilUpdateDeviceSerialPropertiesFromDevice(device, isUpdatingDevice);
      await utilUpdateDeviceSerialProperties(properties);
      await utilSaveTagsOnDeviceSerial(device, tagGroupsOptions);
      const deviceTags = await deviceSerialPortClass.get(DevicePropertyType.ListaTags);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: 'Não foi possível finalizar a atualização do dispositivo, existem parâmetros inválidos.',
      });
      throw Error();
    }
  };

  const handleSaveClick = () => {
    if (activeFlow === FlowTypes.factory) {
      saveFactoryDevices();
    } else {
      setTimeout(() => {
        setLoading(true);
        notificationController.success({ message: 'Cadastro de novos dispositivos finalizado com sucesso!' });
        setNewFactoryDevice({} as FactoryDeviceModel);
        setSelectFlowBlocked(false);
        setSelectClientBlocked(false);
        setActiveFlow(FlowTypes.factory);
        setFactoryDevices([]);
        localStorage.removeItem('devicesToSave');
        navigate(`/dispositivos-fabrica`);
      }, 1700);
      setLoading(false);
    }
  };

  const handleAddClick = async () => {
    if (activeFlow === FlowTypes.factory) {
      addFactoryDeviceToTable();
    } else {
      if (!clientSelected) {
        notificationController.error({ message: 'Erro!', description: 'Necessário selecionar um cliente.' });
        return;
      }

      addFactoryDeviceToTable();

      // Salvar dispositivo no banco
      const saved = await saveFactoryDevice({ ...newFactoryDevice, idCliente: clientSelected });

      // Escrever propriedades no dispositivo
      if (saved) {
        const deviceResponse = await deviceService.get(`obter-por-imei/${newFactoryDevice.imei}`);
        await saveDevice(deviceResponse);
      }

      setProfileSelected(null);
    }
  };

  useEffect(() => {
    const dontShowModalTutorial = localStorage.getItem(HideDeviceTutorialLocalStorage.FactoryDevice) === 'true';
    setCheckboxChecked(dontShowModalTutorial);
    setModalTutorial(!dontShowModalTutorial);
  }, []);

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: !id ? 'Novo(s) dispositivo(s) na fábrica' : 'Editar dipositivo(s)',
        handleBackClick: handleBackClick,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    dispatch(
      setFooter({
        confirmButtonDisabled: factoryDevices.length === 0,
        confirmButtonText: 'Salvar',
        handleCancelButtonClick: () => setModalCancel(true),
        handleConfirmButtonClick: () => setModalConfirmSave(true),
        cancelButtonText: 'Cancelar',
      }),
    );
  }, [factoryDevices.length, dispatch]);

  useEffect(() => {
    const storedData = localStorage.getItem('devicesToSave');
    if (storedData) {
      setFactoryDevices(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('devicesToSave', JSON.stringify(factoryDevices));
  }, [factoryDevices]);

  useEffect(() => {
    if (profileSelected !== null) {
      setNewFactoryDevice({
        ...newFactoryDevice,
        idPerfilDispositivo: profileSelected.id,
        perfil: profileSelected.nome,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileSelected]);

  useEffect(() => setNewFactoryDevice({ ...newFactoryDevice, numeroSerie: '' }), [typeConfigDeviceSelected]);

  return (
    <>
      <ModalTutorial
        title={'Tutorial para cadastro do dispositivo'}
        open={modalTutorial}
        onCancel={() => setModalTutorial(false)}
        onOk={() => setModalTutorial(false)}
        okText="Vamos lá!"
      >
        <Row align="middle">
          <Col xs={24} md={24}>
            <S.ContainerModal>
              <S.WapperModal>
                <IconDownload />
                <S.TitleModal>Etapa 1</S.TitleModal>
              </S.WapperModal>
              <S.SubtitleModal>
                Caso seja necessário instalar o driver do dispositivo clique na aba de “Drivers”.{' '}
              </S.SubtitleModal>
            </S.ContainerModal>
          </Col>

          <Col xs={24} md={24}>
            <S.ContainerModal>
              <S.WapperModal>
                <IconDevice />
                <S.TitleModal>Etapa 2</S.TitleModal>
              </S.WapperModal>
              <S.SubtitleModal>Pegue o dispositivo e conecte a saída USB-C dele no computador.</S.SubtitleModal>
            </S.ContainerModal>
          </Col>

          <Col xs={24} md={24}>
            <S.ContainerModal>
              <S.WapperModal>
                <IconLink />
                <S.TitleModal>Etapa 3</S.TitleModal>
              </S.WapperModal>
              <S.SubtitleModal>
                Clique no botão de sincronizar e depois autorize o navegador para abrir a conexão com o dispositivo.
              </S.SubtitleModal>
            </S.ContainerModal>
          </Col>

          <Col xs={24} md={24}>
            <S.ContainerModal>
              <S.WapperModal>
                <IconSettings />
                <S.TitleModal>Etapa 4</S.TitleModal>
              </S.WapperModal>
              <S.SubtitleModal>Selecione o cliente e o perfil de configuração.</S.SubtitleModal>
            </S.ContainerModal>
          </Col>

          <Col xs={24} md={24}>
            <S.ContainerModal>
              <S.WapperModal>
                <IconAccessTime />
                <S.TimeModal>Tempo necessário: 1 minuto</S.TimeModal>
              </S.WapperModal>
            </S.ContainerModal>
          </Col>

          <Col xs={24} md={24}>
            <S.ContainerModal>
              <S.WapperModal>
                <Checkbox
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    alignSelf: 'flex-end',
                    flexDirection: 'row',
                  }}
                  checked={checkboxChecked}
                  value={checkboxChecked}
                  onChange={(e: CheckboxChangeEvent) => handleCheckbox(e)}
                >
                  <S.CheckboxModal>Não mostrar novamente</S.CheckboxModal>
                </Checkbox>
              </S.WapperModal>
            </S.ContainerModal>
          </Col>
        </Row>
      </ModalTutorial>
      <Modal
        title={`Cancelar ${!id ? 'cadastro' : 'edição'}`}
        open={modalCancel}
        onOk={() => handleCancelRegister()}
        onCancel={() => setModalCancel(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            Deseja realmente cancelar {!id ? 'o cadastro' : 'a edição'} de dispositivos?
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Salvar dispositivos"
        open={modalConfirmSave}
        onOk={() => handleSaveClick()}
        onCancel={() => setModalConfirmSave(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>Confirmar cadastro de todos os dispositivos listados?</Col>
        </Row>
      </Modal>

      <ModalIndividualTag
        visible={modalIndividualTag}
        setVisible={setModalIndividualTag}
        device={newFactoryDevice}
        setDevice={setNewFactoryDevice}
      />

      <ModalGroupTag
        visible={modalGroupTag}
        setVisible={setModalGroupTag}
        device={newFactoryDevice}
        setDevice={setNewFactoryDevice}
        tagGroupsOptions={tagGroupsOptions}
      />

      <Spinner spinning={loading}>
        <S.Wrapper>
          <S.ContainerNewDevice>
            <Description
              title="Cadastro do dispositivo"
              subtitle={`Siga as etapas para concluir o cadastro do dispositivo`}
              icon={<QuestionOutlined />}
              iconTooltipText="Visualizar tutorial"
              iconOnClick={() => {
                setModalTutorial(true);
                setCheckboxChecked(checkboxChecked);
              }}
            >
              <CollapseCard>
                <Panel header="Drivers" key="1">
                  <DriverDownload />
                </Panel>
                <Panel header="Cadastrar" key="2">
                  <CreateFlow flowBlocked={selectFlowBlocked} activeFlow={activeFlow} setActiveFlow={setActiveFlow} />
                </Panel>
                <Panel header="Conecte o USB" key="3">
                  <USBConnector newFactoryDevice={newFactoryDevice} setNewFactoryDevice={setNewFactoryDevice} />
                </Panel>
                <Panel header="Configuração do dispositivo" key="4">
                  <RadioGroup
                    style={{ width: '100%' }}
                    value={typeConfigDeviceSelected}
                    onChange={(e) => setTypeConfigDeviceSelected(e.target.value)}
                  >
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Radio value={0}>QR Code</Radio>
                      {typeConfigDeviceSelected == 0 && (
                        <QRCodeReader newFactoryDevice={newFactoryDevice} setNewFactoryDevice={setNewFactoryDevice} />
                      )}
                      <Radio value={1}>Manual</Radio>
                      {typeConfigDeviceSelected == 1 && (
                        <BaseForm layout="vertical">
                          <BaseFormInputItem label="Número série">
                            <MaskedInput
                              guide={false}
                              showMask
                              mask={serialNumberMask}
                              placeholder="Digite o número de série"
                              className="ant-input"
                              value={newFactoryDevice.numeroSerie}
                              onChange={(e) => handleChangeSerialNumberInput(e.target.value)}
                            />
                          </BaseFormInputItem>
                        </BaseForm>
                      )}
                    </Space>
                  </RadioGroup>
                </Panel>
                {activeFlow === FlowTypes.factoryClient && (
                  <Panel header="Selecionar" key="5">
                    <ClientAndProfileSelector
                      clientSelected={clientSelected}
                      setClientSelected={setClientSelected}
                      profileSelected={profileSelected}
                      setProfileSelected={setProfileSelected}
                      clientBlocked={selectClientBlocked}
                      newDevice={newFactoryDevice}
                    />
                  </Panel>
                )}
                {isJM15(newFactoryDevice.modelo) && activeFlow === FlowTypes.factoryClient && (
                  <Panel header="Vincular" key="6">
                    <CardTags
                      newDevice={newFactoryDevice}
                      openModalIndividualTag={() => setModalIndividualTag(true)}
                      openModalGroupTag={() => setModalGroupTag(true)}
                    />
                  </Panel>
                )}
              </CollapseCard>
            </Description>
            <S.ButtonContainer>
              <Button
                type="primary"
                onClick={() => handleAddClick()}
                disabled={!factoryDeviceReadyToSave(newFactoryDevice)}
              >
                Adicionar
              </Button>
            </S.ButtonContainer>
          </S.ContainerNewDevice>
          <S.ContainerListDevices>
            <Description
              title="Dispositivos cadastrados"
              subtitle={`Após a leitura de todos os dispositivos, clique em salvar para finalizar`}
            >
              <DevicesToSaveTable
                factoryDevices={factoryDevices}
                setFactoryDevices={setFactoryDevices}
                showDelete={activeFlow === FlowTypes.factoryClient ? false : true}
              />
            </Description>
          </S.ContainerListDevices>
        </S.Wrapper>
      </Spinner>
    </>
  );
};
export default FactoryDeviceCreate;
