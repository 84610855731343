export const getFenceNameByType = (idTipoCerca: number) => {
  switch (idTipoCerca) {
    case 1:
      return 'Polígono';
    case 2:
      return 'Ponto e raio';
    case 3:
      return 'Rota';
    default:
      return '-';
  }
};
