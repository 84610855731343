export const valoresDefaultPorId: { [key: string]: string } = {
  '2': '30',
  '3': '21600',
  '4': '86400',
  '48': 'false',
  '49': '500',
  '50': 'true',
  '51': '20',
  '68': '1',
  '70': '0',
  '71': '1',
  '72': '2',
  '73': '0',
  '74': '',
  '75': '10',
  '76': '10',
  '77': '0',
  '80': '1',
  '83': '115200',
  '84': '19200',
  '85': '0.3',
  '86': '50',
  '87': '30',
  '88': '30',
};

export const codeStringExample: { [key: string]: string } = {
  '0': `"type_msg": "json",
"value": {
        "cnt": 1,
        "s_num": "",
        "ts": 1707253817236,
        "gps": [
            0,
            0
        ],
        "alt": -1,
        "speed": -1,
        "nsat": -1,
        "hdop": -1,
        "azi": 0,
        "ive": 0,
        "rssi": 15,
        "evt": {},
        "bckup": 0,
        "hourmt": 0,
        "odomt": 0,
        "tag": 0,
        "ts_n": 1707253826678
 }`,
  '1': `"type_msg": "string",
"value": "000000000003FFFF;202402-00004-0000004;868695060041595;11;teste;1707168298625;0.00000;0.00000;0.00;0;0.00;0;31;99.00;0.00;0;0;0.00;0.00",`,
  '2': `"type_msg": "zip",
"value": "000000000003FF0316A22D0000042D000004000007E879F70F7B000000010000000000000074657374650000018D7E7D8E79000000000000000000000000000000001426AC000000000000000000000000000000"`,
};
