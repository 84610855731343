import React from 'react';
import { PropertyModel } from '@app/domain/property/propertyModel';

interface ShouldRenderInputProps {
  children: React.ReactNode;
  properties: PropertyModel[];
  idEmbarcado: number;
}

const ShouldRenderInput: React.FC<ShouldRenderInputProps> = ({ children, properties, idEmbarcado }) => {
  return properties.some((property) => property.idEmbarcado === idEmbarcado) ? <>{children}</> : null;
};

export default ShouldRenderInput;
