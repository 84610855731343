import React from 'react';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import ShouldRenderInput from '@app/components/common/ModulesProfileConfig/ShouldRenderInput';
import { Col, Form, Row } from 'antd';
import * as S from '../ModulesProfileConfig.styles';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { Input } from '../../inputs/Input/Input';
import { PropertyModel } from '@app/domain/property/propertyModel';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';

interface EventosProps {
  properties: DeviceProfileProperty[];
}

const EventosView: React.FC<EventosProps> = ({ properties }) => {
  const props = properties as PropertyModel[];
  const getPropertyValue = (idEmbarcado: DevicePropertyType) =>
    properties?.find((p) => p.idEmbarcado == idEmbarcado)?.valorPropriedade ?? '';

  return (
    <Form layout="vertical">
      <Row gutter={[18, 18]} align={'middle'}>
        <Col xs={24} md={12} xxl={8}>
          <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoEstadoIgnicao} properties={props}>
            <Checkbox key={21} checked={getPropertyValue(DevicePropertyType.EventoEstadoIgnicao) === '1'} disabled>
              <S.CheckboxLabel>Ignição</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </Col>

        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoLimiteVelocidade} properties={props}>
                <Checkbox
                  key={16}
                  checked={getPropertyValue(DevicePropertyType.EventoLimiteVelocidade) === '1'}
                  disabled
                >
                  <S.CheckboxLabel>Limite de velocidade (km/h)</S.CheckboxLabel>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={8}>
              <ShouldRenderInput idEmbarcado={DevicePropertyType.LimiteVelocidade} properties={props}>
                {getPropertyValue(DevicePropertyType.EventoLimiteVelocidade) === '1' && (
                  <Input
                    key={3}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={getPropertyValue(DevicePropertyType.LimiteVelocidade)}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="km/h"
                  />
                )}
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={12} xxl={8}>
          <ShouldRenderInput
            idEmbarcado={DevicePropertyType.EventoDeteccaoMovimentoIgnicaoDesligada}
            properties={props}
          >
            <Checkbox
              key={59}
              checked={getPropertyValue(DevicePropertyType.EventoDeteccaoMovimentoIgnicaoDesligada) === '1'}
              disabled
            >
              <S.CheckboxLabel>Detecção de movimento</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </Col>
        <Col xs={24} md={12} xxl={8}>
          <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoEstadoEntradaDigital} properties={props}>
            <Checkbox
              key={25}
              checked={getPropertyValue(DevicePropertyType.EventoEstadoEntradaDigital) === '1'}
              disabled
            >
              <S.CheckboxLabel>Entrada digital 1</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </Col>
        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoLimiteEntradaAnalogica} properties={props}>
                <Checkbox
                  key={27}
                  checked={getPropertyValue(DevicePropertyType.EventoLimiteEntradaAnalogica) === '1'}
                  disabled
                >
                  <S.CheckboxLabel>Limite da entrada analógica (mA)</S.CheckboxLabel>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={8}>
              <ShouldRenderInput idEmbarcado={DevicePropertyType.LimiteEntradaAnalogica} properties={props}>
                {getPropertyValue(DevicePropertyType.EventoLimiteEntradaAnalogica) === '1' && (
                  <Input
                    key={3}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={getPropertyValue(DevicePropertyType.LimiteEntradaAnalogica)}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="mA"
                  />
                )}
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={12} xxl={8}>
          <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoMensagemRS232} properties={props}>
            <Checkbox key={56} checked={getPropertyValue(DevicePropertyType.EventoMensagemRS232) === '1'} disabled>
              <S.CheckboxLabel>RS232</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </Col>

        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoDeteccaoFrenagemBrusca} properties={props}>
                <Checkbox
                  key={52}
                  checked={getPropertyValue(DevicePropertyType.EventoDeteccaoFrenagemBrusca) === '1'}
                  disabled
                >
                  <S.CheckboxLabel>Sensibilidade frenagem brusca (g)</S.CheckboxLabel>
                  <S.InputObs>0,25 a 2</S.InputObs>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={8}>
              <ShouldRenderInput
                idEmbarcado={DevicePropertyType.SensibilidadeDeteccaoFrenagemBrusca}
                properties={props}
              >
                {getPropertyValue(DevicePropertyType.EventoDeteccaoFrenagemBrusca) === '1' && (
                  <Input
                    key={3}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={getPropertyValue(DevicePropertyType.SensibilidadeDeteccaoFrenagemBrusca)}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="g"
                  />
                )}
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>

        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoDeteccaoAceleracaoBrusca} properties={props}>
                <Checkbox
                  key={53}
                  checked={getPropertyValue(DevicePropertyType.EventoDeteccaoAceleracaoBrusca) === '1'}
                  disabled
                >
                  <S.CheckboxLabel>Sensibilidade aceleração brusca (g)</S.CheckboxLabel>
                  <S.InputObs>0,25 a 2</S.InputObs>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={8}>
              <ShouldRenderInput
                idEmbarcado={DevicePropertyType.SensibilidadeDeteccaoAceleracaoBrusca}
                properties={props}
              >
                {getPropertyValue(DevicePropertyType.EventoDeteccaoAceleracaoBrusca) === '1' && (
                  <Input
                    key={3}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={getPropertyValue(DevicePropertyType.SensibilidadeDeteccaoAceleracaoBrusca)}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="g"
                  />
                )}
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>

        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoDeteccaoCurvaAcentuada} properties={props}>
                <Checkbox
                  key={54}
                  checked={getPropertyValue(DevicePropertyType.EventoDeteccaoCurvaAcentuada) === '1'}
                  disabled
                >
                  <S.CheckboxLabel>Sensibilidade curva acentuada (g)</S.CheckboxLabel>
                  <S.InputObs>0,25 a 2</S.InputObs>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={8}>
              <ShouldRenderInput
                idEmbarcado={DevicePropertyType.SensibilidadeDeteccaoCurvaAcentuada}
                properties={props}
              >
                {getPropertyValue(DevicePropertyType.EventoDeteccaoCurvaAcentuada) === '1' && (
                  <Input
                    key={3}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={getPropertyValue(DevicePropertyType.SensibilidadeDeteccaoCurvaAcentuada)}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="g"
                  />
                )}
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>

        <Col xs={24} xl={12} xxl={8}>
          <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoDeteccaoModoBateriaInterna} properties={props}>
            <Checkbox
              key={55}
              checked={getPropertyValue(DevicePropertyType.EventoDeteccaoModoBateriaInterna) === '1'}
              disabled
            >
              <S.CheckboxLabel>Modo bateria interna</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </Col>

        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoDeteccaoBateriaInternaBaixa} properties={props}>
                <Checkbox
                  key={57}
                  checked={getPropertyValue(DevicePropertyType.EventoDeteccaoBateriaInternaBaixa) === '1'}
                  disabled
                >
                  <S.CheckboxLabel>Limite bateria interna baixa (V)</S.CheckboxLabel>
                  <S.InputObs>3,4 a 3,6</S.InputObs>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={8}>
              <ShouldRenderInput idEmbarcado={DevicePropertyType.LimiteBateriaBaixa} properties={props}>
                {getPropertyValue(DevicePropertyType.EventoDeteccaoBateriaInternaBaixa) === '1' && (
                  <Input
                    key={58}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={getPropertyValue(DevicePropertyType.LimiteBateriaBaixa)}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="V"
                  />
                )}
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={12} xxl={8}>
          <ShouldRenderInput idEmbarcado={DevicePropertyType.MensagemRecebidaSerial} properties={props}>
            <Checkbox key={21} checked={getPropertyValue(DevicePropertyType.MensagemRecebidaSerial) === '1'} disabled>
              <S.CheckboxLabel>TTL</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </Col>

        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput idEmbarcado={DevicePropertyType.EventoLimiteRpm} properties={props}>
                <Checkbox key={18} checked={getPropertyValue(DevicePropertyType.EventoLimiteRpm) === '1'} disabled>
                  <S.CheckboxLabel>Limite de rpm (alto e médio)</S.CheckboxLabel>
                  <S.InputObs>1000 a 7000 rpm</S.InputObs>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={6}>
              <ShouldRenderInput idEmbarcado={DevicePropertyType.LimiteRpmFaixaVermelha} properties={props}>
                {getPropertyValue(DevicePropertyType.EventoLimiteRpm) === '1' && (
                  <Input
                    key={20}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={getPropertyValue(DevicePropertyType.LimiteRpmFaixaVermelha)}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="rpm"
                  />
                )}
              </ShouldRenderInput>
            </Col>
            <Col span={6}>
              <ShouldRenderInput idEmbarcado={DevicePropertyType.LimiteRpmFaixaAmarela} properties={props}>
                {getPropertyValue(DevicePropertyType.EventoLimiteRpm) === '1' && (
                  <Input
                    key={19}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={getPropertyValue(DevicePropertyType.LimiteRpmFaixaAmarela)}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="rpm"
                  />
                )}
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default EventosView;
