import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as S from '../../view.styles';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { notificationController } from '@app/controllers/notificationController';
import { useMounted } from '@app/hooks/useMounted';
import { ViewFirmwareUpdateHistory } from '../ViewDeviceFilters/ViewFirmwareUpdateHistory';
import { ViewConfigUpdateHistory } from '../ViewDeviceFilters/ViewConfigUpdateHistory';
import { ViewFirmwareUpdateHistoryModel } from '@app/domain/device/viewFirmwareUpdateHistoryModel';
import {
  ViewConfigUpdateHistoryGroupedByModuleModel,
  ViewConfigUpdateHistoryGroupedModel,
  ViewConfigUpdateModule,
} from '@app/domain/device/viewConfigUpdateHistoryModel';
import { ViewLogHistory } from '../ViewDeviceFilters/ViewLogHistory';
import { ViewCommandHistoryModel } from '@app/domain/device/viewCommandHistoryModel';
import { ViewCommandHistory } from '../ViewDeviceFilters/ViewCommandHistory';
import { DeviceLogModel } from '@app/domain/deviceLog/DeviceLogModel';
import IDeviceLogService, { DeviceLogService } from '@app/services/deviceLogService';
import { hasFactoryAccess } from '@app/controllers/accessController';
import IDeviceHistoryService, { DeviceHistoryService } from '@app/services/deviceHistoryService';
import { DeviceTwinHistoryModel } from '@app/domain/device-history/device-twin-history-model';
import { DeviceProfileHistoryModel } from '@app/domain/device-history/device-profile-history-model';
import { ViewDeviceTwinHistory } from '../ViewDeviceFilters/ViewDeviceTwinHistory';
import { ViewDeviceProfileHistory } from '../ViewDeviceFilters/ViewDeviceProfileHistory';
import { readUser } from '@app/services/localStorage.service';
import { UserType } from '@app/constants/enums/userType';

const filters = [
  { idFilter: 1, filter: 'Atualizações do firmware' },
  { idFilter: 2, filter: 'Atualizações das configurações remotas' },
  { idFilter: 3, filter: 'Comandos' },
  { idFilter: 4, filter: 'Logs' },
  { idFilter: 5, filter: 'Atualizações de propriedades gerais' },
  { idFilter: 6, filter: 'Atualizações do perfil' },
];

const logService: IDeviceLogService = new DeviceLogService();
const deviceHistoryService: IDeviceHistoryService = new DeviceHistoryService();

export const ViewHistorics: React.FC = () => {
  const { isMounted } = useMounted();
  const { id } = useParams();
  const user = readUser();
  const [isFactory, setIsFactory] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(0);
  const [updateFirmwareHistory, setUpdateFirmwareHistory] = useState<ViewFirmwareUpdateHistoryModel[]>([]);
  const [logHistory, setLogHistory] = useState<DeviceLogModel[]>([]);
  const [commandHistory, setCommandHistory] = useState<ViewCommandHistoryModel[]>([]);
  const [deviceTwinHistory, setDeviceTwinHistory] = useState<DeviceTwinHistoryModel[]>([]);
  const [deviceProfileHistory, setDeviceProfileHistory] = useState<DeviceProfileHistoryModel[]>([]);
  const [updateConfigHistoryGroupedByModule, setUpdateConfigHistoryGroupedByModule] = useState<
    ViewConfigUpdateHistoryGroupedByModuleModel[]
  >([]);

  const fetchDeviceTwinHistory = async () => {
    setLoading(true);

    deviceHistoryService
      .getDeviceTwinHistory(`${id}`)
      .then((res) => {
        if (isMounted.current) {
          setDeviceTwinHistory(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({
          message: `Erro ao obter histórico de atualizações do dispositivo gêmeo. ${err}`,
        });
      });
  };
  const fetchDeviceProfileHistory = async () => {
    setLoading(true);

    deviceHistoryService
      .getDeviceProfileHistory(`${id}`)
      .then((res) => {
        if (isMounted.current) {
          setDeviceProfileHistory(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({ message: `Erro ao obter histórico de atualizações do perfil. ${err}` });
      });
  };
  const fetchUpdateFirmwareHistory = async () => {
    setLoading(true);

    deviceHistoryService
      .getFirmwareUpdateHistory(`${id}`)
      .then((res: ViewFirmwareUpdateHistoryModel[]) => {
        if (isMounted.current) {
          setUpdateFirmwareHistory(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({ message: `Erro ao obter histórico de atualizações de firmware. ${err}` });
      });
  };
  const fetchUpdateConfigHistory = async () => {
    setLoading(true);

    deviceHistoryService
      .getConfigUpdateGroupedHistory(`${id}`)
      .then((res: ViewConfigUpdateHistoryGroupedModel[]) => {
        if (isMounted.current) {
          groupConfigUpdateHistoryByModule(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({
          message: `Erro ao obter histórico de atualizações de parâmetros do perfil. ${err}`,
        });
      });
  };
  const fetchCommandHistory = async () => {
    setLoading(true);

    deviceHistoryService
      .getCommandHistory(`${id}`)
      .then((res: ViewCommandHistoryModel[]) => {
        if (isMounted.current) {
          setCommandHistory(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({ message: `Erro ao obter histórico de comandos. ${err}` });
      });
  };
  const fetchLogHistory = async () => {
    setLoading(true);

    logService
      .getArray(`obter-por-id-dispositivo/${id}`)
      .then((res) => {
        if (isMounted.current) {
          setLogHistory(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({ message: `Erro ao obter histórico de LOG. ${err}` });
      });
  };
  const fetchLogHistoryAdmin = async () => {
    setLoading(true);

    logService
      .getArray(`obter-por-id-dispositivo-admin/${id}`)
      .then((res) => {
        if (isMounted.current) {
          setLogHistory(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({ message: `Erro ao obter histórico de LOG. ${err}` });
      });
  };
  const groupConfigUpdateHistoryByModule = (res: ViewConfigUpdateHistoryGroupedModel[]) => {
    const group: ViewConfigUpdateHistoryGroupedByModuleModel[] = [];
    res.forEach((r) => {
      if (group.findIndex((g) => g.id === r.id) === -1) {
        group.push({
          id: r.id,
          dataHora: r.dataHora,
          usuario: r.usuario,
          modulos:
            res.map((m) => {
              if (m.id === r.id) {
                const objeto = {
                  idModulo: m.idModulo ?? 0,
                  modulo: m.modulo ?? '',
                  status: m.status ?? '',
                } as ViewConfigUpdateModule;
                return objeto;
              } else {
                return {} as ViewConfigUpdateModule;
              }
            }) ?? [],
        });
      }
    });
    setUpdateConfigHistoryGroupedByModule(group);
  };
  const handleFetchLogs = () => {
    if (hasFactoryAccess()) {
      setIsFactory(true);
      fetchLogHistoryAdmin();
    } else {
      fetchLogHistory();
    }
  };

  useEffect(() => {
    switch (filter) {
      case 1:
        fetchUpdateFirmwareHistory();
        break;
      case 2:
        fetchUpdateConfigHistory();
        break;
      case 3:
        fetchCommandHistory();
        break;
      case 4:
        handleFetchLogs();
        break;
      case 5:
        fetchDeviceTwinHistory();
        break;
      case 6:
        fetchDeviceProfileHistory();
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <>
      <Spinner spinning={loading}>
        <Form layout="vertical">
          <BaseFormInputItem label="Filtro">
            <S.DeviceTypeWrapper>
              <>
                {filters.map((f) =>
                  user?.type != UserType.Client || (f.idFilter != 3 && f.idFilter != 6) ? (
                    <S.DeviceTypeButton
                      key={f.idFilter}
                      active={filter === f.idFilter}
                      onClick={() => setFilter(f.idFilter)}
                    >
                      {f.filter}
                    </S.DeviceTypeButton>
                  ) : (
                    <></>
                  ),
                )}
              </>
            </S.DeviceTypeWrapper>
          </BaseFormInputItem>
        </Form>
        {
          {
            1: <ViewFirmwareUpdateHistory updateFirmwareHistory={updateFirmwareHistory} />,
            2: <ViewConfigUpdateHistory updateConfigHistory={updateConfigHistoryGroupedByModule} />,
            3: <ViewCommandHistory commandHistory={commandHistory} />,
            4: <ViewLogHistory logHistory={logHistory} isFactory={isFactory} />,
            5: <ViewDeviceTwinHistory twinHistory={deviceTwinHistory} />,
            6: <ViewDeviceProfileHistory profileHistory={deviceProfileHistory} />,
          }[filter]
        }
      </Spinner>
    </>
  );
};
