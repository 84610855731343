import { FenceModel, PathPoint } from '@app/domain/fence/fenceModel';
import { FC, useState } from 'react';
import * as S from './map-fence-modal.styles';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Col, Menu, Row } from 'antd';
import { CloseOutlined, FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { TagColor } from '@app/components/common/TagColor/TagColor';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { IconMore } from '@app/assets/slump-icons';
import { MenuItem } from '@app/components/common/Menu/Menu';
import { Table } from '@app/components/common/Table/Table';
import { getFenceNameByType } from '@app/pages/fence/functions/commonFenceFunctions';
import { genericExportToExcel } from '@app/utils/exportToExcel';

interface MapFenceModalProps {
  fences?: FenceModel[];
  handleRemoveFence?: (fence: FenceModel) => void;
  handleActivateInactivateFence?: (fence: FenceModel) => void;
  handleOnSelectFence: (fence: FenceModel) => void;
}

export const MapFenceModal: FC<MapFenceModalProps> = ({
  fences,
  handleRemoveFence,
  handleActivateInactivateFence,
  handleOnSelectFence,
}) => {
  const [modalFencesVisible, setModalFencesVisible] = useState<boolean>(false);

  const handleExportExcel = async () => {
    const data =
      fences?.map((f) => ({
        ...f,
        idTipoCerca: getFenceNameByType(f.idTipoCerca ?? 0),
        pontos: f.pontos.length,
        raio: f.raio ? `${f.raio}m` : ' - ',
        largura: f.largura ? `${f.largura}m` : ' - ',
        eventoEntradaSaida: !!f.eventos?.entradaSaida ? 'Sim' : 'Não',
        eventoLimiteVelocidade: !!f.eventos?.limiteVelocidade ? 'Sim' : 'Não',
        ativo: !!f.ativo ? 'Sim' : 'Não',
      })) ?? [];

    await genericExportToExcel(`cercas`, columns, data);
  };

  const columns: ColumnsType<FenceModel> = [
    {
      title: 'Cod.',
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => <>{id || ' - '}</>,
    },
    {
      title: 'Cerca',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Tipo da cerca',
      dataIndex: 'idTipoCerca',
      key: 'idTipoCerca',
      render: (idTipoCerca) => getFenceNameByType(idTipoCerca ?? 0),
    },
    {
      title: 'Quantidade de pontos',
      dataIndex: 'pontos',
      key: 'pontos',
      render: (pontos: PathPoint[]) => <>{pontos?.length > 0 ? pontos.length : ' - '}</>,
    },
    {
      title: 'Raio',
      dataIndex: 'raio',
      key: 'raio',
      render: (raio: FenceModel['raio']) => <>{raio ? `${raio}m` : ' - '}</>,
    },
    {
      title: 'Largura da banda',
      dataIndex: 'largura',
      key: 'largura',
      render: (largura: FenceModel['largura']) => <>{largura ? `${largura}m` : ' - '}</>,
    },
    {
      title: 'Evento Entrada/Saída',
      dataIndex: 'eventoEntradaSaida',
      key: 'eventoEntradaSaida',
      render: (_, { eventos }) => <>{!!eventos?.entradaSaida ? 'Sim' : 'Não'}</>,
    },
    {
      title: 'Evento Limite de velocidade',
      dataIndex: 'eventoEntradaSaida',
      key: 'eventoLimiteVelocidade',
      render: (_, { eventos }) => <>{!!eventos?.limiteVelocidade ? 'Sim' : 'Não'}</>,
    },
    {
      title: 'Ativo',
      dataIndex: 'ativo',
      key: 'ativo',
      render: (ativo: boolean) => {
        return (
          <>
            {ativo ? (
              <TagColor background="#E9F4EE" color="#083F18">
                Ativo
              </TagColor>
            ) : (
              <TagColor background="#FEE9EA" color="#620E12">
                Inativo
              </TagColor>
            )}
          </>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      showSorterTooltip: false,
      sortDirections: [],
      defaultSortOrder: 'descend',
      render: (id, fence) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <DropdownTable
              buttonText=""
              iconD={
                <div style={{ color: '#545454' }}>
                  <IconMore />
                </div>
              }
              trigger={['click']}
              placement="bottomRight"
              overlay={
                <Menu>
                  {handleActivateInactivateFence != undefined ? (
                    <MenuItem key="" onClick={() => handleActivateInactivateFence(fence)}>
                      <Button type="text">{fence.ativo ? 'Inativar' : 'Ativar'}</Button>
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                  {handleRemoveFence != undefined ? (
                    <MenuItem key="" onClick={() => handleRemoveFence(fence)}>
                      <Button type="text">Remover</Button>
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                </Menu>
              }
            ></DropdownTable>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <S.ButtonModalFences disabled={!fences?.length}>
        Cercas cadastradas
        <Button
          type="primary"
          size="small"
          color="white"
          icon={<PlusOutlined />}
          onClick={() => setModalFencesVisible(true)}
        ></Button>
      </S.ButtonModalFences>
      <S.ModalFences visible={modalFencesVisible}>
        <Row>
          <Col span={24}>
            <S.ModalFencesHeader>
              <Row justify={'space-between'}>
                <Col>Cercas</Col>
                <Col>
                  <CloseOutlined onClick={() => setModalFencesVisible(false)} />
                </Col>
              </Row>
            </S.ModalFencesHeader>
          </Col>
          <Col span={24}>
            <Row justify={'end'}>
              <Col>
                <Button type="link" icon={<FileExcelOutlined />} onClick={() => handleExportExcel()}>
                  Exportar excel
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              onRow={(fence: FenceModel) => ({ onClick: () => handleOnSelectFence(fence) })}
              bordered={true}
              size="middle"
              columns={columns}
              dataSource={fences}
              loading={false}
              style={{
                width: '100%',
                paddingTop: 0,
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
              }}
              pagination={{ pageSize: 4, style: { marginTop: '1rem' } }}
            />
          </Col>
        </Row>
      </S.ModalFences>
    </>
  );
};
