import React, { useState, useRef, useCallback, useEffect, FC } from 'react';
import * as S from './styles';
import {
  GoogleMap,
  LoadScript,
  Polygon,
  Circle,
  StandaloneSearchBox,
  StandaloneSearchBoxProps,
  GoogleMapProps,
  Marker,
  Polyline,
} from '@react-google-maps/api';
import { PointCount } from '@app/components/common/GoogleMapItems/PointCount';
import { Input } from '@app/components/common/inputs/Input/Input';
import { FenceModel, LatLngLiteral, PathPoint } from '@app/domain/fence/fenceModel';
import { ZoomButtons } from '@app/components/common/GoogleMapItems/ZoomButtons';
import { UndoRedoDeleteButtons } from '@app/components/common/GoogleMapItems/UndoRedoDeleteButtons';
import appSettings from '@app/config/appsettings';
import { notificationController } from '@app/controllers/notificationController';
import { MapFenceSaved } from './map-fence-saved';
import { MapFenceSelectedCard } from './map-fence-selected-card/map-fence-selected-card';
import { MapFenceModal } from './map-fence-modal/map-fence-modal';
import { MapFenceTutorial } from './map-fence-tutorial';
import { Spin } from 'antd';

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '0.5rem',
};

// used to center the map if no fences have points
const supermixLocation = {
  lat: -19.878877423869252,
  lng: -43.97931295928024,
};

interface MapFenceCreateProps {
  fence?: FenceModel;
  setFence?: React.Dispatch<React.SetStateAction<FenceModel>>;
  showTutorial?: boolean;
  showInactiveFences?: boolean;
  fences: FenceModel[];
  maxPointsPerFence?: number;
  handleRemoveFence?: (fence: FenceModel) => void;
  handleActivateInactivateFence?: (fence: FenceModel) => void;
  handleSelectFence?: (fence: FenceModel) => void;
}

export const MapFenceCreate: FC<MapFenceCreateProps> = (props) => {
  const {
    fences,
    fence = {} as FenceModel,
    setFence = () => {},
    showTutorial = false,
    showInactiveFences = false,
    maxPointsPerFence = 0,
    handleRemoveFence,
    handleActivateInactivateFence = () => {},
    handleSelectFence = () => {},
  } = props;
  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>();
  const [selectedFence, setSelectedFence] = useState<FenceModel>();
  const [undoStack, setUndoStack] = useState<PathPoint[][]>([]);
  const [redoStack, setRedoStack] = useState<PathPoint[][]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const polygonRef = useRef<any>(null);
  const [dragStart, setDragStart] = useState<LatLngLiteral | null>(null);
  const controlsRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);

  const getMapInitialPoint = useCallback((fences: FenceModel[]) => {
    if (fence.pontos?.length > 0) return undefined;

    setLocationLoading(true);

    const registeringFences = fences.find((fence) => fence.id);

    // Request user location if wont have fences registereds
    if (!registeringFences) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCenter({ lat: latitude, lng: longitude });
            setLocationLoading(false);
          },
          () => {
            notificationController.warning({
              message: 'Aviso!',
              description: 'Não foi possível obter sua localização.',
            });
            setCenter(supermixLocation);
            setLocationLoading(false);
          },
        );
      } else {
        setCenter(supermixLocation);
        setLocationLoading(false);
      }
      return;
    }

    // Find the first active fence with points
    const activeFenceWithPoints = fences.find((fence) => fence.ativo && fence.pontos?.length > 0);
    if (activeFenceWithPoints) {
      setCenter({
        lat: activeFenceWithPoints.pontos[0].lat,
        lng: activeFenceWithPoints.pontos[0].lng,
      });
      setLocationLoading(false);
      return;
    }

    // If no active fences, find the first inactive fence with points
    const inactiveFenceWithPoints = fences.find((fence) => !fence.ativo && fence.pontos?.length > 0);
    if (inactiveFenceWithPoints) {
      setCenter({
        lat: inactiveFenceWithPoints.pontos[0].lat,
        lng: inactiveFenceWithPoints.pontos[0].lng,
      });
      setLocationLoading(false);
      return;
    }

    // Default location if no fences have points
    setCenter(supermixLocation);
    setLocationLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMapClick = (e: google.maps.MapMouseEvent) => {
    if (selectedFence) handleOnSelectFence(selectedFence);

    if (!maxPointsPerFence) {
      return;
    }

    if (fence.pontos?.length >= maxPointsPerFence) {
      notificationController.error({
        message: `A cerca pode ter no máximo ${maxPointsPerFence} pontos.`,
      });
      return;
    }

    switch (fence.idTipoCerca) {
      case 1:
        onPolygonClick(e);
        break;
      case 2:
        onCircleClick(e);
        break;
      case 3:
        onPolylineClick(e);
        break;
      default:
        break;
    }
  };

  const handleOnSelectFence = (fence: FenceModel) => {
    handleSelectFence(fence);
    setSelectedFence((prevState) => (prevState?.nome == fence.nome ? undefined : fence));
  };

  const handlePathChange = (fenceUpdated: FenceModel | ((prevState: FenceModel) => FenceModel)) => {
    setUndoStack((prevState) => [...prevState, fence.pontos]);
    // setPath(newPath);
    setFence(fenceUpdated);
    setRedoStack([]);
  };

  const undo = useCallback(() => {
    if (undoStack?.length === 0) return;
    const newUndoStack = [...undoStack];
    const previousPath = newUndoStack.pop();
    // setPath(previousPath || []);
    setFence((prevState) => ({ ...prevState, pontos: previousPath || [] }));
    setUndoStack(newUndoStack);
    setRedoStack((prevState) => [...prevState, fence.pontos]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fence.pontos, undoStack]);

  const redo = useCallback(() => {
    if (redoStack?.length === 0) return;
    const newRedoStack = [...redoStack];
    const newPath = newRedoStack.pop();
    // setPath(newPath || []);
    setFence((prevState) => ({ ...prevState, pontos: newPath || [] }));
    setUndoStack((prevState) => [...prevState, fence.pontos]);
    setRedoStack(newRedoStack);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fence.pontos, redoStack]);

  const deleteAll = () => {
    setUndoStack([...undoStack, fence.pontos]);
    // setPath([]);
    setFence((prevState) => ({ ...prevState, pontos: [] }));
    setRedoStack([]);
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const mapDiv = mapRef.current?.getDiv();
      if (!mapDiv || !mapDiv.contains(document.activeElement)) return;

      if (event.ctrlKey && event.key === 'z') {
        undo();
      }
      if (event.ctrlKey && event.key === 'y') {
        redo();
      }
      if (event.ctrlKey && event.shiftKey && (event.key === 'Z' || event.key === 'z')) {
        redo();
      }
    },
    [redo, undo],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const onPolygonLoad = (polygon: google.maps.Polygon) => {
    polygonRef.current = polygon;
  };

  const onPolygonClick = (e: google.maps.MapMouseEvent) => {
    if (e.domEvent instanceof MouseEvent && !e.domEvent.ctrlKey && e.latLng && mapRef.current) {
      const newPoint = { lat: e.latLng!.lat(), lng: e.latLng!.lng() };
      handlePathChange((prevState) => ({ ...prevState, pontos: [...prevState.pontos, newPoint] }));
    }
  };

  const onPolygonDragEnd = () => {
    if (polygonRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newPolygonPath = (polygonRef.current as any)
        .getPath()
        .getArray()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((latlng: any) => {
          return { lat: latlng.lat(), lng: latlng.lng() };
        });
      handlePathChange((prevState) => ({ ...prevState, pontos: newPolygonPath }));
    }
  };

  const onMarkerDragEnd = (index: number, event: google.maps.MapMouseEvent) => {
    const newLatLng = event.latLng;
    if (!newLatLng) return;
    handlePathChange((prevState) => ({
      ...prevState,
      pontos: prevState.pontos?.map((p, idx) => {
        if (idx === index) {
          return { lat: newLatLng.lat(), lng: newLatLng.lng() };
        }
        return p;
      }),
    }));
  };

  const onCircleClick = (e: google.maps.MapMouseEvent) => {
    if (e.domEvent instanceof MouseEvent && !e.domEvent.ctrlKey && e.latLng && mapRef.current) {
      // setCircleCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      setFence((prevState) => ({ ...prevState, pontos: [{ lat: e.latLng!.lat(), lng: e.latLng!.lng() }] }));
    }
  };

  const onPolylineClick = (e: google.maps.MapMouseEvent) => {
    if (e.domEvent instanceof MouseEvent && !e.domEvent.ctrlKey && e.latLng && mapRef.current) {
      const newPoint = { lat: e.latLng!.lat(), lng: e.latLng!.lng() };
      // handlePathChange((prevState) => [...prevState, pontos: newPoint]);
      handlePathChange((prevState) => ({ ...prevState, pontos: [...prevState.pontos, newPoint] }));
    }
  };

  const onPlacesChanged: StandaloneSearchBoxProps['onPlacesChanged'] = () => {
    if (searchBoxRef.current) {
      const places = searchBoxRef.current.getPlaces();
      const place = places?.[0];

      if (place && place.geometry && place.geometry.location && mapRef.current) {
        mapRef.current.panTo(place.geometry.location);
        mapRef.current.setZoom(15);
      }
    }
  };

  const onMapLoad: GoogleMapProps['onLoad'] = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
    map.setOptions({
      gestureHandling: 'cooperative',
      fullscreenControl: true,
      zoomControl: false,
      streetViewControl: false,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      },
    });
    if (map && controlsRef.current) {
      map.controls[google.maps.ControlPosition.RIGHT_TOP].push(controlsRef.current);
    }
  }, []);

  useEffect(() => {
    handlePathChange((prevState) => ({ ...prevState, pontos: [] }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fence.idTipoCerca]);

  useEffect(() => {
    getMapInitialPoint(fences);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadScript googleMapsApiKey={appSettings().MAPS_API_KEY || ''} libraries={['places', 'geometry']}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14} onClick={onMapClick} onLoad={onMapLoad}>
        <S.MapLoading visible={locationLoading}>
          <Spin /> Carregando localização
        </S.MapLoading>
        <StandaloneSearchBox
          onLoad={(ref) => {
            if (ref) searchBoxRef.current = ref;
          }}
          onPlacesChanged={onPlacesChanged}
        >
          <S.MapInputSearch type="text" placeholder="Pesquisar no Google Maps" />
        </StandaloneSearchBox>

        {/* Inicio Cerca atual */}
        {fence?.pontos?.length > 0 && fence.idTipoCerca === 1 && (
          <Polygon
            ref={polygonRef}
            path={fence.pontos}
            key={1}
            onClick={onPolygonClick}
            onLoad={onPolygonLoad}
            draggable={true}
            onDragEnd={onPolygonDragEnd}
            options={{
              fillColor: '#e99e9b',
              fillOpacity: 0.5,
              strokeColor: 'red',
              strokeOpacity: 1,
              strokeWeight: 2,
            }}
          />
        )}
        {fence?.pontos?.length > 0 && fence.idTipoCerca === 3 && (
          <Polyline
            path={fence.pontos}
            key={1}
            onClick={onPolylineClick}
            draggable={false}
            options={{
              strokeColor: 'red',
              strokeOpacity: 1,
              strokeWeight: 2,
            }}
          />
        )}
        {fence.pontos?.map((vertex, index) => (
          <Marker
            key={index}
            position={{ lat: vertex.lat, lng: vertex.lng }}
            draggable={true}
            onDragEnd={(e) => onMarkerDragEnd(index, e)}
            icon={{
              path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0', // Cria um círculo
              fillColor: 'red',
              fillOpacity: 1.0,
              scale: 1,
              strokeColor: 'red',
              strokeWeight: 1,
            }}
          />
        ))}
        {fence.idTipoCerca === 2 && (
          <Circle
            center={fence.pontos[0]}
            radius={fence.raio}
            onClick={onCircleClick}
            draggable={true}
            onDragStart={(e) => {
              // Store the starting position
              setDragStart({
                lat: e.latLng!.lat(),
                lng: e.latLng!.lng(),
              });
            }}
            onDragEnd={(e) => {
              // Ending position
              const endPos = {
                lat: e.latLng!.lat(),
                lng: e.latLng!.lng(),
              };

              // Calculate the offset
              const offset = {
                lat: endPos.lat - dragStart!.lat,
                lng: endPos.lng - dragStart!.lng,
              };

              // Update the center position of the circle
              setFence((prevState) => ({
                ...prevState,
                pontos: [
                  {
                    lat: prevState.pontos[0].lat + offset.lat,
                    lng: prevState.pontos[0].lng + offset.lng,
                  },
                ],
              }));
            }}
            options={{
              fillColor: '#e99e9b',
              fillOpacity: 0.5,
              strokeColor: 'red',
              strokeOpacity: 1,
              strokeWeight: 2,
            }}
          />
        )}
        {/* Fim Cerca atual */}

        <S.ButtonsMapWrapper>
          {fence.idTipoCerca === 3 && (
            <>
              <Input
                style={{ height: '2.5rem', width: '11rem' }}
                placeholder="Digite a largura (m)"
                value={fence.largura === undefined ? '' : fence.largura}
                onChange={(event) => {
                  const value = event.target.value;
                  const sanitizedValue = value.replace(/[^\d]/g, '');

                  setFence((prevState) => ({
                    ...prevState,
                    largura: sanitizedValue === '' ? undefined : Number(sanitizedValue),
                  }));
                }}
              />
            </>
          )}
          {(fence.idTipoCerca === 1 || fence.idTipoCerca === 3) && (
            <>
              <UndoRedoDeleteButtons undo={undo} redo={redo} delete={deleteAll} />
              <PointCount maxValue={maxPointsPerFence || 0} currentValue={fence?.pontos?.length || 0} />
              <ZoomButtons mapRef={mapRef} />
            </>
          )}
          {fence.idTipoCerca === 2 && (
            <>
              <Input
                style={{ height: '2.5rem', width: '9.5rem' }}
                placeholder="Digite o raio (m)"
                value={fence.raio === undefined ? '' : fence.raio}
                onChange={(event) => {
                  const value = event.target.value;
                  const sanitizedValue = value.replace(/[^\d]/g, '');

                  setFence((prevState) => ({
                    ...prevState,
                    raio: sanitizedValue === '' ? undefined : Number(sanitizedValue),
                  }));
                }}
              />
              <UndoRedoDeleteButtons undo={undo} redo={redo} delete={deleteAll} />
              <ZoomButtons mapRef={mapRef} />
            </>
          )}
        </S.ButtonsMapWrapper>

        <MapFenceSelectedCard fence={selectedFence} />
        <MapFenceModal
          fences={fences}
          handleRemoveFence={handleRemoveFence}
          handleOnSelectFence={handleOnSelectFence}
          handleActivateInactivateFence={handleActivateInactivateFence}
        />
        <MapFenceSaved
          fences={fences}
          selectedFence={selectedFence}
          handleOnSelectFence={handleOnSelectFence}
          showInactiveFences={showInactiveFences}
        />
        <MapFenceTutorial show={showTutorial} fenceType={fence.idTipoCerca} mapRef={mapRef} />
      </GoogleMap>
    </LoadScript>
  );
};
