import React from 'react';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import * as S from '../ModulesProfileConfig.styles';
import ShouldRenderInput from '@app/components/common/ModulesProfileConfig/ShouldRenderInput';
import { RadioGroup, Radio } from '@app/components/common/Radio/Radio';
import { Col, Form, Row, Space } from 'antd';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { CardInfoProperty } from '../../Card/CardInfo/CardDeviceInformation.styles';
import { Input } from '../../inputs/Input/Input';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import { PropertyModel } from '@app/domain/property/propertyModel';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';

interface RedeProps {
  properties: DeviceProfileProperty[];
}

const RedeView: React.FC<RedeProps> = ({ properties }) => {
  const props = properties as PropertyModel[];
  const getPropertyValue = (idEmbarcado: DevicePropertyType) => {
    const propValue = properties?.find((p) => p.idEmbarcado == idEmbarcado)?.valorPropriedade;
    return propValue == 'null' ? ' ' : propValue;
  };

  return (
    <Form layout="vertical">
      <Row gutter={[18, 18]}>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.Apn} properties={props}>
          <Col xs={24} md={8}>
            <CardInfoProperty>APN</CardInfoProperty>
            <Input
              key={3}
              type="text"
              className="ant-input"
              placeholder="Digite o valor"
              value={getPropertyValue(DevicePropertyType.Apn)}
              disabled
            />
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.UsuarioApn} properties={props}>
          <Col xs={24} md={8}>
            <CardInfoProperty>Usuário APN</CardInfoProperty>
            <Input
              key={3}
              type="text"
              className="ant-input"
              placeholder="Digite o valor"
              value={getPropertyValue(DevicePropertyType.UsuarioApn)}
              disabled
            />
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.SenhaApn} properties={props}>
          <Col xs={24} md={8}>
            <CardInfoProperty>Senha APN</CardInfoProperty>
            <Input
              key={3}
              type="text"
              className="ant-input"
              placeholder="Digite o valor"
              value={getPropertyValue(DevicePropertyType.SenhaApn)}
              disabled
            />
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.IpServidor} properties={props}>
          <Col xs={24} md={8}>
            <CardInfoProperty>Servidor primário</CardInfoProperty>
            <BaseFormInputItem>
              <RadioGroup value={getPropertyValue(DevicePropertyType.IpServidor) ? 'IP' : 'DNS'} disabled={true}>
                <Space direction="vertical">
                  <Radio value={'IP'}>IP</Radio>
                  <Radio value={'DNS'}>DNS</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        {
          {
            IP: (
              <ShouldRenderInput idEmbarcado={DevicePropertyType.IpServidor} properties={props}>
                <Col xs={24} md={8}>
                  <CardInfoProperty>IP do servidor primário</CardInfoProperty>
                  <Input
                    key={3}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={getPropertyValue(DevicePropertyType.IpServidor)}
                    disabled
                  />
                </Col>
              </ShouldRenderInput>
            ),
            DNS: (
              <ShouldRenderInput idEmbarcado={DevicePropertyType.Dns} properties={props}>
                <Col xs={24} md={8}>
                  <CardInfoProperty>DNS do servidor primário</CardInfoProperty>
                  <Input
                    key={3}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={getPropertyValue(DevicePropertyType.Dns)}
                    disabled
                  />
                </Col>
              </ShouldRenderInput>
            ),
          }[getPropertyValue(DevicePropertyType.IpServidor) ? 'IP' : 'DNS']
        }
        <ShouldRenderInput idEmbarcado={DevicePropertyType.PortaServidor} properties={props}>
          <Col xs={24} md={8}>
            <CardInfoProperty>Porta do servidor primário</CardInfoProperty>
            <Input
              key={3}
              type="text"
              className="ant-input"
              placeholder="Digite o valor"
              value={getPropertyValue(DevicePropertyType.PortaServidor)}
              disabled
            />
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.TipoProtocolo} properties={props}>
          <Col xs={24} md={8}>
            <CardInfoProperty>Tipo do servidor primário</CardInfoProperty>
            <BaseFormInputItem>
              <RadioGroup key={11} disabled={true} value={getPropertyValue(DevicePropertyType.TipoProtocolo)}>
                <Space direction="vertical">
                  <Radio value={'1'}>TCP</Radio>
                  <Radio value={'2'}>UDP</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
      </Row>
      <Row>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.IpServidorSecundario} properties={props}>
          <BaseFormInputItem style={{ minWidth: '400px', width: 'fit-content' }}>
            <Checkbox checked={!!getPropertyValue(DevicePropertyType.IpServidorSecundario)} disabled={true}>
              <S.CheckboxLabel>Servidor secundário</S.CheckboxLabel>
            </Checkbox>
          </BaseFormInputItem>
        </ShouldRenderInput>
      </Row>
      {getPropertyValue(DevicePropertyType.IpServidorSecundario) && (
        <Row gutter={[18, 18]}>
          <ShouldRenderInput idEmbarcado={DevicePropertyType.IpServidorSecundario} properties={props}>
            <Col xs={24} md={8}>
              <CardInfoProperty>Servidor secundário</CardInfoProperty>
              <BaseFormInputItem style={{ width: '10rem' }}>
                <RadioGroup
                  value={getPropertyValue(DevicePropertyType.IpServidorSecundario) ? 'IP' : 'DNS'}
                  disabled={true}
                >
                  <Space direction="vertical">
                    <Radio value={'IP'}>IP</Radio>
                    <Radio value={'DNS'}>DNS</Radio>
                  </Space>
                </RadioGroup>
              </BaseFormInputItem>
            </Col>
          </ShouldRenderInput>
          {
            {
              IP: (
                <ShouldRenderInput idEmbarcado={DevicePropertyType.IpServidorSecundario} properties={props}>
                  <Col xs={24} md={8}>
                    <CardInfoProperty>IP do servidor secundário</CardInfoProperty>
                    <Input
                      key={3}
                      type="text"
                      className="ant-input"
                      placeholder="Digite o valor"
                      value={getPropertyValue(DevicePropertyType.IpServidorSecundario)}
                      disabled
                    />
                  </Col>
                </ShouldRenderInput>
              ),
              DNS: (
                <ShouldRenderInput idEmbarcado={DevicePropertyType.DnsSecundario} properties={props}>
                  <Col xs={24} md={8}>
                    <CardInfoProperty>DNS do servidor secundário</CardInfoProperty>
                    <Input
                      key={3}
                      type="text"
                      className="ant-input"
                      placeholder="Digite o valor"
                      value={getPropertyValue(DevicePropertyType.DnsSecundario)}
                      disabled
                    />
                  </Col>
                </ShouldRenderInput>
              ),
            }[getPropertyValue(DevicePropertyType.IpServidorSecundario) ? 'IP' : 'DNS']
          }
          <ShouldRenderInput idEmbarcado={DevicePropertyType.PortaServidorSecundario} properties={props}>
            <Col xs={24} md={8}>
              <CardInfoProperty>Porta do servidor secundário</CardInfoProperty>
              <Input
                key={3}
                type="text"
                className="ant-input"
                placeholder="Digite o valor"
                value={getPropertyValue(DevicePropertyType.PortaServidorSecundario)}
                disabled
              />
            </Col>
          </ShouldRenderInput>
          <ShouldRenderInput idEmbarcado={DevicePropertyType.TipoProtocoloSecundario} properties={props}>
            <BaseFormInputItem label="Tipo servidor secundário">
              <RadioGroup key={47} disabled={true} value={getPropertyValue(DevicePropertyType.TipoProtocoloSecundario)}>
                <Space direction="vertical">
                  <Radio value={'1'}>TCP</Radio>
                  <Radio value={'2'}>UDP</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </ShouldRenderInput>
        </Row>
      )}
    </Form>
  );
};

export default RedeView;
