import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Table } from '@app/components/common/Table/Table';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeader } from '@app/store/slices/headerSlice';
import type { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { notificationController } from '@app/controllers/notificationController';
import { Col, Row } from 'antd';
import { Modal } from '@app/components/common/Modal/Modal';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { IconMore } from '@app/assets/slump-icons';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import Dashboard from '@app/components/dashboard/Dashboard';
import { useNavigate } from 'react-router-dom';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { isInputMatching } from '@app/utils/utils';
import { Tag } from '@app/components/common/Tag/Tag';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { hasAccessByRoles } from '@app/controllers/accessController';
import { UserType } from '@app/constants/enums/userType';
import { DeviceProfileService } from '@app/services/deviceProfileService';
import { DeviceProfileModel } from '@app/domain/deviceProfile/deviceProfileModel';

const deviceProfileService = new DeviceProfileService();

export const DeviceProfileDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [deviceProfile, setDeviceProfile] = useState<DeviceProfileModel>({} as DeviceProfileModel);
  const [deviceProfiles, setDeviceProfiles] = useState<DeviceProfileModel[]>();
  const [loading, setLoading] = useState(false);
  const [modalEnableDisableProfile, setModalEnableDisableProfile] = useState(false);
  const [tableData, setTableData] = useState<DeviceProfileModel[]>();

  const fetchDeviceProfiles = useCallback(() => {
    setLoading(true);
    deviceProfileService
      .getArray('')
      .then((response) => {
        setDeviceProfiles(response);
        setTableData(response);
      })
      .catch((error) => {
        notificationController.error({
          message: `Erro ao buscar verões de firmware: ${error.message}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleVisualizationClick = (deviceProfileId: number) => {
    navigate(`/perfil-configuracao/visualizar/${deviceProfileId}`);
  };

  const handleExportToExcel = () => {
    const dataToExport = tableData?.map((item) => ({
      ...item,
      ativo: item.ativo ? 'Ativo' : 'Inativo',
    })) as unknown as DeviceProfileModel[];
    genericExportToExcel('perfil-configuracao', columns, dataToExport ?? []);
  };

  const handleDisableEnableClick = useCallback((deviceProfile: DeviceProfileModel) => {
    setDeviceProfile(deviceProfile);
    setModalEnableDisableProfile(true);
  }, []);

  const renderStatus = useCallback((ativo: boolean) => {
    return ativo ? (
      <Tag color="#E9F4EE" style={{ color: '#083F18', width: '64px', textAlign: 'center' }}>
        Ativo
      </Tag>
    ) : (
      <Tag color="#FEE9EA" style={{ color: '#620E12', width: '64px', textAlign: 'center' }}>
        Inativo
      </Tag>
    );
  }, []);

  const columns: ColumnsType<DeviceProfileModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: true,
      sorter: (a, b) => (a?.id ? a.id : 0) - (b?.id ? b.id : 0),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Cliente',
      key: 'cliente',
      dataIndex: 'cliente',
      showSorterTooltip: true,
      sorter: (a, b) => (a?.id ? a.id : 0) - (b?.id ? b.id : 0),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Perfil',
      dataIndex: 'nome',
      showSorterTooltip: true,
      sorter: (a, b) => (a.nome ?? '').localeCompare(b.nome ?? ''),
    },
    {
      title: 'Equipamento',
      dataIndex: 'equipamento',
      showSorterTooltip: false,
      sorter: (a, b) => (a.equipamento ?? '').localeCompare(b.equipamento ?? ''),
    },
    {
      title: 'Modelo',
      dataIndex: 'modelo',
      showSorterTooltip: false,
      sorter: (a, b) => (a.modelo ?? '').localeCompare(b.modelo ?? ''),
    },
    {
      title: 'Grupo de firmware',
      dataIndex: 'grupoFirmware',
      showSorterTooltip: false,
      sorter: (a, b) => (a.grupoFirmware ?? '').localeCompare(b.grupoFirmware ?? ''),
    },
    {
      title: 'Status',
      dataIndex: 'ativo',
      // sorter: (a, b) => (a.status && b.status ? a.status.localeCompare(b.status) : 0),
      showSorterTooltip: true,
      sorter: (a, b) => (a.ativo ? 1 : 0) - (b.ativo ? 1 : 0),
      render: (ativo: boolean) => renderStatus(ativo),
    },
    {
      title: '',
      dataIndex: 'id',
      width: '3%',
      showSorterTooltip: false,
      render: (_, deviceProfile) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <DropdownTable
              buttonText=""
              iconD={
                <div style={{ color: '#545454' }}>
                  <IconMore />
                </div>
              }
              trigger={['click']}
              placement="bottomRight"
              overlay={
                <Menu>
                  {/*<MenuItem key="delete" onClick={() => handleEditClick(Number(deviceProfile.id))}>*/}
                  {/*  <Button type="text">Editar</Button>*/}
                  {/*</MenuItem>*/}
                  {hasAccessByRoles([UserType.FactoryAdmin, UserType.Factory, UserType.Client]) && (
                    <MenuItem key="delete" onClick={() => handleDisableEnableClick(deviceProfile)}>
                      <Button type="text">{deviceProfile.ativo ? 'Desativar' : 'Ativar'}</Button>
                    </MenuItem>
                  )}
                  <MenuItem key="edit" onClick={() => handleVisualizationClick(Number(deviceProfile.id))}>
                    <Button type="text">Visualizar</Button>
                  </MenuItem>
                </Menu>
              }
            ></DropdownTable>
          </div>
        );
      },
    },
  ];

  const handleSearchOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const inputValue = event.currentTarget.value.toLowerCase();

    if (inputValue.length > 0) {
      return setTableData(
        deviceProfiles?.filter(
          (item) =>
            isInputMatching(item.id?.toString(), inputValue) ||
            isInputMatching(item.nome, inputValue) ||
            isInputMatching(item.cliente, inputValue) ||
            isInputMatching(item.equipamento, inputValue) ||
            isInputMatching(item.modelo, inputValue) ||
            isInputMatching(item.grupoFirmware, inputValue),
        ),
      );
    }

    return setTableData(deviceProfiles);
  };

  const enableDisableProfile = useCallback(
    (deviceProfileId: number) => {
      setLoading(true);
      deviceProfileService
        .post(`${deviceProfileId}/ativar-desativar`, {})
        .then(() => {
          notificationController.success({ message: 'Perfil de configuração alterado com sucesso' });
          fetchDeviceProfiles();
        })
        .catch(() => {
          notificationController.error({ message: 'Erro ao alterar perfil de configuração' });
        })
        .finally(() => {
          setModalEnableDisableProfile(false);
          setLoading(false);
        });
    },
    [fetchDeviceProfiles],
  );

  useEffect(() => {
    fetchDeviceProfiles();
    dispatch(setHeader({ title: 'Perfil de configuração' }));
  }, [dispatch, fetchDeviceProfiles]);

  return (
    <>
      <Modal
        title={deviceProfile.ativo ? 'Desativar perfil' : 'Ativar perfil'}
        open={modalEnableDisableProfile}
        onOk={() => enableDisableProfile(Number(deviceProfile.id))}
        onCancel={() => setModalEnableDisableProfile(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            {deviceProfile.ativo ? (
              <CloseCircleOutlined size={20} style={{ color: '#C41B24', marginRight: '1rem' }} />
            ) : (
              <CheckCircleOutlined size={20} style={{ color: '#3DB05D', marginRight: '1rem' }} />
            )}
            <span>
              Deseja realmente{' '}
              <strong>
                {deviceProfile.ativo ? 'desativar' : 'ativar'} o perfil {deviceProfile.nome}
              </strong>{' '}
              selecionado?
            </span>
          </Col>
        </Row>
      </Modal>

      <PageTitle>Perfil de configuração</PageTitle>
      <Spinner spinning={loading}>
        <Dashboard
          buttonText={hasAccessByRoles([UserType.FactoryAdmin, UserType.Client]) ? 'Cadastrar novo perfil' : ''}
          handleButtonClick={() => navigate('/perfil-configuracao/cadastrar')}
          placeholderTextSearch="Pesquisar"
          handleSearchOnChange={handleSearchOnChange}
          exportToExcel={handleExportToExcel}
          table={
            <Table
              columns={
                hasAccessByRoles([UserType.Factory, UserType.FactoryAdmin])
                  ? columns
                  : columns.filter((c) => c.key != 'cliente')
              }
              dataSource={tableData}
              bordered
            />
          }
        />
      </Spinner>
    </>
  );
};
