import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import IFenceService, { FenceService } from '@app/services/fenceService';
import { Row, Col, Select } from 'antd';
import { IconMap } from '@app/assets/slump-icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import { FenceGroupModel } from '@app/domain/fence/fenceGroupModel';
import { FenceModel } from '@app/domain/fence/fenceModel';
import { useCallback, useEffect, useState } from 'react';
import { notificationController } from '@app/controllers/notificationController';
import { Modal } from '@app/components/common/Modal/Modal';
import { CardInfoTitle, CardInfoValue } from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Panel } from '@app/components/common/Collapse/Collapse';
import { groupBy } from 'lodash';
import * as S from './Cerca.styles';
import { PropertyModel } from '@app/domain/property/propertyModel';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { MapFenceView } from '../../FenceMap/map-fence-view';

interface CercaProps {
  idModel?: number;
  properties: PropertyModel[];
  handleChangeDeviceProfileProperties: (properties: DeviceProfileProperty[]) => void;
}

const fenceService: IFenceService = new FenceService();

export const CercaConfigUpdate: React.FC<CercaProps> = ({
  idModel,
  properties,
  handleChangeDeviceProfileProperties,
}) => {
  const [group, setGroup] = useState<FenceGroupModel>();
  const [groups, setGroups] = useState<FenceGroupModel[]>([]);
  const [fences, setFences] = useState<FenceModel[]>([]);
  const [modalMapVisible, setModalMapVisible] = useState<boolean>(false);

  const handleChangeGroup = (idGroup: number) => {
    setGroup(groups.find((g) => g.id == idGroup));

    const idGroupProp = properties.find((p) => p.idEmbarcado == DevicePropertyType.IdGrupoCercas);

    if (idGroupProp)
      handleChangeDeviceProfileProperties([{ ...idGroupProp, valorPropriedade: idGroup ? `${idGroup}` : '' }]);
  };

  const fetchGroups = useCallback(() => {
    fenceService
      .getArray(`/grupos-por-modelo/${idModel}`)
      .then((res: FenceGroupModel[]) => setGroups(res.filter((g) => g.ativo)))
      .catch(() => notificationController.error({ message: `Erro ao obter cercas.` }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (group?.id)
      fenceService
        .get(`/grupos/${group.id}`)
        .then((response) => {
          setFences(response.cercas ?? []);
        })
        .catch((err) => {
          notificationController.error({ message: `Erro ao buscar cerca. ${err}` });
        });
  }, [group]);

  useEffect(() => fetchGroups(), [fetchGroups]);

  return (
    <BaseForm layout="vertical" style={{ width: '100%', overflowX: 'hidden' }}>
      <Row gutter={18}>
        <Col xs={24}>
          <BaseFormInputItem label="Grupo de cercas">
            <Select
              showArrow
              showSearch
              value={group?.id}
              placeholder="Selecione o grupo"
              onChange={(value) => handleChangeGroup(Number(value))}
              options={groups.map((g) => ({ value: g.id, label: g.nome }))}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
            ></Select>
          </BaseFormInputItem>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8}>
          <Button type="primary" icon={<IconMap />} onClick={() => setModalMapVisible(true)} disabled={!group?.id}>
            Visualizar mapa
          </Button>
        </Col>
      </Row>
      <Modal
        title="Visualizar mapa"
        open={modalMapVisible}
        width={'100%'}
        onCancel={() => setModalMapVisible(false)}
        footer
      >
        <Row>
          <Col xs={24} sm={12} md={6}>
            <CardInfoValue>Grupo</CardInfoValue>
            &nbsp;<CardInfoTitle>{group?.nome}</CardInfoTitle>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <CardInfoValue>Quantidade de cercas</CardInfoValue>
            &nbsp;<CardInfoTitle>{fences?.length}</CardInfoTitle>
          </Col>
        </Row>
        <MapFenceView fences={fences} />
        <S.CollapseMap activeKey={1} style={{ marginTop: '1rem' }}>
          <Panel
            key="1"
            header={
              <Row align={'middle'} justify={'space-between'}>
                <Col>
                  <S.CollapseHeaderName>Cercas do grupo</S.CollapseHeaderName>
                </Col>
              </Row>
            }
          >
            {Object.keys(groupBy(fences, (f) => f.tipoCerca)).map((f) => {
              const fencesFiltered = fences.filter((fence) => fence.tipoCerca == f);
              return (
                <div key={f} style={{ paddingBottom: '1rem' }}>
                  <Row>
                    <S.TableTypeName>{f}</S.TableTypeName>
                  </Row>
                  <Row>
                    <S.TableFenceQuantity>{fencesFiltered?.length}</S.TableFenceQuantity>&nbsp;
                    <S.TableFenceDescription>{fencesFiltered?.length > 1 ? 'Cercas' : 'Cerca'}</S.TableFenceDescription>
                  </Row>
                  <Row gutter={[18, 18]} style={{ overflowY: 'auto', maxHeight: '30rem', width: '100%' }}>
                    {fencesFiltered?.map((item, index) => {
                      return (
                        <Col key={index} xs={12} xl={6}>
                          <S.TableFenceName>{item.nome}</S.TableFenceName>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              );
            })}
          </Panel>
        </S.CollapseMap>
      </Modal>
    </BaseForm>
  );
};
