import React from 'react';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateDeviceConfigUpdateStepOne } from './components/steps/create-step-one';
import { ConfigUpdateDeviceModel, ConfigUpdateModel } from '@app/domain/deviceConfigUpdate/deviceConfigUpdate';
import { Modal } from '@app/components/common/Modal/Modal';
import { Col, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TableCollapsible } from '@app/components/tables/TableCollapsible/table-collapsible';
import { setFooter } from '@app/store/slices/footerSlice';
import { CreateDeviceConfigUpdateStepTwo } from './components/steps/create-step-two';
import { Container } from '@app/components/common/Container/Container.styles';
import * as S from './create.styles';
import { readUser } from '@app/services/localStorage.service';
import { notificationController } from '@app/controllers/notificationController';
import IFactoryDeviceService, { FactoryDeviceService } from '@app/services/factoryDeviceService';
import IFirmwareGroupService, { FirmwareGroupService } from '@app/services/firmwareGroupService';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { SendParameter, setParameters } from '@app/store/slices/sendParametersSlice';

enum StepConfigUpdate {
  Devices = 0,
  Parameters = 1,
}

const deviceService: IFactoryDeviceService = new FactoryDeviceService();
const firmwareGroupService: IFirmwareGroupService = new FirmwareGroupService();

export const DeviceConfigUpdateCreate: React.FC = () => {
  const { id } = useParams();
  const user = readUser();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [currentStep, setCurrentStep] = useState<StepConfigUpdate>(
    !id ? StepConfigUpdate.Devices : StepConfigUpdate.Parameters,
  );
  const [configUpdate, setConfigUpdate] = useState<ConfigUpdateModel>({} as ConfigUpdateModel);
  const [incompatibleDevices, setIncompatibleDevices] = useState<ConfigUpdateDeviceModel[]>([]);

  const handleBackClick = async () => navigate(`/atualizacao-configuracao-dispositivo`);
  const handleCreateDeviceConfigUpdateWhenHasId = async (id: string) => {
    try {
      setLoading(true);

      const deviceResponse = await deviceService.get(id);

      let idGroupFirmware = deviceResponse.idGrupoFirmware;

      if (!deviceResponse.idGrupoFirmware) {
        try {
          const groupResponse = await firmwareGroupService.getGroupByIdModelAndVersion(
            deviceResponse.idModelo,
            deviceResponse.versaoFirmware,
          );

          if (groupResponse.id) {
            idGroupFirmware = groupResponse.id;
          } else {
            throw Error;
          }
        } catch (error) {
          notificationController.error({
            message: 'Erro!',
            description: 'Não foi encontrada versão cadastrada compatível com a do dispositivo.',
          });
          setLoading(false);
          return;
        }
      }

      setConfigUpdate({
        idModelo: deviceResponse.idModelo,
        dataHora: new Date(),
        idGrupoFirmware: idGroupFirmware,
        usuario: user?.userName ?? '',
        idCliente: user?.idCliente ?? 1,
        dispositivos: [
          {
            id: deviceResponse.id,
            numeroSerie: deviceResponse.numeroSerie,
            versaoFirmware: deviceResponse.versaoFirmware,
          } as ConfigUpdateDeviceModel,
        ],
      } as ConfigUpdateModel);

      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: 'Nova configuração remota',
        handleBackClick: () => setModalCancelVisible(true),
      }),
    );

    if (id) {
      handleCreateDeviceConfigUpdateWhenHasId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (configUpdate?.idGrupoFirmware)
      firmwareGroupService
        .getArray(`obter-propriedades-perfil-envio/${configUpdate.idGrupoFirmware}`)
        .then((res) => {
          dispatch(setParameters(res as unknown as SendParameter[]));
        })
        .catch((error) => {
          notificationController.error(error);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configUpdate?.idGrupoFirmware]);

  useEffect(() => {
    const updateFooter = () => {
      switch (currentStep) {
        case StepConfigUpdate.Devices:
          dispatch(
            setFooter({
              confirmButtonDisabled: !(configUpdate?.dispositivos?.length > 0 || id),
              confirmButtonText: 'Próxima etapa',
              handleConfirmButtonClick: () => setCurrentStep(StepConfigUpdate.Parameters),
              cancelButtonText: 'Cancelar',
              handleCancelButtonClick: () => setModalCancelVisible(true),
            }),
          );
          break;
        case StepConfigUpdate.Parameters:
          configUpdate.id
            ? dispatch(
                setFooter({
                  confirmButtonText: 'Sair',
                  handleConfirmButtonClick: () => handleBackClick(),
                }),
              )
            : dispatch(
                setFooter({
                  confirmButtonDisabled: true,
                  confirmButtonText: 'Sair',
                  handleConfirmButtonClick: () => handleBackClick(),
                  cancelButtonText: 'Cancelar',
                  handleCancelButtonClick: () => setModalCancelVisible(true),
                }),
              );
          break;
        default:
          break;
      }
    };
    updateFooter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configUpdate, currentStep]);

  return (
    <>
      <Spinner spinning={loading} />
      <Modal
        title="Cancelar agendamento"
        open={modalCancelVisible}
        onOk={handleBackClick}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>
            Deseja realmente <strong>&nbsp;cancelar o agendamento?</strong>
          </Col>
        </Row>
      </Modal>

      <Container style={{ height: '100%' }}>
        <Row gutter={32} style={{ height: '100%' }}>
          {/* container steps */}
          <S.ContainerStepOne xs={24} md={12} xl={12}>
            {
              {
                [StepConfigUpdate.Devices]: (
                  <CreateDeviceConfigUpdateStepOne
                    openModalEdit={openModalEdit}
                    setOpenModalEdit={setOpenModalEdit}
                    deviceConfigUpdate={configUpdate}
                    setDeviceConfigUpdate={setConfigUpdate}
                    setIncompatibleDevices={setIncompatibleDevices}
                  />
                ),
                [StepConfigUpdate.Parameters]: (
                  <CreateDeviceConfigUpdateStepTwo
                    deviceConfigUpdate={configUpdate}
                    setDeviceConfigUpdate={setConfigUpdate}
                  />
                ),
              }[currentStep]
            }
          </S.ContainerStepOne>
          {/* container dispositivos */}
          <S.ContainerStepTwo xs={24} md={12} xl={12}>
            <S.DeviceConfigUpdateDescription
              title="Dispositivos"
              subtitle="Após preencher todas as informações, verifique se os itens selecionados estão corretos ."
            >
              <Row style={{ height: '100%' }}>
                <Col span={24}>
                  <TableCollapsible
                    key="1"
                    data={configUpdate.dispositivos?.map((d) => d.numeroSerie ?? '')}
                    header={
                      <Row align={'middle'} justify={'space-between'}>
                        <Col>Dispositivo(s)</Col>
                        {!id && currentStep == StepConfigUpdate.Devices && (
                          <a style={{ textDecoration: 'underline' }} onClick={() => setOpenModalEdit(true)}>
                            Editar
                          </a>
                        )}
                      </Row>
                    }
                    subtitle={
                      <>
                        <strong>{id ? 1 : configUpdate.dispositivos?.length}</strong>&nbsp;
                        {id || configUpdate.dispositivos?.length > 1 ? 'Dispositivos' : 'Dispositivo'}
                      </>
                    }
                  />
                  {currentStep === StepConfigUpdate.Devices && incompatibleDevices.length > 0 && (
                    <TableCollapsible
                      key="2"
                      header={
                        <Row align={'middle'} justify={'space-between'}>
                          <Col>Dispositivos incompatíveis</Col>
                        </Row>
                      }
                      data={incompatibleDevices?.map((d) => d.numeroSerie ?? '')}
                      subtitle={
                        <>
                          <strong>{incompatibleDevices?.length}</strong>&nbsp;
                          {incompatibleDevices?.length > 1 ? 'Dispositivos' : 'Dispositivo'}
                        </>
                      }
                    />
                  )}
                </Col>
              </Row>
            </S.DeviceConfigUpdateDescription>
          </S.ContainerStepTwo>
        </Row>
      </Container>
    </>
  );
};
