import { NotificationType } from '@app/components/common/Notification/Notification';
import { Priority } from '@app/constants/enums/priorities';
import Cookies from 'js-cookie';
import moment from 'moment';
import { KeyboardEvent } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IDecodedUserToken {
  client_admin: string | null;
  master: string | null;
  user_filial: string | null;
}

export const getDifferenceDaysBetweenDates = (startDate: Date, endDate: Date): number => {
  if (startDate.toString() == 'Invalid Date' || endDate.toString() == 'Invalid Date') return 0;
  return moment(endDate).diff(moment(startDate), 'days');
};

export const getDifferenceBetweenDatesInMinutes = (startDate: Date, endDate: Date): number => {
  if (startDate.toString() == 'Invalid Date' || endDate.toString() == 'Invalid Date') return 0;
  const minutes = moment.utc(moment(endDate).diff(moment(startDate))).minutes();
  return minutes;
};

export const getDifferenceBetweenDatesInHours = (startDate: Date, endDate: Date): number => {
  if (startDate.toString() == 'Invalid Date' || endDate.toString() == 'Invalid Date') return 0;
  const hours = moment.utc(moment(endDate).diff(moment(startDate))).hours();
  return hours;
};

export const isDateMatching = (itemDate: string, inputValue: string): boolean => {
  const formatDate = (date: string) => moment(date).format('DD/MM/YYYY HH:mm');
  const cleanString = (str: string): string => str.replaceAll(/[^a-z0-9]/gi, '');

  const formattedDate = formatDate(itemDate);
  const cleanedInputValue = cleanString(inputValue);

  return formattedDate.includes(inputValue) || cleanString(formattedDate).includes(cleanedInputValue);
};

export const isInputMatching = (itemValue: string | undefined, inputValue: string): boolean => {
  if (!itemValue) return false; // if the itemValue is undefined or null, return false

  const cleanString = (str: string): string => str.replaceAll(/[^a-z0-9]/gi, '');
  const cleanedInputValue = cleanString(inputValue);

  return itemValue.toLowerCase().includes(inputValue) || cleanString(itemValue).includes(cleanedInputValue);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupByKey = (list: any[], key: string) =>
  list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});

export const camelize = (string: string): string => {
  return string
    .split(' ')
    .map((word, index) => (index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)))
    .join('');
};

export const capitalize = (word: string): string => `${word[0].toUpperCase()}${word.slice(1)}`;

export const hexToRGB = (hex: string): string => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
};

export const getDifference = (value: number, prevValue: number): string | null =>
  prevValue !== 0 ? `${((Math.abs(value - prevValue) / prevValue) * 100).toFixed(0)}%` : '100%';

export const normalizeProp = (prop: string | number | [number, number]): string =>
  typeof prop === 'number' ? `${prop}px` : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString();

export const defineColorByPriority = (priority: Priority): string => {
  switch (priority) {
    case Priority.INFO:
      return 'var(--primary-color)';
    case Priority.LOW:
      return 'var(--confimed-hover)';
    case Priority.MEDIUM:
      return 'var(--new-alert-color)';
    case Priority.HIGH:
      return 'var(--reported-color)';
    default:
      return 'var(--confimed-hover)';
  }
};

export const defineColorBySeverity = (severity: NotificationType | undefined, rgb = false): string => {
  const postfix = rgb ? 'rgb-color' : 'color';
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`;
    case 'info':
    default:
      return `var(--primary-${postfix})`;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeBy = (a: any[], b: any[], key: string): any[] =>
  a.filter((elem) => !b.find((subElem) => subElem[key] === elem[key])).concat(b);

export const getSmoothRandom = (factor: number, start: number): number => {
  const halfEnvelope = 1 / factor / 2;
  const max = Math.min(1, start + halfEnvelope);
  const min = Math.max(0, start - halfEnvelope);

  return Math.random() * (max - min) + min;
};

export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h, s;
    const l = (max + min) / 2;
    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          h = 0;
      }
      h /= 6;
    }
    return {
      h,
      s,
      l,
    };
  } else {
    throw new Error('Non valid HEX color');
  }
};

export const formatNumberWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const maskIdProps = {
  guide: true,
  mask: (value: string): (string | RegExp)[] => {
    let result: (string | RegExp)[] = [];
    const chunks = value.split('.');

    for (let i = 0; i < 4; ++i) {
      const chunk = (chunks[i] || '').replace(/_/gi, '');

      if (chunk === '') {
        result.push(/\d/, /\d/, /\d/, '.');
      } else if (+chunk === 0) {
        result.push(/\d/, '.');
      } else if (chunks.length < 4 || (chunk.length < 3 && chunks[i].indexOf('_') !== -1)) {
        if ((chunk.length < 2 && +`${chunk}00` > 255) || (chunk.length < 3 && +`${chunk}0` > 255)) {
          result.push(/\d/, /\d/, '.');
        } else {
          result.push(/\d/, /\d/, /\d/, '.');
        }
      } else {
        result.push(...new Array(chunk.length).fill(/\d/), '.');
      }
    }

    result = result.slice(0, -1);
    return result;
  },
  pipe: (value: string): string | false => {
    if (value === '.' || value.endsWith('..')) return false;

    const parts = value.split('.');

    if (parts.length > 4 || parts.some((part) => part === '00' || Number(part) < 0 || Number(part) > 255)) {
      return false;
    }

    return value;
  },
};

export const maskIdVersionProps = {
  guide: true,
  mask: (value: string): (string | RegExp)[] => {
    let result: (string | RegExp)[] = [];
    const chunks = value.split('.');

    for (let i = 0; i < 2; ++i) {
      const chunk = (chunks[i] || '').replace(/_/gi, '');

      if (chunk === '') {
        result.push(/\d/, /\d/, /\d/, '.');
      } else if (+chunk === 0) {
        result.push(/\d/, '.');
      } else if (chunks.length < 2 || (chunk.length < 3 && chunks[i].indexOf('_') !== -1)) {
        if ((chunk.length < 2 && +`${chunk}00` > 255) || (chunk.length < 3 && +`${chunk}0` > 255)) {
          result.push(/\d/, /\d/, '.');
        } else {
          result.push(/\d/, /\d/, /\d/, '.');
        }
      } else {
        result.push(...new Array(chunk.length).fill(/\d/), '.');
      }
    }

    result = result.slice(0, -1);
    return result;
  },
  pipe: (value: string): string | false => {
    if (value === '.' || value.endsWith('..')) return false;

    const parts = value.split('.');

    if (parts.length !== 2 || parts.some((part) => part === '00' || Number(part) < 0 || Number(part) > 255)) {
      return false;
    }

    return value;
  },
};

export const maskIdAndLetterProps = {
  guide: true,
  mask: (value: string): (string | RegExp)[] => {
    let result: (string | RegExp)[] = [];
    const chunks = value.split('.');

    for (let i = 0; i < 4; ++i) {
      const chunk = (chunks[i] || '').replace(/_/gi, '');

      if (i >= 2) {
        if (chunk === 'x' || chunk === '') {
          result.push('x', '.');
        } else {
          result.push('.', /[X-Xx-x0-9]/);
        }
      } else {
        if (chunk === '') {
          result.push(/[X-Xx-x0-9]/, /[X-Xx-x0-9]/, /[X-Xx-x0-9]/, '.');
        } else if (+chunk === 0) {
          result.push(/[X-Xx-x0-9]/, '.');
        } else if (chunks.length < 4 || (chunk.length < 3 && chunks[i].indexOf('_') !== -1)) {
          if ((chunk.length < 2 && +`${chunk}00` > 255) || (chunk.length < 3 && +`${chunk}0` > 255)) {
            result.push(/[X-Xx-x0-9]/, /[X-Xx-x0-9]/, '.');
          } else {
            result.push(/[X-Xx-x0-9]/, /[X-Xx-x0-9]/, /[X-Xx-x0-9]/, '.');
          }
        } else {
          result.push(...new Array(chunk.length).fill(/[X-Xx-x0-9]/), '.');
        }
      }
    }

    result = result.slice(0, -1);
    return result;
  },
  pipe: (value: string): string | false => {
    if (value === '.' || value.endsWith('..')) return false;

    const parts = value.split('.');

    if (parts.length > 4 || parts.some((part) => part === '00' || Number(part) < 0 || Number(part) > 255)) {
      return false;
    }

    return value;
  },
};

export const maskId = {
  guide: true,
  mask: (value: string): (string | RegExp)[] => {
    let result: (string | RegExp)[] = [];
    const chunks = value.split('.');

    for (let i = 0; i < 4; ++i) {
      const chunk = (chunks[i] || '').replace(/_/gi, '');

      if (chunk === '') {
        result.push(/[X-Xx-x0-9]/, /[X-Xx-x0-9]/, /[X-Xx-x0-9]/, /[X-Xx-x0-9]/);
      } else if (+chunk === 0) {
        result.push(/[X-Xx-x0-9]/, /[X-Xx-x0-9]/, 'x', 'x');
      } else if (chunks.length < 4 || (chunk.length < 3 && chunks[i].indexOf('_') !== -1)) {
        if ((chunk.length < 2 && +`${chunk}00` > 255) || (chunk.length < 3 && +`${chunk}0` > 255)) {
          result.push(/[X-Xx-x0-9]/, /[X-Xx-x0-9]/, /[X-Xx-x0-9]/, 'x');
        } else {
          result.push(/[X-Xx-x0-9]/, /[X-Xx-x0-9]/, /[X-Xx-x0-9]/, /[X-Xx-x0-9]/);
        }
      } else {
        result.push(...new Array(chunk.length).fill(/[X-Xx-x0-9]/), 'x', 'x');
      }
    }

    result = result.slice(0, -2); // Remove the last two dots
    return result;
  },
  pipe: (value: string): string | false => {
    if (value === '.' || value.endsWith('..')) return false;

    const parts = value.split('.');

    if (parts.length > 4 || parts.some((part) => part === '00' || Number(part) < 0 || Number(part) > 255)) {
      return false;
    }

    return value;
  },
};

export const isValidIP = (ip: string): boolean => {
  const ipRegex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return ipRegex.test(ip);
};

export const onKeyDownOnlyNumber = (event: KeyboardEvent<HTMLInputElement>) =>
  !/[0-9]/.test(event.key) && event.key != 'Backspace' && event.key != 'Delete' && event.preventDefault();

export const isJM15 = (modelName: string | undefined | null) => modelName === 'J-M15';
export const isJR11 = (modelName: string | undefined | null) => modelName === 'J-R11';
export const isJR12 = (modelName: string | undefined | null) => modelName === 'J-R12';

export const formatarData = (data: Date) => `${moment(data).format('DD/MM/YYYY HH:mm')}h`;

export const formatUsername = (username: string) => {
  const nameSepareted = username.split('.');

  if (nameSepareted.length < 2) return username;

  const firstName = nameSepareted[0].charAt(0).toUpperCase() + nameSepareted[0].slice(1);
  const secondName = nameSepareted[1].charAt(0).toUpperCase() + nameSepareted[1].slice(1);

  return `${firstName} ${secondName}`;
};

export const limparCookie = () => {
  document.cookie = 'x-ms-routing-name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  Cookies.remove('x-ms-routing-name');
  document.cookie = '';
};
export const uniqueById = <T, K extends keyof T>(array: T[], key: K): T[] => [
  ...new Map(array.map((item) => [item[key], item])).values(),
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objectToFormData = (obj: any, formData: FormData = new FormData(), parentKey: string = ''): FormData => {
  if (obj && typeof obj === 'object' && !(obj instanceof Date) && !(obj instanceof File)) {
    if (Array.isArray(obj)) {
      obj.forEach((value, index) => {
        const key = parentKey ? `${parentKey}[${index}]` : `${index}`;
        objectToFormData(value, formData, key);
      });
    } else {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        const fullKey = parentKey ? `${parentKey}.${key}` : key;
        objectToFormData(value, formData, fullKey);
      });
    }
  } else {
    formData.append(parentKey, obj === null ? '' : obj);
  }

  return formData;
};
