import React, { useCallback, useEffect, useState } from 'react';
import { BaseForm } from '../../forms/BaseForm/BaseForm';
import { Col, Row } from 'antd';
import { BaseFormInputItem } from '../../forms/components/BaseFormInputItem/BaseFormInputItem';
import { Checkbox } from '../../Checkbox/Checkbox';
import { Select } from '../../selects/Select/Select';
import { notificationController } from '@app/controllers/notificationController';
import { Panel } from '../../Collapse/Collapse';
import { Button } from '../../buttons/Button/Button';
import * as S from './CAN.styles';
import ModalParametersCan from './Modal/ModalParametersCan';
import { PropertyModel } from '@app/domain/property/propertyModel';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import IVehicleService, { VehicleService } from '@app/services/vehicleService';
import { TipoVeiculoParametro, VehicleManufacturerModel, VehicleModel } from '@app/domain/vehicle/vehicleModel';

const vehicleService: IVehicleService = new VehicleService();

interface CanProps {
  properties: PropertyModel[];
  getPropertyValue: (idEmbarcado: DevicePropertyType) => string;
  handleChangeDeviceProfileProperties: (properties: DeviceProfileProperty[], toRemove?: boolean) => void;
}

export const CAN: React.FC<CanProps> = ({ properties, getPropertyValue, handleChangeDeviceProfileProperties }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [canEnabled, setCanEnabled] = useState(false);
  const [vehicles, setVehicles] = useState<VehicleModel[]>([]);
  const [manufacturers, setManufacturers] = useState<VehicleManufacturerModel[]>([]);
  const [vehicleSelected, setVehicleSelected] = useState<VehicleModel>();
  const [idManufacturerSelected, setIdManufacturerSelected] = useState<number>();
  const [parametersSelecteds, setParametersSelecteds] = useState<string[]>([]);

  const handleOnChangeEventCheckboxProperty = (e: CheckboxChangeEvent, sideProperty?: DevicePropertyType[]) => {
    const idEmbarcado = e.target.name;
    const propertyValue = e.target.checked ? '1' : '0';

    if (!idEmbarcado) return;

    let propsToUpdate: DeviceProfileProperty[] = [];

    properties.map((p) => {
      if (p.idEmbarcado == Number(idEmbarcado))
        propsToUpdate = propsToUpdate.concat({ ...p, valorPropriedade: propertyValue } as DeviceProfileProperty);
      if (sideProperty?.includes(p.idEmbarcado))
        propsToUpdate = propsToUpdate.concat({
          ...p,
          valorPropriedade: getDefaultPropertyValue(p.idEmbarcado),
        } as DeviceProfileProperty);
    });

    handleChangeDeviceProfileProperties(propsToUpdate);
  };
  const handleOnChangeCanEnabled = (e: CheckboxChangeEvent) => {
    handleOnChangeEventCheckboxProperty(e, [DevicePropertyType.IdVeiculoCan]);
    setCanEnabled(!canEnabled);

    if (canEnabled) {
      setIdManufacturerSelected(undefined);
      setVehicleSelected(undefined);
    }
  };
  const getDefaultPropertyValue = (idEmbarcado: DevicePropertyType) =>
    properties?.find((p) => p.idEmbarcado == idEmbarcado)?.valorDefault ?? '';

  const fetchManufactures = useCallback(() => {
    vehicleService
      .getManufactures()
      .then((response) => setManufacturers(response))
      .catch(() => notificationController.error({ message: 'Erro ao buscar os fabricantes' }));
  }, []);
  useEffect(() => {
    setVehicleSelected(undefined);

    if (idManufacturerSelected)
      vehicleService
        .getArray(`obter-por-id-fabricante/${idManufacturerSelected}`)
        .then((response) => setVehicles(response.filter((m) => m.status)))
        .catch(() => notificationController.error({ message: 'Erro ao buscar os veículo' }));
  }, [idManufacturerSelected]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchManufactures(), []);
  useEffect(() => {
    const canValueProp = getPropertyValue(DevicePropertyType.RedeCanHabilitada) === '1';
    setCanEnabled(canValueProp && !canEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setParametersSelecteds([]);

    const vehicleProp = properties.find((p) => p.idEmbarcado == DevicePropertyType.IdVeiculoCan);

    if (vehicleProp)
      handleChangeDeviceProfileProperties([
        {
          ...vehicleProp,
          valorPropriedade: vehicleSelected ? `${vehicleSelected.id}` : '',
        },
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleSelected]);
  useEffect(() => {
    const addCanProp = properties.find((p) => p.idEmbarcado == DevicePropertyType.AdicionarIdentifierCan);
    const addCanPropValue = { ...addCanProp } as DeviceProfileProperty;

    if (parametersSelecteds.length > 0)
      handleChangeDeviceProfileProperties(
        parametersSelecteds.map((nomeId) => ({
          ...addCanPropValue,
          valorPropriedade: nomeId,
        })),
      );
    else handleChangeDeviceProfileProperties([addCanPropValue], true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parametersSelecteds]);

  const renderParamsCalculated = (params: string[]) => {
    console.log(vehicleSelected?.parametros);

    const calculateds = vehicleSelected?.parametros?.filter(
      (p) => params.includes(`${p.idIdentifierCan}`) && p.tipo == TipoVeiculoParametro.Calculado,
    );
    return calculateds?.length && calculateds?.length > 0 ? (
      <>
        <S.Subtitle>Calculado</S.Subtitle>
        <Row style={{ paddingTop: '1rem' }}>
          <S.ParametersQuantity>{parametersSelecteds?.length}</S.ParametersQuantity>&nbsp;
          <S.ParametersQuantityDescription>
            {calculateds?.length > 1 ? 'Parâmetros' : 'Parâmetro'}
          </S.ParametersQuantityDescription>
        </Row>
        <Row gutter={[16, 16]} style={{ paddingBottom: '1rem' }}>
          {calculateds.map((parameter) => (
            <Col key={parameter.idIdentifierCan} xs={24} md={4} lg={4}>
              <S.CanVehiclesParameterName>{parameter.nome}</S.CanVehiclesParameterName>
            </Col>
          ))}
        </Row>
      </>
    ) : (
      <></>
    );
  };
  const renderParamsOnOff = (params: string[]) => {
    const onOffs = vehicleSelected?.parametros?.filter(
      (p) => params.includes(`${p.idIdentifierCan}`) && p.tipo == TipoVeiculoParametro.LigadoDesligado,
    );
    return onOffs?.length && onOffs.length > 0 ? (
      <>
        <S.Subtitle>On/Off</S.Subtitle>
        <Row style={{ paddingTop: '1rem' }}>
          <S.ParametersQuantity>{onOffs?.length}</S.ParametersQuantity>&nbsp;
          <S.ParametersQuantityDescription>
            {onOffs?.length > 1 ? 'Parâmetros' : 'Parâmetro'}
          </S.ParametersQuantityDescription>
        </Row>
        <Row gutter={[16, 16]}>
          {onOffs.map((parameter) => (
            <Col key={parameter.idIdentifierCan} xs={24} md={4} lg={4}>
              <S.CanVehiclesParameterName>{parameter.nome}</S.CanVehiclesParameterName>
            </Col>
          ))}
        </Row>
      </>
    ) : (
      <></>
    );
  };

  return (
    <>
      <ModalParametersCan
        visible={modalVisible}
        setVisible={setModalVisible}
        parameters={vehicleSelected?.parametros ?? []}
        parametersSelecteds={parametersSelecteds}
        setParametersSelecteds={setParametersSelecteds}
      />
      <BaseForm layout="vertical">
        <Row>
          <BaseFormInputItem>
            <Checkbox
              checked={canEnabled}
              name={`${DevicePropertyType.RedeCanHabilitada}`}
              onChange={(e) => handleOnChangeCanEnabled(e)}
            >
              Habilitar CAN
            </Checkbox>
          </BaseFormInputItem>
        </Row>
        <Row gutter={18}>
          <Col xs={24} md={5}>
            <BaseFormInputItem label="Fabricante">
              <Select
                showArrow
                showSearch
                disabled={getPropertyValue(DevicePropertyType.RedeCanHabilitada) !== '1'}
                value={idManufacturerSelected}
                placeholder="Selecione o fabricante"
                onChange={(value) => setIdManufacturerSelected(value as number)}
                options={manufacturers.map((m) => ({ label: m.nome, value: m.id }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
              ></Select>
            </BaseFormInputItem>
          </Col>
          <Col xs={24} md={5}>
            <BaseFormInputItem label="Veículo" errorText="Campo obrigatório">
              <Select
                showArrow
                showSearch
                placeholder="Selecione o veículo"
                disabled={!idManufacturerSelected}
                value={vehicleSelected?.id}
                onChange={(value) => setVehicleSelected(vehicles.find((v) => v.id == Number(value)))}
                options={vehicles.map((m) => ({ label: `${m.nome} | ${m.ano}`, value: m.id }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
              ></Select>
            </BaseFormInputItem>
          </Col>
        </Row>
        {vehicleSelected && (
          <Row gutter={[18, 18]}>
            <Col xs={24}>
              <S.Collapse>
                <Panel
                  key="1"
                  header={
                    <Row align={'middle'} justify={'space-between'}>
                      <Col>
                        <S.CollapseHeaderName>Parâmetros da CAN</S.CollapseHeaderName>
                      </Col>
                      <Button type="ghost" onClick={() => setModalVisible(true)}>
                        Selecionar parâmetros
                      </Button>
                    </Row>
                  }
                >
                  {renderParamsCalculated(parametersSelecteds)}
                  {renderParamsOnOff(parametersSelecteds)}
                </Panel>
              </S.Collapse>
            </Col>
          </Row>
        )}
      </BaseForm>
    </>
  );
};
