import React, { FC } from 'react';
import { Collapse } from 'antd';
const { Panel } = Collapse;
import * as S from '../styles';
import { DeviceProfileProperty, ModuleProperties } from '@app/domain/deviceProfile/deviceProfileModel';
import Rede from '@app/components/common/ModulesProfileConfig/Rede/Rede';
import PerfilEnvio from '@app/components/common/ModulesProfileConfig/PerfilEnvio/PerfilEnvio';
import Eventos from '@app/components/common/ModulesProfileConfig/Eventos/Eventos';
import Entrada from '@app/components/common/ModulesProfileConfig/Entrada/Entrada';
import IdentificadorMotorista from '@app/components/common/ModulesProfileConfig/IdentificadorMotorista/IdentificadorMotorista';
import { CAN } from '@app/components/common/ModulesProfileConfig/CAN/CAN';
import { Cerca } from '@app/components/common/ModulesProfileConfig/Cerca/Cerca';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { ProtocolCommunication } from '@app/domain/protocolCommunication/protocolCommunicationModel';

interface ModuleComponentProps {
  idModel?: number;
  module: ModuleProperties;
  getPropertyValue: (idEmbarcado: DevicePropertyType) => string;
  handleChangeDeviceProfileProperties: (properties: DeviceProfileProperty[], toRemove?: boolean) => void;
  protocols?: ProtocolCommunication[];
}

const ModuleComponent: FC<ModuleComponentProps> = ({
  idModel,
  module,
  getPropertyValue,
  handleChangeDeviceProfileProperties,
  protocols,
}) => {
  const componentsMap: { [key: string]: React.ElementType } = {
    Rede,
    Entrada,
    PerfilEnvio,
    Evento: Eventos,
    IdentificadorMotorista,
    CAN,
    Cercas: Cerca,
  };

  const renderModuleContent = (name: string) => {
    const Component = componentsMap[name];

    if (Component) {
      return (
        <Component
          idModel={idModel}
          protocols={protocols}
          properties={module.propriedades}
          getPropertyValue={getPropertyValue}
          handleChangeDeviceProfileProperties={handleChangeDeviceProfileProperties}
        />
      );
    }

    return <></>;
  };

  const renderModuleTitle = (name: string) => {
    switch (name) {
      case 'PerfilEnvio':
        return 'Perfil de envio';
      case 'CAN':
        return 'Veículos CAN';
      case 'IdentificadorMotorista':
        return 'Configurações';
      default:
        return name;
    }
  };

  return (
    <S.CollapseContainer>
      <S.Collapse defaultActiveKey={module.id} bordered={false} style={{ paddingLeft: '-16px' }}>
        <Panel header={renderModuleTitle(module.nome)} key={module.id} style={{ paddingLeft: '-16px' }}>
          {renderModuleContent(module.nome)}
        </Panel>
      </S.Collapse>
    </S.CollapseContainer>
  );
};

export default ModuleComponent;
