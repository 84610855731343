import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { isValidIP, maskIdProps } from '@app/utils/utils';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import * as S from '../ModulesProfileConfig.styles';
import ShouldRenderInput from '@app/components/common/ModulesProfileConfig/ShouldRenderInput';
import { RadioGroup, Radio } from '@app/components/common/Radio/Radio';
import { Form, Space } from 'antd';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { PropertyModel } from '@app/domain/property/propertyModel';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';

type ServerType = 'IP' | 'DNS';

interface RedeProps {
  properties: PropertyModel[];
  getPropertyValue: (idEmbarcado: DevicePropertyType) => string;
  handleChangeDeviceProfileProperties: (properties: DeviceProfileProperty[]) => void;
}

const Rede: React.FC<RedeProps> = ({ properties, getPropertyValue, handleChangeDeviceProfileProperties }) => {
  const [primaryServer, setPrimaryServer] = useState<ServerType>('IP');
  const [secondaryServer, setSecondaryServer] = useState<ServerType>('DNS');
  const [existsSecondaryServer, setExistsSecondaryServer] = useState<boolean>(false);

  const getDefaultPropertyValue = (idEmbarcado: DevicePropertyType) =>
    properties?.find((p) => p.idEmbarcado == idEmbarcado)?.valorDefault ?? '';
  const handleOnBlurEventValidateNumber = (e: React.FocusEvent<HTMLInputElement>) => {
    const idEmbarcado = Number(e.target.name);

    if (!idEmbarcado) return;

    let propertyValue = Number(e.target.value);
    const min = e.target.min ? Number(e.target.min) : undefined;
    const max = e.target.max ? Number(e.target.max) : undefined;

    if (min !== undefined && propertyValue < min) propertyValue = min;
    if (max !== undefined && propertyValue > max) propertyValue = max;

    handleChangePropertyValue(idEmbarcado, `${propertyValue}`);
  };
  const handleOnChangeEventPropertyValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const idEmbarcado = Number(e.target.name);
    const propertyValue = e.target.value;

    if (!idEmbarcado) return;

    if (e.target.type === 'number') {
      const max = e.target.max ? Number(e.target.max) : undefined;
      const value = Number(propertyValue);

      if (max !== undefined && value > max) return;
    }

    handleChangePropertyValue(idEmbarcado, propertyValue);
  };
  const handleChangePropertyValue = (idEmbarcado: DevicePropertyType, value: string) => {
    const prop = {
      ...properties?.find((p) => p.idEmbarcado == idEmbarcado),
      valorPropriedade: value,
    } as DeviceProfileProperty;
    handleChangeDeviceProfileProperties([prop]);
  };
  const handleOnChangePrimaryServer = () => {
    if (primaryServer == 'IP') {
      setPrimaryServer('DNS');
      handleChangePropertyValue(DevicePropertyType.IpServidor, '');
    } else {
      setPrimaryServer('IP');
      handleChangePropertyValue(DevicePropertyType.Dns, '');
    }
  };
  const handleOnChangeSecondaryServer = () => {
    if (secondaryServer == 'IP') {
      setSecondaryServer('DNS');
      handleChangePropertyValue(DevicePropertyType.IpServidorSecundario, '');
    } else {
      setSecondaryServer('IP');
      handleChangePropertyValue(DevicePropertyType.DnsSecundario, '');
    }
  };
  const handleOnChangeExistsSecondaryServer = () => {
    setExistsSecondaryServer(!existsSecondaryServer);
    if (!existsSecondaryServer) {
      handleChangePropertyValue(
        DevicePropertyType.IpServidorSecundario,
        getDefaultPropertyValue(DevicePropertyType.IpServidorSecundario),
      );
      handleChangePropertyValue(
        DevicePropertyType.DnsSecundario,
        getDefaultPropertyValue(DevicePropertyType.DnsSecundario),
      );
      handleChangePropertyValue(
        DevicePropertyType.PortaServidorSecundario,
        getDefaultPropertyValue(DevicePropertyType.PortaServidorSecundario),
      );
      handleChangePropertyValue(
        DevicePropertyType.TipoProtocoloSecundario,
        getDefaultPropertyValue(DevicePropertyType.TipoProtocoloSecundario),
      );
    } else {
      handleChangePropertyValue(DevicePropertyType.IpServidorSecundario, '');
      handleChangePropertyValue(DevicePropertyType.DnsSecundario, '');
      handleChangePropertyValue(DevicePropertyType.PortaServidorSecundario, '');
      handleChangePropertyValue(DevicePropertyType.TipoProtocoloSecundario, '');
    }
  };

  return (
    <Form layout="vertical">
      <S.Row>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.Apn} properties={properties}>
          <BaseFormInputItem label="APN" style={{ minWidth: '400px', width: 'fit-content' }}>
            <MaskedInput
              key={DevicePropertyType.Apn}
              className="ant-input"
              mask={() => Array(24).fill(/[a-zA-Z0-9-_.:/?&=]/)}
              guide={false}
              placeholder="Digite o APN"
              maxLength={50}
              value={getPropertyValue(DevicePropertyType.Apn)}
              onChange={(e) => handleChangePropertyValue(DevicePropertyType.Apn, e.target.value)}
            />
          </BaseFormInputItem>
        </ShouldRenderInput>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.UsuarioApn} properties={properties}>
          <BaseFormInputItem label="Usuário APN" style={{ minWidth: '400px', width: 'fit-content' }}>
            <>
              <Input
                key={8}
                type="text"
                className="ant-input"
                placeholder="Informe o usuário"
                maxLength={50}
                value={getPropertyValue(DevicePropertyType.UsuarioApn)}
                onChange={(e) => handleChangePropertyValue(DevicePropertyType.UsuarioApn, e.target.value)}
              />
              <S.InputObs>Opcional</S.InputObs>
            </>
          </BaseFormInputItem>
        </ShouldRenderInput>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.SenhaApn} properties={properties}>
          <BaseFormInputItem label="Senha APN" style={{ minWidth: '400px', width: 'fit-content' }}>
            <>
              <Input
                key={9}
                type="text"
                className="ant-input"
                placeholder="Informe a senha"
                maxLength={50}
                value={getPropertyValue(DevicePropertyType.SenhaApn)}
                onChange={(e) => handleChangePropertyValue(DevicePropertyType.SenhaApn, e.target.value)}
              />
              <S.InputObs>Opcional</S.InputObs>
            </>
          </BaseFormInputItem>
        </ShouldRenderInput>
      </S.Row>
      <S.Row>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.IpServidor} properties={properties}>
          <BaseFormInputItem label="Servidor primário" style={{ width: '10rem' }}>
            <RadioGroup value={primaryServer} onChange={() => handleOnChangePrimaryServer()}>
              <Space direction="vertical">
                <Radio value={'IP'}>IP</Radio>
                <Radio value={'DNS'}>DNS</Radio>
              </Space>
            </RadioGroup>
          </BaseFormInputItem>
        </ShouldRenderInput>
        {
          {
            IP: (
              <ShouldRenderInput idEmbarcado={DevicePropertyType.IpServidor} properties={properties}>
                <BaseFormInputItem label="IP do servidor primário" style={{ minWidth: '350px' }}>
                  <MaskedInput
                    key={13}
                    {...maskIdProps}
                    className="ant-input"
                    placeholder="Informe o IP do servidor primário"
                    value={getPropertyValue(DevicePropertyType.IpServidor)}
                    onChange={(e) => handleChangePropertyValue(DevicePropertyType.IpServidor, e.target.value)}
                    onBlur={(e) => {
                      let value = e.target.value;
                      value = value.replace(/_/g, '');
                      handleChangePropertyValue(
                        DevicePropertyType.IpServidor,
                        isValidIP(value) ? value : getDefaultPropertyValue(DevicePropertyType.IpServidor),
                      );
                    }}
                  />
                </BaseFormInputItem>
              </ShouldRenderInput>
            ),
            DNS: (
              <ShouldRenderInput idEmbarcado={DevicePropertyType.Dns} properties={properties}>
                <BaseFormInputItem label="DNS do servidor primário" style={{ minWidth: '350px' }}>
                  <Input
                    key={43}
                    {...maskIdProps}
                    className="ant-input"
                    placeholder="Informe o DNS do servidor primário"
                    value={getPropertyValue(DevicePropertyType.Dns)}
                    onChange={(e) => handleChangePropertyValue(DevicePropertyType.Dns, e.target.value)}
                    onBlur={(e) =>
                      handleChangePropertyValue(DevicePropertyType.Dns, e.target.value.replaceAll('_', ''))
                    }
                  />
                </BaseFormInputItem>
              </ShouldRenderInput>
            ),
          }[primaryServer]
        }
        <ShouldRenderInput idEmbarcado={DevicePropertyType.PortaServidor} properties={properties}>
          <BaseFormInputItem label="Porta do servidor primário" style={{ minWidth: '350px' }}>
            <>
              <Input
                key={12}
                type="number"
                min={0}
                max={65535}
                className="ant-input"
                placeholder="Informe a porta do servidor primário"
                value={getPropertyValue(DevicePropertyType.PortaServidor)}
                name={`${DevicePropertyType.PortaServidor}`}
                onChange={handleOnChangeEventPropertyValue}
                onBlur={handleOnBlurEventValidateNumber}
              />
            </>
          </BaseFormInputItem>
        </ShouldRenderInput>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.TipoProtocolo} properties={properties}>
          <BaseFormInputItem label="Tipo servidor primário">
            <RadioGroup
              key={11}
              value={getPropertyValue(DevicePropertyType.TipoProtocolo)}
              onChange={(e) => handleChangePropertyValue(DevicePropertyType.TipoProtocolo, e.target.value)}
            >
              <Space direction="vertical">
                <Radio value={'1'}>TCP</Radio>
                <Radio value={'2'}>UDP</Radio>
              </Space>
            </RadioGroup>
          </BaseFormInputItem>
        </ShouldRenderInput>
      </S.Row>
      <S.Row>
        <ShouldRenderInput idEmbarcado={DevicePropertyType.IpServidorSecundario} properties={properties}>
          <BaseFormInputItem style={{ minWidth: '400px', width: 'fit-content' }}>
            <Checkbox
              value={existsSecondaryServer}
              checked={existsSecondaryServer}
              onChange={() => handleOnChangeExistsSecondaryServer()}
            >
              <S.CheckboxLabel>Servidor secundário</S.CheckboxLabel>
            </Checkbox>
          </BaseFormInputItem>
        </ShouldRenderInput>
      </S.Row>
      {existsSecondaryServer && (
        <S.Row>
          <BaseFormInputItem label="Servidor secundário" style={{ width: '10rem' }}>
            <RadioGroup value={secondaryServer} onChange={() => handleOnChangeSecondaryServer()}>
              <Space direction="vertical">
                <Radio value={'IP'}>IP</Radio>
                <Radio value={'DNS'}>DNS</Radio>
              </Space>
            </RadioGroup>
          </BaseFormInputItem>
          {
            {
              IP: (
                <ShouldRenderInput idEmbarcado={DevicePropertyType.IpServidorSecundario} properties={properties}>
                  <BaseFormInputItem label="IP do servidor secundário" style={{ minWidth: '350px' }}>
                    <MaskedInput
                      key={44}
                      {...maskIdProps}
                      className="ant-input"
                      placeholder="Informe o IP do servidor secundário"
                      value={getPropertyValue(DevicePropertyType.IpServidorSecundario)}
                      onChange={(e) =>
                        handleChangePropertyValue(DevicePropertyType.IpServidorSecundario, e.target.value)
                      }
                      onBlur={(e) => {
                        const value = e.target.value;
                        handleChangePropertyValue(
                          DevicePropertyType.IpServidorSecundario,
                          isValidIP(value) ? value : getDefaultPropertyValue(DevicePropertyType.IpServidorSecundario),
                        );
                      }}
                    />
                  </BaseFormInputItem>
                </ShouldRenderInput>
              ),
              DNS: (
                <ShouldRenderInput idEmbarcado={DevicePropertyType.DnsSecundario} properties={properties}>
                  <BaseFormInputItem label="DNS do servidor secundário" style={{ minWidth: '350px' }}>
                    <MaskedInput
                      key={45}
                      {...maskIdProps}
                      className="ant-input"
                      placeholder="Informe o DNS do servidor secundário"
                      value={getPropertyValue(DevicePropertyType.DnsSecundario)}
                      onChange={(e) => handleChangePropertyValue(DevicePropertyType.DnsSecundario, e.target.value)}
                      onBlur={(e) =>
                        handleChangePropertyValue(DevicePropertyType.DnsSecundario, e.target.value.replaceAll('_', ''))
                      }
                    />
                  </BaseFormInputItem>
                </ShouldRenderInput>
              ),
            }[secondaryServer]
          }
          <ShouldRenderInput idEmbarcado={DevicePropertyType.PortaServidorSecundario} properties={properties}>
            <BaseFormInputItem label="Porta do servidor secundário" style={{ minWidth: '350px' }}>
              <>
                <Input
                  key={46}
                  type="number"
                  className="ant-input"
                  placeholder="Informe a porta do servidor secundário"
                  value={getPropertyValue(DevicePropertyType.PortaServidorSecundario)}
                  min={0}
                  max={65535}
                  name={`${DevicePropertyType.PortaServidorSecundario}`}
                  onChange={handleOnChangeEventPropertyValue}
                  onBlur={handleOnBlurEventValidateNumber}
                />
                <S.InputObs>0 a 65535</S.InputObs>
              </>
            </BaseFormInputItem>
          </ShouldRenderInput>
          <ShouldRenderInput idEmbarcado={DevicePropertyType.TipoProtocoloSecundario} properties={properties}>
            <BaseFormInputItem label="Tipo servidor secundário">
              <RadioGroup
                key={47}
                value={getPropertyValue(DevicePropertyType.TipoProtocoloSecundario)}
                onChange={(e) => handleChangePropertyValue(DevicePropertyType.TipoProtocoloSecundario, e.target.value)}
              >
                <Space direction="vertical">
                  <Radio value={'1'}>TCP</Radio>
                  <Radio value={'2'}>UDP</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </ShouldRenderInput>
        </S.Row>
      )}
    </Form>
  );
};

export default Rede;
