import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { DeviceProfileProperty, ModuleProperties } from '@app/domain/deviceProfile/deviceProfileModel';

const propsToNotInsertDefaultValue = [
  DevicePropertyType.LimiteAngulo,
  DevicePropertyType.LimiteDistancia,
  DevicePropertyType.LimiteVelocidade,
  DevicePropertyType.LimiteEntradaAnalogica,
  DevicePropertyType.SensibilidadeDeteccaoFrenagemBrusca,
  DevicePropertyType.SensibilidadeDeteccaoAceleracaoBrusca,
  DevicePropertyType.SensibilidadeDeteccaoCurvaAcentuada,
  DevicePropertyType.LimiteBateriaBaixa,
  DevicePropertyType.LimiteRpmFaixaVermelha,
  DevicePropertyType.LimiteRpmFaixaAmarela,
];

export const formatPropertiesValues = (modules?: ModuleProperties[]): DeviceProfileProperty[] => {
  const propsDefault: DeviceProfileProperty[] = [];

  modules?.map((m) =>
    m.propriedades.map((p) =>
      propsDefault.push({
        ...p,
        valorPropriedade: !propsToNotInsertDefaultValue.includes(p.idEmbarcado) ? p.valorDefault : '',
      }),
    ),
  );

  return propsDefault;
};

export const deviceValidateProperties = (properties: DeviceProfileProperty[]): boolean => {
  return deviceValidateRede(properties) && deviceValidateCAN(properties);
};

const getPropertyByIdEmbarcado = (
  properties: DeviceProfileProperty[],
  idEmbarcado: DevicePropertyType,
): DeviceProfileProperty | undefined => {
  return properties?.find((p) => p.idEmbarcado == idEmbarcado);
};

const validNotAllowNullProperty = (property?: DeviceProfileProperty): boolean =>
  !property || !!property.valorPropriedade;

export const deviceValidateRede = (properties: DeviceProfileProperty[]): boolean => {
  const apn = getPropertyByIdEmbarcado(properties, DevicePropertyType.Apn);
  const ipServidor = getPropertyByIdEmbarcado(properties, DevicePropertyType.IpServidor);
  const dnsServidor = getPropertyByIdEmbarcado(properties, DevicePropertyType.Dns);
  const portaServidor = getPropertyByIdEmbarcado(properties, DevicePropertyType.PortaServidor);

  return (
    validNotAllowNullProperty(apn) &&
    validNotAllowNullProperty(portaServidor) &&
    (validNotAllowNullProperty(ipServidor) || validNotAllowNullProperty(dnsServidor))
  );
};

export const deviceValidateCAN = (properties: DeviceProfileProperty[]): boolean => {
  const can = getPropertyByIdEmbarcado(properties, DevicePropertyType.RedeCanHabilitada);

  return can?.valorPropriedade === '1'
    ? !!getPropertyByIdEmbarcado(properties, DevicePropertyType.IdVeiculoCan)?.valorPropriedade
    : true;
};
