import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from '@app/components/common/Modal/Modal';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { Col, Row, Space } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setFooter } from '@app/store/slices/footerSlice';
import { notificationController } from '@app/controllers/notificationController';
import { ModelDeviceModel } from '@app/domain/modelDevice/modelDeviceModel';
import { EquipmentModel } from '@app/domain/equipment/equipmentModel';
import IEquipmentService, { EquipmentService } from '@app/services/equipmentService';
import IModelDeviceService, { ModelDeviceService } from '@app/services/modelDeviceService';
import IDeviceProfileService, { DeviceProfileService } from '@app/services/deviceProfileService';
import {
  DeviceProfileModel,
  DeviceProfileProperty,
  VersionProperties,
} from '@app/domain/deviceProfile/deviceProfileModel';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Description } from '@app/components/common/Description/Description';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '@app/components/common/selects/Select/Select';
import { Input } from '@app/components/common/inputs/Input/Input';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import ModuleComponent from '@app/pages/device-profile/components/ModuleComponent';
import { FirmwareGroupModel } from '@app/domain/firmwareGroup/firmwareGroupModel';
import IFirmwareGroupService, { FirmwareGroupService } from '@app/services/firmwareGroupService';
import { readUser } from '@app/services/localStorage.service';
import { UserType } from '@app/constants/enums/userType';
import { ClientModel } from '@app/domain/client/clientModel';
import IClientService, { ClientService } from '@app/services/clientService';
import { SendParameter, setParameters } from '@app/store/slices/sendParametersSlice';
import { DividerLine } from '@app/components/common/divider/DividerLine.styles';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { deviceValidateProperties, formatPropertiesValues } from '@app/utils/device';
import { ProtocolCommunication } from '@app/domain/protocolCommunication/protocolCommunicationModel';

const clientService: IClientService = new ClientService();
const equipmentTypeService: IEquipmentService = new EquipmentService();
const modelDeviceService: IModelDeviceService = new ModelDeviceService();
const firmwareGroupService: IFirmwareGroupService = new FirmwareGroupService();
const deviceProfileService: IDeviceProfileService = new DeviceProfileService();

export const DeviceProfileCreate: React.FC = () => {
  const user = readUser();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [isFactory, setIsFactory] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);
  const [modalConfirmSave, setModalConfirmSave] = useState(false);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [models, setModels] = useState<ModelDeviceModel[]>([]);
  const [equipments, setEquipments] = useState<EquipmentModel[]>([]);
  const [groupFirmwares, setGroupFirmwares] = useState<FirmwareGroupModel[]>([]);
  const [clientsLoading, setClientsLoading] = useState<boolean>(false);
  const [modelsLoading, setModelsLoading] = useState<boolean>(false);
  const [equipmentsLoading, setEquipmentsLoading] = useState<boolean>(false);
  const [groupFirmwaresLoading, setGroupFirmwaresLoading] = useState<boolean>(false);
  const [deviceProfile, setDeviceProfile] = useState<DeviceProfileModel>({ ativo: true });
  const [versionProperties, setVersionProperties] = useState<VersionProperties>();
  const [protocolsCommunication, setProtocolsCommunication] = useState<ProtocolCommunication[]>([]);

  //handlers
  const handleBackClick = () => setModalCancel(true);
  const handleChangeDeviceProfileProperties = (properties: DeviceProfileProperty[], toRemove?: boolean) => {
    if (toRemove) {
      setDeviceProfile((prev) => ({
        ...prev,
        propriedades: prev.propriedades?.filter((dp) => !properties.find((p) => p.idPropriedade == dp.idPropriedade)),
      }));
    }

    setDeviceProfile((prev) => ({
      ...prev,
      propriedades: prev.propriedades
        ?.filter((dp) => !properties.find((p) => p.idPropriedade == dp.idPropriedade))
        .concat(properties),
    }));
  };
  const getPropertyValue = (idEmbarcado: DevicePropertyType) =>
    deviceProfile.propriedades?.find((p) => p.idEmbarcado == idEmbarcado)?.valorPropriedade ?? '';

  const handleSaveDeviceProfile = useCallback(async () => {
    setLoading(true);
    deviceProfileService
      .post('', {
        ...deviceProfile,
        idCliente: isFactory ? deviceProfile.idCliente : user?.idCliente ?? 1,
      })
      .then(() => {
        notificationController.success({
          message: 'Sucesso',
          description: 'Perfil de configuração cadastrado com sucesso',
        });
        navigate(`/perfil-configuracao`);
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => {
        setLoading(false);
        setModalConfirmSave(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceProfile]);

  const buttonSaveDisabled = useMemo(() => {
    return !deviceProfile.nome || !deviceValidateProperties(deviceProfile.propriedades ?? []);
  }, [deviceProfile]);

  //fetchs
  const fetchClients = async () => {
    setClientsLoading(true);
    clientService
      .getArray('')
      .then((res) => {
        setClients(res);
        setClientsLoading(false);
      })
      .catch(() => {
        notificationController.error({ message: 'Erro ao buscar a lista de clientes' });
        setClientsLoading(false);
      });
  };
  useEffect(() => {
    if (deviceProfile.idCliente) {
      setEquipmentsLoading(true);
      equipmentTypeService
        .getArray('')
        .then((res) => {
          setEquipments(res);
          setEquipmentsLoading(false);
        })
        .catch(() => {
          notificationController.error({ message: 'Erro ao buscar a lista de equipamentos' });
          setEquipmentsLoading(false);
        });
    }
  }, [deviceProfile.idCliente]);
  useEffect(() => {
    if (deviceProfile.idEquipamento) {
      setModelsLoading(true);
      modelDeviceService
        .getArray(`obter-por-id-equipamento/${deviceProfile.idEquipamento}`)
        .then((res) => {
          setModels(res);
          setModelsLoading(false);
        })
        .catch(() => {
          notificationController.error({ message: 'Erro ao buscar a lista de modelos' });
          setModelsLoading(false);
        });
    } else {
      setModels([]);
    }
  }, [deviceProfile.idEquipamento]);
  useEffect(() => {
    if (deviceProfile.idModelo) {
      setGroupFirmwaresLoading(true);
      firmwareGroupService
        .getArray(`obter-todos-por-id-modelo/${deviceProfile.idModelo}`)
        .then((res) => {
          setGroupFirmwares(res);
          setGroupFirmwaresLoading(false);
        })
        .catch(() => {
          notificationController.error({ message: 'Erro ao buscar a lista de versões' });
          setGroupFirmwaresLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceProfile.idModelo]);
  useEffect(() => {
    if (!deviceProfile.idGrupoFirmware) {
      setDeviceProfile((prev) => ({ ...prev, propriedades: [] }));
      setVersionProperties(undefined);
      return;
    }

    setLoading(true);

    firmwareGroupService
      .getPropertiesByIdGroupFirmware(deviceProfile.idGrupoFirmware)
      .then((res) => {
        setVersionProperties(res);
        setDeviceProfile((prev) => ({ ...prev, propriedades: formatPropertiesValues(res.modulos) }));
      })
      .catch((error) => notificationController.error(error))
      .finally(() => setLoading(false));

    firmwareGroupService
      .getArray(`obter-propriedades-perfil-envio/${deviceProfile.idGrupoFirmware}`)
      .then((res) => {
        dispatch(setParameters(res as unknown as SendParameter[]));
      })
      .catch((error) => notificationController.error(error));

    firmwareGroupService
      .getProtocolsCommunicationByIdGroupFirmware(deviceProfile.idGrupoFirmware)
      .then((res) => {
        setProtocolsCommunication(res);
      })
      .catch((error) => notificationController.error(error));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceProfile.idGrupoFirmware]);

  //renders
  useEffect(() => {
    dispatch(setHeaderRegister({ title: 'Novo perfil de configuração', handleBackClick: handleBackClick }));

    const userFactory = user?.type === UserType.Factory || user?.type === UserType.FactoryAdmin;
    userFactory ? fetchClients() : setDeviceProfile({ ...deviceProfile, idCliente: user?.idCliente });
    setIsFactory(userFactory);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(
      setFooter({
        confirmButtonDisabled: buttonSaveDisabled,
        confirmButtonText: 'Salvar',
        handleCancelButtonClick: () => setModalCancel(true),
        handleConfirmButtonClick: () => setModalConfirmSave(true),
        cancelButtonText: 'Cancelar',
      }),
    );
  }, [buttonSaveDisabled, dispatch]);

  return (
    <>
      <Modal
        title="Cancelar agendamento"
        open={modalCancel}
        onOk={() => navigate(`/perfil-configuracao`)}
        onCancel={() => setModalCancel(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            <span>
              Deseja realmente <strong> cancelar o cadastro?</strong>
            </span>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Confirmar cadastro"
        open={modalConfirmSave}
        onOk={() => handleSaveDeviceProfile()}
        onCancel={() => setModalConfirmSave(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <span>
              Deseja realmente <strong>confirmar o cadastro</strong> do perfil?
            </span>
          </Col>
        </Row>
      </Modal>
      <Spinner spinning={loading}></Spinner>
      <PageContainer>
        <Description title="Informações do perfil" subtitle="Preencha os campos abaixo para cadastrar um novo perfil" />
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Row gutter={18}>
            {isFactory && (
              <Col xl={6} md={12} sm={24}>
                <BaseFormInputItem label="Cliente">
                  <Select
                    showArrow
                    showSearch
                    loading={clientsLoading}
                    placeholder="Selecione o cliente"
                    value={deviceProfile.idCliente}
                    onChange={(value) =>
                      setDeviceProfile({
                        ...deviceProfile,
                        idCliente: value as number,
                        idEquipamento: undefined,
                        idModelo: undefined,
                        idGrupoFirmware: undefined,
                        propriedades: [],
                      })
                    }
                    options={clients.map((c) => ({
                      value: c.id,
                      label: c.razaoSocial,
                    }))}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </BaseFormInputItem>
              </Col>
            )}
            <Col xl={6} md={12} sm={24}>
              <BaseFormInputItem label="Equipamento">
                <Select
                  showArrow
                  showSearch
                  loading={equipmentsLoading}
                  placeholder="Selecione o equipamento"
                  value={deviceProfile.idEquipamento}
                  disabled={!deviceProfile.idCliente}
                  onChange={(value) =>
                    setDeviceProfile({
                      ...deviceProfile,
                      idEquipamento: value as number,
                      idModelo: undefined,
                      idGrupoFirmware: undefined,
                      propriedades: [],
                    })
                  }
                  options={equipments.map((c) => ({ value: c.id, label: c.nome }))}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </BaseFormInputItem>
            </Col>
            <Col xl={6} md={12} sm={24}>
              <BaseFormInputItem label="Modelo">
                <Select
                  showArrow
                  showSearch
                  loading={modelsLoading}
                  placeholder="Selecione o modelo"
                  value={deviceProfile.idModelo}
                  disabled={!deviceProfile.idEquipamento}
                  onChange={(value) =>
                    setDeviceProfile({
                      ...deviceProfile,
                      idModelo: value as number,
                      idGrupoFirmware: undefined,
                      propriedades: [],
                    })
                  }
                  options={models.map((model) => ({
                    value: model.id,
                    label: model.nome,
                  }))}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </BaseFormInputItem>
            </Col>
            <Col xl={6} md={12} sm={24}>
              <BaseFormInputItem label="Grupo de firmware">
                <Select
                  showArrow
                  showSearch
                  loading={groupFirmwaresLoading}
                  placeholder="Selecione o grupo de firmware"
                  value={deviceProfile.idGrupoFirmware}
                  disabled={!deviceProfile.idModelo}
                  style={{ width: '100%' }}
                  onChange={(value) =>
                    setDeviceProfile({
                      ...deviceProfile,
                      idGrupoFirmware: value as number,
                      propriedades: [],
                    })
                  }
                  options={groupFirmwares?.map((c) => ({
                    value: c.id,
                    label: c.nome,
                  }))}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </BaseFormInputItem>
            </Col>
            <Col xl={6} md={12} sm={24}>
              <BaseFormInputItem label="Nome do perfil">
                <Input
                  placeholder="Informe o nome do perfil"
                  value={deviceProfile.nome}
                  onChange={(e) => setDeviceProfile({ ...deviceProfile, nome: e.target.value })}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row>
            <BaseFormInputItem label="Status do perfil">
              <RadioGroup
                value={deviceProfile.ativo}
                onChange={(e) => setDeviceProfile({ ...deviceProfile, ativo: e.target.value })}
              >
                <Space size={8} direction="vertical">
                  <Radio value={true} checked={deviceProfile.ativo}>
                    Ativo
                  </Radio>
                  <Radio value={false} checked={!deviceProfile.ativo}>
                    Inativo
                  </Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Row>
          {versionProperties?.modulos.map((module, index) => (
            <>
              <ModuleComponent
                key={module.id}
                idModel={deviceProfile.idModelo}
                module={module}
                getPropertyValue={getPropertyValue}
                handleChangeDeviceProfileProperties={handleChangeDeviceProfileProperties}
                protocols={protocolsCommunication}
              />
              {versionProperties.modulos.length - 1 != index && (
                <div style={{ paddingLeft: '1rem' }}>
                  <DividerLine />
                </div>
              )}
            </>
          ))}
        </BaseForm>
      </PageContainer>
    </>
  );
};
