import styled from 'styled-components';

export const FencePageContainer = styled.div`
  padding: 0.4rem 0rem 0rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
`;

export const CreateFenceContainer = styled.div`
  padding-top: 2.5rem;
  height: 100%;
`;
