import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from '@app/components/common/Modal/Modal';
import { Col, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { notificationController } from '@app/controllers/notificationController';
import { Description } from '@app/components/common/Description/Description';
import { setFooter } from '@app/store/slices/footerSlice';
import { CreateCanVehicleStepOne } from './components/steps/create-step-one';
import { CreateCanVehicleStepThree } from './components/steps/create-step-three';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { ContainerBorderRight } from '@app/components/common/Container/Container.styles';
import React from 'react';
import { CreateCanVehicleParametersTable } from './components/table/CreateCanVehicleParametersTable';
import { CreateCanVehicleStepTwo } from './components/steps/create-step-two';
import IVehicleService, { VehicleService } from '@app/services/vehicleService';
import { VehicleModel } from '@app/domain/vehicle/vehicleModel';
import { objectToFormData } from '@app/utils/utils';

enum StepCreateCanVehicle {
  CanVehicle = 0,
  CanVehicleObs = 1,
  CanVehicleParameters = 2,
}

const vehicleService: IVehicleService = new VehicleService();

export const CanVehicleCreate: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [vehicle, setVehicle] = useState<VehicleModel>({
    status: true,
    tipoInformacao: 0,
    tipoVeiculo: 0,
  } as VehicleModel);
  const [currentStep, setCurrentStep] = useState<StepCreateCanVehicle>(StepCreateCanVehicle.CanVehicle);

  const fetchVehicle = async () => {
    setLoading(true);
    vehicleService
      .get(`${id}`)
      .then((res) => setVehicle(res))
      .catch((error) => notificationController.error(error))
      .finally(() => setLoading(false));
  };

  const handleBackClick = async () => navigate(`/veiculos-can`);
  const handleChangeStep = (step: StepCreateCanVehicle) => setCurrentStep(step);

  const handleSaveVehicle = useCallback(() => {
    setLoading(true);

    const formData = objectToFormData(vehicle);

    [...files].map((f) => formData.append('files', f, f.name));

    if (id) {
      vehicleService.api
        .put('', formData, { headers: { 'Content-Type': 'mulipart/form-data' } })
        .then(() => {
          notificationController.success({ message: 'Sucesso!', description: 'Veículo cadastrado.' });
          handleBackClick();
        })
        .catch((error) => notificationController.error(error))
        .finally(() => setLoading(false));
    } else {
      vehicleService.api
        .post('', formData, { headers: { 'Content-Type': 'mulipart/form-data' } })
        .then(() => {
          notificationController.success({ message: 'Sucesso!', description: 'Veículo cadastrado.' });
          handleBackClick();
        })
        .catch((error) => notificationController.error(error))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle, files]);

  useEffect(() => {
    if (id) fetchVehicle();

    dispatch(
      setHeaderRegister({
        title: !id ? 'Novo Veículo CAN' : 'Editar Veículo CAN',
        handleBackClick: () => setModalCancelVisible(true),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (currentStep) {
      case StepCreateCanVehicle.CanVehicle:
        dispatch(
          setFooter({
            confirmButtonDisabled:
              !vehicle?.nome ||
              !vehicle?.ano ||
              !vehicle?.idVeiculoFabricante ||
              !vehicle?.velocidadeCan ||
              !vehicle?.quantidadeBits,
            confirmButtonText: 'Próxima etapa',
            handleCancelButtonClick: () => setModalCancelVisible(true),
            handleConfirmButtonClick: () => handleChangeStep(StepCreateCanVehicle.CanVehicleObs),
            cancelButtonText: 'Cancelar',
          }),
        );
        break;
      case StepCreateCanVehicle.CanVehicleObs:
        dispatch(
          setFooter({
            confirmButtonDisabled: false,
            confirmButtonText: 'Próxima etapa',
            handleCancelButtonClick: () => setModalCancelVisible(true),
            handleConfirmButtonClick: () => handleChangeStep(StepCreateCanVehicle.CanVehicleParameters),
            cancelButtonText: 'Cancelar',
            tagAButtonText: 'Voltar',
            handleTagAButtonClick: () => setCurrentStep(StepCreateCanVehicle.CanVehicle),
          }),
        );
        break;
      case StepCreateCanVehicle.CanVehicleParameters:
        dispatch(
          setFooter({
            confirmButtonDisabled: !vehicle?.parametros?.length,
            confirmButtonText: 'Finalizar',
            handleCancelButtonClick: () => setModalCancelVisible(true),
            handleConfirmButtonClick: () => handleSaveVehicle(),
            cancelButtonText: 'Cancelar',
            tagAButtonText: 'Voltar',
            handleTagAButtonClick: () => setCurrentStep(StepCreateCanVehicle.CanVehicleObs),
          }),
        );
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle, currentStep]);

  return (
    <>
      <Spinner spinning={loading}></Spinner>
      <Modal
        title={`Cancelar ${!id ? 'cadastro' : 'edição'} veículo CAN`}
        open={modalCancelVisible}
        onOk={handleBackClick}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>
            Deseja realmente <strong>&nbsp;cancelar {!id ? 'o cadastro' : 'a edição'} do veículo CAN?</strong>
          </Col>
        </Row>
      </Modal>

      <Row style={{ height: '100%' }}>
        <Col xs={24} md={12} xl={8}>
          <ContainerBorderRight>
            {
              {
                [StepCreateCanVehicle.CanVehicle]: (
                  <CreateCanVehicleStepOne vehicle={vehicle} setVehicle={setVehicle} />
                ),
                [StepCreateCanVehicle.CanVehicleObs]: (
                  <CreateCanVehicleStepTwo
                    vehicle={vehicle}
                    setVehicle={setVehicle}
                    fileList={files}
                    setFileList={setFiles}
                  />
                ),
                [StepCreateCanVehicle.CanVehicleParameters]: (
                  <CreateCanVehicleStepThree vehicle={vehicle} setVehicle={setVehicle} />
                ),
              }[currentStep]
            }
          </ContainerBorderRight>
        </Col>
        <Col xs={24} md={12} xl={16}>
          <div style={{ marginLeft: '3rem' }}>
            <Description
              title="Parâmetros do veículo"
              subtitle="Após preencher todas as informações, verifique se os itens selecionados estão corretos ."
            >
              <CreateCanVehicleParametersTable vehicle={vehicle} setVehicle={setVehicle} />
            </Description>
          </div>
        </Col>
      </Row>
    </>
  );
};
