import { useEffect, useState } from 'react';
import { Modal } from '@app/components/common/Modal/Modal';
import { Col, Row } from 'antd';
import { Button, ButtonGroup } from '@app/components/common/buttons/Button/Button';
import React from 'react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '@app/components/common/selects/Select/Select';
import { Radio, RadioChangeEvent, RadioGroup } from '@app/components/common/Radio/Radio';
import { Input } from '@app/components/common/inputs/Input/Input';
import { CanVehicleBitsSize, CanVehicleInitialBit } from '@app/constants/canVehicleData';
import { TipoVeiculoParametro, VehicleModel, VehicleParameterLinkedModel } from '@app/domain/vehicle/vehicleModel';
import MaskedInput from 'react-text-mask';

interface ModalEditCanVehicleProps {
  idParameter?: number;
  vehicle: VehicleModel;
  setVehicle: React.Dispatch<React.SetStateAction<VehicleModel>>;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalEditCanVehicle: React.FC<ModalEditCanVehicleProps> = (props) => {
  const { idParameter, vehicle, setVehicle, visible, setVisible } = props;
  const [parameterOnEdit, setParameterOnEdit] = useState<VehicleParameterLinkedModel>();

  const handleSaveParameter = () => {
    setVehicle({
      ...vehicle,
      parametros: vehicle.parametros?.map((p) => (p.id == idParameter && parameterOnEdit ? parameterOnEdit : p)),
    });
    setParameterOnEdit(undefined);
    setVisible(false);
  };

  useEffect(() => {
    if (idParameter) setParameterOnEdit(vehicle.parametros?.find((p) => p.id == idParameter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idParameter]);

  return (
    <>
      <Modal
        title="Editar parâmetros"
        open={visible}
        onOk={handleSaveParameter}
        onCancel={() => setVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Row gutter={6}>
            <Col xs={12}>
              <BaseFormInputItem label="Nome ID" errorText="Campo obrigatório">
                <Input disabled={true} value={parameterOnEdit?.nome} />
              </BaseFormInputItem>
            </Col>
            <Col xs={12}>
              <BaseFormInputItem label=" " errorText="">
                <ButtonGroup>
                  <Button key={parameterOnEdit?.unidadeMedida} type="primary">
                    {parameterOnEdit?.unidadeMedida}
                  </Button>
                </ButtonGroup>
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col xs={24} md={12}>
              <BaseFormInputItem label="Tipo de dado">
                <RadioGroup
                  disabled={true}
                  onChange={(e: RadioChangeEvent) => setParameterOnEdit(e.target.value)}
                  value={parameterOnEdit?.tipo}
                  style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
                >
                  <Radio value={TipoVeiculoParametro.Calculado}>Calculado</Radio>
                  <Radio value={TipoVeiculoParametro.LigadoDesligado}>ON/OFF</Radio>
                </RadioGroup>
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col xs={6}>
              <BaseFormInputItem label="ID" errorText="Campo obrigatório">
                <MaskedInput
                  className="ant-input"
                  mask={() => Array(24).fill(/[0-9]/)}
                  guide={false}
                  type="text"
                  key={1}
                  placeholder="Digite o ID"
                  value={parameterOnEdit?.parametroId}
                  onChange={(event) => setParameterOnEdit({ ...parameterOnEdit, parametroId: event.target.value })}
                />
              </BaseFormInputItem>
            </Col>
            <Col xs={9}>
              <BaseFormInputItem label="Bit inicial" errorText="Campo obrigatório">
                <Select
                  showArrow
                  showSearch
                  placeholder="0 a 63"
                  value={parameterOnEdit?.bitInicial}
                  onChange={(value) => setParameterOnEdit({ ...parameterOnEdit, bitInicial: Number(value as string) })}
                  options={CanVehicleInitialBit.map((c) => ({ value: c, label: `${c}` }))}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </BaseFormInputItem>
            </Col>
            <Col xs={9}>
              <BaseFormInputItem label="Tamanho em bits" errorText="Campo obrigatório">
                <Select
                  showArrow
                  showSearch
                  placeholder="1 a 63"
                  value={parameterOnEdit?.tamanhoBits}
                  onChange={(value) => setParameterOnEdit({ ...parameterOnEdit, tamanhoBits: Number(value as string) })}
                  options={CanVehicleBitsSize.map((c) => ({ value: c, label: `${c}` }))}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
          {parameterOnEdit?.tipo == TipoVeiculoParametro.Calculado && (
            <>
              <Row gutter={6}>
                <Col xs={9}>
                  <BaseFormInputItem label="Offset" errorText="Campo obrigatório">
                    <MaskedInput
                      className="ant-input"
                      mask={() => Array(24).fill(/[0-9.-]/)}
                      guide={false}
                      type="text"
                      key={2}
                      placeholder="Digite o offset"
                      value={parameterOnEdit?.offset}
                      onChange={(event) => setParameterOnEdit({ ...parameterOnEdit, offset: event.target.value })}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={9}>
                  <BaseFormInputItem label="Resolução" errorText="Campo obrigatório">
                    <MaskedInput
                      className="ant-input"
                      mask={() => Array(24).fill(/[0-9.-]/)}
                      guide={false}
                      type="text"
                      key={3}
                      placeholder="Digite a resolução"
                      value={parameterOnEdit?.resolucao}
                      onChange={(event) => setParameterOnEdit({ ...parameterOnEdit, resolucao: event.target.value })}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            </>
          )}
          {parameterOnEdit?.tipo == TipoVeiculoParametro.LigadoDesligado && (
            <>
              <Row gutter={6}>
                <Col xs={6}>
                  <BaseFormInputItem label="Ligado" errorText="Campo obrigatório">
                    <RadioGroup
                      name="ligado"
                      onChange={(e) =>
                        setParameterOnEdit({
                          ...parameterOnEdit,
                          ligado: e.target.value ? 1 : 0,
                          desligado: e.target.value ? 0 : 1,
                        })
                      }
                      value={parameterOnEdit?.ligado === 1 ? true : false}
                      style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
                    >
                      <Radio value={true}>1</Radio>
                      <Radio value={false}>0</Radio>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
                <Col xs={6}>
                  <BaseFormInputItem label="Desligado" errorText="Campo obrigatório">
                    <RadioGroup
                      name="desligado"
                      onChange={(e) =>
                        setParameterOnEdit({
                          ...parameterOnEdit,
                          desligado: e.target.value ? 1 : 0,
                          ligado: e.target.value ? 0 : 1,
                        })
                      }
                      value={parameterOnEdit?.desligado === 1 ? true : false}
                      style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
                    >
                      <Radio value={true}>1</Radio>
                      <Radio value={false}>0</Radio>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
              </Row>
            </>
          )}
        </BaseForm>
      </Modal>
    </>
  );
};
