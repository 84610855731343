import React from 'react';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import * as S from '../ModulesProfileConfig.styles';
import ShouldRenderInput from '@app/components/common/ModulesProfileConfig/ShouldRenderInput';
import { Form } from 'antd';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { PropertyModel } from '@app/domain/property/propertyModel';

interface EntradaProps {
  properties: DeviceProfileProperty[];
}

const EntradaView: React.FC<EntradaProps> = ({ properties }) => {
  const props = properties as PropertyModel[];
  const getPropertyValue = (idEmbarcado: DevicePropertyType) =>
    properties?.find((p) => p.idEmbarcado == idEmbarcado)?.valorPropriedade;

  return (
    <Form layout="vertical">
      <ShouldRenderInput idEmbarcado={DevicePropertyType.EntradaAnalogicaHabilitada} properties={props}>
        <Checkbox
          key={26}
          disabled
          checked={getPropertyValue(DevicePropertyType.EntradaAnalogicaHabilitada) === '1'}
          style={{ width: '13rem' }}
        >
          <S.CheckboxLabel>Habilitar entrada analógica</S.CheckboxLabel>
        </Checkbox>
      </ShouldRenderInput>
    </Form>
  );
};

export default EntradaView;
