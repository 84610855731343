import React, { useCallback, useEffect, useState } from 'react';
import { Description } from '@app/components/common/Description/Description';
import { Col, RadioChangeEvent, Row, DatePicker } from 'antd';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { notificationController } from '@app/controllers/notificationController';
import { Select } from '@app/components/common/selects/Select/Select';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { CardInfoContainer, CardTitle } from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Input } from '@app/components/common/inputs/Input/Input';
import { CanVehicleSpeeds } from '@app/constants/canVehicleSpeed';
import { CanVehicleBits } from '@app/constants/canVehicleBits';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import moment from 'moment';
import { VehicleManufacturerModel, VehicleModel } from '@app/domain/vehicle/vehicleModel';
import IVehicleService, { VehicleService } from '@app/services/vehicleService';

const { RangePicker } = DatePicker;

interface ICreateStepOne {
  vehicle: VehicleModel;
  setVehicle: React.Dispatch<React.SetStateAction<VehicleModel>>;
}

const vehicleService: IVehicleService = new VehicleService();

type YearCache = {
  fromYear: number | null;
  toYear: number | null;
  startOfNextYear: moment.Moment | null;
  endOfNextYear: moment.Moment | null;
  startOfPreviousYear: moment.Moment | null;
  endOfPreviousYear: moment.Moment | null;
  twoYearsFromNow: moment.Moment | null;
};

export const CreateCanVehicleStepOne: React.FC<ICreateStepOne> = ({ vehicle, setVehicle }) => {
  const [manufacturesLoading, setManufacturesLoading] = useState(false);
  const [manufacturers, setManufacturers] = useState<VehicleManufacturerModel[]>([]);

  const fetchManufactures = useCallback(async () => {
    setManufacturesLoading(true);
    vehicleService
      .getManufactures()
      .then((res) => setManufacturers(res))
      .catch((error) => notificationController.error(error))
      .finally(() => setManufacturesLoading(false));
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeYear = (values: any) => {
    if (values) {
      const yearFrom = new Date(values[0]._d).getFullYear();
      const yearTo = new Date(values[1]._d).getFullYear();
      setVehicle({ ...vehicle, ano: `${yearFrom}-${yearTo}` });
    } else {
      setVehicle({ ...vehicle, ano: '' });
    }
  };

  useEffect(() => {
    fetchManufactures();
  }, [fetchManufactures]);

  const disabledDate = ((cache: YearCache) => (current: moment.Moment) => {
    const currentYear = moment().year();
    const twoYearsAhead = currentYear + 2;

    if (current.year() >= twoYearsAhead) return true;

    const arrayYear = vehicle.ano?.split('-');

    const fromYear = arrayYear?.length > 0 && arrayYear[0] ? moment().year(Number(arrayYear[0])) : null;
    const toYear = arrayYear?.length > 0 && arrayYear[1] ? moment().year(Number(arrayYear[1])) : null;

    if (fromYear !== cache.fromYear || toYear !== cache.toYear) {
      cache.fromYear = Number(fromYear);
      cache.toYear = Number(toYear);
      cache.startOfNextYear = fromYear !== null ? fromYear.startOf('year') : null;
      cache.endOfNextYear = fromYear !== null ? fromYear.endOf('year') : null;
      cache.startOfPreviousYear = toYear !== null ? toYear.startOf('year') : null;
      cache.endOfPreviousYear = toYear !== null ? toYear.endOf('year') : null;
    }

    if (fromYear && !toYear) {
      return (
        !current.isBetween(cache.startOfNextYear!, cache.endOfNextYear!, 'day', '[]') &&
        current.year() !== Number(fromYear)
      );
    }

    if (toYear && !fromYear) {
      return (
        !current.isBetween(cache.startOfPreviousYear!, cache.endOfPreviousYear!, 'day', '[]') &&
        current.year() !== Number(toYear)
      );
    }

    if (fromYear && toYear) {
      return !current.isBetween(cache.startOfPreviousYear!, cache.endOfNextYear!, 'day', '[]');
    }

    return false;
  })({
    fromYear: null,
    toYear: null,
    startOfNextYear: null,
    endOfNextYear: null,
    startOfPreviousYear: null,
    endOfPreviousYear: null,
    twoYearsFromNow: null,
  });

  return (
    <>
      <Description title="Etapa 1 de 3" subtitle="Após preencher os campos, clique em próxima etapa." />
      <CardInfoContainer>
        <CardTitle>Informações do veículo</CardTitle>
        <BaseForm layout="vertical" style={{ width: '100%', marginTop: '1rem' }}>
          <Row gutter={6}>
            <Col xs={24} md={12}>
              <BaseFormInputItem label="Nome do veículo" errorText="Campo obrigatório">
                <Input
                  placeholder="Digite o nome do veículo"
                  value={vehicle.nome}
                  onChange={(event) => setVehicle({ ...vehicle, nome: event.currentTarget.value })}
                />
              </BaseFormInputItem>
            </Col>
            <Col xs={24} md={12}>
              <BaseFormInputItem label="Fabricante" errorText="Campo obrigatório">
                <Select
                  showArrow
                  showSearch
                  loading={manufacturesLoading}
                  placeholder="Selecione o fabricante"
                  disabled={!vehicle.nome}
                  value={vehicle.idVeiculoFabricante}
                  onChange={(value) =>
                    setVehicle({ ...vehicle, idVeiculoFabricante: manufacturers.find((m) => m.id === value)?.id ?? 0 })
                  }
                  options={manufacturers.map((m) => ({ value: m.id, label: `${m.nome}` }))}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row gutter={6} style={{ marginTop: '-1.5rem' }}>
            <Col xs={24} md={12}>
              <BaseFormInputItem label="Ano" errorText="Campo obrigatório">
                <RangePicker
                  picker="year"
                  disabledDate={disabledDate}
                  onChange={(values) => handleChangeYear(values)}
                  disabled={!vehicle.idVeiculoFabricante}
                  value={
                    vehicle.ano?.length > 0
                      ? [moment(`01/01/${vehicle.ano.split('-')[0]}`), moment(`01/01/${vehicle.ano.split('-')[1]}`)]
                      : undefined
                  }
                />
              </BaseFormInputItem>
            </Col>
            <Col xs={24} md={12}>
              <BaseFormInputItem label="Velocidade CAN" errorText="Campo obrigatório">
                <Select
                  showArrow
                  showSearch
                  placeholder="Selecione a velocidade"
                  disabled={!vehicle.ano}
                  value={vehicle.velocidadeCan}
                  onChange={(value) =>
                    setVehicle({ ...vehicle, velocidadeCan: CanVehicleSpeeds.find((s) => s == value) })
                  }
                  options={CanVehicleSpeeds.map((m) => ({ value: m, label: `${m}` }))}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col xs={24} md={12}>
              <BaseFormInputItem label="Quantidade de Bits" errorText="Campo obrigatório">
                <Select
                  showArrow
                  showSearch
                  placeholder="Selecione a quantidade de bits"
                  disabled={!vehicle.velocidadeCan}
                  value={vehicle.quantidadeBits}
                  onChange={(value) =>
                    setVehicle({ ...vehicle, quantidadeBits: CanVehicleBits.find((b) => b == value) })
                  }
                  options={CanVehicleBits.map((m) => ({ value: m, label: `${m}` }))}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col xs={12} md={8}>
              <BaseFormInputItem label="Tipo do veículo">
                <RadioGroup
                  name="tipoVeiculo"
                  defaultValue={0}
                  value={vehicle.tipoVeiculo}
                  onChange={(e: RadioChangeEvent) => setVehicle({ ...vehicle, tipoVeiculo: Number(e.target.value) })}
                  style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
                >
                  <Radio value={0}>Identifier</Radio>
                  <Radio value={1}>PGN</Radio>
                </RadioGroup>
              </BaseFormInputItem>
            </Col>
            <Col xs={12} md={8}>
              <BaseFormInputItem label="Tipo da informação">
                <RadioGroup
                  name="tipoInformacao"
                  defaultValue={0}
                  value={vehicle.tipoInformacao}
                  onChange={(e: RadioChangeEvent) => setVehicle({ ...vehicle, tipoInformacao: Number(e.target.value) })}
                  style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
                >
                  <Radio value={0}>MSB</Radio>
                  <Radio value={1}>LSB</Radio>
                </RadioGroup>
              </BaseFormInputItem>
            </Col>
            <Col xs={12} md={8}>
              <BaseFormInputItem label="Status">
                <RadioGroup
                  name="status"
                  defaultValue={true}
                  value={vehicle.status}
                  onChange={(e: RadioChangeEvent) => setVehicle({ ...vehicle, status: e.target.value })}
                  style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
                >
                  <Radio value={true}>Ativo</Radio>
                  <Radio value={false}>Inativo</Radio>
                </RadioGroup>
              </BaseFormInputItem>
            </Col>
          </Row>
        </BaseForm>
      </CardInfoContainer>
    </>
  );
};
