import { FC } from 'react';
import * as S from './styles';
import { CheckOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface CollapseStepHeaderProps {
  finished?: boolean;
  title: string;
  helperTextList?: string[];
  errorDescription?: string;
}

export const CollapseStepHeader: FC<CollapseStepHeaderProps> = ({
  finished,
  title,
  helperTextList,
  errorDescription,
}) => {
  return (
    <Tooltip title={errorDescription}>
      <S.CollapseStepWrapper>
        {finished ? (
          <S.CollapseStepIndicatorFinished>
            <CheckOutlined />
          </S.CollapseStepIndicatorFinished>
        ) : (
          <S.CollapseStepIndicator>
            <S.CollapseStepIndicatorContent />
          </S.CollapseStepIndicator>
        )}
        {title}
        {helperTextList && helperTextList.length > 0 && (
          <Tooltip
            color="white"
            showArrow={false}
            placement="rightTop"
            autoAdjustOverflow
            overlayStyle={{ maxWidth: '30rem' }}
            overlayInnerStyle={{ color: '#545454', border: '1px solid #bababa' }}
            overlay={
              <S.CollapseStepIndicatorHelperList>
                {helperTextList.map((h, i) => (
                  <li key={i}>{h}</li>
                ))}
              </S.CollapseStepIndicatorHelperList>
            }
          >
            <S.CollapseStepIndicatorHelper />
          </Tooltip>
        )}
      </S.CollapseStepWrapper>
    </Tooltip>
  );
};
