export interface VehicleModel {
  id?: number;
  nome: string;
  idVeiculoFabricante: number;
  ano: string;
  status: boolean;
  observacoes: string;
  criadoEm: Date;
  atualizadoEm: Date;
  tipoInformacao: number;
  velocidadeCan?: number;
  quantidadeBits?: number;
  tipoVeiculo: number;
  fabricante: string;
  fabricanteImagemUrl: string;
  arquivos?: VehicleFileModel[];
  arquivosParaDeletar?: number[];
  parametros?: VehicleParameterLinkedModel[];
}

export interface VehicleManufacturerModel {
  id?: number;
  nome: string;
  imageUrl: string;
}

export interface VehicleFileModel {
  id?: number;
  idVeiculo: number;
  nome: string;
  tipo: string;
  url: string;
}

export interface VechicleParameterModel {
  id?: number;
  nome: string;
  unidadeMedida: string;
  idEmbarcado?: number;
  tipo: TipoVeiculoParametro;
}

export interface VehicleParameterLinkedModel {
  id?: number;
  idIdentifierCan?: number;
  idParametro?: number;
  parametroId?: string;
  nome?: string;
  unidadeMedida?: string;
  idEmbarcado?: number;
  tipo?: TipoVeiculoParametro;
  bitInicial?: number;
  tamanhoBits?: number;
  ligado?: number;
  desligado?: number;
  resolucao?: string;
  offset?: string;
}

export enum TipoVeiculoParametro {
  Calculado = 1,
  LigadoDesligado = 2,
}
