import styled from 'styled-components';
import { Switch as AntdSwitch } from 'antd';

export const Switch = styled(AntdSwitch)`
  &.ant-switch[aria-checked='false'] {
    background-image: linear-gradient(to right, var(--disabled-input-color), var(--disabled-input-color)),
      linear-gradient(to right, var(--background-color), var(--background-color));

    & .ant-switch-inner {
      color: var(--text-label);
    }
  }
  &.ant-switch {
    min-width: 2.4rem;
    line-height: 22px;
  }
`;
