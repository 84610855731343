import { FenceGroupModel } from '@app/domain/fence/fenceGroupModel';
import { FenceModel } from '@app/domain/fence/fenceModel';
import { Col, Row } from 'antd';
import { FC, useMemo, useState } from 'react';
import { notificationController } from '@app/controllers/notificationController';
import IFenceService, { FenceService } from '@app/services/fenceService';
import * as S from './styles';
import {
  CardInfoProperty,
  CardInfoTitle,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import { getFenceNameByType } from '../../functions/commonFenceFunctions';
import { TagColor } from '@app/components/common/TagColor/TagColor';
import { MapFenceCreate } from '@app/components/common/FenceMap/map-fence-create';

const fenceService: IFenceService = new FenceService();

interface ViewFenceStepUniqueProps {
  groupFence?: FenceGroupModel;
  setGroupFence: React.Dispatch<React.SetStateAction<FenceGroupModel | undefined>>;
  showInactives: boolean;
}

export const ViewFenceStepUnique: FC<ViewFenceStepUniqueProps> = ({ groupFence, setGroupFence, showInactives }) => {
  const [fence, setFence] = useState<FenceModel>();
  const [showInfo, setShowInfo] = useState<boolean>(true);

  const handleSelectFence = (fence: FenceModel) => {
    setGroupFence((prevState) => ({
      ...prevState,
      cercas: prevState?.cercas?.map((c) =>
        c.nome == fence.nome ? { ...c, selected: c.selected === true ? false : true } : { ...c, selected: false },
      ),
    }));
  };
  const handleInactiveFence = (fence: FenceModel) => {
    fenceService
      .delete(`${fence.id}/ativar-desativar`)
      .then(() => {
        setGroupFence((prevState) => ({
          ...prevState,
          cercas: prevState?.cercas?.map((c) => (c.nome == fence.nome ? { ...c, ativo: !c.ativo } : c)),
        }));
        notificationController.success({
          message: 'Sucesso!',
          description: `Cerca ${fence.nome} ${fence.ativo ? 'inativada' : 'ativada'}.`,
        });
      })
      .catch(() =>
        notificationController.error({
          message: 'Erro!',
          description: `Não foi possível ${fence.ativo ? 'inativar' : 'ativar'} a cerca.`,
        }),
      );
  };

  useMemo(() => setFence(groupFence?.cercas?.find((c) => c.selected)), [groupFence?.cercas]);

  return (
    <Row gutter={8}>
      <Col xs={24} sm={showInfo ? 6 : 0} style={{ height: 'calc(100vh - 21.7rem)', marginTop: '1rem' }}>
        {fence ? (
          <>
            <CardInfoTitle>Informações da cerca:</CardInfoTitle>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row align={'bottom'}>
                  <Col span={12}>
                    <CardInfoValue>Nome</CardInfoValue>
                    <CardInfoProperty>{fence.nome}</CardInfoProperty>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Col>
                        {fence?.ativo === false ? (
                          <TagColor background="#FEE9EA" color="#620E12">
                            Inativo
                          </TagColor>
                        ) : (
                          <TagColor background="#E9F4EE" color="#083F18">
                            {fence?.ativo === true ? 'Ativo' : '-'}
                          </TagColor>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <CardInfoValue>Tipo da cerca</CardInfoValue>
                <Button type={'primary'} shape="round">
                  {getFenceNameByType(fence?.idTipoCerca ?? 1)}
                </Button>
              </Col>
              <Col span={24}>
                <CardInfoValue>Evento Entrada/Saída</CardInfoValue>
                <CardInfoProperty>
                  {
                    {
                      1: 'Entrada',
                      2: 'Saída',
                      3: 'Entrada e Saída',
                      0: 'Não se aplica',
                    }[fence?.eventos?.entradaSaida ?? 0]
                  }
                </CardInfoProperty>
                {fence?.eventos?.entradaSaida ? (
                  <Row style={{ marginTop: '0.5rem' }}>
                    <Col span={24}>
                      <CardInfoValue>Periférico</CardInfoValue>
                      <CardInfoProperty>
                        {fence?.eventos?.entradaSaidaPeriferico == 1
                          ? 'Pulsado'
                          : fence?.eventos?.entradaSaidaPeriferico == 2
                          ? 'Corta corrente'
                          : 'Não se aplica'}
                      </CardInfoProperty>
                      {fence.eventos.entradaSaidaPeriferico === 1 ? (
                        <Row gutter={16}>
                          <Col>
                            <CardInfoValue>Qtd. ciclos</CardInfoValue>
                            <CardInfoProperty>{fence?.eventos?.entradaSaidaCiclos}</CardInfoProperty>
                          </Col>
                          <Col>
                            <CardInfoValue>Tempo ligado(s)</CardInfoValue>
                            <CardInfoProperty>{fence?.eventos?.entradaSaidaTempoLigado} s</CardInfoProperty>
                          </Col>
                          <Col>
                            <CardInfoValue>Tempo Desligado(s)</CardInfoValue>
                            <CardInfoProperty>{fence?.eventos?.entradaSaidaTempoDesligado} s</CardInfoProperty>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </Col>
              <Col span={24}>
                <CardInfoValue>Evento Limite de velocidade</CardInfoValue>
                <CardInfoProperty>{fence?.eventos?.limiteVelocidade ? 'Sim' : 'Não'}</CardInfoProperty>
                {fence?.eventos?.limiteVelocidade ? (
                  <Row style={{ marginTop: '0.5rem' }}>
                    <Col span={24}>
                      <CardInfoValue>Limite velocidade</CardInfoValue>
                      <CardInfoProperty>{fence?.eventos?.limiteVelocidadeValor} km/h</CardInfoProperty>
                      <CardInfoValue>Periférico</CardInfoValue>
                      <CardInfoProperty>
                        {fence?.eventos?.limiteVelocidadePulsado == 1 ? 'Pulsado' : 'Não se aplica'}
                      </CardInfoProperty>
                      {fence.eventos.limiteVelocidadePulsado === 1 ? (
                        <Row gutter={16}>
                          <Col>
                            <CardInfoValue>Qtd. ciclos</CardInfoValue>
                            <CardInfoProperty>{fence?.eventos?.limiteVelocidadeCiclos}</CardInfoProperty>
                          </Col>
                          <Col>
                            <CardInfoValue>Tempo ligado(s)</CardInfoValue>
                            <CardInfoProperty>{fence?.eventos?.limiteVelocidadeTempoLigado} s</CardInfoProperty>
                          </Col>
                          <Col>
                            <CardInfoValue>Tempo Desligado(s)</CardInfoValue>
                            <CardInfoProperty>{fence?.eventos?.limiteVelocidadeTempoDesligado} s</CardInfoProperty>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </>
        ) : (
          <S.FenceViewRow>
            <Col>
              <CardInfoValue>Nenhuma cerca selecionada.</CardInfoValue>
              <CardInfoProperty>Para apresentar as informações da cerca desejada</CardInfoProperty>
              <CardInfoProperty>selecione no mapa ou na tabela de cercas.</CardInfoProperty>
            </Col>
          </S.FenceViewRow>
        )}
      </Col>
      <Col xs={24} sm={showInfo ? 18 : 24} style={{ height: 'calc(100vh - 21.7rem)' }}>
        <S.FenceViewCollapseButton type="primary" onClick={() => setShowInfo(!showInfo)} shape="circle" size="small">
          {!showInfo ? <RightOutlined /> : <LeftOutlined />}
        </S.FenceViewCollapseButton>
        {groupFence?.cercas?.length && (
          <MapFenceCreate
            fences={groupFence.cercas}
            showInactiveFences={showInactives}
            handleActivateInactivateFence={handleInactiveFence}
            handleSelectFence={handleSelectFence}
          />
        )}
      </Col>
    </Row>
  );
};
