import { VechicleParameterModel, VehicleManufacturerModel, VehicleModel } from '@app/domain/vehicle/vehicleModel';
import { ApiServiceBase, IApiServiceBase } from '@app/services/base/apiServiceBase';

export default interface IVehicleService extends IApiServiceBase<VehicleModel, VehicleModel> {
  getManufactures(): Promise<VehicleManufacturerModel[]>;
  getParameters(): Promise<VechicleParameterModel[]>;
}

export class VehicleService extends ApiServiceBase<VehicleModel, VehicleModel> implements IVehicleService {
  constructor() {
    super('Veiculo');
  }

  async getManufactures(): Promise<VehicleManufacturerModel[]> {
    return (await this.api.get(`fabricantes`))?.data;
  }
  async getParameters(): Promise<VechicleParameterModel[]> {
    return (await this.api.get(`parametros`))?.data;
  }
}
