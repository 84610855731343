import { FenceType } from '@app/constants/enums/fence/fence-type-enum';
import { FenceModel } from '@app/domain/fence/fenceModel';
import { Circle, Marker, Polygon, Polyline } from '@react-google-maps/api';
import { FC, useEffect, useState } from 'react';

interface MapFenceTutorialProps {
  show: boolean;
  fenceType?: FenceType;
  mapRef?: React.MutableRefObject<google.maps.Map | null>;
}

export const MapFenceTutorial: FC<MapFenceTutorialProps> = ({ show, fenceType, mapRef }) => {
  const [fence, setFence] = useState<FenceModel>();
  const [animationInterval, setAnimationInterval] = useState<NodeJS.Timeout>();

  const handleDrawCircle = () => {
    const currentCenter = mapRef?.current?.getCenter();

    if (!currentCenter) return;

    const mapCenter = {
      lat: currentCenter.lat(),
      lng: currentCenter.lng(),
    };

    let index = 0;
    const interval = setInterval(() => {
      switch (index) {
        case 0:
          setFence((prev) => ({ ...prev, pontos: [...(prev?.pontos ?? []), mapCenter] }));
          index += 1;
          break;
        case 1:
          setFence((prev) => ({ ...prev, raio: 500 }) as FenceModel);
          index += 1;
          break;
        case 2:
          setFence((prev) => ({ ...prev, raio: undefined, pontos: [] }));
          index = 0;
          break;
      }
    }, 500);

    setAnimationInterval(interval);
    return () => clearInterval(interval);
  };
  const handleDrawRoute = () => {
    const currentCenter = mapRef?.current?.getCenter();

    if (!currentCenter) return;

    const mapCenter = {
      lat: currentCenter.lat(),
      lng: currentCenter.lng(),
    };

    const routeVertices = [
      { lat: mapCenter.lat + 0.005, lng: mapCenter.lng - 0.005 },
      { lat: mapCenter.lat - 0.003, lng: mapCenter.lng - 0.004 },
      { lat: mapCenter.lat + 0.004, lng: mapCenter.lng + 0.005 },
    ];

    let index = 0;
    const interval = setInterval(() => {
      const coord = routeVertices[index];

      if (!coord) {
        setFence((prev) => ({ ...prev, pontos: [] }));
        index = 0;
        return;
      }

      setFence((prev) => ({ ...prev, pontos: [...(prev?.pontos ?? []), coord] }));
      index += 1;
    }, 1000);

    setAnimationInterval(interval);
    return () => clearInterval(interval);
  };
  const handleDrawPolygn = () => {
    const currentCenter = mapRef?.current?.getCenter();

    if (!currentCenter) return;

    const mapCenter = {
      lat: currentCenter.lat(),
      lng: currentCenter.lng(),
    };

    const triangleVertices = [
      { lat: mapCenter.lat + 0.01, lng: mapCenter.lng - 0.01 },
      { lat: mapCenter.lat - 0.01, lng: mapCenter.lng - 0.01 },
      { lat: mapCenter.lat, lng: mapCenter.lng + 0.01 },
    ];

    let index = 0;
    const interval = setInterval(() => {
      const coord = triangleVertices[index];

      if (!coord) {
        setFence((prev) => ({ ...prev, pontos: [] }));
        index = 0;
        return;
      }

      setFence((prev) => ({ ...prev, pontos: [...(prev?.pontos ?? []), coord] }));
      index += 1;
    }, 1000);

    setAnimationInterval(interval);
    return () => clearInterval(interval);
  };

  useEffect(() => {
    if (animationInterval) {
      clearInterval(animationInterval);
      setAnimationInterval(undefined);
    }

    if (!show) return;

    switch (fenceType) {
      case FenceType.Poligono:
        handleDrawPolygn();
        break;
      case FenceType.PontoRaio:
        handleDrawCircle();
        break;
      case FenceType.Rota:
        handleDrawRoute();
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, fenceType]);

  return !show ? (
    <></>
  ) : (
    <>
      {
        {
          [FenceType.Poligono]: (
            <Polygon
              path={fence?.pontos}
              options={{
                fillColor: '#a0e99b',
                strokeColor: '#12720e',
                fillOpacity: 0.5,
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
          ),
          [FenceType.Rota]: (
            <>
              <Polyline
                path={fence?.pontos}
                options={{
                  strokeOpacity: 1,
                  strokeColor: '#12720e',
                  strokeWeight: 1,
                }}
              />
            </>
          ),
          [FenceType.PontoRaio]: (
            <>
              {fence?.raio && (
                <Circle
                  center={fence?.pontos[0]}
                  radius={fence?.raio}
                  options={{
                    fillColor: '#a0e99b',
                    strokeColor: '#12720e',
                    fillOpacity: 0.5,
                    strokeOpacity: 1,
                    strokeWeight: 2,
                  }}
                />
              )}
            </>
          ),
        }[fenceType ?? 1]
      }
      {fence?.pontos.map((p, i) => (
        <Marker
          key={i}
          position={p}
          icon={{
            path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0',
            fillColor: '#12720e',
            strokeColor: '#12720e',
            fillOpacity: 1.0,
            scale: 1,
            strokeWeight: 1,
          }}
        />
      ))}
    </>
  );
};
