import React, { useState } from 'react';
import { Description } from '@app/components/common/Description/Description';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { notificationController } from '@app/controllers/notificationController';
import CollapseCard from '@app/components/common/CollapseCard/CollapseCard';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';
import { IconClose } from '@app/assets/slump-icons';
import { ReactComponent as IconArrowUpload } from '@app/assets/icons/upload.svg';
import { DividerLine } from '@app/components/common/divider/DividerLine.styles';
import FilePdfOutlined from '@ant-design/icons/lib/icons/FilePdfOutlined';
import { Popover } from '@app/components/common/Popover/Popover';
import { Input, TextArea } from '@app/components/common/inputs/Input/Input';
import * as S from './styles/CreateStepTwo.styles';
import { Modal } from '@app/components/common/Modal/Modal';
import CloseCircleOutlined from '@ant-design/icons/lib/icons/CloseCircleOutlined';
import { VehicleModel } from '@app/domain/vehicle/vehicleModel';

interface ICreateStepTwo {
  vehicle: VehicleModel;
  setVehicle: React.Dispatch<React.SetStateAction<VehicleModel>>;
  fileList: File[];
  setFileList: React.Dispatch<React.SetStateAction<File[]>>;
}

export const CreateCanVehicleStepTwo: React.FC<ICreateStepTwo> = (props) => {
  const { vehicle, setVehicle, fileList, setFileList } = props;
  const [idToDelete, setIdToDelete] = useState<number>();
  const [actualSize, setActualSize] = useState<number>(0);
  const [modalRemove, setModalRemove] = useState<boolean>(false);

  const handleChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files !== null) {
      let sum = 0;
      let sameFiles = 0;
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        sum += element.size;
        if (fileList.find((f) => f.name === files[index].name)) sameFiles++;
        if (vehicle.arquivos && vehicle.arquivos.find((f) => f.nome === files[index].name)) sameFiles++;
      }

      fileList.forEach((f) => (sum += f.size));

      const mb = sum / 1000000;

      if (mb > 50) {
        notificationController.error({ message: 'O tamanho máximo permitido para upload é de 50mb.' });
        return;
      }

      if (sameFiles > 0) {
        notificationController.error({ message: 'Ja existe um arquivo com mesmo nome na lista.' });
        return;
      }

      setActualSize(mb);
      setFileList([...fileList, ...(e.target.files as unknown as File[])]);
    }
  };

  const handleRemoveFile = () => {
    idToDelete && setVehicle({ ...vehicle, arquivosParaDeletar: [...(vehicle.arquivosParaDeletar ?? []), idToDelete] });
  };

  return (
    <>
      <Modal
        title="Excluir arquivo"
        open={modalRemove}
        onOk={() => handleRemoveFile()}
        onCancel={() => setModalRemove(false)}
        cancelText="Não"
        okText="Sim"
      >
        <CloseCircleOutlined style={{ color: 'var(--error-color)', marginRight: '1rem' }} />
        Deseja realmente excluir o arquivo selecionado?
      </Modal>
      <Description title="Etapa 2 de 3" subtitle="Após preencher os campos, clique em próxima etapa." />
      <BaseForm layout="vertical" style={{ width: '100%' }}>
        <CollapseCard>
          <S.CustomPanel header="Adicionar" key="1">
            <S.FileContainer>
              <section style={{ width: '29rem', marginBottom: '1rem' }}>
                <HelperNotification>Adicione arquivos no formato pdf ou doc. (Opcional)</HelperNotification>
              </section>

              <div>
                <S.FileUploadLabelButton htmlFor="file-upload" className="custom-file-upload">
                  <IconArrowUpload />
                  Adicionar arquivo
                </S.FileUploadLabelButton>
                <Input
                  type="file"
                  id="file-upload"
                  style={{ display: 'none' }}
                  accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,application/pdf"
                  multiple={true}
                  onChange={handleChangeFiles}
                />
              </div>

              <section style={{ marginTop: '1rem' }}>
                <p style={{ color: '#8C8C8C', fontSize: '1rem' }}>
                  O limite do tamanho total de um ou mais arquivos é de 50 MB.
                </p>
                <DividerLine />
              </section>
              <S.FileUploadCardContainer>
                {fileList.map((file: File) => (
                  <S.FileUploadCard key={file.name}>
                    <FilePdfOutlined size={40} />
                    <Popover content={file.name}>
                      <S.FileNameText>{file.name}</S.FileNameText>
                    </Popover>
                    <span
                      style={{ marginTop: '0.2rem', cursor: 'pointer' }}
                      onClick={() => setFileList(fileList.filter((f) => f.name !== file.name))}
                    >
                      <IconClose />
                    </span>
                  </S.FileUploadCard>
                ))}
              </S.FileUploadCardContainer>

              {vehicle.arquivos && vehicle.arquivos.length > 0 && (
                <S.FileUploadCardContainer>
                  {vehicle.arquivos?.map((file) => (
                    <S.FileUploadCard key={file.nome}>
                      <FilePdfOutlined size={40} />
                      <Popover content={file.nome}>
                        <S.FileNameText>{file.nome}</S.FileNameText>
                      </Popover>
                      <span
                        style={{ marginTop: '0.2rem', cursor: 'pointer' }}
                        onClick={() => {
                          setIdToDelete(file.id);
                          setModalRemove(true);
                        }}
                      >
                        <IconClose />
                      </span>
                    </S.FileUploadCard>
                  ))}
                </S.FileUploadCardContainer>
              )}

              <S.FileSizeContainer>
                <p>{actualSize.toFixed(2)} / 50 MB</p>
              </S.FileSizeContainer>
            </S.FileContainer>
          </S.CustomPanel>

          <S.CustomPanel header="Observações do modelo do veículo" key="2">
            <BaseFormInputItem supportText="Opcional" label="Observações" style={{ margin: '0 0.5rem' }}>
              <TextArea
                showCount={true}
                maxLength={700}
                style={{ height: '29rem', resize: 'none' }}
                value={vehicle.observacoes}
                onChange={(e) => setVehicle({ ...vehicle, observacoes: e.target.value })}
              />
            </BaseFormInputItem>
          </S.CustomPanel>
        </CollapseCard>
      </BaseForm>
    </>
  );
};
