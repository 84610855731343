import { BaseForm } from '../../forms/BaseForm/BaseForm';
import { Col, Row } from 'antd';
import { BaseFormInputItem } from '../../forms/components/BaseFormInputItem/BaseFormInputItem';
import { Checkbox } from '../../Checkbox/Checkbox';
import { Select } from '../../selects/Select/Select';
import { useCallback, useEffect, useState } from 'react';
import IFenceService, { FenceService } from '@app/services/fenceService';
import { FenceGroupModel } from '@app/domain/fence/fenceGroupModel';
import { FenceModel } from '@app/domain/fence/fenceModel';
import { notificationController } from '@app/controllers/notificationController';
import { Button } from '../../buttons/Button/Button';
import { IconMap } from '@app/assets/slump-icons';
import { Panel } from '../../Collapse/Collapse';
import * as S from './Cerca.styles';
import { groupBy } from 'lodash';
import { PropertyModel } from '@app/domain/property/propertyModel';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { MapFenceView } from '../../FenceMap/map-fence-view';

const fenceService: IFenceService = new FenceService();

interface CercaProps {
  idModel?: number;
  properties: PropertyModel[];
  handleChangeDeviceProfileProperties: (properties: DeviceProfileProperty[]) => void;
}

export const Cerca: React.FC<CercaProps> = ({ idModel, properties, handleChangeDeviceProfileProperties }) => {
  const [idGroupFences, setIdGroupFences] = useState<number>();
  const [enableGroup, setEnableGroup] = useState<boolean>(false);
  const [enableViewMap, setEnableViewMap] = useState<boolean>(false);
  const [groups, setGroups] = useState<FenceGroupModel[]>([]);
  const [fences, setFences] = useState<FenceModel[]>([]);

  const fetchGroups = useCallback(() => {
    fenceService
      .getArray(`/grupos-por-modelo/${idModel}`)
      .then((res: FenceGroupModel[]) => setGroups(res.filter((g) => g.ativo)))
      .catch(() => notificationController.error({ message: `Erro ao obter cercas.` }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchGroups(), []);
  useEffect(() => setIdGroupFences(undefined), [enableGroup]);
  useEffect(() => {
    const idGroupProp = properties.find((p) => p.idEmbarcado == DevicePropertyType.IdGrupoCercas);

    if (idGroupProp)
      handleChangeDeviceProfileProperties([
        { ...idGroupProp, valorPropriedade: idGroupFences ? `${idGroupFences}` : '' },
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idGroupFences]);
  useEffect(() => {
    if (idGroupFences)
      fenceService
        .get(`/grupos/${idGroupFences}`)
        .then((response) => setFences(response.cercas ?? []))
        .catch((err) => notificationController.error({ message: `Erro ao buscar cerca. ${err}` }));
  }, [idGroupFences]);

  return (
    <BaseForm layout="vertical">
      <Row>
        <BaseFormInputItem>
          <Checkbox checked={enableGroup} onChange={() => setEnableGroup(!enableGroup)}>
            Adicionar grupo
          </Checkbox>
        </BaseFormInputItem>
      </Row>
      <Row gutter={18}>
        <Col xs={24} md={5}>
          <BaseFormInputItem label="Grupo de cercas" errorText="Campo obrigatório">
            <Select
              showArrow
              showSearch
              disabled={!enableGroup}
              value={idGroupFences}
              placeholder="Selecione o grupo"
              onChange={(value) => setIdGroupFences(Number(value))}
              options={groups.map((g) => ({ value: g.id, label: g.nome }))}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
            ></Select>
          </BaseFormInputItem>
        </Col>
      </Row>
      {idGroupFences && (
        <Row gutter={[18, 18]}>
          <Col xs={24}>
            <Row style={{ marginBottom: '1rem' }}>{enableViewMap && <MapFenceView fences={fences} />}</Row>
            <S.CollapseMap>
              <Panel
                key="1"
                header={
                  <Row align={'middle'} justify={'space-between'}>
                    <Col>
                      <S.CollapseHeaderName>Cercas do grupo</S.CollapseHeaderName>
                    </Col>
                    <Button type="ghost" icon={<IconMap />} onClick={() => setEnableViewMap(!enableViewMap)}>
                      Visualizar mapa
                    </Button>
                  </Row>
                }
              >
                {Object.keys(groupBy(fences, (f) => f.tipoCerca)).map((f) => {
                  const fencesFiltered = fences.filter((fence) => fence.tipoCerca == f);
                  return (
                    <div key={f} style={{ paddingBottom: '1rem' }}>
                      <Row>
                        <S.TableTypeName>{f}</S.TableTypeName>
                      </Row>
                      <Row>
                        <S.TableFenceQuantity>{fencesFiltered?.length}</S.TableFenceQuantity>&nbsp;
                        <S.TableFenceDescription>
                          {fencesFiltered?.length > 1 ? 'Cercas' : 'Cerca'}
                        </S.TableFenceDescription>
                      </Row>
                      <Row gutter={[18, 18]} style={{ overflowY: 'auto', maxHeight: '30rem', width: '100%' }}>
                        {fencesFiltered?.map((item, index) => {
                          return (
                            <Col key={index} xs={12} xl={6}>
                              <S.TableFenceName>{item.nome}</S.TableFenceName>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  );
                })}
              </Panel>
            </S.CollapseMap>
          </Col>
        </Row>
      )}
    </BaseForm>
  );
};
