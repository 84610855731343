export enum DevicePropertyType {
  NumeroSerie = 0,
  IntervaloColetaGps = 1,
  IntervaloEnvioCarroLigado = 2,
  IntervaloEnvioBateriaExterna = 3,
  IntervaloEnvioBateriaInterna = 4,
  IntervaloColetaInfoCan = 5,
  IoPosChave = 6,
  Apn = 7,
  UsuarioApn = 8,
  SenhaApn = 9,
  Imei = 10,
  TipoProtocolo = 11,
  PortaServidor = 12,
  IpServidor = 13,
  ConnectionString = 14,
  DadosValidos = 15,
  EventoLimiteVelocidade = 16,
  LimiteVelocidade = 17,
  EventoLimiteRpm = 18,
  LimiteRpmFaixaAmarela = 19,
  LimiteRpmFaixaVermelha = 20,
  EventoEstadoIgnicao = 21,
  RedeCanHabilitada = 22,
  SensibilidadeDeteccaoAceleracaoBrusca = 23,
  EntradaDigitalHabilitada = 24,
  EventoEstadoEntradaDigital = 25,
  EntradaAnalogicaHabilitada = 26,
  EventoLimiteEntradaAnalogica = 27,
  LimiteEntradaAnalogica = 28,
  ValorHorimetro = 29,
  ValorOdometro = 30,
  AdicionarCerca = 31,
  RemoverCerca = 32,
  CercasSalvas = 33,
  CortaCorrente = 34,
  BitrateCan = 35,
  AdicionarIdentifierCan = 36,
  IdentifierCanSalvos = 37,
  NomeDispositivo = 38,
  TesteFabrica = 39,
  RemoverTodosIdentifiersCanSalvos = 40,
  ModeloVeiculoCan = 41,
  ModeloDispositivo = 42,
  Dns = 43,
  IpServidorSecundario = 44,
  DnsSecundario = 45,
  PortaServidorSecundario = 46,
  TipoProtocoloSecundario = 47,
  EventoEnvioDistancia = 48,
  LimiteDistancia = 49,
  EventoEnvioAngulo = 50,
  LimiteAngulo = 51,
  EventoDeteccaoFrenagemBrusca = 52,
  EventoDeteccaoAceleracaoBrusca = 53,
  EventoDeteccaoCurvaAcentuada = 54,
  EventoDeteccaoModoBateriaInterna = 55,
  EventoMensagemRS232 = 56,
  EventoDeteccaoBateriaInternaBaixa = 57,
  LimiteBateriaBaixa = 58,
  EventoDeteccaoMovimentoIgnicaoDesligada = 59,
  SensibilidadeDeteccaoFrenagemBrusca = 60,
  SensibilidadeDeteccaoCurvaAcentuada = 61,
  LimpaCalibracaoAcelerometro = 62,
  VersaoFirmware = 63,
  IdGrupoCercas = 64,
  IMSI = 65,
  ICCID = 66,
  ApelidoDispositivo = 67,
  //Identificador de Motorista
  TipoEnvio = 68,
  ParametrosEnvio = 69,
  ProtocoloComunicacaoRastreador = 70,
  TipoBloqueio = 71,
  TipoIdentificacao = 72,
  PacoteLogout = 73,
  AcaoDesligarVeiculo = 74,
  DelayIgnicaoDesligada = 75,
  DelayIgnicaoLigada = 76,
  AcaoBloqueioTemporizado = 77,
  SalvarTag = 78,
  ExcluirTodasTags = 79,
  EstadoBuzzer = 80,
  ListaTags = 81,
  ModoLeituraTags = 82,
  VelocidadeTTL = 83,
  VelocidadeRS232 = 84,
  MensagemRecebidaSerial = 85,

  // TODO: Confirmar IdEmbarcado
  // TempoSopro = 990,
  // PressaoDeteccao = 991,
  // ConcentracaoAdmissivel = 992,
  // PercentualTestesAleatorios = 993,
  // TempoSolicitarTeste = 994,
  // TempoRealizarTeste = 995,
  IdVeiculoCan = 999,
}
